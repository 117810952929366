import { Component, HostBinding, HostListener, Input } from '@angular/core';
import { ChipCoreComponent } from './../chip-core.component';

@Component({
  selector: 'campus-filter-chip',
  templateUrl: './filter-chip.component.html',
})
export class FilterChipComponent extends ChipCoreComponent {
  ripple = false;

  @HostBinding('class')
  defaultClasses = [
    'filter-chip',
    ...this.coreClasses,

    'selected:border-secondary',
    'selected:secondary-container',
    'cursor-pointer',
    'selected:cursor-default',
  ];

  @HostBinding('class.selected')
  @HostBinding('attr.aria-selected')
  @HostBinding('attr.data-has-leading-icon')
  @Input()
  selected: boolean;

  ariaLabelRemove = 'Verwijder ' + this.ariaLabel || this.label;

  @HostListener('click')
  onClick() {
    if (this.disabled) return;
    this.selected = !this.selected;
  }
}
