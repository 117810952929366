import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { ManageCollectionItemInterface } from '../../manage-collection/interfaces/manage-collection-item.interface';
import { ManageCollectionEventInterface } from '../interfaces/manage-collection-event.interface';

export const COLLECTION_MANAGER_SERVICE_TOKEN = new InjectionToken('CollectionManagerService');

export interface CollectionManagerServiceInterface {
  manageCollections(
    title: string,
    items: ManageCollectionItemInterface[],
    linkableItems: ManageCollectionItemInterface[],
    linkedItemIds: number[],
    recentItemIds: number[],
    collectionType: string, // e.g. 'taken' or 'bundels',
    subtitle?: string
  ): ManageCollectionEventInterface;

  manageCollectionsDynamic(
    title: string,
    items: ManageCollectionItemInterface[],
    linkableItems$: Observable<ManageCollectionItemInterface[]>,
    linkedItemIds$: Observable<number[]>,
    recentItemIds$: Observable<number[]>,
    collectionType: string,
    subtitle: string,
    createCollectionLabel: string,
    context?: string
  ): ManageCollectionEventInterface;
}
