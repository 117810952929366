import { EventManager } from '@angular/platform-browser';
import { EMPTY } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

/**
 * This plugin adds support for HostBinding to streams
 *
 * use: (or any variation)
 * @HostBinding('$.class.whatever')
 * @HostListener('$.class.whatever')
 * whatever$: Observable<boolean>;
 *
 * @HostBinding('$.data-theme.attr')
 * @HostListener('$.data-theme.attr')
 * theme$: Observable<string>;
 *
 * @HostBinding('$.style.height.px')
 * @HostListener('$.style.height.px')
 * height$: Observable<number>;
 */
export class BindEventPlugin {
  manager!: EventManager;

  supports(event: string): boolean {
    return event.startsWith('$.');
  }

  addEventListener(element: HTMLElement, event: string): () => void {
    element[event] = EMPTY;

    const method = this.getMethod(element, event);
    const sub = this.manager
      .getZone()
      .onStable.pipe(
        take(1),
        switchMap(() => element[event])
      )
      .subscribe((value) => method(value));

    return () => sub.unsubscribe();
  }

  private getMethod(element: HTMLElement, event: string): (v: any) => void {
    const [, key, value, unit = ''] = event.split('.');

    if (event.endsWith('.attr')) {
      return (v) => (v === undefined ? element.removeAttribute(key) : element.setAttribute(key, String(v)));
    }

    if (key === 'class') {
      return (v) => element.classList.toggle(value, !!v);
    }

    if (key === 'style') {
      return (v) => element.style.setProperty(value, `${v}${unit}`);
    }

    return (v) => (element[key] = v);
  }
}
