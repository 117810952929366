import { InjectionToken, Injector } from '@angular/core';
import { AuthAdapterInterface } from '../../interfaces';
import { ACMAdapter } from './adapters/acm.adapter';
import { BasicAuthAdapter } from './adapters/basic.adapter';
import { FacebookAdapter } from './adapters/facebook.adapter';
import { GoogleAdapter } from './adapters/google.adapter';
import { LeerIdAdapter } from './adapters/leerId.adapter';
import { MicrosoftAdapter } from './adapters/microsoft.adapter';
import { SmartschoolAdapter } from './adapters/smartschool.adapter';

export class AdapterFactory {
  static createAdapter(type: string | InjectionToken<AuthAdapterInterface>, injector: Injector): AuthAdapterInterface {
    if(typeof type === 'string') {
      switch (type) {
        case 'basic':
          return injector.get(BasicAuthAdapter);
        case 'acm':
          return injector.get(ACMAdapter);
        case 'smartschool':
          return injector.get(SmartschoolAdapter);
        case 'google':
          return injector.get(GoogleAdapter);
        case 'facebook':
          return injector.get(FacebookAdapter);
        case 'microsoft':
          return injector.get(MicrosoftAdapter);
        case 'leerId':
          return injector.get(LeerIdAdapter);
        default:
          throw new Error(`Adapter type ${type} not supported`);
      }
    } else {
      return injector.get(type);
    }
  }
}
