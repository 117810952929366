import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NAME } from './portal.reducer';
import { PortalState } from './portal.state';

export const selectPortalState = createFeatureSelector<PortalState>(NAME);

export const selectPortalMode = createSelector(selectPortalState, (state: PortalState) => state.mode);
export const selectPortalRole = createSelector(selectPortalState, (state: PortalState) => state.role);
export const selectLoginForm = createSelector(selectPortalState, (state: PortalState) => state.loginForm);
export const loginFormIsOpen = createSelector(selectLoginForm, (state) => state.isOpen);
