import { animate, animation, AnimationReferenceMetadata, keyframes, style } from '@angular/animations';

import { EASE_STANDARD, EASE_ZOOM, TIMING_DEFAULT } from './constants';

export const zoomIn = animation(
  [
    animate(
      '{{ timing }}ms {{ delay }}ms {{ ease }}',
      keyframes([
        style({
          opacity: 0,
          transform: 'scale3d(.3, .3, .3)',
          offset: 0,
        }),
        style({
          opacity: 1,
          transform: 'scale3d(1, 1, 1)',
          offset: 0.5,
        }),
      ])
    ),
  ],
  {
    params: { timing: TIMING_DEFAULT, ease: EASE_STANDARD, delay: 0 },
  }
);

export function zoomInY(a: string, b: string): AnimationReferenceMetadata {
  return animation(
    animate(
      '{{ timing }}ms {{ delay }}ms {{ ease }}',
      keyframes([
        style({
          opacity: 0,
          transform: `scale3d(.1, .1, .1) translate3d(0, {{ a }}, 0)`,
          offset: 0,
        }),
        style({
          opacity: 1,
          transform: `scale3d(.475, .475, .475) translate3d(0, {{ b }}, 0)`,
          offset: 0.6,
        }),
      ])
    ),
    { params: { timing: TIMING_DEFAULT, ease: EASE_ZOOM, delay: 0, a, b } }
  );
}

export function zoomInX(a: string, b: string): AnimationReferenceMetadata {
  return animation(
    animate(
      '{{ timing }}ms {{ delay }}ms {{ ease }}',
      keyframes([
        style({
          opacity: 0,
          transform: `scale3d(.1, .1, .1) translate3d({{ a }}, 0, 0)`,
          offset: 0,
        }),
        style({
          opacity: 1,
          transform: `scale3d(.475, .475, .475) translate3d({{ b }}, 0, 0)`,
          offset: 0.6,
        }),
      ])
    ),
    { params: { timing: TIMING_DEFAULT, ease: EASE_ZOOM, delay: 0, a, b } }
  );
}

export const zoomInDown = zoomInY('-1000px', '10px');
export const zoomInUp = zoomInY('1000px', '-10px');
export const zoomInLeft = zoomInX('-1000px', '10px');
export const zoomInRight = zoomInX('1000px', '-10px');

export const zoomOut = animation(
  [
    animate(
      '{{ timing }}ms {{ delay }}ms {{ ease }}',
      keyframes([
        style({
          opacity: 1,
          offset: 0,
        }),
        style({
          opacity: 0,
          transform: 'scale3d(.3, .3, .3)',
          offset: 0.5,
        }),
        style({
          opacity: 0,
          offset: 1,
        }),
      ])
    ),
  ],
  {
    params: { timing: TIMING_DEFAULT, ease: EASE_STANDARD, delay: 0 },
  }
);

export function zoomOutY(a: string, b: string): AnimationReferenceMetadata {
  return animation(
    animate(
      '{{ timing }}ms {{ delay }}ms {{ ease }}',
      keyframes([
        style({
          opacity: 1,
          transform: `scale3d(.475, .475, .475) translate3d(0, {{ a }}, 0)`,
          offset: 0.4,
        }),
        style({
          opacity: 0,
          transform: `scale3d(.1, .1, .1) translate3d(0, {{ b }}, 0)`,
          offset: 1,
        }),
      ])
    ),
    { params: { timing: TIMING_DEFAULT, ease: EASE_STANDARD, delay: 0, a, b } }
  );
}

export function zoomOutX(a: string, b: string): AnimationReferenceMetadata {
  return animation(
    animate(
      '{{ timing }}ms {{ delay }}ms {{ ease }}',
      keyframes([
        style({
          opacity: 1,
          transform: `scale3d(.475, .475, .475) translate3d({{ a }}, 0, 0)`,
          offset: 0.4,
        }),
        style({
          opacity: 0,
          transform: `scale3d(.1, .1, .1) translate3d({{ b }}, 0, 0)`,
          offset: 1,
        }),
      ])
    ),
    { params: { timing: TIMING_DEFAULT, ease: EASE_ZOOM, delay: 0, a, b } }
  );
}

export const zoomOutDown = zoomOutY('-60px', '2000px');
export const zoomOutUp = zoomOutY('60px', '-2000px');
export const zoomOutLeft = zoomOutX('42px', '-2000px');
export const zoomOutRight = zoomOutX('-42px', '2000px');
