import { inject, Injectable } from '@angular/core';
import { AuthFacade } from '@campus/public-portal';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { RedirectService } from '../services/redirect.service';

@Injectable({
  providedIn: 'root',
})
export class RedirectIfAuthenticatedGuard {
  private authFacade = inject(AuthFacade);
  private redirectService = inject(RedirectService);

  canActivate(): Observable<boolean> {
    return this.authFacade.getCurrent().pipe(
      take(1),
      map((userState) => {
        if (userState) {
          this.redirectService.toPlatform(userState);
          return false;
        }
        return true;
      })
    );
  }
}

export function redirectIfAuthenticated() {
  return inject(RedirectIfAuthenticatedGuard).canActivate();
}
