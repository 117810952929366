/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { ContactFormEntryInterface } from '../interfaces';
import { ModelApiBase } from '../model-api.base';
import { PolpoApi } from '../polpo-api';

@Injectable({ providedIn: 'root' })
export class ContactFormEntryApi extends ModelApiBase {
  constructor(api: PolpoApi) {
    super(api, 'ContactFormEntries');
  }

  create(data: ContactFormEntryInterface): Observable<ContactFormEntryInterface> {
    const path = '';
    return this.post(path, undefined, data);
  }
}
