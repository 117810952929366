import { Params, RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';
import { MemoizedSelector, MemoizedSelectorWithProps } from '@ngrx/store';

export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams?: Params;
  data?: RouteDataInterface;
  routeParts?: RouterStateUrl[];
  urlParts?: string[];
}

export class CustomSerializer implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot) {
    let currentRoute = routerState.root;
    const routes = [];
    const {
      url,
      root: { queryParams },
    } = routerState;

    do {
      routes.push({
        url: currentRoute.url[0] ? currentRoute.url[0].path : '',
        params: currentRoute.params,
        data: currentRoute.data,
      });
      currentRoute = currentRoute.firstChild;
    } while (currentRoute);

    return {
      url,
      params: routes[routes.length - 1].params,
      queryParams,
      routeParts: routes,
    };
  }
}

export interface RouteDataSelectorInterface {
  /**
   * The store selector to use for fetching the data to be used in the breadcrumbs.
   *
   * The data returned by the selector can be anything. For objects, a specific property can
   * be displayed using `displayProperty`.
   */
  selector: MemoizedSelectorWithProps<any, any, any> | ((props: { id: number }) => MemoizedSelector<any, any>);
  /**
   * The parameter from the current route to use in `selector`.
   *
   * For instance, in the route `/student/:personId`, `selectorParamName` could be `personId`.
   */
  selectorParamName?: string;
  /**
   * The property of the data used in `selector` to actually display.
   */
  displayProperty?: string;

  /**
   * Return the data and let the function return the desired string.
   */
  parseData?: (data: any) => string;
}

export interface HeaderDetailsRouteDataInterface {
  title: RouteDataSelectorInterface | string;
  icon?: string;
  navigateBack?: boolean | string[];
  logo?: RouteDataSelectorInterface | string;
  message?: RouteDataSelectorInterface | string;
  usetifulTag?: string;
  showPageNavigationIcon?: boolean;
}
export interface RouteDataInterface {
  /* Breadcrumbs */

  /**
   * The literal text to display in the breadcrumbs for this route.
   */
  breadcrumbText?: string | RouteDataSelectorInterface;

  /**
   * Wether the tour button should be visible on this route.
   */
  enableTour?: boolean;

  /**
   * Whether the tour is visible for a specific role
   * default (teacher & admins)
   */
  tourRoles?: string[];

  /**
   * Flag to prevent the route from being added to the breadcrumbs.
   * If omitted or set to false, the route will be added.
   *
   */
  skip?: boolean;
  /**
   * Extra details to use in backdrop-header
   */

  /* Other route data */
  headerDetails?: HeaderDetailsRouteDataInterface;

  /**
   * Whether the navigation should be visible on this route.
   */
  showNav?: boolean;

  denseMenu?: boolean;

  /**
   * Whether query params should be preserved on this route.
   *
   * @type {boolean}
   * @memberof RouteDataInterface
   */
  preserveQueryParams?: boolean;
}
