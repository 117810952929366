import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { ButtonCoreComponent } from '../button-core/button-core.component';

@Component({
  selector: 'campus-inline-icon-button, [inline-icon-button]',
  templateUrl: './inline-icon-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InlineIconButtonComponent extends ButtonCoreComponent {
  @HostBinding('class')
  defaultClasses = [
    ...this.coreClasses,
    'inline-icon-button',

    'flex',
    'transparent',
    'text-opacity-medium',

    'p-3xs',

    'corner-full',
    'border',
    'border-transparent',

    'hover:text-primary',
    'hover:text-opacity-high',

    'selected:ring',
    'selected:ring-primary',
    'selected:ring-2',
    'disabled:opacity-low',
  ];

  @Input() icon: string;
}
