import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'campus-chip-group',
  template: '<div><ng-content></ng-content></div>',
})
export class ChipGroupComponent {
  @HostBinding('class')
  defaultClasses = ['cluster', 'cluster-space-2xs'];
}
