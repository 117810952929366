import {
  Component,
  EventEmitter,
  InjectionToken,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { AuthAdapterInterface } from '../../interfaces';
import { PersonInterface } from '../../services';

// initial & processing state changes by actions in the contact component
// success and error state depend on external factors (api response)
// export type State = 'initial' | 'processing' | 'success' | 'error';
export enum StateEnum {
  INITIAL = 'initial',
  PROCESSING = 'processing',
  SUCCESS = 'success',
  ERROR = 'error',
}

@Component({
  template: '',
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class BaseFormComponent<T> implements OnChanges, OnInit, OnDestroy {
  // @Input and @Output are passed down to child components
  @Input() success: boolean;

  @Input() error: string;

  // name is suffixed with 'form' because 'submit' event is otherwise triggered a second time by the browser
  @Output() submitForm = new EventEmitter<T>();
  @Output() afterSuccess = new EventEmitter<void>();
  @Output() resetForm = new EventEmitter<void>();

  @Output() forgetPerson = new EventEmitter<PersonInterface>();

  // beware: protected props can't be used in a template (aot)
  protected destroy$: Subject<void> = new Subject<void>();

  state = StateEnum.INITIAL;
  isErrorState = false;

  form: UntypedFormGroup;

  constructor() {}

  // lifecycle hooks are not passed down to child components
  ngOnChanges(changes: SimpleChanges): void {
    const { success, error } = changes;

    if (error && !!error.currentValue) {
      this.state = error.currentValue; //if specific error was passed
      this.isErrorState = true;
    } else {
      if (success && !success.firstChange && success.currentValue !== null) {
        this.state = success.currentValue ? StateEnum.SUCCESS : StateEnum.ERROR; //general error or success
        this.isErrorState = this.state === StateEnum.ERROR;

        if (this.state === StateEnum.SUCCESS) {
          setTimeout(() => {
            this.afterSuccess.emit();
          }, 4000); // wait for animation to finish (animation is pure css, so no way to programatically know the duration)
        }
      }
    }
    if (this.form) {
      this.setFormControlsState('enable');
    }
  }

  ngOnInit(): void {
    if (!this.form) throw new Error('Form is not yet initialized!');
    this.form.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        filter(() => this.state !== StateEnum.INITIAL),
        tap(() => {
          this.state = StateEnum.INITIAL;
          this.isErrorState = false;
        })
      )
      .subscribe();
  }

  public submit(adapter: string | InjectionToken<AuthAdapterInterface>) {
    if (this.form.invalid) return;
    this.setFormControlsState('disable');
    this.submitForm.emit({ adapter, ...this.form.value });
    this.state = StateEnum.PROCESSING;
  }

  public reset() {
    this.form.reset();
    this.isErrorState = false;
    this.state = StateEnum.INITIAL;
    this.resetForm.emit();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  protected setFormControlsState(action: 'enable' | 'disable') {
    Object.keys(this.form.controls).forEach(
      (control) => this.form.controls[control][action]({ onlySelf: true }) // onlySelf: we don't want to trigger valueChanges event on the form
    );
  }
}
