<div class="[ container container-max-width-4xl stack ]">
  <h1 class="[ headline-large text-center ]">Bestand downloaden</h1>

  <div *ngIf="isLoggedIn$ | async; else unauthenticated" class="[ cluster ]">
    <div class="[ items-start justify-center ]">
      <p *ngIf="url$ | async as url; else error"
        >Indien de download niet automatisch start, <a [href]="url" target="_blank">klik hier</a>.</p
      >
      <ng-template #error>
        <p>Dit bestand is niet beschikbaar voor jouw account.</p>
      </ng-template>
    </div>
  </div>
  <ng-template #unauthenticated>
    <div class="[ stack items-center ]">
      <p>Je moet aangemeld zijn om deze pagina te bekijken.</p>
      <p><a [routerLink]="['/login']">Klik hier om aan te melden.</a></p>
    </div>
  </ng-template>
</div>
