import { Component, HostBinding, HostListener, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { asEmptyAttribute, EmptyAttribute, InputBooleanAttribute, isTruthyInput } from '../core/empty-attribute';
import { errorMessages } from '../core/error-messages';
import { FormElementCore } from '../core/form-element-core';

@Component({
  selector: 'campus-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CheckboxComponent,
      multi: true,
    },
  ],
})
export class CheckboxComponent extends FormElementCore<boolean> implements OnInit, OnChanges {
  public pressing = false;
  public focused = false;
  public errorText: string | Record<string, string> = errorMessages;
  public supportingTextOrErrorText$: Observable<string>;

  /**
   * This enables the checkbox to be used with [checked]="false|true" or as checked attribute.
   * @example <campus-checkbox [checked]="true"></campus-checkbox>
   * @example <campus-checkbox checked></campus-checkbox>
   */
  @Input()
  set checked(value: InputBooleanAttribute) {
    this.prevIndeterminate = isTruthyInput(this.indeterminate);
    this.indeterminate = false;
    this.value = isTruthyInput(value);
  }

  @HostBinding('attr.tabindex')
  @Input()
  tabindex = 0;

  @HostBinding('class.indeterminate')
  @Input()
  indeterminate: InputBooleanAttribute;

  @HostBinding('class.inline')
  @Input()
  inline: boolean;

  @Input() target: 'wrapper' | 'none' | undefined = 'wrapper';

  @HostBinding('attr.role') role = 'checkbox';

  @HostBinding('attr.aria-checked')
  ariaChecked: boolean;

  @HostBinding('attr.data-checked')
  dataChecked: EmptyAttribute;

  @HostBinding('class.checked')
  isChecked: boolean;

  @HostBinding('class.unselected')
  isUnselected: boolean;

  @HostBinding('class.selected')
  isSelected: boolean;

  @HostBinding('class.prev-unselected')
  isPrevUnselected: boolean;

  @HostBinding('class.prev-checked')
  isPrevChecked: boolean;

  @HostBinding('class.prev-indeterminate')
  prevIndeterminate: boolean;

  @HostBinding('class.prev-disabled')
  prevDisabled: boolean;

  @HostBinding('class')
  defaultClasses = [
    'cds-checkbox',
    'relative',
    'p-2xs',
    'inline-flex',
    'gap-s',
    'items-center',
    'max-w-fit',
    'outline-none',
    'corner-full',
    'cursor-pointer',

    'group',
  ];

  private _prevNone: boolean;
  private _prevChecked: boolean;

  ngOnInit(): void {
    super.ngOnInit();
    this.supportingTextOrErrorText$ = this.getSupportingTextOrErrorText$();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (changes.disabled) {
      this.tabindex = this.disabled ? -1 : 0;
    }
    if (changes.indeterminate) {
      this.indeterminate = isTruthyInput(this.indeterminate);
    }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.code === 'Space' || event.code === 'Enter') {
      event.preventDefault();
      this.toggle();
    }
  }

  @HostListener('focusin')
  onFocusIn() {
    this.focused = true;
  }

  @HostListener('focusout')
  onFocusOut() {
    this.blur();
    this.focused = false;
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    event.preventDefault();
    this.pressing = true;
  }

  @HostListener('mouseup')
  onMouseUp() {
    this.toggle();
    this.pressing = false;
  }

  toggle() {
    this.handleInput({ target: { checked: !this.value } });
  }

  handleInput(event) {
    this.prevIndeterminate = isTruthyInput(this.indeterminate);
    this.indeterminate = false;
    this.value = (event.target as HTMLInputElement).checked;
    this.blur();
  }

  protected onValueChanged(): void {
    this.isPrevUnselected = !this._prevChecked && !this.prevIndeterminate;
    this.isPrevChecked = this._prevChecked && !this.prevIndeterminate;
    this.prevDisabled = isTruthyInput(this.disabled);

    this.ariaChecked = this.value;
    this.dataChecked = asEmptyAttribute(this.value);
    this.isChecked = this.value && !this.indeterminate;
    this.isUnselected = !this.value && !this.indeterminate;
    this.isSelected = this.value || isTruthyInput(this.indeterminate);
  }

  private getSupportingTextOrErrorText$() {
    return this.status$.pipe(
      map((status) => (status === 'invalid' && this.errorText ? this.getMatchingErrorText() : this.supportingText))
    );
  }

  private getMatchingErrorText() {
    if (typeof this.errorText === 'string') {
      return this.errorText;
    }

    if (this.formControl.errors) {
      for (const key in this.formControl.errors) {
        if (key in this.errorText) {
          const text: string = this.errorText[key];

          const replaced = text.replace(/\{\{(\w+)\}\}/g, (match: string, captured: string) =>
            this[captured].toString()
          );

          return replaced;
        }
      }
    }
  }
}
