import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import {
  ENVIRONMENT_ALERTS_FEATURE_TOKEN,
  ENVIRONMENT_ALLOWED_ROLES,
  ENVIRONMENT_API_TOKEN,
  ENVIRONMENT_ASSETS_TOKEN,
  ENVIRONMENT_AUTHOR_TOOL_TOKEN,
  ENVIRONMENT_COLLECTION_MANAGEMENT_FEATURE_TOKEN,
  ENVIRONMENT_ERROR_MANAGEMENT_FEATURE_TOKEN,
  ENVIRONMENT_FAVORITES_FEATURE_TOKEN,
  ENVIRONMENT_FLUO_METHOD_ID_TOKEN,
  ENVIRONMENT_FRESHWORKS_FEATURE_TOKEN,
  ENVIRONMENT_FROALA_TOKEN,
  ENVIRONMENT_GLOBAL_SEARCH_FEATURE_TOKEN,
  ENVIRONMENT_GOOGLE_ANALYTICS_TOKEN,
  ENVIRONMENT_GROUPS_FEATURE_TOKEN,
  ENVIRONMENT_HISTORY_FEATURE_TOKEN,
  ENVIRONMENT_ICON_MAPPING_TOKEN,
  ENVIRONMENT_LOGIN_TOKEN,
  ENVIRONMENT_LOGOUT_TOKEN,
  ENVIRONMENT_LOOP_TOKEN,
  ENVIRONMENT_MESSAGES_FEATURE_TOKEN,
  ENVIRONMENT_METHODS_FEATURE_TOKEN,
  ENVIRONMENT_NEWS_FEATURE_TOKEN,
  ENVIRONMENT_PERSONALISATION_TOKEN,
  ENVIRONMENT_PLATFORM_TOKEN,
  ENVIRONMENT_PROFILE_TOKEN,
  ENVIRONMENT_RECAPTCHA_TOKEN,
  ENVIRONMENT_SCORE_MAPPING_TOKEN,
  ENVIRONMENT_SEARCHMODES_TOKEN,
  ENVIRONMENT_SSO_TOKEN,
  ENVIRONMENT_SVG_MAPPING_TOKEN,
  ENVIRONMENT_TASK_FEATURE_TOKEN,
  ENVIRONMENT_TERM_PRIVACY_TOKEN,
  ENVIRONMENT_TESTING_TOKEN,
  ENVIRONMENT_TEXT_MAPPING_TOKEN,
  ENVIRONMENT_TIME_TOKEN,
  ENVIRONMENT_TOURS_FEATURE_TOKEN,
  ENVIRONMENT_UI_TOKEN,
  ENVIRONMENT_WEBSITE_TOKEN,
} from './interfaces';

@NgModule({
  imports: [CommonModule],
  declarations: [],
})
export class EnvironmentModule {
  static forRoot(environmentVariables): ModuleWithProviders<EnvironmentModule> {
    return {
      ngModule: EnvironmentModule,
      providers: [
        {
          provide: ENVIRONMENT_ALERTS_FEATURE_TOKEN,
          useValue: environmentVariables.environmentAlertsFeature,
        },
        {
          provide: ENVIRONMENT_GLOBAL_SEARCH_FEATURE_TOKEN,
          useValue: environmentVariables.environmentGlobalSearch,
        },
        {
          provide: ENVIRONMENT_MESSAGES_FEATURE_TOKEN,
          useValue: environmentVariables.environmentMessagesFeature,
        },
        {
          provide: ENVIRONMENT_ERROR_MANAGEMENT_FEATURE_TOKEN,
          useValue: environmentVariables.environmentErrorManagementFeature,
        },
        {
          provide: ENVIRONMENT_FAVORITES_FEATURE_TOKEN,
          useValue: environmentVariables.environmentFavoritesFeature,
        },
        {
          provide: ENVIRONMENT_HISTORY_FEATURE_TOKEN,
          useValue: environmentVariables.environmentHistoryFeature,
        },
        {
          provide: ENVIRONMENT_COLLECTION_MANAGEMENT_FEATURE_TOKEN,
          useValue: environmentVariables.environmentCollectionManagementFeature,
        },
        {
          provide: ENVIRONMENT_WEBSITE_TOKEN,
          useValue: environmentVariables.environmentWebsite,
        },
        {
          provide: ENVIRONMENT_LOGOUT_TOKEN,
          useValue: environmentVariables.environmentLogout,
        },
        {
          provide: ENVIRONMENT_LOGIN_TOKEN,
          useValue: environmentVariables.environmentLogin,
        },
        {
          provide: ENVIRONMENT_PROFILE_TOKEN,
          useValue: environmentVariables.environmentProfile,
        },
        {
          provide: ENVIRONMENT_TERM_PRIVACY_TOKEN,
          useValue: environmentVariables.environmentTermPrivacy,
        },
        {
          provide: ENVIRONMENT_ICON_MAPPING_TOKEN,
          useValue: environmentVariables.environmentIconMapping,
          multi: true,
        },
        {
          provide: ENVIRONMENT_TEXT_MAPPING_TOKEN,
          useValue: environmentVariables.environmentTextMapping,
        },
        {
          provide: ENVIRONMENT_SVG_MAPPING_TOKEN,
          useValue: environmentVariables.environmentSvgMapping,
          multi: true,
        },
        {
          provide: ENVIRONMENT_API_TOKEN,
          useValue: environmentVariables.environmentApi,
        },
        {
          provide: ENVIRONMENT_FROALA_TOKEN,
          useValue: environmentVariables.environmentFroala,
        },
        {
          provide: ENVIRONMENT_AUTHOR_TOOL_TOKEN,
          useValue: environmentVariables.environmentAuthorTool,
        },
        {
          provide: ENVIRONMENT_SSO_TOKEN,
          useValue: environmentVariables.environmentSsoSettings,
        },
        {
          provide: ENVIRONMENT_SEARCHMODES_TOKEN,
          useValue: environmentVariables.environmentSearchModes,
        },
        {
          provide: ENVIRONMENT_TESTING_TOKEN,
          useValue: environmentVariables.environmentTesting,
        },
        {
          provide: ENVIRONMENT_UI_TOKEN,
          useValue: environmentVariables.environmentUi,
        },
        {
          provide: ENVIRONMENT_ASSETS_TOKEN,
          useValue: environmentVariables.environmentAssets,
        },
        {
          provide: ENVIRONMENT_METHODS_FEATURE_TOKEN,
          useValue: environmentVariables.environmentMethodsFeature,
        },
        {
          provide: ENVIRONMENT_NEWS_FEATURE_TOKEN,
          useValue: environmentVariables.environmentNewsFeature,
        },
        {
          provide: ENVIRONMENT_FRESHWORKS_FEATURE_TOKEN,
          useValue: environmentVariables.environmentFreshworksFeature,
        },
        {
          provide: ENVIRONMENT_FAVORITES_FEATURE_TOKEN,
          useValue: environmentVariables.environmentFavoritesFeature,
        },
        {
          provide: ENVIRONMENT_HISTORY_FEATURE_TOKEN,
          useValue: environmentVariables.environmentHistoryFeature,
        },
        {
          provide: ENVIRONMENT_SEARCHMODES_TOKEN,
          useValue: environmentVariables.environmentSearchModes,
        },
        {
          provide: ENVIRONMENT_LOOP_TOKEN,
          useValue: environmentVariables.environmentLoop,
        },
        {
          provide: ENVIRONMENT_RECAPTCHA_TOKEN,
          useValue: environmentVariables.environmentRecaptcha,
        },
        {
          provide: ENVIRONMENT_PERSONALISATION_TOKEN,
          useValue: environmentVariables.environmentPersonalisation,
        },
        {
          provide: ENVIRONMENT_TOURS_FEATURE_TOKEN,
          useValue: environmentVariables.environmentToursFeature,
        },
        {
          provide: ENVIRONMENT_GOOGLE_ANALYTICS_TOKEN,
          useValue: environmentVariables.environmentGoogleAnalytics,
        },
        {
          provide: ENVIRONMENT_GROUPS_FEATURE_TOKEN,
          useValue: environmentVariables.environmentGroupsFeature,
        },
        {
          provide: ENVIRONMENT_PLATFORM_TOKEN,
          useValue: environmentVariables.environmentPlatform || 'kabas', // default for now
        },
        {
          provide: ENVIRONMENT_SCORE_MAPPING_TOKEN,
          useValue: environmentVariables.environmentScoreMapping,
        },
        {
          provide: ENVIRONMENT_TIME_TOKEN,
          useValue: environmentVariables.environmentTime,
        },
        {
          provide: ENVIRONMENT_ALLOWED_ROLES,
          useValue: environmentVariables.environmentAllowedRoles,
        },
        {
          provide: ENVIRONMENT_TASK_FEATURE_TOKEN,
          useValue: environmentVariables.environmentTaskFeature,
        },
        {
          provide: ENVIRONMENT_FLUO_METHOD_ID_TOKEN,
          useValue: environmentVariables.environmentFluoMethodId,
        },
      ],
    };
  }
}
