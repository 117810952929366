import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TooltipCoreComponent } from './tooltip-core.component';

@Component({
  selector: 'campus-tooltip',
  templateUrl: './plain-tooltip.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlainTooltipComponent extends TooltipCoreComponent {
  @Input() plainTooltip: string;
}
