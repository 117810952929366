import { inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WINDOW } from '@campus/browser';
import { AuthFacade, ContentRequestService } from '@campus/public-portal';
import { Observable, of } from 'rxjs';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class ContentLinkPageViewModel {
  private route = inject(ActivatedRoute);
  private contentService = inject(ContentRequestService);
  private auth = inject(AuthFacade);
  private window = inject(WINDOW);

  private eduContentId: number;

  public user$ = this.auth.user$;
  public url$: Observable<string | undefined>;

  constructor() {
    this.eduContentId = +this.route.snapshot.paramMap.get('id');
    this.openContent();
  }

  openContent(): void {
    this.url$ = this.user$.pipe(
      switchMap((user) => (user ? this.contentService.requestUrl(this.eduContentId) : of(null))),
      filter((url) => !!url),
      tap((url) => this.window.open(url, '_blank')),
      catchError(() => of(undefined))
    );
  }
}
