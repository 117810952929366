import { Injectable, inject } from '@angular/core';
import { WINDOW } from '@campus/browser';
import { ENVIRONMENT_LOGIN_TOKEN } from '@campus/environment';
import { PersonInterface } from '@campus/public-portal';

@Injectable({
  providedIn: 'root',
})
export class RedirectService {
  private environment = inject(ENVIRONMENT_LOGIN_TOKEN);
  private window = inject(WINDOW);

  public toPlatform(user: PersonInterface) {
    if (!user) return;

    const url = this.getPlatformUrl();

    this.redirect(url);
  }

  public getPlatformUrl() {
    return this.environment.url;
  }

  public redirect(url: string) {
    this.window.location.assign(url);
  }
}
