<div class="[ stack ]">
  <h3 class="[ text-center title-medium ]" data-cy="login-no-access-header">Nog geen toegang?</h3>
  <div class="[ grid grid-cols-2 ]" *ngIf="role !== 'schooladmin'; else schooladmin">
    <div>
      <h4 class="[ text-center title-medium ]" data-cy="login-no-access-teacher">leerkrachten</h4>
      <p class="[ text-center ]" data-cy="login-no-access-teacher-info"
        >Vraag uw logingegevens aan de schoolbeheerder.</p
      >
    </div>
    <div>
      <h4 class="[ text-center title-medium ]" data-cy="login-no-access-student">leerlingen</h4>
      <p class="[ text-center ]">
        <a routerLink="/registratie" class="[ underline ] [ text-secondary ]" data-cy="link-student-register"
          >Registreer als nieuwe leerling</a
        >
      </p>
    </div>
  </div>
</div>

<ng-template #schooladmin>
  <p class="[ text-center ]" data-cy="login-no-access-schooladmin-info">
    Vul
    <a
      href="https://www.diekeure.be/educatief/polpo-bestelformulier/"
      class="[ underline ] [ text-secondary ]"
      data-cy="link-schooladmin-info"
      >het aanvraagformulier</a
    >
    in.
  </p>
</ng-template>
