import { Action, ActionReducer } from '@ngrx/store';

import { UserActions } from '../user';
import { MetaReducerActionTypes } from './meta-reducer.actions';

export function clearState(rootReducer: ActionReducer<any>): ActionReducer<any> {
  return function (state: any, action: Action) {
    const allowedActions: string[] = [UserActions.ACTION_CLEAR_USER, MetaReducerActionTypes.ClearState];

    if (allowedActions.includes(action.type)) {
      // return an undefined state
      // therefore all of the reducers will return the initial value as they are supposed to
      state = undefined;
    }

    return rootReducer(state, action);
  };
}
