import { animate, AnimationMetadata, keyframes, style, transition } from '@angular/animations';

export const wiggleAnimation: AnimationMetadata[] = [
  transition('false=>true', [
    style({ transform: 'translateX(0)' }),
    animate(
      '620ms cubic-bezier(0.36, 0.07, 0.19, 0.97)',
      keyframes([
        style({ transform: 'translateX(-1px)' }),
        style({ transform: 'translateX(2px)' }),
        style({ transform: 'translateX(-4px)' }),
        style({ transform: 'translateX(4px)' }),
        style({ transform: 'translateX(-4px)' }),
        style({ transform: 'translateX(4px)' }),
        style({ transform: 'translateX(-4px)' }),
        style({ transform: 'translateX(2px)' }),
        style({ transform: 'translateX(-1px)' }),
        style({ transform: 'translateX(0)' }),
      ])
    ),
  ]),
];

export const wiggleLargeAnimation: AnimationMetadata[] = [
  transition('*=>true', [
    style({ transform: 'translateX(0)' }),
    animate(
      '1000ms cubic-bezier(0.3, 0, 0.8, 0.15)',
      keyframes([
        style({ transform: 'translateX(-2px)' }),
        style({ transform: 'translateX(4px)' }),
        style({ transform: 'translateX(-8px)' }),
        style({ transform: 'translateX(8px)' }),
        style({ transform: 'translateX(-8px)' }),
        style({ transform: 'translateX(8px)' }),
        style({ transform: 'translateX(-8px)' }),
        style({ transform: 'translateX(4px)' }),
        style({ transform: 'translateX(-2px)' }),
        style({ transform: 'translateX(0)' }),
      ])
    ),
  ]),
];
