import { InjectionToken } from '@angular/core';
import {
  EnvironmentApiInterface,
  EnvironmentAssetsInterface,
  EnvironmentAuthorToolInterface,
  EnvironmentErrorManagementFeatureInterface,
  EnvironmentFreshworksFeatureInterface,
  EnvironmentFroalaInterface,
  EnvironmentGoogleAnalyticsInterface,
  EnvironmentGroupsFeatureInterface,
  EnvironmentLoginInterface,
  EnvironmentPlatformType,
  EnvironmentRecaptchaInterface,
  EnvironmentScoreMappingType,
  EnvironmentSearchModesInterface,
  EnvironmentSsoInterface,
  EnvironmentTaskFeatureInterface,
  EnvironmentWebsiteInterface,
} from './environment.interfaces';

export const ENVIRONMENT_ICON_MAPPING_TOKEN = new InjectionToken('environmentIconMapping');
export const ENVIRONMENT_TEXT_MAPPING_TOKEN = new InjectionToken('environmentTextMapping');
export const ENVIRONMENT_SVG_MAPPING_TOKEN = new InjectionToken('environmentSvgMapping');

export const ENVIRONMENT_API_TOKEN = new InjectionToken<EnvironmentApiInterface>('environmentApiBase');
export const ENVIRONMENT_FROALA_TOKEN = new InjectionToken<EnvironmentFroalaInterface>('environmentFroala');
export const ENVIRONMENT_AUTHOR_TOOL_TOKEN = new InjectionToken<EnvironmentAuthorToolInterface>('environmentAT');

export const ENVIRONMENT_WEBSITE_TOKEN = new InjectionToken<EnvironmentWebsiteInterface>('environmentWebsite');
export const ENVIRONMENT_ASSETS_TOKEN = new InjectionToken<EnvironmentAssetsInterface>('environmentAssets');

export const ENVIRONMENT_FAVORITES_FEATURE_TOKEN = new InjectionToken('environmentQuickLinkFeature');
export const ENVIRONMENT_HISTORY_FEATURE_TOKEN = new InjectionToken('environmentHistoryFeature');

export const ENVIRONMENT_LOGOUT_TOKEN = new InjectionToken('environmentLogout');

export const ENVIRONMENT_LOGIN_TOKEN = new InjectionToken<EnvironmentLoginInterface>('environmentLogin');

export const ENVIRONMENT_PROFILE_TOKEN = new InjectionToken('environmentProfile');

export const ENVIRONMENT_TERM_PRIVACY_TOKEN = new InjectionToken('environmentTermPrivacy');

export const ENVIRONMENT_ERROR_MANAGEMENT_FEATURE_TOKEN =
  new InjectionToken<EnvironmentErrorManagementFeatureInterface>('environmentErrorManagementFeature');

export const ENVIRONMENT_MESSAGES_FEATURE_TOKEN = new InjectionToken('environmentMessagesFeature');

export const ENVIRONMENT_ALERTS_FEATURE_TOKEN = new InjectionToken('environmentAlertsFeature');

export const ENVIRONMENT_METHODS_FEATURE_TOKEN = new InjectionToken('environmentMethodsFeature');

export const ENVIRONMENT_NEWS_FEATURE_TOKEN = new InjectionToken('environmentNewsFeature');

export const ENVIRONMENT_FRESHWORKS_FEATURE_TOKEN = new InjectionToken<EnvironmentFreshworksFeatureInterface>(
  'environmentFreshworksFeature'
);

export const ENVIRONMENT_GLOBAL_SEARCH_FEATURE_TOKEN = new InjectionToken('environmentGlobalSearchFeature');

export const ENVIRONMENT_SSO_TOKEN = new InjectionToken<EnvironmentSsoInterface>('environmentSsoSettings');

export const ENVIRONMENT_SEARCHMODES_TOKEN = new InjectionToken<EnvironmentSearchModesInterface>(
  'environmentSearchModes'
);

export const ENVIRONMENT_TESTING_TOKEN = new InjectionToken('environmentTesting');
export const ENVIRONMENT_UI_TOKEN = new InjectionToken('environmentUi');
export const ENVIRONMENT_TIME_TOKEN = new InjectionToken('environmentTime');
export const ENVIRONMENT_COLLECTION_MANAGEMENT_FEATURE_TOKEN = new InjectionToken(
  'environmentCollectionManagementFeature'
);
export const ENVIRONMENT_LOOP_TOKEN = new InjectionToken('environmentLoop');
export const ENVIRONMENT_RECAPTCHA_TOKEN = new InjectionToken<EnvironmentRecaptchaInterface>('environmentRecaptcha');
export const ENVIRONMENT_PERSONALISATION_TOKEN = new InjectionToken('environmentPersonalisation');

export const ENVIRONMENT_TOURS_FEATURE_TOKEN = new InjectionToken('environmentToursFeature');

export const ENVIRONMENT_GOOGLE_ANALYTICS_TOKEN = new InjectionToken<EnvironmentGoogleAnalyticsInterface>(
  'environmentGoogleAnalytics'
);

export const ENVIRONMENT_GROUPS_FEATURE_TOKEN = new InjectionToken<EnvironmentGroupsFeatureInterface>(
  'environmentGroupsFeature'
);
export const ENVIRONMENT_PLATFORM_TOKEN = new InjectionToken<EnvironmentPlatformType>('environmentPlatform');

export const ENVIRONMENT_SCORE_MAPPING_TOKEN = new InjectionToken<EnvironmentScoreMappingType>(
  'environmentScoreMapping'
);
export const ENVIRONMENT_ALLOWED_ROLES = new InjectionToken<string[]>('environmentAllowedRoles');

export const ENVIRONMENT_TASK_FEATURE_TOKEN = new InjectionToken<EnvironmentTaskFeatureInterface>(
  'environmentTaskFeature'
);

export const ENVIRONMENT_FLUO_METHOD_ID_TOKEN = new InjectionToken<number>('environmentFluoMethodId');
