import { Directive, ElementRef, Inject, Optional, Renderer2 } from '@angular/core';
import { EnvironmentTestingInterface, ENVIRONMENT_TESTING_TOKEN } from '@campus/environment';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[data-cy]',
})
export class DataCyDirective {
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    @Optional()
    @Inject(ENVIRONMENT_TESTING_TOKEN)
    private environmentTesting: EnvironmentTestingInterface
  ) {
    if (this.environmentTesting && this.environmentTesting.removeDataCyAttributes) {
      this.renderer.removeAttribute(this.el.nativeElement, 'data-cy');
    } else {
      // Uncomment this to see where data-cy elements are on the page, useful for debugging
      // this.applyDebugLabels();
    }
  }

  private applyDebugLabels() {
    const div = document.createElement('div');
    const dataCyValue = this.el.nativeElement.getAttribute('data-cy');
    div.style.cssText = `
      position: absolute;
      font-size: 16px;
      font-family: sans-serif;
      font-variant: normal;
      font-weight: normal;
      text-transform: none;
      line-height: normal;
      top: -18px;
      left: 0;
      color: #00f;
      text-shadow: 1px 1px #fff, -1px -1px #fff;
    `;
    div.textContent = dataCyValue;

    this.el.nativeElement.style.cssText = `
      position: relative;
      border: 2px solid #00f;
    `;
    this.el.nativeElement.style.border = '2px solid #00f';
    // For places where we can't see the div above the panel, add hover text instead
    this.el.nativeElement.title = dataCyValue;
    this.el.nativeElement.append(div);
  }
}
