import { inject, Injectable } from '@angular/core';

import { ENVIRONMENT_RECAPTCHA_TOKEN, EnvironmentRecaptchaInterface } from '@campus/environment';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { GRECAPTCHA_TOKEN } from '../../tokens';
import { ContactFormEntryApi, ContactFormEntryInterface } from '../api';

@Injectable({
  providedIn: 'root',
})
export class ContactformEntryService {
  private contactApi: ContactFormEntryApi = inject(ContactFormEntryApi);
  private grecaptcha: ReCaptchaV2.ReCaptcha = inject(GRECAPTCHA_TOKEN);
  private recaptcha: EnvironmentRecaptchaInterface = inject(ENVIRONMENT_RECAPTCHA_TOKEN);

  private isReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _contactSuccess$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public contactSuccess$: Observable<boolean> = this._contactSuccess$.asObservable();

  constructor() {
    this.setIsReady$();
  }

  private send(data: ContactFormEntryInterface): Observable<boolean> {
    return this.getNewToken$().pipe(
      switchMap((token) => {
        data.captcha = token;
        const res = this.contactApi.create(data);
        return res;
      }),
      map((response) => !!response),
      catchError((_) => of(false)),
      take(1)
    );
  }

  private setIsReady$(): void {
    this.grecaptcha.ready(() => {
      this.isReady$.next(true);
    });
  }

  public getNewToken$(): Observable<string> {
    return this.isReady$.pipe(
      filter((isReady) => isReady),
      take(1),
      switchMap(() => {
        return from(this.grecaptcha.execute(this.recaptcha.key, { action: 'contact' }));
      })
    );
  }

  public submit(data: ContactFormEntryInterface): void {
    this.send(data).subscribe((success) => {
      this._contactSuccess$.next(success);
    });
  }

  public reset() {
    this._contactSuccess$.next(null);
  }
}
