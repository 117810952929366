import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';

@Injectable()
export class WindowScrollService {
  public scroll$: Observable<number>;
  public thresholdReached$: Observable<boolean>;

  pinnedThreshold = 120;

  constructor(@Inject(DOCUMENT) private document: any) {
    this.scroll$ = fromEvent(window, 'scroll').pipe(
      map((event) => window.scrollY || this.document.documentElement.scrollTop),
      share()
    );
    this.thresholdReached$ = this.scroll$.pipe(map((scrollTop) => scrollTop >= this.pinnedThreshold));
  }
}
