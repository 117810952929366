<svg
  class="[ flex-1 ]"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  viewBox="0 0 995.17 451.5"
>
  <defs>
    <linearGradient
      id="kabas-not-found-gradient"
      x1="675.71"
      y1="-403.35"
      x2="682.01"
      y2="-403.35"
      gradientTransform="translate(29.78 796.94) rotate(-20.71)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#d1e1f2" />
      <stop offset="0.56" stop-color="#e9f1f9" />
      <stop offset="1" stop-color="#fff" />
    </linearGradient>
    <linearGradient
      id="kabas-not-found-gradient-2"
      x1="631.51"
      y1="-403.35"
      x2="637.81"
      y2="-403.35"
      gradientTransform="translate(29.78 796.94) rotate(-20.71)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#fff" />
      <stop offset="0.44" stop-color="#e9f1f9" />
      <stop offset="1" stop-color="#d1e1f2" />
    </linearGradient>
    <linearGradient
      id="kabas-not-found-gradient-3"
      x1="661.67"
      y1="-239.38"
      x2="679.72"
      y2="-239.38"
      gradientTransform="translate(29.78 796.94) rotate(-20.71)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#d1e1f2" />
      <stop offset="0.39" stop-color="#d6e5f3" />
      <stop offset="0.88" stop-color="#e6eff8" />
      <stop offset="1" stop-color="#ebf2f9" />
    </linearGradient>
    <linearGradient
      id="kabas-not-found-gradient-4"
      x1="681.18"
      y1="-244.85"
      x2="704.43"
      y2="-244.85"
      xlink:href="#kabas-not-found-gradient-3"
    />
    <linearGradient
      id="kabas-not-found-gradient-5"
      x1="718.29"
      y1="-713.83"
      x2="746.16"
      y2="-713.83"
      gradientTransform="matrix(0.88, -0.48, 0.48, 0.88, 170.35, 1261.75)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#d1e1f2" />
      <stop offset="0.28" stop-color="#d6e5f4" />
      <stop offset="0.62" stop-color="#e6eff8" />
      <stop offset="1" stop-color="#fff" />
    </linearGradient>
    <linearGradient
      id="kabas-not-found-gradient-6"
      x1="590.93"
      y1="-318.55"
      x2="707.08"
      y2="-318.55"
      xlink:href="#kabas-not-found-gradient-3"
    />
    <linearGradient
      id="kabas-not-found-gradient-7"
      x1="635.26"
      y1="-402.98"
      x2="678.46"
      y2="-402.98"
      xlink:href="#kabas-not-found-gradient"
    />
    <linearGradient
      id="kabas-not-found-gradient-8"
      x1="696.46"
      y1="-395.1"
      x2="724.2"
      y2="-395.1"
      gradientTransform="translate(29.78 796.94) rotate(-20.71)"
      xlink:href="#kabas-not-found-gradient-5"
    />
    <linearGradient
      id="kabas-not-found-gradient-9"
      x1="699.88"
      y1="-365.17"
      x2="718.93"
      y2="-365.17"
      gradientTransform="translate(29.78 796.94) rotate(-20.71)"
      xlink:href="#kabas-not-found-gradient-5"
    />
    <linearGradient
      id="kabas-not-found-gradient-10"
      x1="654.57"
      y1="-325.01"
      x2="660.65"
      y2="-366.21"
      gradientTransform="translate(-136.73 586.81)"
      xlink:href="#kabas-not-found-gradient-5"
    />
    <clipPath id="clip-path">
      <path
        d="M524.8,192.21c-3.86,8.72-28.76,18.13-37.42,14.14-1.26-.58-1.75-3.71-1.94-5.92-.12-1.36-.29-2.71-.47-4.07-1.09-8.13,4.09-16.35,12.62-19.58h0c8.66-3.27,18.15-.39,22.63,6.68.56.88,1.15,1.75,1.79,2.59C523.36,187.8,525.41,190.83,524.8,192.21Z"
        style="fill: none"
      />
    </clipPath>
    <linearGradient
      id="kabas-not-found-gradient-11"
      x1="10036.92"
      y1="3100.65"
      x2="10040.08"
      y2="3100.65"
      gradientTransform="matrix(-0.93, -0.35, -0.35, 0.94, 10844.94, 796.94)"
      xlink:href="#kabas-not-found-gradient"
    />
    <linearGradient
      id="kabas-not-found-gradient-12"
      x1="10014.71"
      y1="3100.65"
      x2="10017.87"
      y2="3100.65"
      gradientTransform="matrix(-0.93, -0.35, -0.35, 0.94, 10844.94, 796.94)"
      xlink:href="#kabas-not-found-gradient-2"
    />
    <linearGradient
      id="kabas-not-found-gradient-13"
      x1="10029.86"
      y1="3183.03"
      x2="10038.93"
      y2="3183.03"
      gradientTransform="matrix(-0.93, -0.35, -0.35, 0.94, 10844.94, 796.94)"
      xlink:href="#kabas-not-found-gradient-3"
    />
    <linearGradient
      id="kabas-not-found-gradient-14"
      x1="10039.66"
      y1="3180.28"
      x2="10051.34"
      y2="3180.28"
      gradientTransform="matrix(-0.93, -0.35, -0.35, 0.94, 10844.94, 796.94)"
      xlink:href="#kabas-not-found-gradient-3"
    />
    <linearGradient
      id="kabas-not-found-gradient-15"
      x1="9552.32"
      y1="4027.93"
      x2="9566.32"
      y2="4027.93"
      gradientTransform="matrix(-0.88, -0.48, -0.48, 0.88, 10704.6, 1261.75)"
      xlink:href="#kabas-not-found-gradient-5"
    />
    <linearGradient
      id="kabas-not-found-gradient-16"
      x1="9994.32"
      y1="3143.26"
      x2="10052.67"
      y2="3143.26"
      gradientTransform="matrix(-0.93, -0.35, -0.35, 0.94, 10844.94, 796.94)"
      xlink:href="#kabas-not-found-gradient-3"
    />
    <linearGradient
      id="kabas-not-found-gradient-17"
      x1="10016.6"
      y1="3100.84"
      x2="10038.3"
      y2="3100.84"
      gradientTransform="matrix(-0.93, -0.35, -0.35, 0.94, 10844.94, 796.94)"
      xlink:href="#kabas-not-found-gradient"
    />
    <linearGradient
      id="kabas-not-found-gradient-18"
      x1="10047.34"
      y1="3104.8"
      x2="10061.28"
      y2="3104.8"
      gradientTransform="matrix(-0.93, -0.35, -0.35, 0.94, 10844.94, 796.94)"
      xlink:href="#kabas-not-found-gradient-5"
    />
    <linearGradient
      id="kabas-not-found-gradient-19"
      x1="10049.06"
      y1="3119.84"
      x2="10058.63"
      y2="3119.84"
      gradientTransform="matrix(-0.93, -0.35, -0.35, 0.94, 10844.94, 796.94)"
      xlink:href="#kabas-not-found-gradient-5"
    />
    <linearGradient
      id="kabas-not-found-gradient-20"
      x1="10026.29"
      y1="3140.01"
      x2="10029.35"
      y2="3119.31"
      gradientTransform="matrix(-0.93, -0.35, -0.35, 0.94, 10844.94, 796.94)"
      xlink:href="#kabas-not-found-gradient-5"
    />
    <clipPath id="clip-path-2">
      <path
        d="M374.56,154c1.94,4.39,14.42,9.12,18.77,7.11.63-.29.88-1.86,1-3,.06-.69.15-1.37.24-2.05a9.46,9.46,0,0,0-6.33-9.83h0a9.4,9.4,0,0,0-11.35,3.35c-.28.45-.58.88-.9,1.3C375.28,151.8,374.26,153.32,374.56,154Z"
        style="fill: none"
      />
    </clipPath>
  </defs>
  <g id="_404" data-name="404">
    <g id="interstellar_objects">
      <path d="M426.11,375a.68.68,0,1,1-.68-.68A.68.68,0,0,1,426.11,375Z" class="bright-star" />

      <path d="M574.8,332.16a.2.2,0,0,1-.2.19.2.2,0,0,1-.19-.19.2.2,0,1,1,.39,0Z" class="bright-star" />
      <path d="M463.22,354.78a.18.18,0,1,1-.36,0,.18.18,0,1,1,.36,0Z" class="bright-star" />
      <path d="M382.4,326.46a.68.68,0,0,1-1.36,0,.68.68,0,0,1,1.36,0Z" class="bright-star" />
      <path d="M524.74,143a.68.68,0,1,1-.68-.68A.67.67,0,0,1,524.74,143Z" class="bright-star" />
      <path d="M535.49,180.33a.68.68,0,1,1-.68-.68A.67.67,0,0,1,535.49,180.33Z" class="bright-star" />
      <path d="M702.17,97.24a.69.69,0,1,1-.68-.68A.68.68,0,0,1,702.17,97.24Z" class="bright-star" />
      <path d="M782.65,219a.69.69,0,1,1-.68-.68A.69.69,0,0,1,782.65,219Z" class="bright-star" />
      <path d="M817.45,323.24a.68.68,0,1,1-.68-.68A.68.68,0,0,1,817.45,323.24Z" class="bright-star" />
      <path d="M738.2,388.93a.68.68,0,1,1-.68-.68A.67.67,0,0,1,738.2,388.93Z" class="bright-star" />
      <path d="M586,364.29a.68.68,0,1,1-.68-.68A.67.67,0,0,1,586,364.29Z" class="bright-star" />
      <path d="M618.88,269.05a.68.68,0,1,1-.68-.68A.68.68,0,0,1,618.88,269.05Z" class="bright-star" />
      <path d="M675.79,306.92a.68.68,0,1,1-.68-.68A.68.68,0,0,1,675.79,306.92Z" class="bright-star" />
      <path d="M575.66,158.68A.68.68,0,1,1,575,158,.68.68,0,0,1,575.66,158.68Z" class="bright-star" />
      <path d="M408.82,79.54a.68.68,0,1,1-.68-.69A.69.69,0,0,1,408.82,79.54Z" class="bright-star" />
      <path d="M324,111.57a.68.68,0,1,1-.68-.68A.68.68,0,0,1,324,111.57Z" class="bright-star" />
      <path d="M207.81,167.69a.68.68,0,1,1-.68-.68A.68.68,0,0,1,207.81,167.69Z" class="bright-star" />
      <path d="M183.28,241a.68.68,0,0,1-1.36,0,.68.68,0,1,1,1.36,0Z" class="bright-star" />
      <circle cx="304.62" cy="354.45" r="0.68" class="bright-star" />
      <path d="M310.27,432.94a.68.68,0,1,1-1.36,0,.68.68,0,1,1,1.36,0Z" class="bright-star" />
      <path d="M207,344.86a.68.68,0,1,1-.68-.68A.67.67,0,0,1,207,344.86Z" class="bright-star" />
      <path d="M426.2,420.81a.68.68,0,1,1-.68-.68A.67.67,0,0,1,426.2,420.81Z" class="bright-star" />
      <path d="M577,405.37a.69.69,0,1,1-.69-.68A.68.68,0,0,1,577,405.37Z" class="bright-star" />
      <path d="M712.84,435.81a.68.68,0,1,1-.68-.68A.68.68,0,0,1,712.84,435.81Z" class="bright-star" />
      <path d="M785.63,428.79a.68.68,0,1,1-1.36,0,.68.68,0,1,1,1.36,0Z" class="bright-star" />
      <path d="M815.43,114.88a.68.68,0,1,1-.68-.68A.67.67,0,0,1,815.43,114.88Z" class="bright-star" />
      <path d="M813.38,47.9a.68.68,0,0,1-1.36,0,.68.68,0,0,1,1.36,0Z" class="bright-star" />
      <path d="M741.91,71a.68.68,0,1,1-.68-.68A.67.67,0,0,1,741.91,71Z" class="bright-star" />
      <path d="M463,53.16a.68.68,0,1,1-.68-.69A.68.68,0,0,1,463,53.16Z" class="bright-star" />
      <path d="M316.19,50.35a.68.68,0,1,1-.68-.68A.67.67,0,0,1,316.19,50.35Z" class="bright-star" />
      <path d="M255.06,104.67a.69.69,0,1,1-.68-.68A.69.69,0,0,1,255.06,104.67Z" class="bright-star" />
      <path d="M507.32,349.82a.68.68,0,1,1-.68-.68A.68.68,0,0,1,507.32,349.82Z" class="bright-star" />
      <path d="M531.6,253.57a.69.69,0,1,1-.68-.68A.69.69,0,0,1,531.6,253.57Z" class="bright-star" />
      <path d="M442.8,252.65a.68.68,0,1,1-.68-.68A.68.68,0,0,1,442.8,252.65Z" class="bright-star" />
      <path d="M198.74,254.31a.69.69,0,1,1-.69-.69A.69.69,0,0,1,198.74,254.31Z" class="bright-star" />
      <path d="M416.47,274.26a.68.68,0,1,1-.68-.68A.68.68,0,0,1,416.47,274.26Z" class="bright-star" />
      <path d="M419.23,205.39a.68.68,0,1,1-.68-.68A.68.68,0,0,1,419.23,205.39Z" class="bright-star" />
      <path d="M289.42,155.74a.69.69,0,1,1-.69-.68A.68.68,0,0,1,289.42,155.74Z" class="bright-star" />
      <path d="M549.76,294.82a.68.68,0,1,1-.68-.68A.68.68,0,0,1,549.76,294.82Z" class="bright-star" />
      <path d="M507.39,73.87a.68.68,0,1,1-.68-.68A.67.67,0,0,1,507.39,73.87Z" class="bright-star" />
      <circle
        cx="181.54"
        cy="309.47"
        r="5.35"
        transform="translate(-153.02 439.1) rotate(-80.78)"
        style="fill: #2d2e83"
      />
      <g style="opacity: 0.39">
        <circle cx="297.28" cy="85.72" r="10.71" style="fill: #2d2e83" />
        <circle
          cx="310.92"
          cy="79"
          r="2.94"
          transform="translate(182.93 373.16) rotate(-80.74)"
          style="fill: #2d2e83"
        />
      </g>
      <circle
        cx="784.83"
        cy="170.1"
        r="14"
        transform="translate(502.57 921.91) rotate(-81.65)"
        style="fill: #2d2e83; opacity: 0.39"
      />
      <path d="M184.9,180.24a1.68,1.68,0,1,1-1.68-1.67A1.68,1.68,0,0,1,184.9,180.24Z" style="fill: #2d2e83" />
      <path d="M367.31,365.54a1.68,1.68,0,1,1-1.68-1.68A1.68,1.68,0,0,1,367.31,365.54Z" style="fill: #2d2e83" />
      <circle cx="335" cy="171.26" r="1.68" style="fill: #2d2e83" />
      <circle cx="352.45" cy="126.45" r="1.68" style="fill: #2d2e83" />
      <circle cx="603.5" cy="87.4" r="1.68" transform="translate(379.78 654.67) rotate(-76.72)" style="fill: #2d2e83" />
      <circle cx="736.64" cy="54.22" r="5.23" style="fill: #2d2e83; opacity: 0.68" />
      <circle cx="299.94" cy="397.72" r="2.85" style="fill: #2d2e83" />
      <circle cx="226.95" cy="137.92" r="1.95" style="fill: #2d2e83" />
      <circle cx="793.3" cy="443.58" r="3.67" style="fill: #2d2e83" />
      <circle cx="759.1" cy="398.33" r="6.71" style="fill: #2d2e83" />
      <g style="opacity: 0.68">
        <circle cx="566.66" cy="62.64" r="9.66" style="fill: #2d2e83" />
      </g>
      <g style="opacity: 0.39">
        <circle cx="784.64" cy="81.25" r="9.66" style="fill: #2d2e83" />
      </g>
      <path d="M760.47,443.22a4.68,4.68,0,1,0,0-6.62A4.68,4.68,0,0,0,760.47,443.22Z" style="fill: #2d2e83" />
      <path d="M641.6,144.83a3.52,3.52,0,1,0,0-5A3.52,3.52,0,0,0,641.6,144.83Z" style="fill: #2d2e83" />
      <path d="M427,149.42a9.11,9.11,0,1,1-9.11-9.11A9.11,9.11,0,0,1,427,149.42Z" style="fill: #2d2e83" />
      <path d="M908.07,364.47a84,84,0,1,1-84-84A84,84,0,0,1,908.07,364.47Z" style="fill: #2d2e83" />
      <path
        d="M824.11,280.51a83.56,83.56,0,0,0-40.85,10.63,83.91,83.91,0,0,1,76,149.49,83.91,83.91,0,0,0-35.16-160.12Z"
        style="fill: #36a9e1"
      />
      <path d="M266,83.81a65.76,65.76,0,1,1-65.75-65.76A65.76,65.76,0,0,1,266,83.81Z" style="fill: #2d2e83" />
      <path
        d="M200.22,18.05a65.45,65.45,0,0,0-32,8.33,65.72,65.72,0,0,1,59.53,117.09A65.73,65.73,0,0,0,200.22,18.05Z"
        style="fill: #36a9e1"
      />
      <path d="M694.78,45.88A42.8,42.8,0,1,1,652,3.08,42.8,42.8,0,0,1,694.78,45.88Z" style="fill: #2d2e83" />
      <path
        d="M652,3.08A42.58,42.58,0,0,0,631.16,8.5,42.78,42.78,0,0,1,669.9,84.71,42.78,42.78,0,0,0,652,3.08Z"
        style="fill: #2d4b88"
      />
      <path d="M306.63,118.62a9.12,9.12,0,1,1-9.12-9.11A9.11,9.11,0,0,1,306.63,118.62Z" style="fill: #2d2e83" />
      <path d="M280.23,323.74a2.93,2.93,0,1,1-2.93-2.93A2.93,2.93,0,0,1,280.23,323.74Z" style="fill: #2d2e83" />
      <path
        d="M277.3,320.81a2.94,2.94,0,0,0-1.42.37,2.93,2.93,0,0,1,2.65,5.22,2.93,2.93,0,0,0-1.23-5.59Z"
        style="fill: #36a9e1"
      />
      <path d="M636.48,395.38a13.6,13.6,0,1,1-13.6-13.6A13.6,13.6,0,0,1,636.48,395.38Z" style="fill: #2d2e83" />
      <g style="opacity: 0.39">
        <path d="M368.66,398.33a13,13,0,1,1-13-13A13,13,0,0,1,368.66,398.33Z" style="fill: #2d2e83" />
        <path
          d="M355.67,385.34a12.85,12.85,0,0,0-6.32,1.65,13,13,0,0,1,11.76,23.13,13,13,0,0,0-5.44-24.78Z"
          style="fill: #2d2e83"
        />
      </g>
      <path d="M221.62,377.65a58,58,0,1,1-58-58A58,58,0,0,1,221.62,377.65Z" style="fill: #2d2e83" />
      <path
        d="M163.61,319.63A57.7,57.7,0,0,0,135.38,327a58,58,0,0,1,52.52,103.3,58,58,0,0,0-24.29-110.65Z"
        style="fill: #2d4b88"
      />
    </g>
    <g id="maestro">
      <path
        d="M521,185.54l3.86-2A2.24,2.24,0,0,0,526,180.8l-1.75-4.62a2.23,2.23,0,0,0-2.64-1.37l-4.2,1.08Z"
        style="fill: url(#kabas-not-found-gradient)"
      />
      <polygon points="525.29 181.89 523.05 175.96 518.47 176.41 522.37 176.86 525.29 181.89" style="fill: #a5cbef" />
      <path
        d="M485.58,199l-4.2,1.08a2.23,2.23,0,0,1-2.64-1.37L477,194a2.23,2.23,0,0,1,1.08-2.78l3.86-2Z"
        style="fill: url(#kabas-not-found-gradient-2)"
      />
      <path
        d="M561.14,331.35s4.3.86,5,3.55c.42,1.7,1.61,5.43,2.43,7.93a10.11,10.11,0,0,0,2.71,4.26l.73.69a5.29,5.29,0,0,0,3.74,1.44c3.1-.08,8.19-1.09,8.22-6.88a9.79,9.79,0,0,0-2.06-6c-1.18-1.56-3-4.63-4.71-10.19,0,0-2.95-5.11-8.21-4.36Z"
        style="fill: url(#kabas-not-found-gradient-3)"
      />
      <path
        d="M571.53,335.54a4.71,4.71,0,0,0-.92-1.76,6.55,6.55,0,0,0-1.77-1.14,9.91,9.91,0,0,1-4.49-5.2l-3.21,3.91s4.3.86,5,3.55c.42,1.7,1.61,5.43,2.43,7.93a10.11,10.11,0,0,0,2.71,4.26l.73.69a5.29,5.29,0,0,0,3.74,1.44,13.89,13.89,0,0,0,3.21-.45c0-.12,0-.24,0-.37-.32-2.91-3.2-4.75-4.89-7.15A22.72,22.72,0,0,1,571.53,335.54Z"
        style="fill: #b7d5f2"
      />
      <path d="M561.15,331.33v0l.13,0Z" style="fill: #a5cbef" />
      <path
        d="M581.88,336.38c-1.18-1.56-3-4.63-4.71-10.19,0,0-2.07-3.59-5.84-4.31a10.25,10.25,0,0,1,4,3.68c1.69,5.57,3.53,8.64,4.71,10.19a9.88,9.88,0,0,1,2.06,6c0,5.78-5.12,6.79-8.22,6.87a4.74,4.74,0,0,1-.84,0,5.29,5.29,0,0,0,2.7.68c3.1-.08,8.19-1.09,8.22-6.88A9.79,9.79,0,0,0,581.88,336.38Z"
        style="fill: #a5cbef"
      />
      <path
        d="M576.47,314.46l2.23,8.09a5.19,5.19,0,0,0,1.91,2.79c1.86,1.38,5,4.21,5.82,8,1.1,5.21,21.45.87,18.42-7.29a1.66,1.66,0,0,0-1.67-1.07h0l-.82-2.83a4.16,4.16,0,0,0-2.59-2.78c-2.69-1-7.36-3.14-8.93-7Z"
        style="fill: url(#kabas-not-found-gradient-4)"
      />
      <path
        d="M604.85,326a1.66,1.66,0,0,0-1.67-1.07h0l-.82-2.83a4.16,4.16,0,0,0-2.59-2.78c-2.69-1-7.36-3.14-8.93-7l-5.73.86c.27.9.56,1.81.85,2.7a3.9,3.9,0,0,0,.91,1.7,4.54,4.54,0,0,0,2.38.83,13,13,0,0,1,5.39,2.32,6.77,6.77,0,0,1,2.23,2.56,14.48,14.48,0,0,1,.71,2.92,7.92,7.92,0,0,0,4,5.55,6.18,6.18,0,0,0,1,.35C604.6,330.51,605.74,328.44,604.85,326Z"
        style="fill: #b7d5f2"
      />
      <path
        d="M585.61,330.38c-.8-3.77-4-6.6-5.82-8a5.27,5.27,0,0,1-1.84-2.56l.75,2.71a5.19,5.19,0,0,0,1.91,2.79c1.86,1.38,5,4.21,5.82,8,1.1,5.21,21.45.87,18.42-7.29a1.7,1.7,0,0,0-.55-.74C603.39,332,586.61,335.1,585.61,330.38Z"
        style="fill: #a5cbef"
      />
      <path
        d="M477.45,273.3s3.59,6,6.85,8.64l-.1.42a2.7,2.7,0,0,1-3.41,2c-1.76-.53-3.77-1.59-4.22-3.73L475,296.93a1.74,1.74,0,0,1-1.77,1.58h0a1.76,1.76,0,0,1-1.71-1.83l.48-10.19-3.91,12.43a1.73,1.73,0,0,1-2,1.17h0a1.73,1.73,0,0,1-1.33-2L466.79,286l-4,9a1.56,1.56,0,0,1-2.07.78h0a1.55,1.55,0,0,1-.81-1.94l3.33-9.23-4.36,5a1.38,1.38,0,0,1-1.88.21h0a1.39,1.39,0,0,1-.3-1.94L462.32,276a25.82,25.82,0,0,1,4.7-7.87S467.67,269.52,477.45,273.3Z"
        style="fill: url(#kabas-not-found-gradient-5)"
      />
      <path
        d="M548.66,216.35c1.7,3.6,13.13,4.24,13.13,4.24s.36-13.15-1.59-20.1-19-7.08-19-7.08c-4.89-7-19-.52-19-.52l-28.13,11.76c-7.66-2.31-9.69,7.28-9.69,7.28-4.34-1.3-6.79,7.77-6.79,7.77s-7.53,16.14-5.24,24.44c0,0-10.55,16.54-9.4,27.64,0,0,5.13,9.09,14.44,3.2,0,0,3-1,1-5.47,0,0,8.32-10.27,8.32-19.26,0,0,5.83-3.45,6.5-10.2l10.45,20.48a12.74,12.74,0,0,1,.68,1.78s2.55,25.61,27.28,48.84,29.41,22.14,29.41,22.14,10.06-2.11,7.88-11.46c0,0-7.89-21.79-23.8-22.56a40.43,40.43,0,0,0-4.91-27.41c.19-.1.37-.19.54-.3,3.41,2.83,12.18,9.32,21.45,10.05,0,0,1.88,22,14.44,34.59,0,0,10.67,3.09,15.44-5.84,0,0-2.22-38.18-12.69-48.82l-25.61-19.28h0C553.68,231.34,549.61,219.08,548.66,216.35Z"
        style="fill: url(#kabas-not-found-gradient-6)"
      />
      <path
        d="M477.27,273.72c-8.54,5.4-13.56-1.79-14.34-3,0,.37,0,.73.08,1.08,0,0,5.13,9.09,14.44,3.2,0,0,2.35-.76,1.51-4A2.72,2.72,0,0,1,477.27,273.72Z"
        style="fill: #231f20"
      />
      <path
        d="M500.66,170.81h0a1.37,1.37,0,0,0-1.76-.79l-6.79,2.57a1.36,1.36,0,0,0-.8,1.75h0a20.81,20.81,0,0,0-7.64,24.47s1.3,8.55,3.63,9.6c9.48,4.28,34.62-5.23,38.9-14.7,1.06-2.34-3.63-9.6-3.63-9.6A20.81,20.81,0,0,0,500.66,170.81Z"
        style="fill: #231f20"
      />
      <path
        d="M500.26,169.78h0a1.35,1.35,0,0,0-1.75-.79l-6.79,2.56a1.37,1.37,0,0,0-.8,1.76h0a20.81,20.81,0,0,0-7.64,24.47s1.29,8.54,3.63,9.6c9.48,4.27,34.62-5.23,38.9-14.7,1.05-2.34-3.63-9.61-3.63-9.61A20.79,20.79,0,0,0,500.26,169.78Z"
        style="fill: url(#kabas-not-found-gradient-7)"
      />
      <path
        d="M524.8,192.21c-3.86,8.72-28.76,18.13-37.42,14.14-1.26-.58-1.75-3.71-1.94-5.92-.12-1.36-.29-2.71-.47-4.07-1.09-8.13,4.09-16.35,12.62-19.58h0c8.66-3.27,18.15-.39,22.63,6.68.56.88,1.15,1.75,1.79,2.59C523.36,187.8,525.41,190.83,524.8,192.21Z"
        style="fill: #231f20"
      />
      <path
        d="M520.5,186.56c-.57-.74-1.09-1.51-1.59-2.3-4-6.3-12.44-8.87-20.16-5.95h0a18.06,18.06,0,0,0-7.88,5.74,18.14,18.14,0,0,1,7.06-4.77h0c7.72-2.92,16.17-.35,20.16,5.94a28,28,0,0,0,1.6,2.31c1.19,1.56,3,4.26,2.48,5.49a8.62,8.62,0,0,1-1,1.65,9.64,9.64,0,0,0,1.82-2.62C523.53,190.82,521.7,188.13,520.5,186.56Z"
        style="fill: #292929"
      />
      <polygon points="479.97 199.02 477.73 193.09 481.46 190.4 478.83 193.31 479.97 199.02" style="fill: #a5cbef" />
      <path
        d="M484.73,211.05a2.46,2.46,0,0,0-.29.88,3,3,0,0,0-.83-.12,5.72,5.72,0,0,1,.49.93,3.69,3.69,0,0,0,.56.74,4,4,0,0,0,.37.35c.18.15.37.28.54.43a9.6,9.6,0,0,1,1.35,1.62,45.28,45.28,0,0,0,3.3,3.76l.43.42c.82.82,1.85,1.65,3,1.49.22,0,.47-.13.54-.34a.59.59,0,0,0-.07-.48,4.2,4.2,0,0,0-1.65-1.5,17,17,0,0,1-3.45-3.06,5.6,5.6,0,0,1-1.2-1.73,18.24,18.24,0,0,0-.9-1.83,5.32,5.32,0,0,0-.68-1.12c-.26-.31-.92-.83-1.36-.61C484.83,210.89,484.78,211,484.73,211.05Z"
        style="fill: #a5cbef"
      />
      <path
        d="M505.6,260.54a1.66,1.66,0,0,0,.49-.64,7.58,7.58,0,0,1,5.21-3.69,5,5,0,0,0-3.46-2.88c-1.49-.47-3.07-.61-4.52-1.2a4.32,4.32,0,0,1-2.91-3.35c-.08-1.28.67-2.64.09-3.79a3.78,3.78,0,0,0-.82-1l-2.29-2.14a36.52,36.52,0,0,1-3.73-3.34c-.78-.94-1.61-2.06-2.82-2.18.17,1.26.6,2.46.88,3.7a10.1,10.1,0,0,0,1.06,2.37,12.54,12.54,0,0,0,.48-2.38c0,.18.32.63.4.79l4.31,8.43,2.67,5.24a15,15,0,0,0,1.2,2.45c.76,1,1.55,2,2.36,3,.33.39.82.83,1.3.64Z"
        style="fill: #b7d5f2"
      />
      <path
        d="M486.77,250.24h0c.36-.06.9-.57.4-.8a2.93,2.93,0,0,0-1.38-.14,15.59,15.59,0,0,0-3.41.49c.08.68.87,1,1.56,1a12,12,0,0,1,1.69-.13c.38.05.73.19,1.12.23C486.75,250.69,486.75,250.47,486.77,250.24Z"
        style="fill: #a5cbef"
      />
      <path
        d="M475.93,268.44a11,11,0,0,1-3.32-1.32c-.21-.1-.51-.18-.64,0a5.94,5.94,0,0,0,2.45,2.21,6.09,6.09,0,0,0,1.56.52,7.17,7.17,0,0,1,.81.11c.68.19,1.34.43,2,.56-.07-.17-.12-.35-.19-.53s-.22-.38-.2-.5.3-.37.38-.48.52-.56.28-.77-.75,0-1,0c-.51.07-1,.16-1.54.19A4.75,4.75,0,0,1,475.93,268.44Z"
        style="fill: #a5cbef"
      />
      <path
        d="M545.66,213a.76.76,0,0,0-.68.08c-.23.21-.13.58,0,.87a22.91,22.91,0,0,0,1,2.45,2.61,2.61,0,0,1,.33.67c.13.58.11,1.21.19,1.79a16.11,16.11,0,0,0,.64,2.93c.6,1.79,1.79,3.33,2.33,5.14a5.24,5.24,0,0,1-.45,3.29,7.41,7.41,0,0,1-.76,1.61,8.82,8.82,0,0,1-1.91,1.85,9,9,0,0,0-2.19,2.61c-.64,1.13-1.77,2.86-1.55,4.21a1.27,1.27,0,0,0,1.32,1.13,2.31,2.31,0,0,0,1-.66,6,6,0,0,1,5.28-1.45c.4.12.5.28.69.67a7.45,7.45,0,0,1,.71,2.7,6.68,6.68,0,0,1-1.18,4.64,8.51,8.51,0,0,1-1.55,1.78,11.77,11.77,0,0,1-2.07,1.18,176.72,176.72,0,0,1-24.27,9.4,35.91,35.91,0,0,1-10.48,2c-2.63,0-5.34-.52-7.92-.38.08.23.17.49.27.8a53.68,53.68,0,0,0,2.11,9.32,9.08,9.08,0,0,0,2.89-.23c.82-.21,1.53-.65,2.33-.9a24.88,24.88,0,0,0,5.08-2.66c2.36-1.36,5.41-2.29,7.73-.84,1.14.72,2,1.95,3.26,2.36s2.73-.16,4-.73q3.59-1.62,7.13-3.36a5.27,5.27,0,0,0,2.72-2.22,10.83,10.83,0,0,0,.48-2.29c.62-2.85,3.45-4.6,6.09-5.83s5.58-2.45,7-5a9.48,9.48,0,0,0,.59-6.19l-1.95-1.47a65.72,65.72,0,0,0-1.26-11.33v0c-.22-1.14-.46-2.28-.72-3.41l0-.1-.24-1,0-.14c-.08-.33-.17-.67-.25-1l0-.18c-.08-.34-.17-.67-.26-1l0-.1c-.2-.74-.4-1.47-.61-2.21L550,220.5a2.41,2.41,0,0,1-.08-.25c-.1-.32-.19-.63-.29-.94s-.11-.37-.17-.55-.18-.57-.28-.86c0-.12-.08-.24-.11-.35l-.41-1.2h0c1.7,3.6,13.13,4.24,13.13,4.24v-.28h0V220h0c0-.68,0-1.86,0-3.35h0a32.61,32.61,0,0,1-4.73-.65C553.23,215.14,549.51,213.82,545.66,213Z"
        style="fill: #b7d5f2"
      />
      <path
        d="M478,282.89a7.5,7.5,0,0,1-1.15-2.91c-.66.5-.64,1.46-.62,2.29a33.58,33.58,0,0,1-1.6,9.54,6.65,6.65,0,0,0-.35,2.79,6.27,6.27,0,0,0,.49,1.5l.26.65,1.55-16.16A4.14,4.14,0,0,0,478,282.89Z"
        style="fill: #a5cbef"
      />
      <path d="M501.91,223.1a3.22,3.22,0,1,1-4.15-1.88A3.22,3.22,0,0,1,501.91,223.1Z" style="fill: #ef0405" />
      <circle cx="498.9" cy="224.23" r="2.17" style="fill: #f9696a" />
      <rect
        x="522.57"
        y="209.71"
        width="13.73"
        height="4.2"
        transform="translate(-40.69 200.88) rotate(-20.71)"
        style="fill: #b7d5f2"
      />
      <path
        d="M485,205.74a39.86,39.86,0,0,0,1.11,11.1A42.76,42.76,0,0,0,490,227a51.12,51.12,0,0,0,6.13,9.08c1.2,1.42,2.47,2.79,3.8,4.09a50.17,50.17,0,0,0,4.06,3.64,23.56,23.56,0,0,0,4.68,2.86,46.6,46.6,0,0,0,4.89,1.89l1.24.41a1,1,0,1,0,.85-1.89c-1.63-.54-3.26-1.08-4.85-1.74-.79-.33-1.57-.68-2.33-1.08q-.58-.3-1.14-.63l-.12-.07c.18.1,0,0,0,0l-.26-.16-.56-.38a37.84,37.84,0,0,1-4.13-3.46c-.65-.61-1.28-1.23-1.9-1.86l-.22-.23-.12-.13c-.16-.16-.31-.33-.46-.49-.3-.32-.59-.64-.88-1a50.84,50.84,0,0,1-6.19-8.6l-.33-.59-.15-.27s.08.15,0,0l-.1-.19c-.22-.4-.42-.8-.62-1.21-.39-.78-.75-1.58-1.09-2.38a43,43,0,0,1-1.76-5c-.12-.44-.24-.87-.34-1.31,0-.11-.05-.21-.08-.31s-.05-.22,0-.09,0,0,0,0l-.15-.7c-.18-.87-.33-1.75-.46-2.63a34.38,34.38,0,0,1-.35-5.07c0-.21-1.54-2.48-1.79-2.23a.81.81,0,0,0-.23.52Z"
        style="fill: #ef4305"
      />
      <path
        d="M526.18,190.18a40.43,40.43,0,0,1,6.52,9.06,43.65,43.65,0,0,1,3.8,10.14,51.63,51.63,0,0,1,1.4,10.87c.05,1.86,0,3.72-.14,5.58a47.21,47.21,0,0,1-.64,5.41,23,23,0,0,1-1.61,5.24,47.66,47.66,0,0,1-2.42,4.65l-.66,1.14a1.06,1.06,0,0,1-1.37.51,1,1,0,0,1-.52-1.37c.87-1.48,1.73-3,2.49-4.52.38-.77.73-1.55,1-2.35.15-.4.3-.81.43-1.23a.56.56,0,0,0,0-.12c-.06.19,0,0,0,0s.06-.19.08-.29.12-.44.18-.66a38.15,38.15,0,0,0,.8-5.32c.09-.89.15-1.77.19-2.65,0-.11,0-.21,0-.32s0-.4,0-.18c0-.23,0-.45,0-.68,0-.43,0-.87,0-1.3a51.22,51.22,0,0,0-1-10.55c0-.22-.09-.44-.14-.66s0-.2-.07-.3,0,.17,0,.05,0-.15-.05-.22c-.11-.44-.22-.87-.34-1.31-.22-.84-.48-1.68-.76-2.51a43.3,43.3,0,0,0-2-4.94c-.2-.41-.4-.81-.61-1.21l-.15-.28,0-.08c.06.11,0,0,0,0-.11-.21-.23-.42-.35-.63-.44-.77-.9-1.53-1.39-2.27a35.67,35.67,0,0,0-3.09-4c-.14-.15-.49-2.88-.14-2.85a.75.75,0,0,1,.52.23Z"
        style="fill: #ef4305"
      />
      <path
        d="M556.06,244h0a5.87,5.87,0,0,1-2.34-4.32c-.06-.86-.14-1.72-.23-2.58a81.87,81.87,0,0,1-12,8.93c-1.25.76-2.51,1.49-3.79,2.19a93.58,93.58,0,0,1-17.81,7.21q-2.57.75-5.17,1.36a112.22,112.22,0,0,1-12,2l.9,1.77a10.76,10.76,0,0,1,.47,1.15,5.56,5.56,0,0,1,.27,1.14c.07.52.21,1.42.46,2.64,1.65-.21,3.3-.45,4.95-.73A112.36,112.36,0,0,0,525,261c.4-.13,1.54,1.68,1.94,1.54a92.25,92.25,0,0,0,11.48-4.71c.23-.12-.27-2.17,0-2.29a89.93,89.93,0,0,0,12.75-7.72C552.82,246.63,554.47,245.32,556.06,244Z"
        style="fill: #231f20"
      />
      <path
        d="M559.65,277.5a17.18,17.18,0,0,1-5.86-1.38l-13.22-4.82.19.28,0,0c3.41,2.83,12.18,9.32,21.45,10.05,0,0,1.88,22,14.44,34.59l.63.15a5.19,5.19,0,0,0-.25-.79q-5-13-9.95-25.93c-1.52-4-2.81-9.05.3-11.94C565.17,276.29,562.29,277.48,559.65,277.5Z"
        style="fill: #b7d5f2"
      />
      <path
        d="M545.16,299.27a40.19,40.19,0,0,0-3.9-25.53l-1.71-1.25c2.43,8.93,4.75,18.89.39,27.05-.56,1.06-1.16,2.52-.23,3.28a2.59,2.59,0,0,0,1.68.37,19.64,19.64,0,0,1,14,6.11c4.53,4.83,6.55,11.9,12,15.67a5.76,5.76,0,0,0,1.75.83,10.14,10.14,0,0,0-.2-4S561.07,300,545.16,299.27Z"
        style="fill: #b7d5f2"
      />
      <path
        d="M482.67,215.27a6.43,6.43,0,0,0,1.31-1.73,1.86,1.86,0,0,0-.1-1.72c-4-.41-6.23,7.88-6.23,7.88s-7.53,16.14-5.24,24.44c0,0-10.14,15.9-9.46,27a4.34,4.34,0,0,0,2.42-2.64,25.46,25.46,0,0,0,.86-3.87c1.22-5.79,5.52-10.59,6.56-16.41.15-.85.4-1.92,1.25-2.1s1.4.51,1.93,1.07,1.38,1.09,2,.66a1.27,1.27,0,0,0,.15-1.73,6.78,6.78,0,0,0-1.42-1.3,7.55,7.55,0,0,1-2.25-6.5,22.54,22.54,0,0,1,2.17-6.79,1.84,1.84,0,0,1,.63-.88c.51-.31,1.17,0,1.75.11s1.38.12,1.56-.46A13.91,13.91,0,0,1,482.67,215.27Z"
        style="fill: #a5cbef"
      />
      <path
        d="M541.23,193.41c-1.75-2.5-4.67-3.27-7.71-3.24.24.12.47.25.69.38a7.36,7.36,0,0,1,3.66,4c.42,1.48,0,3.06,0,4.6,0,.58.21,1.32.79,1.37.83.08.9-1.16,1.08-2a3.22,3.22,0,0,1,2.61-2.26,9.11,9.11,0,0,1,3.63.24c2.37.48,4.88,1.07,6.58,2.79a21.19,21.19,0,0,1,2.35,3.4,5.88,5.88,0,0,0,3.06,2.66,2.88,2.88,0,0,0,3-.88,32.14,32.14,0,0,0-.8-4C558.25,193.53,541.23,193.41,541.23,193.41Z"
        style="fill: #a5cbef"
      />
      <path
        d="M552.61,190.49S547,186.24,543,185l-.07-.43a2.71,2.71,0,0,1,2.44-3.09c1.82-.17,4.09.07,5.3,1.89l-4.64-15.75a1.74,1.74,0,0,1,1.05-2.12h0a1.75,1.75,0,0,1,2.27,1.05l3.36,9.64-1-13a1.73,1.73,0,0,1,1.44-1.85h0a1.74,1.74,0,0,1,2,1.34l2.62,12.05.38-9.83a1.56,1.56,0,0,1,1.63-1.5h0a1.56,1.56,0,0,1,1.48,1.5l.35,9.81,2.16-6.31a1.4,1.4,0,0,1,1.67-.9h0a1.39,1.39,0,0,1,1,1.7l-2,7.69s2.21,4.95.59,9.18C565.06,186.11,563.1,190.34,552.61,190.49Z"
        style="fill: url(#kabas-not-found-gradient-8)"
      />
      <path
        d="M566.44,168.55a4.36,4.36,0,0,0-1.61,2.74c-.35,1.29-.69,2.58-1,3.87a10.81,10.81,0,0,1-1.18,3.12c-.49.77-1.16,1.41-1.7,2.14a15.69,15.69,0,0,0-1.65,3.11,1.29,1.29,0,0,0-.11,1.11,1.33,1.33,0,0,0,.57.42,17.57,17.57,0,0,1,3.34,1.72,5.29,5.29,0,0,1,.78.72,4.63,4.63,0,0,0,1.22-1.39c1.62-4.23-.59-9.18-.59-9.18l2-7.69A1.34,1.34,0,0,0,566.44,168.55Z"
        style="fill: #a5cbef"
      />
      <path
        d="M550.15,183.07c.38.42.77.82,1.17,1.22a4,4,0,0,0,1,.8c.29.15.59.25.88.41s.8.58,1.14.29a.28.28,0,0,0,.09-.14c.08-.22-.12-.44-.29-.61a17.41,17.41,0,0,1-3.46-4.63A8.07,8.07,0,0,0,549.1,178l1.6,5.42a4,4,0,0,0-1.4-1.28C549.57,182.45,549.87,182.76,550.15,183.07Z"
        style="fill: #a5cbef"
      />
      <path
        d="M558,214.54c-1-6.12-3.37-18.31-5.87-23.44a.77.77,0,0,1,.69-1.11c3.14,0,10.91-.6,12.64-5.3,0,0,12,23.28,10.95,28.95-.83,4.31-5.37,9.93-11.55,8.39A9.35,9.35,0,0,1,558,214.54Z"
        style="fill: url(#kabas-not-found-gradient-9)"
      />
      <path
        d="M565.48,184.69a5.4,5.4,0,0,1-1.37,2.07c.16.48.33,1,.51,1.42a62,62,0,0,1,2.86,8.4,5,5,0,0,1-.27,2,37.53,37.53,0,0,1-1.34,4.54,3.76,3.76,0,0,0-.31,1.27,1.16,1.16,0,0,0,.63,1.07,1.26,1.26,0,0,0,.72,0,5.28,5.28,0,0,0,1.88-1,2.87,2.87,0,0,1,2.39-.77,2.36,2.36,0,0,1,1.36,1.25,15.39,15.39,0,0,1,.64,2.3,17.31,17.31,0,0,1,.47,2.76c.19,2.79-.62,5.54-.86,8.33,0,.55-.07,1.1-.07,1.65a11.86,11.86,0,0,0,3.71-6.34C577.51,208,565.48,184.69,565.48,184.69Z"
        style="fill: #a5cbef"
      />
      <path
        d="M552.52,191.89a.76.76,0,0,1,.76-.74c3.14,0,10.91-.6,12.64-5.3l-.44-1.16c-1.73,4.7-9.5,5.26-12.64,5.3a.77.77,0,0,0-.69,1.11C552.28,191.34,552.4,191.61,552.52,191.89Z"
        style="fill: #231f20"
      />
      <path d="M548.89,167.13a1.2,1.2,0,1,1-2.24.85,1.2,1.2,0,0,1,2.24-.85Z" style="fill: #231f20" />
      <path d="M554.64,163.09a1.2,1.2,0,1,1-1.54-.69A1.19,1.19,0,0,1,554.64,163.09Z" style="fill: #231f20" />
      <path
        d="M560.76,165a1.2,1.2,0,1,1-2.24.85,1.18,1.18,0,0,1,.69-1.54A1.2,1.2,0,0,1,560.76,165Z"
        style="fill: #231f20"
      />
      <circle
        cx="565.03"
        cy="169.14"
        r="1.2"
        transform="translate(312.13 701.97) rotate(-81.27)"
        style="fill: #231f20"
      />
      <path
        d="M546,183.11c1.13-.1,2-.71,1.94-1.37a.5.5,0,0,0,0-.12,8.6,8.6,0,0,0-2.52-.11,2.68,2.68,0,0,0-1.52.64C544,182.77,544.92,183.2,546,183.11Z"
        style="fill: #231f20"
      />
      <path
        d="M556.85,331.65a142.19,142.19,0,0,1-14.76-15.2,36.5,36.5,0,0,0-4.88-5.21,60.19,60.19,0,0,0-6.93-4.23c-.41-.25-.86-.57-.93-1a1.63,1.63,0,0,1,.61-1.27,13.73,13.73,0,0,1,3.46-2.54c-.17-1.28-2-1-3.34-1.05-2-.07-3.5-1.76-4.79-3.28-4-4.64-8.33-8.94-12-13.81s-6.72-10.41-7.52-16.46l-.41-.17c2.06,8.62,8.3,26.82,26.33,43.76C545.29,323.94,552.83,329.36,556.85,331.65Z"
        style="fill: #b7d5f2"
      />
      <path
        d="M545.46,297.62c.06-.48.13-1.05.19-1.71a43.69,43.69,0,0,1-5.95,4.42c-.65.4-1.32.79-2,1.16a45.16,45.16,0,0,1-9.31,3.66c-.7.2-1.4.37-2.1.54.87,1,1.77,1.91,2.71,2.85a47.5,47.5,0,0,0,6.11-2A45.75,45.75,0,0,0,546,300.1l.81-.67h0A1.6,1.6,0,0,1,545.46,297.62Z"
        style="fill: #231f20"
      />
      <path
        d="M579.43,261.54l-23.78-17.9c1.32,5.41,3.05,11.19,7.63,14.28,2.31,1.56,5.08,2.23,7.74,3.05a2.77,2.77,0,0,1,1.32.7c1.09,1.24-.31,3.78,1.14,4.58s3.07-1.71,4.55-1a2.47,2.47,0,0,1,1.07,1.58,351.75,351.75,0,0,1,11.55,43.64,5.34,5.34,0,0,0,1.25.32l.22-.4S589.9,272.18,579.43,261.54Z"
        style="fill: #b7d5f2"
      />
      <path
        d="M562.59,284.31a40.12,40.12,0,0,0,7.36-5.17,44.19,44.19,0,0,0,6.21-6.69,43.07,43.07,0,0,0,4.65-7.84c.14-.3.27-.6.4-.9a17.2,17.2,0,0,0-1.78-2.17l-.82-.62a41.13,41.13,0,0,1-2.19,4.77c-.32.59-.66,1.18-1,1.75a41.89,41.89,0,0,1-5,6.45c-.49.52-1,1-1.51,1.52a40.71,40.71,0,0,1-6.48,5c-.58.35-1.17.7-1.76,1h0a2,2,0,0,1,1.68,1.69c.05.34.1.74.17,1.18Z"
        style="fill: #231f20"
      />
      <path
        d="M592,308c-3.22,8.23-14.15,7.71-16.14,7.26.29.3.57.6.87.9,0,0,10.67,3.09,15.44-5.84Z"
        style="fill: #231f20"
      />
      <path d="M567.92,319.34c2.18,9.36-9.06,13.34-9.06,13.34l2,.89s10.25-2.39,8.07-11.74Z" style="fill: #231f20" />
      <circle
        cx="531.51"
        cy="256.61"
        r="3.15"
        transform="translate(-46.62 375.42) rotate(-37.38)"
        style="fill: #121010"
      />
      <rect
        x="505.57"
        y="219.46"
        width="32.69"
        height="35.05"
        rx="4.49"
        transform="translate(-50.08 199.85) rotate(-20.71)"
        style="fill: #a5cbef"
      />
      <rect
        x="505.27"
        y="218.66"
        width="32.69"
        height="35.05"
        rx="4.6"
        transform="translate(-49.82 199.69) rotate(-20.71)"
        style="fill: url(#kabas-not-found-gradient-10)"
      />
      <rect
        x="511.45"
        y="228.25"
        width="2.89"
        height="22.48"
        transform="matrix(0.94, -0.35, 0.35, 0.94, -51.55, 196.82)"
        style="fill: #19375f"
      />
      <rect
        x="516.95"
        y="226.17"
        width="2.89"
        height="22.48"
        transform="translate(-50.46 198.63) rotate(-20.71)"
        style="fill: #19375f"
      />
      <path d="M528.1,223.49a1.65,1.65,0,1,1-2.13-1A1.65,1.65,0,0,1,528.1,223.49Z" style="fill: #ef0405" />
      <circle
        cx="522.04"
        cy="225.78"
        r="1.65"
        transform="translate(-44.16 171.71) rotate(-17.93)"
        style="fill: #326ebd"
      />
      <g style="clip-path: url(#clip-path)">
        <path
          d="M512.66,217.42h0a6.33,6.33,0,0,1-8.4-3.06l-8.21-17.71,11.48-5.33L515.75,209A6.31,6.31,0,0,1,512.66,217.42Z"
          style="fill: #f3a470"
        />
        <path d="M503.46,175c3.51,2.5,7.2,9.3,5.25,12.46s-12-8.12-12-8.12Z" style="fill: #93412f" />
        <path
          d="M490.93,181.3c5.14-7.36,10.08-6.31,14.6-1.47s10.3,15.13,7.17,18.39-10.75,4.61-15.21-.38S488,185.46,490.93,181.3Z"
          style="fill: #f3a470"
        />
        <path
          d="M501.64,170.56c5.89,1.77,6.28,4.9,2.82,7.3a55.85,55.85,0,0,1-8.07,4.43s3.62,12.88,6.92,12.61,2.09-3.84,7.62-5.72c3.4-1.14,7.75,9,4.49,11.26s-10.62,3.05-13,.36-4-11.15-6.77-12.33-3.37,1.07-2.4,2.6,4.75,8.67,4.75,8.67-6.87-6-9.2-10.74-2.66-9.68,2.46-11.72,6.56-.88,9.06-3.09S501.64,170.56,501.64,170.56Z"
          style="fill: #93412f"
        />
      </g>
      <g style="opacity: 0.2">
        <path d="M488,184.24a18.21,18.21,0,0,0-2.64,5.78l26.89,12.18a45.1,45.1,0,0,0,6.8-3.9Z" style="fill: #fff" />
        <path
          d="M508.15,203.94l-23.3-10.55a16.41,16.41,0,0,0,.12,3c.06.49.13,1,.19,1.48l17.49,7.92C504.48,205.25,506.33,204.64,508.15,203.94Z"
          style="fill: #fff"
        />
      </g>
      <path
        d="M660.09,354.89c-19.4-5.3-38.37-14.17-52.69-28.57C595,313.88,586.84,297,586.27,279.38a33.27,33.27,0,0,0,12.67-7.44c4-3.91,6.84-9.44,6-15.13a10.12,10.12,0,0,0-9.93-8.64,7.71,7.71,0,0,0-6.21,3c-1.64,2.13-2.57,5-3.44,7.48a56.39,56.39,0,0,0-3,17.78c-1.79.48-3.6.86-5.42,1.17a51.12,51.12,0,0,1-16.92.19c-9.71-1.64-18.92-6.81-24-15.47a26.08,26.08,0,0,1-2.58-6.11c-.68-2.46-4.51-1.41-3.82,1.05,2.82,10.14,10.95,17.93,20.53,21.86,10,4.1,21.79,4.14,32.26,1.39a68.27,68.27,0,0,0,1.1,9.25A74.36,74.36,0,0,0,596,319a90.35,90.35,0,0,0,23.68,22.45,128.79,128.79,0,0,0,29.75,14.35q5.28,1.8,10.66,3.22Zm-73.68-82a52.61,52.61,0,0,1,3-13.91c.41-1.1.84-2.2,1.33-3.27a7.17,7.17,0,0,1,1.95-2.94,4.82,4.82,0,0,1,4.4-.29c4.24,1.52,4.65,6.47,3.3,10.31-1.59,4.53-5.52,8-9.6,10.27a34,34,0,0,1-4.5,2.09C586.31,274.42,586.35,273.67,586.41,272.92Z"
        style="fill: #c9e0f5"
      />
    </g>
    <g id="miss">
      <path
        d="M376.45,150.67l-1.94-1a1.13,1.13,0,0,1-.54-1.4l.87-2.32a1.12,1.12,0,0,1,1.33-.68l2.11.54Z"
        style="fill: url(#kabas-not-found-gradient-11)"
      />
      <polygon points="374.31 148.83 375.44 145.85 377.74 146.08 375.78 146.3 374.31 148.83" style="fill: #a5cbef" />
      <path
        d="M394.23,157.4l2.11.54a1.1,1.1,0,0,0,1.32-.68l.88-2.32a1.13,1.13,0,0,0-.54-1.4l-1.94-1Z"
        style="fill: url(#kabas-not-found-gradient-12)"
      />
      <path
        d="M356.34,223.92s-2.16.43-2.5,1.78c-.21.86-.81,2.73-1.22,4a5.06,5.06,0,0,1-1.36,2.14l-.36.34a2.63,2.63,0,0,1-1.88.72c-1.55,0-4.11-.54-4.12-3.45a5,5,0,0,1,1-3,16.15,16.15,0,0,0,2.36-5.11s1.48-2.57,4.12-2.2Z"
        style="fill: url(#kabas-not-found-gradient-13)"
      />
      <path
        d="M351.12,226a2.39,2.39,0,0,1,.46-.88,3.3,3.3,0,0,1,.89-.57,5,5,0,0,0,2.25-2.62l1.62,2s-2.16.43-2.5,1.78c-.21.86-.81,2.73-1.22,4a5.06,5.06,0,0,1-1.36,2.14l-.36.34a2.63,2.63,0,0,1-1.88.72,6.52,6.52,0,0,1-1.61-.22c0-.06,0-.12,0-.18.16-1.47,1.6-2.39,2.45-3.6A11.28,11.28,0,0,0,351.12,226Z"
        style="fill: #b7d5f2"
      />
      <path d="M356.33,223.91h-.06Z" style="fill: #a5cbef" />
      <path
        d="M345.93,226.44a16.15,16.15,0,0,0,2.36-5.11,4.83,4.83,0,0,1,2.93-2.17,5.24,5.24,0,0,0-2,1.85,16.07,16.07,0,0,1-2.37,5.12,5,5,0,0,0-1,3c0,2.9,2.57,3.41,4.13,3.45a3,3,0,0,0,.42,0,2.56,2.56,0,0,1-1.36.33c-1.55,0-4.11-.54-4.12-3.45A5,5,0,0,1,345.93,226.44Z"
        style="fill: #a5cbef"
      />
      <path
        d="M348.64,215.44l-1.11,4.06a2.64,2.64,0,0,1-1,1.4,7.29,7.29,0,0,0-2.92,4c-.55,2.62-10.76.43-9.24-3.66a.82.82,0,0,1,.84-.54h0l.41-1.43a2.1,2.1,0,0,1,1.3-1.39c1.35-.48,3.69-1.58,4.48-3.53Z"
        style="fill: url(#kabas-not-found-gradient-14)"
      />
      <path
        d="M334.41,221.25a.82.82,0,0,1,.84-.54h0l.41-1.43a2.1,2.1,0,0,1,1.3-1.39c1.35-.48,3.69-1.58,4.48-3.53l2.87.43c-.14.45-.28.9-.43,1.35a1.93,1.93,0,0,1-.45.86,2.33,2.33,0,0,1-1.2.41,6.59,6.59,0,0,0-2.7,1.17,3.4,3.4,0,0,0-1.12,1.29,7.23,7.23,0,0,0-.35,1.46,4,4,0,0,1-2,2.79l-.48.18A2.6,2.6,0,0,1,334.41,221.25Z"
        style="fill: #b7d5f2"
      />
      <path
        d="M344.06,223.43a7.36,7.36,0,0,1,2.92-4,2.61,2.61,0,0,0,.92-1.29l-.37,1.37a2.64,2.64,0,0,1-1,1.4,7.29,7.29,0,0,0-2.92,4c-.55,2.62-10.76.43-9.24-3.66a.81.81,0,0,1,.28-.37C335.14,224.24,343.56,225.8,344.06,223.43Z"
        style="fill: #a5cbef"
      />
      <path
        d="M398.31,194.75a20.12,20.12,0,0,1-3.44,4.34l.05.22a1.36,1.36,0,0,0,1.71,1,2.75,2.75,0,0,0,2.12-1.87l.79,8.21a.87.87,0,0,0,.89.79h0a.87.87,0,0,0,.85-.92l-.24-5.12,2,6.24a.87.87,0,0,0,1,.59h0a.87.87,0,0,0,.67-1l-1-6.11,2,4.51a.78.78,0,0,0,1,.39h0a.77.77,0,0,0,.41-1l-1.68-4.64,2.19,2.54a.71.71,0,0,0,.95.1h0a.7.7,0,0,0,.14-1l-2.82-6a13,13,0,0,0-2.36-3.95S403.22,192.85,398.31,194.75Z"
        style="fill: url(#kabas-not-found-gradient-15)"
      />
      <path
        d="M362.59,166.14c-.85,1.81-6.58,2.13-6.58,2.13s-.18-6.6.8-10.09,9.51-3.56,9.51-3.56c2.46-3.51,9.51-.26,9.51-.26l14.11,5.91c3.85-1.16,4.86,3.65,4.86,3.65,2.18-.65,3.41,3.91,3.41,3.91s3.78,8.11,2.63,12.27c0,0,5.29,8.31,4.71,13.89a4.92,4.92,0,0,1-7.24,1.61s-1.52-.49-.5-2.75c0,0-4.17-5.16-4.17-9.68a7.25,7.25,0,0,1-3.26-5.12l-5.24,10.29a5.64,5.64,0,0,0-.34.89s-1.28,12.87-13.69,24.54-14.75,11.12-14.75,11.12-5-1-3.95-5.76c0,0,4-10.94,11.94-11.33A20.35,20.35,0,0,1,366.81,194l-.27-.15c-1.71,1.42-6.11,4.68-10.76,5.05,0,0-.94,11.07-7.24,17.38,0,0-5.35,1.55-7.74-2.94,0,0,1.11-19.18,6.36-24.52L360,179.16h0A46.71,46.71,0,0,1,362.59,166.14Z"
        style="fill: url(#kabas-not-found-gradient-16)"
      />
      <path
        d="M398.4,195a4.89,4.89,0,0,0,7.19-1.52c0,.18,0,.36,0,.54a4.92,4.92,0,0,1-7.24,1.61s-1.18-.38-.76-2A1.38,1.38,0,0,0,398.4,195Z"
        style="fill: #231f20"
      />
      <path
        d="M386.67,143.27h0a.67.67,0,0,1,.88-.4l3.41,1.29a.69.69,0,0,1,.4.88h0a10.47,10.47,0,0,1,3.83,12.29s-.65,4.3-1.82,4.83c-4.76,2.14-17.37-2.63-19.51-7.39-.53-1.18,1.82-4.82,1.82-4.82A10.43,10.43,0,0,1,386.67,143.27Z"
        style="fill: #231f20"
      />
      <path
        d="M386.87,142.75h0a.68.68,0,0,1,.88-.4l3.4,1.29a.68.68,0,0,1,.4.88h0a10.46,10.46,0,0,1,3.83,12.29s-.64,4.3-1.82,4.83c-4.75,2.15-17.36-2.63-19.51-7.39-.53-1.17,1.82-4.82,1.82-4.82A10.44,10.44,0,0,1,386.87,142.75Z"
        style="fill: url(#kabas-not-found-gradient-17)"
      />
      <path
        d="M374.56,154c1.94,4.39,14.42,9.12,18.77,7.11.63-.29.88-1.86,1-3,.06-.69.15-1.37.24-2.05a9.46,9.46,0,0,0-6.33-9.83h0a9.4,9.4,0,0,0-11.35,3.35c-.28.45-.58.88-.9,1.3C375.28,151.8,374.26,153.32,374.56,154Z"
        style="fill: #231f20"
      />
      <path
        d="M376.72,151.18c.28-.37.55-.76.8-1.16a8.39,8.39,0,0,1,10.11-3h0a9.17,9.17,0,0,1,3.95,2.89,9.12,9.12,0,0,0-3.54-2.4h0a8.37,8.37,0,0,0-10.11,3c-.25.39-.52.78-.8,1.16-.6.78-1.52,2.13-1.25,2.75a3.87,3.87,0,0,0,.51.83,5,5,0,0,1-.92-1.31C375.2,153.32,376.12,152,376.72,151.18Z"
        style="fill: #292929"
      />
      <polygon points="397.04 157.44 398.17 154.46 396.3 153.11 397.62 154.57 397.04 157.44" style="fill: #a5cbef" />
      <path
        d="M394.66,163.48a1.11,1.11,0,0,1,.14.44,1.58,1.58,0,0,1,.42-.06,2.93,2.93,0,0,0-.24.47,1.76,1.76,0,0,1-.29.37c-.06.07-.12.12-.18.18l-.28.21a6,6,0,0,0-.67.82,22.9,22.9,0,0,1-1.66,1.89l-.21.21a1.89,1.89,0,0,1-1.5.74.31.31,0,0,1-.27-.16.37.37,0,0,1,0-.25,2.15,2.15,0,0,1,.83-.75,8.71,8.71,0,0,0,1.73-1.53,3,3,0,0,0,.6-.87,8.91,8.91,0,0,1,.46-.93,2.33,2.33,0,0,1,.34-.56c.13-.15.46-.41.68-.31C394.61,163.4,394.63,163.44,394.66,163.48Z"
        style="fill: #a5cbef"
      />
      <path
        d="M384.19,188.34a.89.89,0,0,1-.25-.32,3.76,3.76,0,0,0-2.61-1.85,2.54,2.54,0,0,1,1.74-1.45c.74-.23,1.54-.31,2.26-.6a2.15,2.15,0,0,0,1.46-1.69c0-.64-.33-1.32,0-1.9a1.57,1.57,0,0,1,.41-.48l1.15-1.08a18.42,18.42,0,0,0,1.87-1.68c.39-.47.8-1,1.41-1.09-.08.63-.3,1.23-.44,1.85a4.91,4.91,0,0,1-.53,1.19,6.27,6.27,0,0,1-.24-1.19c0,.09-.16.32-.2.4L388,182.68l-1.34,2.63a7.78,7.78,0,0,1-.6,1.23c-.38.52-.78,1-1.19,1.51-.16.2-.41.41-.65.32Z"
        style="fill: #b7d5f2"
      />
      <path
        d="M393.64,183.17h0c-.19,0-.46-.29-.21-.41a1.64,1.64,0,0,1,.7-.07,7.68,7.68,0,0,1,1.71.25c0,.34-.44.52-.78.52a6.07,6.07,0,0,0-.85-.06c-.2,0-.37.09-.56.11C393.64,183.4,393.65,183.28,393.64,183.17Z"
        style="fill: #a5cbef"
      />
      <path
        d="M399.07,192.31a5.55,5.55,0,0,0,1.67-.66c.1-.05.25-.09.32,0a3,3,0,0,1-2,1.37,2.7,2.7,0,0,0-.41.05c-.34.1-.67.22-1,.29l.09-.27c0-.06.11-.19.1-.25s-.15-.19-.19-.24-.26-.29-.14-.39.38,0,.49,0,.51.08.77.09A1.22,1.22,0,0,0,399.07,192.31Z"
        style="fill: #a5cbef"
      />
      <path
        d="M364.1,164.48a.37.37,0,0,1,.34,0c.12.11.07.3,0,.44a12.21,12.21,0,0,1-.51,1.23,1.17,1.17,0,0,0-.16.34c-.07.29-.06.61-.1.9a7.27,7.27,0,0,1-.32,1.47c-.3.9-.9,1.68-1.17,2.58a2.66,2.66,0,0,0,.23,1.66,3.52,3.52,0,0,0,.38.81,4.19,4.19,0,0,0,1,.92,4.67,4.67,0,0,1,1.1,1.32,3.94,3.94,0,0,1,.77,2.11.63.63,0,0,1-.66.57,1.1,1.1,0,0,1-.49-.33,3,3,0,0,0-2.65-.73c-.2.06-.26.14-.35.34a4,4,0,0,0-.36,1.35,3.44,3.44,0,0,0,.59,2.34,5.08,5.08,0,0,0,.78.89,5.93,5.93,0,0,0,1,.59A88.51,88.51,0,0,0,375.7,188a18,18,0,0,0,5.26,1c1.32,0,2.68-.26,4-.19l-.13.4a27,27,0,0,1-1.06,4.69,4.43,4.43,0,0,1-1.45-.12c-.41-.1-.77-.33-1.17-.45a12.44,12.44,0,0,1-2.55-1.34c-1.18-.68-2.71-1.15-3.87-.42-.58.36-1,1-1.64,1.18a2.86,2.86,0,0,1-2-.36c-1.2-.54-2.4-1.11-3.58-1.69a2.63,2.63,0,0,1-1.37-1.12,5.37,5.37,0,0,1-.24-1.14c-.31-1.44-1.72-2.32-3.05-2.93s-2.8-1.23-3.5-2.52a4.78,4.78,0,0,1-.29-3.11l1-.74a32.71,32.71,0,0,1,.63-5.69v0q.17-.85.36-1.71v-.06l.12-.5,0-.07.12-.5,0-.09c0-.17.09-.34.13-.51l0,0c.09-.37.2-.74.3-1.11l.18-.62,0-.13.15-.47c0-.09.05-.19.08-.27s.09-.29.14-.43l.06-.19.2-.6h0c-.85,1.81-6.58,2.13-6.58,2.13v-.14h0v0h0V168h0c0-.34,0-.93,0-1.68h0a14.54,14.54,0,0,0,2.37-.33C360.3,165.54,362.17,164.87,364.1,164.48Z"
        style="fill: #b7d5f2"
      />
      <path
        d="M398,199.57a3.86,3.86,0,0,0,.58-1.46c.33.25.32.74.3,1.15a17.06,17.06,0,0,0,.81,4.79,3.38,3.38,0,0,1,.17,1.4,3.21,3.21,0,0,1-.24.76l-.13.33-.78-8.12A2,2,0,0,1,398,199.57Z"
        style="fill: #a5cbef"
      />
      <path d="M386,169.53a1.63,1.63,0,0,0,.94,2.09,1.62,1.62,0,1,0-.94-2.09Z" style="fill: #ef0405" />
      <circle cx="387.55" cy="170.11" r="1.09" style="fill: #f9696a" />
      <polygon points="368.64 163.63 375.08 166.07 375.83 164.09 369.39 161.66 368.64 163.63" style="fill: #b7d5f2" />
      <path
        d="M394.51,160.81a20.13,20.13,0,0,1-.56,5.58,21.38,21.38,0,0,1-1.93,5.08,25.6,25.6,0,0,1-3.08,4.57c-.6.71-1.23,1.4-1.9,2.05a25.34,25.34,0,0,1-2,1.83,12,12,0,0,1-2.34,1.44,24.41,24.41,0,0,1-2.46.94l-.62.21a.53.53,0,0,1-.69-.26.54.54,0,0,1,.26-.69c.82-.27,1.64-.54,2.44-.87.4-.17.79-.34,1.17-.54l.57-.32.06,0c-.09.06,0,0,0,0a.57.57,0,0,0,.13-.08l.29-.19a18.88,18.88,0,0,0,2.07-1.74c.32-.3.64-.61.95-.93a.57.57,0,0,1,.11-.11s.14-.15.06-.07l.23-.25.44-.48a26.28,26.28,0,0,0,3.11-4.32l.16-.3.08-.14s0,.08,0,0l.05-.1.31-.61q.28-.58.54-1.2a20.51,20.51,0,0,0,.89-2.53c.06-.22.12-.44.17-.66a.83.83,0,0,1,0-.15s0-.11,0,0,0,0,0,0,.05-.23.08-.35c.09-.44.17-.88.23-1.32a18,18,0,0,0,.18-2.55c0-.1.77-1.24.89-1.12a.42.42,0,0,1,.12.26Z"
        style="fill: #ef4305"
      />
      <path
        d="M373.87,153a19.84,19.84,0,0,0-3.27,4.55,22,22,0,0,0-1.91,5.09,25.76,25.76,0,0,0-.7,5.46c0,.94,0,1.87.07,2.8a24.62,24.62,0,0,0,.32,2.72,11.68,11.68,0,0,0,.81,2.64,25.71,25.71,0,0,0,1.21,2.33l.33.57a.53.53,0,0,0,.69.26.51.51,0,0,0,.26-.68c-.43-.75-.87-1.5-1.25-2.28-.19-.38-.36-.77-.52-1.18-.08-.2-.15-.41-.22-.61l0-.07c0,.1,0,0,0,0s0-.09-.05-.14-.06-.22-.08-.33a18.65,18.65,0,0,1-.41-2.68c0-.44-.07-.88-.09-1.33a.81.81,0,0,1,0-.16s0-.2,0-.09c0-.11,0-.22,0-.34s0-.44,0-.65a25.47,25.47,0,0,1,.52-5.3c0-.11,0-.22.07-.33a.94.94,0,0,1,0-.16s0,.09,0,0l0-.11c.05-.22.1-.44.16-.66q.18-.63.39-1.26a21.41,21.41,0,0,1,1-2.48c.1-.21.21-.41.31-.61l.07-.14s.06-.1,0,0,0,0,0,0l.17-.32c.22-.38.46-.76.7-1.14a17.85,17.85,0,0,1,1.55-2c.07-.07.24-1.44.07-1.43a.37.37,0,0,0-.26.12Z"
        style="fill: #ef4305"
      />
      <path
        d="M358.88,180h0a3,3,0,0,0,1.18-2.17c0-.43.06-.86.11-1.3a41,41,0,0,0,6,4.49c.62.38,1.25.75,1.89,1.1a46.77,46.77,0,0,0,8.94,3.62c.86.25,1.72.48,2.59.68a55.26,55.26,0,0,0,6,1l-.45.89a4.15,4.15,0,0,0-.24.58,2.63,2.63,0,0,0-.13.57c0,.26-.11.71-.23,1.33-.83-.11-1.66-.23-2.48-.37a57.53,57.53,0,0,1-7.59-1.85c-.21-.07-.78.84-1,.77a46.35,46.35,0,0,1-5.75-2.37c-.12-.06.13-1.09,0-1.15a45.76,45.76,0,0,1-6.4-3.87C360.51,181.35,359.68,180.7,358.88,180Z"
        style="fill: #231f20"
      />
      <path
        d="M357.08,196.86a8.42,8.42,0,0,0,2.94-.69l6.63-2.42-.09.14h0c-1.71,1.42-6.11,4.68-10.76,5.05,0,0-.94,11.07-7.24,17.38l-.32.07a2.55,2.55,0,0,1,.13-.39q2.49-6.53,5-13c.76-2,1.41-4.55-.15-6C354.31,196.26,355.76,196.86,357.08,196.86Z"
        style="fill: #b7d5f2"
      />
      <path
        d="M364.35,207.8A20.22,20.22,0,0,1,366.3,195l.86-.63c-1.21,4.48-2.38,9.49-.2,13.59.29.53.59,1.27.12,1.65a1.33,1.33,0,0,1-.84.18,9.87,9.87,0,0,0-7,3.07c-2.27,2.43-3.28,6-6,7.87a2.86,2.86,0,0,1-.88.42,5.15,5.15,0,0,1,.1-2S356.37,208.19,364.35,207.8Z"
        style="fill: #b7d5f2"
      />
      <path
        d="M395.69,165.6a3.28,3.28,0,0,1-.66-.87,1,1,0,0,1,.05-.86c2-.21,3.13,4,3.13,4s3.78,8.11,2.63,12.27c0,0,5.08,8,4.74,13.56a2.16,2.16,0,0,1-1.21-1.33,11.58,11.58,0,0,1-.43-1.94c-.61-2.91-2.77-5.32-3.29-8.24-.08-.43-.21-1-.63-1.06s-.7.25-1,.54-.69.55-1,.33a.64.64,0,0,1-.07-.87,3.16,3.16,0,0,1,.71-.65,3.81,3.81,0,0,0,1.13-3.27,11.61,11.61,0,0,0-1.09-3.41.9.9,0,0,0-.32-.44c-.25-.16-.58,0-.87.06s-.7.05-.79-.24A7,7,0,0,0,395.69,165.6Z"
        style="fill: #a5cbef"
      />
      <path
        d="M366.32,154.62a4.37,4.37,0,0,1,3.87-1.63l-.35.19a3.66,3.66,0,0,0-1.83,2,8.92,8.92,0,0,0,0,2.31c0,.29-.1.66-.39.69s-.46-.58-.55-1a1.61,1.61,0,0,0-1.31-1.14,4.68,4.68,0,0,0-1.82.12,6.19,6.19,0,0,0-3.3,1.41,10.1,10.1,0,0,0-1.18,1.7,3,3,0,0,1-1.53,1.34,1.48,1.48,0,0,1-1.51-.44,16.18,16.18,0,0,1,.41-2C357.78,154.68,366.32,154.62,366.32,154.62Z"
        style="fill: #a5cbef"
      />
      <path
        d="M360.61,153.15a20.38,20.38,0,0,1,4.81-2.74l0-.22a1.36,1.36,0,0,0-1.22-1.55,2.76,2.76,0,0,0-2.66,1l2.33-7.91a.88.88,0,0,0-.53-1.07h0a.89.89,0,0,0-1.14.53L360.55,146l.51-6.52a.87.87,0,0,0-.73-.93h0a.86.86,0,0,0-1,.67L358,145.25l-.19-4.93a.78.78,0,0,0-.82-.76h0a.79.79,0,0,0-.74.76l-.17,4.92L355,142.07a.7.7,0,0,0-.83-.45h0a.69.69,0,0,0-.5.85l1,3.87a6.91,6.91,0,0,0-.29,4.61S355.35,153.08,360.61,153.15Z"
        style="fill: url(#kabas-not-found-gradient-18)"
      />
      <path
        d="M353.67,142.13a2.13,2.13,0,0,1,.81,1.38l.52,1.94a6.19,6.19,0,0,0,.59,1.57c.25.38.59.71.86,1.08a8,8,0,0,1,.83,1.56.63.63,0,0,1,.05.55.56.56,0,0,1-.29.21,9.12,9.12,0,0,0-1.67.87,3.33,3.33,0,0,0-.39.36,2.26,2.26,0,0,1-.61-.7,6.91,6.91,0,0,1,.29-4.61l-1-3.87A.78.78,0,0,1,353.67,142.13Z"
        style="fill: #a5cbef"
      />
      <path
        d="M361.85,149.43l-.59.61a2.09,2.09,0,0,1-.49.4,4.54,4.54,0,0,0-.45.21c-.14.08-.4.29-.57.14a.18.18,0,0,1-.05-.07c0-.11.06-.22.15-.31a9,9,0,0,0,1.74-2.32,4,4,0,0,1,.78-1.22l-.8,2.72a1.93,1.93,0,0,1,.7-.64C362.14,149.11,362,149.27,361.85,149.43Z"
        style="fill: #a5cbef"
      />
      <path
        d="M357.9,165.23c.53-3.07,1.69-9.2,2.94-11.77a.39.39,0,0,0-.34-.56c-1.58,0-5.48-.3-6.34-2.66,0,0-6,11.7-5.49,14.54.41,2.17,2.69,5,5.79,4.22A4.7,4.7,0,0,0,357.9,165.23Z"
        style="fill: url(#kabas-not-found-gradient-19)"
      />
      <path
        d="M354.16,150.24a2.69,2.69,0,0,0,.68,1c-.08.24-.16.48-.25.71a29.94,29.94,0,0,0-1.43,4.22,2.35,2.35,0,0,0,.13,1,17.58,17.58,0,0,0,.67,2.28,1.83,1.83,0,0,1,.16.64.58.58,0,0,1-.32.54.63.63,0,0,1-.36,0,2.57,2.57,0,0,1-.94-.52,1.44,1.44,0,0,0-1.2-.39,1.25,1.25,0,0,0-.69.63,9,9,0,0,0-.32,1.16,8.11,8.11,0,0,0-.23,1.38,28.21,28.21,0,0,0,.43,4.19,7.56,7.56,0,0,1,0,.83,5.88,5.88,0,0,1-1.86-3.19C348.13,161.94,354.16,150.24,354.16,150.24Z"
        style="fill: #a5cbef"
      />
      <path
        d="M360.66,153.86a.4.4,0,0,0-.38-.38c-1.58,0-5.48-.3-6.34-2.66l.22-.58c.86,2.36,4.76,2.64,6.34,2.66a.39.39,0,0,1,.34.56C360.78,153.58,360.72,153.72,360.66,153.86Z"
        style="fill: #231f20"
      />
      <path d="M362.48,141.42a.6.6,0,1,0,.77-.35A.59.59,0,0,0,362.48,141.42Z" style="fill: #231f20" />
      <path d="M359.59,139.39a.6.6,0,1,0,1.13.43.6.6,0,0,0-1.13-.43Z" style="fill: #231f20" />
      <path d="M356.53,140.35a.6.6,0,1,0,1.12.43.61.61,0,0,0-.35-.78A.6.6,0,0,0,356.53,140.35Z" style="fill: #231f20" />
      <circle cx="354.38" cy="142.43" r="0.6" style="fill: #231f20" />
      <path
        d="M363.93,149.44c-.57,0-1-.35-1-.68a.13.13,0,0,0,0-.06,4.22,4.22,0,0,1,1.27-.06,1.41,1.41,0,0,1,.76.32C364.93,149.28,364.47,149.49,363.93,149.44Z"
        style="fill: #231f20"
      />
      <path
        d="M358.49,224.07a71.2,71.2,0,0,0,7.4-7.64,17.3,17.3,0,0,1,2.45-2.61,28.53,28.53,0,0,1,3.47-2.13c.21-.13.43-.28.47-.52a.86.86,0,0,0-.31-.64,6.81,6.81,0,0,0-1.73-1.28c.08-.64,1-.5,1.67-.53a3.69,3.69,0,0,0,2.41-1.64c2-2.33,4.17-4.49,6-6.94a17.87,17.87,0,0,0,3.78-8.27l.2-.09a45.07,45.07,0,0,1-13.21,22C364.28,220.2,360.5,222.92,358.49,224.07Z"
        style="fill: #b7d5f2"
      />
      <path
        d="M364.2,207c0-.24-.07-.53-.1-.86a20.86,20.86,0,0,0,3,2.22c.33.21.66.4,1,.59a22.91,22.91,0,0,0,4.67,1.84l1.05.27c-.43.48-.88.95-1.35,1.43a25.05,25.05,0,0,1-3.07-1,23.24,23.24,0,0,1-5.48-3.23l-.41-.34h0A.81.81,0,0,0,364.2,207Z"
        style="fill: #231f20"
      />
      <path
        d="M347.16,188.85l11.93-9c-.66,2.72-1.53,5.63-3.83,7.18a14.34,14.34,0,0,1-3.88,1.53,1.49,1.49,0,0,0-.67.35c-.54.62.16,1.9-.56,2.3s-1.54-.86-2.29-.52a1.23,1.23,0,0,0-.53.8,176.37,176.37,0,0,0-5.8,21.92,2.69,2.69,0,0,1-.62.16,1.28,1.28,0,0,1-.11-.2S341.91,194.19,347.16,188.85Z"
        style="fill: #b7d5f2"
      />
      <path
        d="M355.61,200.28a19.83,19.83,0,0,1-3.7-2.59,22.49,22.49,0,0,1-3.11-3.36,21.72,21.72,0,0,1-2.33-3.94c-.07-.15-.14-.3-.2-.45a7.64,7.64,0,0,1,.89-1.09l.41-.31a20.67,20.67,0,0,0,1.1,2.39c.16.3.33.59.51.88a21.07,21.07,0,0,0,2.48,3.24c.25.26.5.52.76.77a21.34,21.34,0,0,0,3.25,2.51c.29.17.59.35.89.51h0a1,1,0,0,0-.85.85c0,.18-.05.37-.08.59Z"
        style="fill: #231f20"
      />
      <path
        d="M340.88,212.21c1.62,4.13,7.1,3.87,8.09,3.64-.14.16-.28.31-.43.46,0,0-5.35,1.55-7.74-2.94Z"
        style="fill: #231f20"
      />
      <path d="M352.93,217.89c-1.09,4.7,4.55,6.69,4.55,6.69l-1,.45s-5.14-1.19-4-5.9Z" style="fill: #231f20" />
      <circle cx="371.19" cy="186.37" r="1.58" style="fill: #121010" />
      <path
        d="M373.55,166.17l11.13,4.21a2.26,2.26,0,0,1,1.31,2.91l-4.63,12.25a2.24,2.24,0,0,1-2.9,1.31l-11.13-4.21a2.27,2.27,0,0,1-1.31-2.91l4.63-12.25A2.25,2.25,0,0,1,373.55,166.17Z"
        style="fill: #a5cbef"
      />
      <path
        d="M367.54,182.26l11,4.17a2.3,2.3,0,0,0,3-1.34l4.59-12.15a2.32,2.32,0,0,0-1.34-3l-11-4.17a2.3,2.3,0,0,0-3,1.34l-4.58,12.15A2.31,2.31,0,0,0,367.54,182.26Z"
        style="fill: url(#kabas-not-found-gradient-20)"
      />
      <polygon points="377.86 182.79 379.22 183.31 383.2 172.74 381.85 172.23 377.86 182.79" style="fill: #19375f" />
      <polygon points="375.1 181.75 376.46 182.26 380.44 171.7 379.09 171.19 375.1 181.75" style="fill: #19375f" />
      <path d="M372.91,169.73a.83.83,0,0,0,.48,1.07.83.83,0,1,0-.48-1.07Z" style="fill: #ef0405" />
      <circle cx="375.95" cy="170.88" r="0.83" transform="translate(-0.21 0.47) rotate(-0.07)" style="fill: #326ebd" />
      <g style="clip-path: url(#clip-path-2)">
        <path d="M380.86,159.15s-.52,2.86-.61,3.14.93,5,3.64,2.25l1.76-4Z" style="fill: #e79d6b" />
        <path
          d="M390.76,150.92c-5.64-3.94-8.35-2.75-8.51-2.68s-2,4.55-2.15,5.11a17.32,17.32,0,0,0-.47,4,39.24,39.24,0,0,0,2.07,3.93,3.83,3.83,0,0,0,1.5.47c.26-.06,3.77-1.49,4.2-2.06a28.26,28.26,0,0,0,2-3.88C389.79,154.54,390.76,150.92,390.76,150.92Z"
          style="fill: #f3a470"
        />
        <path
          d="M386.72,146s-1.85-.52-1.29-2.12,1.61-1.37,2.25-2a2.85,2.85,0,0,1,4,.66c1.45,1.74,1,2.91.1,3.64S388.08,147.89,386.72,146Z"
          style="fill: #e73d5a"
        />
        <path
          d="M392.57,150.72c.11-3.61-2.17-5.53-4.77-6.41s-6.35,1.23-8.43,4.47c-1.75,2.73-1.26,3.76-.9,5.06a5.86,5.86,0,0,0,1.2,2.07c.45-.64.47-3.52,1.11-4,.18-.13,0-.06.19-.08a.84.84,0,0,0,0-.12,8,8,0,0,1,2.33.44c1.38.46,1.72.6,1.72.6l1.05-3a18.92,18.92,0,0,1-.72,3.1s1.68.6,2.2.77c0,0,.46-1.24.69-2.09a20.66,20.66,0,0,1-.47,2.17,8.14,8.14,0,0,1,1.36.87s0,.08,0,.16.29.38.29.4c-.07,1.35-1.34,2.47-1.6,3.74a11,11,0,0,0,2.41-.92C391.72,157,392.42,155.84,392.57,150.72Z"
          style="fill: #e73d5a"
        />
        <path
          d="M391.29,151.35c.11-3.61-.89-6.16-3.49-7s-4.8,1.56-6.79,4.86-2.61,3.09-2.28,4.39l0,.18c.49-.68.91-.35,1.45-.73.2-.15.54-1.12.75-1.13,0-.06,0-.11.05-.15a8,8,0,0,1,2.33.44c1.38.46,1.72.6,1.72.6l1.05-3a18.92,18.92,0,0,1-.72,3.1s1.68.6,2.2.77c0,0,.46-1.24.69-2.09a20.66,20.66,0,0,1-.47,2.17,8.14,8.14,0,0,1,1.36.87l0,.1a3.59,3.59,0,0,1,.78,2.56l.26.64C391.13,157.1,391.14,156.47,391.29,151.35Z"
          style="fill: #e73d5a"
        />
        <path d="M389.18,155.79s.76-.59.91-.27.54,1.53-2.23,3.37S389.18,155.79,389.18,155.79Z" style="fill: #f3a470" />
        <path d="M380.59,152.78s-.26-.94-.58-.79-1.43.83-.32,4S380.59,152.78,380.59,152.78Z" style="fill: #f3a470" />
      </g>
      <g style="opacity: 0.2">
        <path d="M393,150a9.21,9.21,0,0,1,1.32,2.91L380.85,159a22.92,22.92,0,0,1-3.41-1.95Z" style="fill: #fff" />
        <path
          d="M382.91,159.91l11.69-5.3a9.33,9.33,0,0,1-.06,1.49c0,.25-.07.5-.1.74l-8.77,4A28.71,28.71,0,0,1,382.91,159.91Z"
          style="fill: #fff"
        />
      </g>
    </g>
  </g>
</svg>
