<div
  [ngClass]="[
    'option-group-item',
    'relative',

    'flex',
    'gap-xs',
    'items-center',

    'surface-container-low',

    'p-b-xs',

    'outline-none',

    'selected:surface-container-highest',

    'state-layer-group'
  ]"
  [class.cursor-pointer]="!disabled && multiple"
  [attr.data-has-leading-icon]="leadingIcon || hasCheckbox ? true : null"
  [class.opacity-disabled]="disabled"
  (click)="onGroupClicked($event)"
  (keydown)="onGroupKeydown($event)"
  [attr.tabindex]="tabIndex"
  #container
>
  <campus-checkbox
    *ngIf="multiple && hasCheckbox"
    [checked]="allSelected"
    [disabled]="disabled"
    aria-hidden="true"
    class="pointer-event-none"
    tabindex="-1"
    [indeterminate]="!allSelected && someSelected"
    #pseudoCheckbox
  ></campus-checkbox>

  <campus-icon *ngIf="leadingIcon" [svgIcon]="leadingIcon"></campus-icon>

  <div class="[ stack stack-space-3xs ][ grow ]">
    <span *ngIf="overline" class="[ label-overline ]">{{ overline }}</span>
    <span *ngIf="label" class="[ label-large on-surface-variant ]">{{ label }}</span>
    <span *ngIf="supportingText" class="[ body-small on-surface-variant ]">{{ supportingText }}</span>
  </div>

  <span *ngIf="trailingSupportingText" class="[ label-small on-surface-variant ]">{{ trailingSupportingText }}</span>

  <campus-state-layer *ngIf="!disabled && multiple" [for]="containerRef" class="bg-on-surface"></campus-state-layer>
  <campus-focus-ring [for]="containerRef" inward></campus-focus-ring>
</div>

<ng-content></ng-content>
