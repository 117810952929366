import { animate, AnimationMetadata, keyframes, state, style, transition } from '@angular/animations';
import { easing, speed } from './constants';

const slideInBounceConfig = {
  top: {
    translate: 'translateY',
    offset: '-100%',
    bounceMaxOffset: 8,
    bounceMinOffset: 5,
  },
  bottom: {
    translate: 'translateY',
    offset: '100%',
    bounceMaxOffset: 8,
    bounceMinOffset: 5,
  },
  left: {
    translate: 'translateX',
    offset: '-100%',
    bounceMaxOffset: 8,
    bounceMinOffset: 5,
  },
  right: {
    translate: 'translateX',
    offset: '100%',
    bounceMaxOffset: 8,
    bounceMinOffset: 5,
  },
};
const slideInConfig = {
  top: {
    translate: 'translateY',
    offset: '-100%',
  },
  bottom: {
    translate: 'translateY',
    offset: '100%',
  },
  left: {
    translate: 'translateX',
    offset: '-100%',
  },
  right: {
    translate: 'translateX',
    offset: '100%',
  },
};

const stateVoid = (config) => state('void', style({ transform: `${config.translate}(${config.offset})` }));

const stateDone = (config) => state('*', style({ transform: `${config.translate}(0)` }));

const transitionIn = transition('void => *', [animate(`${speed.slow} ${easing.default}`)]);

const transitionOut = transition('* => void', [animate(`${speed.slow} ${easing.default}`)]);

const transitionBounceIn = (config) =>
  transition('void => *', [
    animate(
      `${speed.slow} ${easing.default}`,
      keyframes([
        style({
          transform: `${config.translate}(${config.bounceMaxOffset}px)`,
          offset: 0.75,
        }),
        style({
          transform: `${config.translate}(-${config.bounceMinOffset}px)`,
          offset: 0.9,
        }),
        style({ transform: `${config.translate}(0)`, offset: 1 }),
      ])
    ),
  ]);

const transitionBounceOut = (config) =>
  transition('* => void', [
    animate(
      `${speed.slow} ${easing.default}`,
      keyframes([
        style({
          transform: `${config.translate}(calc(${config.offset} - ${config.bounceMaxOffset}px))`,
          offset: 0.75,
        }),
        style({
          transform: `${config.translate}(calc(${config.offset} + ${config.bounceMinOffset}px))`,
          offset: 0.9,
        }),
        style({
          transform: `${config.translate}(${config.offset})`,
          offset: 1,
        }),
      ])
    ),
  ]);

export const slideInBounceFromTopAnimation: AnimationMetadata[] = [
  stateVoid(slideInBounceConfig.top),
  stateDone(slideInBounceConfig.top),
  transitionBounceIn(slideInBounceConfig.top),
  transitionBounceOut(slideInBounceConfig.top),
];

export const slideInBounceFromBottomAnimation: AnimationMetadata[] = [
  stateVoid(slideInBounceConfig.bottom),
  stateDone(slideInBounceConfig.bottom),
  transitionBounceIn(slideInBounceConfig.bottom),
  transitionBounceOut(slideInBounceConfig.bottom),
];
export const slideInBounceFromLeftAnimation: AnimationMetadata[] = [
  stateVoid(slideInBounceConfig.left),
  stateDone(slideInBounceConfig.left),
  transitionBounceIn(slideInBounceConfig.left),
  transitionBounceOut(slideInBounceConfig.left),
];
export const slideInBounceFromRightAnimation: AnimationMetadata[] = [
  stateVoid(slideInBounceConfig.right),
  stateDone(slideInBounceConfig.right),
  transitionBounceIn(slideInBounceConfig.right),
  transitionBounceOut(slideInBounceConfig.right),
];

export const slideInFromLeftAnimation: AnimationMetadata[] = [
  stateVoid(slideInConfig.left),
  stateDone(slideInConfig.left),
  transitionIn,
  transitionOut,
];
export const slideInFromRightAnimation: AnimationMetadata[] = [
  stateVoid(slideInConfig.right),
  stateDone(slideInConfig.right),
  transitionIn,
  transitionOut,
];
export const slideInFromTopAnimation: AnimationMetadata[] = [
  stateVoid(slideInConfig.top),
  stateDone(slideInConfig.top),
  transitionIn,
  transitionOut,
];
export const slideInFromBottomAnimation: AnimationMetadata[] = [
  stateVoid(slideInConfig.bottom),
  stateDone(slideInConfig.bottom),
  transitionIn,
  transitionOut,
];

export const slideInOutFromRightToLeftAnimation: AnimationMetadata[] = [
  transition(':enter', [
    style({ transform: 'translateX(100%)' }),
    animate('200ms ease-in', style({ transform: 'translateX(0%)' })),
  ]),
  transition(':leave', [animate('200ms ease-in', style({ transform: 'translateX(-100%)' }))]),
];
