import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'campus-public-portal-not-found-hero',
  styleUrls: ['./not-found-hero.component.scss'],
  templateUrl: './not-found-hero.component.html',
})
export class NotFoundHeroComponent {
  @HostBinding('class.kabas-not-found-hero')
  hasKabasNotFoundHeroClass = true;
}
