import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { FileReaderError, FileReaderResult } from './filereader.service';

export const FILEREADER_SERVICE_TOKEN = new InjectionToken<FileReaderServiceInterface>('browser filereader service');

export interface FileReaderServiceInterface {
  loaded$: Observable<FileReaderResult>;
  error$: Observable<FileReaderError>;
  progress$: Observable<number>;

  // service methods
  getFileReader(): FileReaderServiceInterface;
  isFileTypeAllowed(file: File, regex?: RegExp): boolean;
  reset(): void;

  // filereader method implementations
  abort(): void;
  readAsFile(blob: File): void;
  readAsArrayBuffer(blob: Blob): void;
  readAsBinaryString(blob: Blob): void;
  readAsDataURL(blob: Blob): void;
  readAsText(blob: Blob, encoding?: string): void;
  download(blob: Blob, filename: string): void;
}
