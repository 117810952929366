import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: 'campus-empty-state',
  templateUrl: './empty-state.component.html',
})
export class EmptyStateComponent {
  @HostBinding('attr.data-cy')
  dataCy = 'empty-state';

  @HostBinding('class')
  defaultClasses = ['block'];

  @Input()
  dense = false;

  @Input() svgImage?: string;
  @Input() svgIcon?: string;
  @Input() title?: string;
  @Input() description: string;
  @Input() ctaLabel?: string;
  @Input() ctaIcon?: string;
  @Input() showCta?: boolean = true;
  @Input() disabled?: boolean = false;

  @Output() clickCta = new EventEmitter<void>();

  constructor() {}

  public ctaClick() {
    this.clickCta.emit();
  }
}
