import { InjectionToken } from '@angular/core';
import { Action } from '@ngrx/store';
import { AuthAdapterInterface, RegistrationCredentials } from '../../interfaces';
import { LicenseInterface, PersonInterface } from '../../services';
import { LoginCredentials } from '../../types';
import { UserType } from '../../types/user.type';

export enum UserActions {
  ACTION_CLEAR_USER = '[User] ACTION_CLEAR_USER',
  ACTION_CHANGE_USER = '[User] ACTION_CHANGE_USER',
  USER_UPDATE_TYPE = '[User] USER_UPDATE_TYPE',
  ADD_ACTIVE_LICENSE = '[User] ADD_ACTIVE_LICENSE',
  SET_ACTIVE_LICENSES = '[User] SET_ACTIVE_LICENSES',
  CLEAR_ACTIVE_LICENSES = '[User] CLEAR_ACTIVE_LICENSES',
  CLEAR_AUTH_ERROR = '[User] CLEAR_AUTH_ERROR',
  USER_LOGIN = '[Basic Auth] USER_LOGIN',
  USER_LOGIN_SUCCESS = '[Basic Auth] USER_LOGIN_SUCCESS',
  USER_LOGIN_FAIL = '[Basic Auth] USER_LOGIN_FAIL',
  USER_REGISTER = '[Basic Auth] USER_REGISTER',
  USER_REGISTER_SUCCESS = '[Basic Auth] USER_REGISTER_SUCCESS',
  USER_REGISTER_FAIL = '[Basic Auth] USER_REGISTER_FAIL',
  USER_CHECK_PASSWORD = '[User] CHECK PASSWORD',
  USER_CHECK_PASSWORD_SUCCESS = '[User] CHECK PASSWORD SUCCES',
  USER_CHECK_PASSWORD_FAIL = '[User] CHECK PASSWORD FAIL',
  USER_SEND_RESET_PASSWORD_LINK_SUCCESS = '[User] USER_SEND_RESET_PASSWORD_LINK_SUCCESS',
  USER_SEND_RESET_PASSWORD_LINK_FAIL = '[User] USER_SEND_RESET_PASSWORD_LINK_FAIL',
  USER_RESET_PASSWORD_SUCCESS = '[User] USER_RESET_PASSWORD_SUCCESS',
  USER_RESET_PASSWORD_FAIL = '[User] USER_RESET_PASSWORD_FAIL',
  USER_ACCEPT_TERMS_SUCCESS = '[User] USER_ACCEPT_TERMS_SUCCESS',
  USER_ACCEPT_TERMS_FAIL = '[User] USER_ACCEPT_TERMS_FAIL',
  OAUTH_USER_LOGIN = '[Oauth] USER_LOGIN',
}

export class ClearUser implements Action {
  readonly type = UserActions.ACTION_CLEAR_USER;
  /**
   * Clear the current user
   * @param {Object} payload optional (only here to prevent compile errors)
   */
  constructor(public payload?: PersonInterface) {}
}

export class SetUser implements Action {
  readonly type = UserActions.ACTION_CHANGE_USER;
  /**
   * Set the current user
   * @param {UserModel} payload
   */
  constructor(public payload: { current: PersonInterface }) {}
}

export class LoginUser {
  readonly type = UserActions.USER_LOGIN;
  constructor(public payload: { credentials }, public method: string = 'login') {}
}

export class OauthLoginUser {
  readonly type = UserActions.OAUTH_USER_LOGIN;
  constructor(
    public payload: {
      authBase: string;
      provider: string;
      returnUrl: string;
      type: UserType;
    }
  ) {}
}

export class LoginUserSuccess {
  readonly type = UserActions.USER_LOGIN_SUCCESS;
  payload: { current: PersonInterface; credentials: LoginCredentials };
  adapter: string | InjectionToken<AuthAdapterInterface>;
  constructor(current: PersonInterface, credentials: LoginCredentials) {
    this.payload = { current, credentials };
    this.adapter = credentials.adapter;
  }
}

export class UpdateUserType implements Action {
  readonly type = UserActions.USER_UPDATE_TYPE;
  constructor(public payload: { type: UserType }) {}
}

export class AddActiveLicense implements Action {
  readonly type = UserActions.ADD_ACTIVE_LICENSE;
  constructor(public payload: { license: LicenseInterface }) {}
}

export class SetActiveLicenses implements Action {
  readonly type = UserActions.SET_ACTIVE_LICENSES;
  constructor(public payload: { licenses: LicenseInterface[] }) {}
}

export class ClearActiveLicenses implements Action {
  readonly type = UserActions.CLEAR_ACTIVE_LICENSES;
  constructor() {}
}

export class ClearAuthError implements Action {
  readonly type = UserActions.CLEAR_AUTH_ERROR;
  constructor() {}
}

export class LoginUserFail {
  readonly type = UserActions.USER_LOGIN_FAIL;
  constructor(public payload: { error: string | Error }) {}
}

export class RegisterUser {
  readonly type = UserActions.USER_REGISTER;
  constructor(public payload: { credentials: RegistrationCredentials }, public method: string = 'register') {}
}

export class CheckUserPassword {
  readonly type = UserActions.USER_CHECK_PASSWORD;
  constructor() {}
}

export class CheckUserPasswordSuccess {
  readonly type = UserActions.USER_CHECK_PASSWORD_SUCCESS;
  constructor() {}
}
export class CheckUserPasswordFail {
  readonly type = UserActions.USER_CHECK_PASSWORD_FAIL;
  constructor() {}
}

export class RegisterUserSuccess {
  readonly type = UserActions.USER_REGISTER_SUCCESS;
  constructor() {}
}

export class RegisterUserFail {
  readonly type = UserActions.USER_REGISTER_FAIL;
  constructor(public payload: { error: string | Error }) {}
}

export class SendUserResetPasswordLinkSuccess {
  readonly type = UserActions.USER_SEND_RESET_PASSWORD_LINK_SUCCESS;
  constructor() {}
}

export class SendUserResetPasswordLinkFail {
  readonly type = UserActions.USER_SEND_RESET_PASSWORD_LINK_FAIL;
  constructor() {}
}

export class ResetPasswordSuccess {
  readonly type = UserActions.USER_RESET_PASSWORD_SUCCESS;
  constructor() {}
}

export class ResetPasswordFail {
  readonly type = UserActions.USER_RESET_PASSWORD_FAIL;
  constructor() {}
}

export class AcceptTermsSuccess {
  readonly type = UserActions.USER_ACCEPT_TERMS_SUCCESS;
  constructor() {}
}
export class AcceptTermsFail {
  readonly type = UserActions.USER_ACCEPT_TERMS_FAIL;
  constructor() {}
}

export type UserActionMethods =
  | ClearUser
  | SetUser
  | ClearAuthError
  | LoginUserSuccess
  | RegisterUser
  | RegisterUserSuccess
  | RegisterUserFail
  | LoginUserFail
  | LoginUser
  | SendUserResetPasswordLinkSuccess
  | SendUserResetPasswordLinkFail
  | ResetPasswordSuccess
  | ResetPasswordFail
  | UpdateUserType
  | AddActiveLicense
  | SetActiveLicenses
  | ClearActiveLicenses
  | CheckUserPassword
  | CheckUserPasswordFail
  | CheckUserPasswordSuccess;
