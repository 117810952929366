import { Values } from '../shared';

export const SelectionKeys = {
  SPACE: 'Space',
  ENTER: 'Enter',
  NUMPAD_ENTER: 'NumpadEnter',
} as const;

export type SelectionKeys = typeof SelectionKeys[keyof typeof SelectionKeys];

export function isSelectableKey(code: string): code is Values<typeof SelectionKeys> {
  return Object.values(SelectionKeys).some((value) => value === code);
}
