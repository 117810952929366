import { NgModule, NgZone } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { debounceTime, filter, take } from 'rxjs/operators';

/*
 * for E2E environment
 * enables to navigate within the app by navigateByUrl()
 * renamed to window.angularNavigate
 * Based on following https://netbasal.com/time-for-a-quick-er-cypress-visit-in-angular-d7d0faebfabc
 *
 * angularPageReload reloads the current url (needs refurbishing)
 *
 * Return a promise with NavigationEnd [...] from RouterEvents (based on kabas loader mechanism)
 */
@NgModule()
export class E2eModule {
  constructor(router: Router, zone: NgZone) {
    const angularNavigationPromise = () => {
      return router.events
        .pipe(
          filter(
            (event) =>
              event instanceof NavigationEnd ||
              event instanceof NavigationCancel ||
              event instanceof NavigationError ||
              event instanceof NavigationStart
          ),
          debounceTime(750),
          take(1)
        )
        .toPromise();
    };

    (window as any).angularNavigate = (url: string) => {
      zone.run(() => router.navigateByUrl(url));

      return angularNavigationPromise();
    };

    // todo: find more descent way to handle angularPageReload
    /* current fix: navigate to /home (but skip location) then return to current path
     * attempt: switching shouldReuseRoute false / true: not working as intended
     * -> problem with multiple Navigation id's (navigate: id 1, id 2...)
     * -> stops loading after event from id 1, then sets shouldReuseRoute back to true (default)
     * -> result: page is only partly loaded or updated
     */

    (window as any).angularPageReload = () => {
      const currentAppPath = window.location.pathname.replace('/app/', '');

      return zone.run(() =>
        router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          router.navigateByUrl(currentAppPath);

          return angularNavigationPromise();
        })
      );
    };
  }
}
