import { Injectable } from '@angular/core';
import { OauthAdapter } from './oauth.adapter';

@Injectable({
  providedIn: 'root',
})
export class SmartschoolAdapter extends OauthAdapter {
  protected provider = 'smartschool';

  constructor() {
    super();
  }
}

