import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AuthAdapterInterface } from '../../../interfaces';
import { selectPortalMode, selectPortalRole } from '../../../state';
import { PORTAL_ENVIRONMENT_TOKEN } from '../../../tokens';
import { FormMode, LoginCredentials, UserType } from '../../../types';

@Injectable({
  providedIn: 'root',
})
export abstract class BaseAdapter implements AuthAdapterInterface {
  protected environment = inject(PORTAL_ENVIRONMENT_TOKEN);
  protected credentials: LoginCredentials;
  protected mode$: Observable<FormMode>;
  protected userType$: Observable<UserType>;
  protected store = inject(Store);

  constructor() {
    this.mode$ = this.store.select(selectPortalMode);
    this.userType$ = this.store.select(selectPortalRole);
  }

  abstract login(): void;

  register(): void {
    throw new Error('Method not implemented.');
  }

  setCredentials(credentials: LoginCredentials): AuthAdapterInterface {
    this.credentials = credentials;

    return this;
  }
}
