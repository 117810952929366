import { InjectionToken } from '@angular/core';

export const WINDOW = new InjectionToken('WindowToken', {
  providedIn: 'root',
  factory: () => window,
});

// https://github.com/Microsoft/TypeScript/issues/16255
declare global {
  interface Window {
    IntersectionObserver: typeof IntersectionObserver;
    ResizeObserver: typeof ResizeObserver;
    MutationObserver: typeof MutationObserver;
  }
}
