import { Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'campus-badge',
  templateUrl: './badge.component.html',
})
export class BadgeComponent implements OnChanges {
  @HostBinding('class')
  defaultClasses = ['badge', 'label-small', 'center-content', 'corner-full'];

  @Input() cutOff = 1000;

  @Input() value: number;

  @Input() text: string;

  @HostBinding('attr.data-size')
  @Input()
  size: 'small' | 'large' = 'large';

  @HostBinding('class.p-i-3xs')
  get isLarge() {
    return this.size === 'large';
  }

  @HostBinding('attr.aria-label')
  ariaLabel: string;

  public badgeValue: string;

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.value || changes.cutOff) && this.value !== undefined) {
      this.badgeValue = this.value >= this.cutOff ? `${this.cutOff - 1}+` : `${this.value}`;

      if (this.size === 'small' || this.value === 1) {
        this.ariaLabel = 'Nieuwe melding.';
      } else if (this.value >= this.cutOff) {
        this.ariaLabel = `Meer dan ${this.cutOff - 1} nieuwe meldingen.`;
      } else {
        this.ariaLabel = `${this.badgeValue} nieuwe meldingen.`;
      }
    }
  }
}
