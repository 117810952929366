import { Values } from './shared';

export const ModifierKeys = {
  Shift: 'Shift',
  Alt: 'Alt',
  Control: 'Control',
  Meta: 'Meta',
} as const;

export type ModifierKey = Values<typeof ModifierKeys>;

export function isModifierKey(code: string): code is ModifierKey {
  return Object.values(ModifierKeys).some((value) => value === code);
}

export const KeydownCloseKey = {
  ESCAPE: 'Escape',
} as const;

export function isClosableKey(code: string): code is Values<typeof KeydownCloseKey> {
  return Object.values(KeydownCloseKey).some((value) => value === code);
}
