import { animate, state, style, transition, trigger } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import {
  Component,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  Output,
} from '@angular/core';
import { easing, speed } from './../animations/constants';

@Directive({
  selector: '[campusSlideoutModalHeaderContent], [slideoutModalHeaderCotnent], campus-slideout-modal-header-content',
})
export class SlideoutModalHeaderContentDirective {
  @HostBinding('class.ui-slideout-modal__header-content')
  hasSlideoutModalHeaderContentClass = true;
}
@Directive({
  selector: '[campusSlideoutModalHeaderActions], [slideoutModalHeaderActions], campus-slideout-modal-header-actions',
})
export class SlideoutModalHeaderActionsDirective {
  @HostBinding('class.ui-slideout-modal__header-actions')
  hasSlideoutModalHeaderActionsClass = true;
}

@Directive({
  selector: '[campusSlideoutModalBottomActions], [slideoutModalBottomActions], campus-slideout-modal-bottom-actions',
})
export class SlideoutModalBottomActionsDirective {
  @HostBinding('class.ui-slideout-modal__bottom-actions')
  hasSlideoutModalBottomActionsClass = true;
}

@Component({
  selector: 'campus-slideout-modal',
  templateUrl: './slideout-modal.component.html',
  styleUrls: ['./slideout-modal.component.scss'],

  animations: [
    trigger('fadeIn', [
      state('true', style({ opacity: 1, height: '*', display: 'block' })),
      state('false', style({ opacity: 0, height: '0', display: 'none' })),
      transition('false => true', [
        style({ height: '*', display: 'block' }),
        animate(`${speed.slow} ${easing.default}`, style({ opacity: 1 })),
      ]),
      transition('true => false', [
        animate(`${speed.slow} ${easing.default}`, style({ opacity: 0 })),
        style({ height: '0', display: 'none' }),
      ]),
    ]),
    trigger('slideIn', [
      //46px is the largest blur in elevation;  this makes sure no shadows drops on the body
      state('inactive', style({ transform: 'translateX(calc(100% + 50px))' })),
      state('active', style({ transform: '' })),
      state('nextActive', style({ transform: 'translateX(-{{translateNextActive}}px)' }), {
        params: { translateNextActive: 0 },
      }),
      transition('inactive => active', [animate(`${speed.slow} ${easing.default}`)]),
      transition('active => nextActive', [animate(`${speed.slow} ${speed.fast} ${easing.default}`)]),
      transition('nextActive => active', [animate(`${speed.slow} ${easing.default}`)]),
      transition('active => inactive', [animate(`${speed.slow} ${speed.fast} ${easing.default}`)]),
      transition('nextActive => inactive', [animate(`${speed.slow} ${speed.normal} ${easing.default}`)]),
    ]),
  ],
})
export class SlideoutModalComponent {
  private _active = false;
  private _isNextActive = false;

  @HostBinding('class.ui-slideout-modal')
  hasSlideoutModalClass = true;

  @HostBinding('class.ui-slideout-modal--active')
  get active() {
    return this._active;
  }
  @HostBinding('[attr.tabIndex]')
  tabIndex = 0; // used to set focus (for esc handler)

  @HostListener('document:keydown.escape', ['$event'])
  handleEscape(event: KeyboardEvent) {
    if (!this._active || !this.elemRef.nativeElement.contains(this.document.activeElement)) return;
    this.clickScrim(event);
    return false;
  }

  set active(value: boolean) {
    this._active = value;
    this.setAnimState();
  }

  @HostBinding('class.ui-slideout-modal--is-next-active')
  get isNextActive() {
    return this._isNextActive;
  }
  set isNextActive(value: boolean) {
    this._isNextActive = value;
    this.setAnimState();
  }

  @Input() title: string;

  @Output() scrimClicked = new EventEmitter();
  @Output() animationDone = new EventEmitter();

  index: number;
  animState: {
    value: 'active' | 'nextActive' | 'inactive';
    params: { translateNextActive: number };
  } = {
    value: 'inactive',
    params: { translateNextActive: 0 },
  };
  nextOffsetWidth = 0;

  constructor(@Inject(DOCUMENT) private document: Document, public elemRef: ElementRef) {}

  clickScrim(event) {
    this.scrimClicked.emit(event);
  }

  onSlideInDone() {
    this.animationDone.emit();
  }

  private setAnimState() {
    const elemWidth = this.elemRef.nativeElement.childNodes[1].offsetWidth;

    const maxTranslate = elemWidth + 24 - 100;
    const translateNextActive = maxTranslate > this.nextOffsetWidth ? 0 : this.nextOffsetWidth - maxTranslate;

    this.animState = {
      value: this._isNextActive ? 'nextActive' : this._active ? 'active' : 'inactive',
      params: { translateNextActive },
    };
  }
}
