// Material You Specs
export const TIMING_SHORT_1 = 50;
export const TIMING_SHORT_2 = 100;
export const TIMING_SHORT_3 = 150;
export const TIMING_SHORT_4 = 200;
export const TIMING_MEDIUM_1 = 250;
export const TIMING_MEDIUM_2 = 300;
export const TIMING_MEDIUM_3 = 350;
export const TIMING_MEDIUM_4 = 400;
export const TIMING_LONG_1 = 450;
export const TIMING_LONG_2 = 500;
export const TIMING_LONG_3 = 550;
export const TIMING_LONG_4 = 600;
export const TIMING_EXTRA_LONG_1 = 700;
export const TIMING_EXTRA_LONG_2 = 800;
export const TIMING_EXTRA_LONG_3 = 900;
export const TIMING_EXTRA_LONG_4 = 1000;

export const EASE_LINEAR = 'cubic-bezier(0, 0, 1, 1)';
export const EASE_STANDARD_ACCELERATE = 'cubic-bezier(0.3, 0, 0, 1)';
export const EASE_STANDARD_DECELERATE = 'cubic-bezier(0, 0, 0, 1)';
export const EASE_EMPHASIZED = 'cubic-bezier(0.2, 0, 0, 1)';
export const EASE_EMPHASIZED_ACCELERATE = 'cubic-bezier(0.3, 0, 0.8, 0.15)';
export const EASE_EMPHASIZED_DECELERATE = 'cubic-bezier(0.05, 0.7, 0.1, 1)';

//Animations.css specific
export const EASE_ZOOM = 'cubic-bezier(0.550, 0.055, 0.675, 0.190)';
export const EASE_BOUNCE = 'cubic-bezier(0.215, 0.610, 0.355, 1.000)';

// DEFAULTS
export const TIMING_DEFAULT = TIMING_LONG_4;
export const EASE_STANDARD = EASE_LINEAR;
