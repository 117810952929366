import { animate, AnimationMetadata, keyframes, style, transition } from '@angular/animations';

export const popInAnimation: AnimationMetadata[] = [
  transition(':enter', [
    style({ transform: 'scale(0)' }),
    animate(
      '250ms cubic-bezier(0.36, 0.07, 0.19, 0.97)',
      keyframes([
        style({ transform: 'scale(1.2)', offset: 0.8 }),
        style({ transform: 'scale(0.8)', offset: 0.9 }),
        style({ transform: 'scale(1.15)', offset: 0.95 }),
        style({ transform: 'scale(1)', offset: 1 }),
      ])
    ),
  ]),
];
export const popOutAnimation: AnimationMetadata[] = [
  transition(':leave', [
    style({ transform: 'scale(1)' }),
    animate('250ms cubic-bezier(0.36, 0.07, 0.19, 0.97)', style({ transform: 'scale(0)' })),
  ]),
];
