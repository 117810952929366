<div
  class="ui-slideout-modal-scrim"
  (click)="clickScrim($event)"
  [style.zIndex]="index * 2 + 98"
  [@fadeIn]="active"
></div>
<div
  class="ui-slideout-modal-content tablet-l:min-w-2xl"
  [style.zIndex]="index * 2 + 99"
  [@slideIn]="animState"
  (@slideIn.done)="onSlideInDone()"
>
  <div class="ui-slideout-modal__header">
    <h5 *ngIf="title" class="ui-slideout-modal__header__title" data-cy="slideout-modal-header">
      {{ title }}
    </h5>
    <div *ngIf="!title" class="w-full">
      <ng-content select="campus-slideout-modal-header-content"></ng-content>
    </div>
    <ng-content select="campus-slideout-modal-header-actions"></ng-content>
  </div>
  <div class="ui-slideout-modal-content__content">
    <ng-content></ng-content>
  </div>
  <ng-content select="campus-slideout-modal-bottom-actions"></ng-content>
</div>
