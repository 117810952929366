import { ViewportScroller } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, Scroll } from '@angular/router';
import { WindowScrollService } from '@campus/browser';
import { ComponentsModule } from '@campus/components';
import { CookiesModule } from '@campus/cookies';
import { EnvironmentModule } from '@campus/environment';
import { PublicPortalModule } from '@campus/public-portal';
import { SvgModule } from '@campus/svg';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { delay, filter } from 'rxjs/operators';
import { environment } from './../environments/environment';
import { AppEffectsModule } from './app-effects.module';
import { AppRoutingModule } from './app-routing.module';
import { AppStoreModule } from './app-store.module';
import { AppTokenModule } from './app-token.module';
import { AppComponent } from './app.component';
import { ContentLinkPageComponent } from './pages/content-link-page/content-link-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { LoginStudentComponent } from './pages/home-page/login-student/login-student.component';
import { LoginTeacherComponent } from './pages/home-page/login-teacher/login-teacher.component';
import { LegalsHeroComponent } from './pages/legals-page/components/legals-hero/legals-hero.component';
import { LegalsPagePrivacyComponent } from './pages/legals-page/components/legals-page-privacy/legals-page-privacy.component';
import { LegalsPageSalesConditionsComponent } from './pages/legals-page/components/legals-page-sales-conditions/legals-page-sales-conditions.component';
import { LegalsPageTermsOfUseComponent } from './pages/legals-page/components/legals-page-terms-of-use/legals-page-terms-of-use.component';
import { LegalsPageContainerComponent } from './pages/legals-page/legals-page-container.component';
import { ForgotPasswordFormComponent } from './pages/login-page/components/forgot-password-form/forgot-password-form.component';
import { LoginFormComponent } from './pages/login-page/components/login-form/login-form.component';
import { LoginMethodsComponent } from './pages/login-page/components/login-methods/login-methods.component';
import { LoginNoAccessComponent } from './pages/login-page/components/login-no-access/login-no-access.component';
import { LoginPageContainerComponent } from './pages/login-page/login-page-container.component';
import { RegisterFormComponent } from './pages/register-page/components/register-form/register-form.component';
import { RegisterPageContainerComponent } from './pages/register-page/register-page-container.component';

const disableAnimations =
  !('animate' in document.documentElement) || (navigator && /iPhone OS (8|9|10|11|12|13)_/.test(navigator.userAgent));

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    LoginTeacherComponent,
    LoginStudentComponent,
    LegalsPageContainerComponent,
    LegalsPagePrivacyComponent,
    LegalsPageTermsOfUseComponent,
    LegalsPageSalesConditionsComponent,
    LegalsHeroComponent,
    LoginPageContainerComponent,
    LoginFormComponent,
    LoginMethodsComponent,
    LoginNoAccessComponent,
    ForgotPasswordFormComponent,
    RegisterPageContainerComponent,
    RegisterFormComponent,
    ContentLinkPageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SvgModule,
    EnvironmentModule.forRoot({
      environmentSvgMapping: environment.svgMapping,
      environmentIconMapping: environment.iconMapping,
      environmentRecaptcha: environment.recaptcha,
      environmentLogin: environment.login,
      environmentApi: environment.api,
      environmentAssets: environment.assets,
      environmentGoogleAnalytics: environment.googleAnalytics,
      environmentLogout: environment.afterLogout,
      environmentWebsite: environment.website,
    }),
    ReactiveFormsModule,
    BrowserAnimationsModule.withConfig({ disableAnimations }),
    CookiesModule,
    HttpClientModule,
    PublicPortalModule.forRoot({ apiBaseUrl: environment.api.APIBase }),
    ComponentsModule,
    AppTokenModule,
    AppEffectsModule,
    AppStoreModule,
    MatFormFieldModule,
    MatInputModule,
    environment.enableDevTools ? StoreDevtoolsModule.instrument() : [],
    environment.envModules || [],
  ],
  providers: [WindowScrollService],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(router: Router, viewportScroller: ViewportScroller) {
    router.events
      .pipe(
        filter((e): e is Scroll => e instanceof Scroll),
        delay(1)
      )
      .subscribe((e) => {
        if (e.position) {
          viewportScroller.scrollToPosition(e.position);
        } else if (e.anchor) {
          viewportScroller.scrollToAnchor(e.anchor);
        } else {
          viewportScroller.scrollToPosition([0, 0]);
        }
      });
  }
}
