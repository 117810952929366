import { animate, AnimationMetadata, keyframes, state, style, transition } from '@angular/animations';
import { easing, speed } from './constants';

export const translateDropAnimation: AnimationMetadata[] = [
  state('covered', style({ transform: 'translateY(calc({{translateY}}))' }), {
    params: { translateY: '0px' },
  }),
  state('dropped', style({ transform: 'translateY(0px)' })),
  state('static-covered', style({ transform: 'translateY(calc({{translateY}}))' }), {
    params: { translateY: '0px' },
  }),
  transition('covered => dropped', [
    animate(
      `${speed.slow} ${easing.default}`,
      keyframes([
        style({ transform: 'translateY(8px)', offset: 0.75 }),
        style({ transform: 'translateY(-5px)', offset: 0.9 }),
        style({ transform: 'translateY(0)', offset: 1 }),
      ])
    ),
  ]),
  transition(
    'dropped => covered',
    [
      animate(
        `${speed.slow} ${easing.default}`,
        keyframes([
          style({
            transform: 'translateY(calc({{translateY}} - 8px))',
            offset: 0.75,
          }),
          style({
            transform: 'translateY(calc({{translateY}} + 5px))',
            offset: 0.9,
          }),
          style({
            transform: 'translateY(calc({{translateY}}))',
            offset: 1,
          }),
        ])
      ),
    ],
    { params: { translateY: '0px' } }
  ),
];
