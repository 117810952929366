import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { FaqApi, FaqInterface } from '../api';

@Injectable({
  providedIn: 'root',
})
export class FaqItemService {
  private api = inject(FaqApi);

  public getBySlug$(slug: string): Observable<FaqInterface> {
    return this.api.findOne({
      where: {
        slug: {
          like: `%${slug}%`,
        },
      },
    });
  }
}
