import { NgModule } from '@angular/core';
import { UserEffects } from '@campus/public-portal';
import { EffectsModule } from '@ngrx/effects';
import { AppUserEffects } from './state/user/user.effects';

@NgModule({
  imports: [
    EffectsModule.forRoot(),
    EffectsModule.forFeature([
      UserEffects,
      AppUserEffects
    ]),
  ],
})
export class AppEffectsModule {}
