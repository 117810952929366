import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EnvironmentModule } from '@campus/environment';
import { SvgModule } from '@campus/svg';
import { CookiesContentComponent } from './cookies-content/cookies-content.component';
import { COOKIE_INPUT_TOKEN, CookieInputService } from './cookies-content/services/cookie-input';
import { COOKIES_SERVICE_TOKEN, CookiesService } from './cookies-content/services/cookies';

@NgModule({
  imports: [
    CommonModule,
    EnvironmentModule,
    BrowserModule,
    MatSlideToggleModule,
    MatButtonModule,
    BrowserAnimationsModule,
    SvgModule,
  ],
  declarations: [CookiesContentComponent],
  exports: [CookiesContentComponent],
  providers: [
    {
      provide: COOKIES_SERVICE_TOKEN,
      useClass: CookiesService,
    },
    {
      provide: COOKIE_INPUT_TOKEN,
      useClass: CookieInputService,
    },
  ],
})
export class CookiesModule {}
