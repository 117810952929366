<mat-form-field>
  <mat-label>{{ placeholder }}</mat-label>
  <input
    class="ui-filter-text-input__input"
    name="filter-input"
    type="text"
    (keydown.enter)="onEnterPressed($event)"
    [formControl]="input"
    [autocomplete]="autocomplete"
    matInput
  />

  <button
    inline-icon-button
    class="ui-filter-text-input__cancel"
    *ngIf="!!input.value"
    [ngClass]="{ 'ui-filter-text-input__cancel--visible': !!input.value }"
    aria-label="Annuleren"
    clickStopPropagation
    (click)="clear()"
    icon="close"
    matSuffix
  >
  </button>

  <button class="ui-filter-text-input__icon" inline-icon-button icon="filter" matSuffix></button>
</mat-form-field>
