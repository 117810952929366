import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'polpo-forgot-password-form',
  templateUrl: './forgot-password-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotPasswordFormComponent {
  private formBuilder = inject(FormBuilder);

  @Input() passwordResetRequested: boolean;
  @Input() showRegister: boolean;

  @Output() whenCancelled = new EventEmitter<boolean>();
  @Output() whenSubmitted = new EventEmitter<string>();

  public resetForm = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
  });

  public onSubmitForm() {
    this.whenSubmitted.emit(this.resetForm.value.email);
  }

  public onCancel() {
    this.whenCancelled.emit(true);
  }
}
