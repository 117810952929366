import { animate, AnimationMetadata, keyframes, state, style, transition } from '@angular/animations';
import { easing, speed } from './constants';

export const fadeAnimation: AnimationMetadata[] = [
  state('void', style({ opacity: 0 })),
  state('*', style({ opacity: 1 })),
  transition(':enter', animate(`${speed.slow} ${easing.default}`)),
  transition(':leave', animate(`${speed.slow} ${easing.default}`)),
];
export const fadeInOnlyAnimation: AnimationMetadata[] = [
  state('in', style({ opacity: 1 })),
  transition('void => *', [style({ opacity: 0 }), animate(`${speed.slow} ${easing.default}`)]),
];
export const fadeOutOnlyAnimation: AnimationMetadata[] = [
  state('out', style({ opacity: 0 })),
  transition('* => void', [style({ opacity: 1 }), animate(`${speed.slow} ${easing.default}`)]),
];
export const fadeRotateAnimation: AnimationMetadata[] = [
  state('void', style({ opacity: 0, transform: 'rotate(0)' })),
  state('*', style({ opacity: 1, transform: 'rotate(0)' })),
  transition(
    ':enter',
    animate(
      speed.normal,
      keyframes([style({ opacity: 0, transform: 'rotate(0)' }), style({ opacity: 1, transform: 'rotate(360deg)' })])
    )
  ),
  transition(
    ':leave',
    animate(
      speed.normal,
      keyframes([style({ opacity: 1, transform: 'rotate(0)' }), style({ opacity: 0, transform: 'rotate(360deg)' })])
    )
  ),
];
const scale0 = style({ opacity: 0, transform: 'scale(0.7) rotate(45deg)' });
const scale1 = style({ opacity: 1, transform: 'scale(1) rotate(0)' });

export const fadeScaleAnimation: AnimationMetadata[] = [
  state('void', scale0),
  state('*', scale1),
  transition(':enter', animate(`${speed.normal} ${easing.easeOut}`)),
  transition(':leave', animate(`${speed.normal} ${easing.easeOut}`)),
];

export const fadeScaleAnimationWithoutRotating: AnimationMetadata[] = [
  state('void', style({ opacity: 0, transform: 'scale(1.05)' })),
  state('*', style({ opacity: 1, transform: 'scale(1)' })),
  transition(':leave', animate(`${speed.normal} ${easing.easeOut}`)),
];

export interface FadeSlamAnimationOptions {
  delay?: string;
  dustColor?: string;
}
export const fadeSlamAnimation: (options: FadeSlamAnimationOptions) => AnimationMetadata[] = (
  options: FadeSlamAnimationOptions
) => [
  state('void', style({ opacity: 0, transform: 'scale(2)' })),
  state('*', style({ opacity: 1, transform: 'scale(1)' })),
  transition(
    ':enter',
    animate(
      `${speed.verySlow} ${options.delay || '0ms'} ${easing.easeOut}`,
      keyframes([
        style({
          opacity: 0,
          transform: 'scale(2)',
          ...(options.dustColor ? { 'box-shadow': `0 0 0 rgba(${options.dustColor}, 1)` } : {}),
          offset: 0,
        }),
        style({
          opacity: 1,
          transform: 'scale(1)',
          ...(options.dustColor ? { 'box-shadow': `0 0 0 rgba(${options.dustColor}, 1)` } : {}),
          offset: 0.4,
        }),
        style({
          opacity: 1,
          transform: 'scale(1)',
          ...(options.dustColor ? { 'box-shadow': `0 0 40px rgba(${options.dustColor}, 1)` } : {}),
          offset: 0.9,
        }),
        style({
          opacity: 1,
          transform: 'scale(1)',
          ...(options.dustColor ? { 'box-shadow': `0 0 60px rgba(${options.dustColor}, 0)` } : {}),
          offset: 1,
        }),
      ])
    )
  ),
  transition(':leave', animate(`${speed.normal} ${easing.easeOut}`)),
];
