export const FocusState = {
  NONE: 'none',
  LIST_ROOT: 'list-root',
  FIRST_ITEM: 'first-item',
  LAST_ITEM: 'last-item',
} as const;

export type FocusState = typeof FocusState[keyof typeof FocusState];

export type Values<T> = T[keyof T];
