<div class="[ stack ]" *ngIf="!error; else errorLogin">
  <h3 class="[ title-large text-center ]">of</h3>
  <div class="[ cluster center-content cluster-space-xs m-i-xl ]" data-cy="login-methods-container">
    <div>
      <button
        filled-button
        class="login-smartschool"
        (click)="loginWithSmartschool()"
        leadingIcon="smartschool"
        data-cy="btn-login-method-smartschool"
      >
        Smartschool
      </button>
      <button filled-button class="login-acm" (click)="loginWithAcm()" leadingIcon="acm" data-cy="btn-login-method-acm">
        eID/Itsme
      </button>
      <button
        filled-button
        class="login-leerId"
        (click)="loginWithLeerId()"
        leadingIcon="leerId"
        data-cy="btn-login-method-leerId"
      >
        LeerID
      </button>
      <button
        filled-button
        class="login-google"
        (click)="loginWithGoogle()"
        leadingIcon="google"
        data-cy="btn-login-method-google"
      >
        Google
      </button>
      <button
        filled-button
        class="login-microsoft"
        (click)="loginWithMicrosoft()"
        leadingIcon="microsoft"
        data-cy="btn-login-method-microsoft"
      >
        Microsoft
      </button>
      <button
        filled-button
        class="login-facebook"
        (click)="loginWithFacebook()"
        leadingIcon="facebook"
        data-cy="btn-login-method-facebook"
      >
        Facebook
      </button>
    </div>
  </div>
</div>

<ng-template #errorLogin>
  <div class="[ p-be-m m-be-m ][ border-be ]" [ngSwitch]="error" data-cy="login-error">
    <div *ngSwitchCase="'role_option'" class="[ p-m warn-container ]">
      <p>De gebruiker waarmee je probeert aan te melden is nog niet bij ons bekend.</p>
      <p
        >Ben je een <span class="weight-bold">leerkracht</span>? Vraag jouw schoolbeheerder om je account aan te maken,
        of POLPO op Smartschool te activeren.</p
      >
      <p
        >Ben je een <span class="weight-bold">leerling</span>, dan kan je je account aanmaken door op de knop hieronder
        te klikken.</p
      >
    </div>
    <div *ngSwitchCase="'user_role'" class="[ p-m warn-container ]">
      <p>De gebruiker waarmee je probeert aan te melden kan niet worden gebruikt voor deze applicatie.</p>
    </div>
    <div *ngSwitchCase="'not_in_sync'" class="[ p-m warn-container ]">
      <p>De Smartschool-gebruiker waarmee je probeert aan te melden werd nog niet geauthenticeerd voor POLPO.</p>
      <p
        >Vraag je ICT-coördinator of Smartschool-administrator de
        <a
          routerLink="/faq"
          [queryParams]="{
            role: 'teacher',
            slug: 'moet-de-ict-coordinator-of-smartschoolbeheerder-iets-doen-voor-ik-aan-de-slag-kan-op-polpo'
          }"
          fragment="leerlingen-en-klasgroepen"
          >koppeling te activeren</a
        >.</p
      >
    </div>
    <div *ngSwitchDefault class="[ p-m warn-container ]">
      <p
        >Er was een probleem bij het inloggen. Probeer opnieuw, of contacteer de
        <a routerLink="/" fragment="contact">helpdesk</a> als het probleem blijft aanhouden.</p
      >
    </div>
  </div>

  <div class="[ cluster center-content cluster-space-xs m-i-xl ]">
    <div>
      <button
        *ngIf="error === 'role_option'"
        filled-button
        [ngClass]="'login-' + provider"
        (click)="loginWith(provider)"
        [leadingIcon]="provider"
      >
        Maak mijn leerling-account
      </button>
      <button filled-button (click)="cancelLogin()"> Kies een andere aanmeld-methode </button>
    </div>
  </div>
</ng-template>
