import { Component, HostBinding, inject, OnInit } from '@angular/core';
import { ENVIRONMENT_WEBSITE_TOKEN } from '@campus/environment';
import { RegistrationCredentials } from '@campus/public-portal';
import { Observable } from 'rxjs';
import { RegisterPageContainerViewModel } from './register-page-container.viewmodel';

@Component({
  selector: 'polpo-register-page-container',
  templateUrl: './register-page-container.component.html',
  providers: [RegisterPageContainerViewModel],
})
export class RegisterPageContainerComponent implements OnInit {
  @HostBinding('style.padding-top') paddingTop = '132px';
  @HostBinding('style.display') display = 'block';

  @HostBinding('class') classList = 'block p-m';

  private vm = inject(RegisterPageContainerViewModel);
  private environmentWebsite = inject(ENVIRONMENT_WEBSITE_TOKEN);

  public registrationError$ = this.vm.registrationError$;
  public isMaintenanceMode$: Observable<boolean>;
  public statusPageUrl: string;

  ngOnInit(): void {
    this.isMaintenanceMode$ = this.vm.isMaintenanceMode$;
    this.statusPageUrl = this.environmentWebsite.statusPageUrl;
  }

  public register(data: RegistrationCredentials) {
    this.vm.register(data);
  }
}
