<h1>Privacy</h1>
<div>
  <div class="[ stack stack-space-2xl ]">
    <section>
      <div class="[ stack stack-space-s ]">
        <h2>Inleiding </h2>
        <p>
          In dit document worden nadere details verstrekt over de wijze waarop met ‘persoonsgegevens’ wordt omgegaan.
          Als gebruiker van POLPO verstrekt u, bewust of onbewust, steeds gegevens (van uzelf of van anderen) die ons in
          staat stellen u als individu te identificeren, ongeacht of wij dit daadwerkelijk doen. Bepaalde informatie
          wordt al als een persoonsgegeven beschouwd zodra een directe of indirecte link kan worden gelegd tussen deze
          informatie en u.
        </p>
        <p>
          Die Keure, de organisatie achter POLPO, hecht groot belang aan de zorgvuldige omgang met uw persoonsgegevens.
          Ook de wetgever erkent het belang hiervan en heeft een aantal verplichtingen vastgelegd in de ‘Europese
          Algemene Verordening Gegevensbescherming’ (beter bekend als ‘GDPR’). In het onderstaande document worden de
          verschillende aspecten besproken die deel uitmaken van deze verordening.
        </p>
        <p>
          De policy is opgesteld in zo duidelijk mogelijke bewoordingen, ook voor leerlingen. Wij gaan er echter van uit
          dat er af en toe nog vragen kunnen blijven bestaan. Indien bepaalde woorden of zinnen onduidelijk zijn, of
          indien u in het algemeen meer informatie wenst, aarzel dan niet om contact op te nemen met onze helpdesk via
          <a href="mailto:polpo@diekeure.be" class="[ weight-bold text-primary hover:underline ]"
            >polpo&#64;diekeure.be</a
          >.
        </p>
        <p> Die Keure, de organisatie achter POLPO is de volgende onderneming: </p>
        <p>
          die Keure NV <br />
          Kleine Pathoekeweg 3 <br />
          8000 Brugge, West-Vlaanderen, België <br />
          BTW 0405.108.325
        </p>
        <p>
          Indien u als bezoeker gebruik maakt van POLPO, of indien u besluit uzelf te registreren of gegevens via POLPO
          te verstrekken, stemt u in met het gebruik van de door u verstrekte gegevens in overeenstemming met deze
          verklaring. Houd er rekening mee dat POLPO links kan bevatten naar andere websites die mogelijk niet onder
          deze verklaring vallen en waarvoor die Keure derhalve niet aansprakelijk of verantwoordelijk is. Een voorbeeld
          hiervan is wanneer een leerkracht een bundel samenstelt met een verwijzing naar een andere website.
        </p>
        <p>
          Indien die Keure deze verklaring wijzigt nadat u deze eerder hebt goedgekeurd, zal die Keure er alles aan doen
          om u van die wijziging op de hoogte te stellen. Wanneer u na de wijziging inlogt, zult u via een banner of
          pop-up geïnformeerd worden over de nieuwe verklaring. Op dat moment bent u verplicht de nieuwe verklaring door
          te lezen en goed te keuren voordat u verder kunt werken op POLPO.
        </p>
        <p>
          Die Keure benadrukt dat zij zich bewust is van de moeilijkheden en uitdagingen waarmee ouders (en/of bevoegde
          meerderjarigen zoals leerkrachten) mogelijk worden geconfronteerd bij het hedendaagse internetgebruik door
          minderjarige kinderen respectievelijk leerlingen. Die Keure doet er dan ook alles aan om een zo veilig
          mogelijke internetomgeving voor minderjarigen te creëren, met inachtneming van de nodige voorzichtigheid bij
          het ontwerpen van de inhoud van POLPO en met respect voor de verwerking van de gegevens van deze
          minderjarigen.
        </p>
      </div>
    </section>
    <section>
      <div class="[ stack stack-space-s ]">
        <h2>Het verzamelen van gegevens en bijhorende goedkeuringen </h2>
        <p>
          Indien in deze verklaring gesproken wordt over 'gegevens' kan dit zowel over gegevens gaan van meerderjarigen
          als van minderjarigen.
        </p>
        <p>
          Zowel leerkrachten als leerlingen moeten akkoord gaan met deze verklaring voordat zij gebruik kunnen maken
          POLPO. Zelfs al zijn ouders en bevoegde meerderjarigen aansprakelijk voor de handelingen van minderjarigen,
          ook de studenten moeten altijd een expliciete goedkeuring geven vooraleer zij POLPO kunnen gebruiken. Die
          Keure volgt hiermee de redenering dat kinderen vanaf 12 jaar een 'onderscheidingsvermogen' hebben. Dat is ook
          de reden waarom er een aparte goedkeuring gevraagd wordt aan iedere minderjarige die POLPO gebruikt.
        </p>
        <h3
          >Hoe gaat die Keure om met de goedkeuring van ouders voor de verwerking van persoonsgegevens als de ouders
          zelf niet met POLPO werken?</h3
        >
        <p>
          Die Keure gaat ervan uit dat de goedkeuring door de ouders via de school tot stand komt, aangezien de school
          de verantwoordelijke voor de verwerking van persoonsgegevens is.
        </p>
        <h3>Over welke persoonsgegevens gaat het eigenlijk?</h3>
        <p>
          Die Keure verzamelt via POLPO persoonsgegevens. Die Keure zal niet meer gegevens verzamelen dan nodig voor
          haar opdracht t.a.v. de scholen (waarbij de school de verantwoordelijke van de verwerking is). In het kader
          van deze opdracht zal die Keure de volgende persoonsgegevens verzamelen:
        </p>
        <ul class="">
          <li>
            Persoonsgegevens:
            <ul>
              <li>Naam</li>
              <li>Voornaam</li>
              <li>E-mail adres (leerkracht)</li>
            </ul>
            Bovengenoemde gegevens zijn noodzakelijk voor de identificatie van gebruikers op het platform, het
            faciliteren van het aanmeldingsproces, het versturen van wachtwoorden en het verstrekken van uitnodigingen
            voor toegang tot het platform.
          </li>
          <li>
            Single Sign-On Gegevens <br />
            Het platform maakt gebruik van Single Sign-On (SSO) functionaliteit om het aanmeldingsproces te
            vereenvoudigen. Deze methode stelt gebruikers in staat zich op het platform te authenticeren met behulp van
            de inloggegevens van een externe identiteitsprovider.
            <ul>
              <li
                >Basis profiel data:
                <ul>
                  <li>Voornaam</li>
                  <li>Familienaam</li>
                  <li>Avatar</li>
                  <li>E-mailadres indien voorhanden</li>
                  <li>Gebruikersnaam indien van toepassing</li>
                </ul>
              </li>
              <li
                >Dit voor volgende identity providers indien geactiveerd:
                <ul>
                  <li>Smartschool</li>
                  <li>Google</li>
                  <li>Microsoft</li>
                  <li>LeerId</li>
                  <li>Overheid vlaanderen ACM</li>
                  <li>Facebook</li>
                </ul>
              </li>
            </ul>
            De info die wordt uitgewisseld tussen identity provider en POLPO wordt ook tijdens het koppelen weergegeven
            ter goedkeuring.
          </li>

          <li>
            Gegevens in relatie tot een school:
            <ul>
              <li>
                Om de juiste functionaliteiten van het platform te kunnen aanbieden
                <ul
                  ><li> Rol binnen een school (leerkracht, leerling, schoolbeheerder) </li></ul
                >
              </li>
              <li>
                Om een correcte koppeling te maken tussen leerling en leerkracht
                <ul
                  ><li> Groepen (leerling & leerkracht) </li></ul
                >
              </li>
              <li>
                Om correct bestellingen af te handelen
                <ul>
                  <li>Voornaam en naam contactpersoon school bij bestellingen</li>
                  <li>Email van contactpersoon</li>
                </ul>
              </li>
              <li>
                Ter preventie van misbruik
                <ul
                  ><li>Het IP-adres van gebruikers</li></ul
                >
              </li>
              <li>
                Om bundels te delen tussen leerkrachten onderling + om toegang te krijgen tot lesmateriaal aangekocht
                door een school
                <ul
                  ><li>De school waar een leerkracht les geeft</li></ul
                >
              </li>
              <li
                >Om klasstructuren in Smartschool te synchroniseren naar POLPO, indien de beheerder dit aanlegt:
                <ul>
                  <li>De Smartschool gebruikersnaam van leerlingen en leerkrachten </li>
                  <li>De klasgroepen waarin ze lesgeven</li>
                  <li>De Smartschool schoolgegevens </li>
                  <li>De koppelingen tussen leerlingen, leerkrachten en klassen </li>
                  <li
                    >Basis profiel data:
                    <ul>
                      <li>Voornaam </li>
                      <li>Familienaam </li>
                      <li>Avatar </li>
                      <li>E-mailadres </li>
                      <li>Gebruikersnaam </li>
                      <li>Rol binnen Smartschool i.g.v. Smartschool-koppeling</li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            Gegevens i.v.m. onderwijsactiviteiten op het platform (leerling)
            <ul>
              <li
                >Om inzicht te krijgen in bekwaamheid van een leerling, om hun voortgang op te volgen en om de
                performantie van de oefeningen te verbeteren en om leerinhoud op maat van de leerling te kunnen
                voorstellen
                <ul
                  ><li
                    >Scores, tijd gespendeerd, afgewerkt, aantal pogingen, hulp nodig, tijdstip -> bij digitale
                    oefeningen: om gericht oefeningen te kunnen voorstellen voor leerlingen en inzage in de bekwaamheid
                    te krijgen</li
                  >
                  <li
                    >Registratie van leerhandelingen (answered, experienced, completed, rated, …) met bijhorende
                    details</li
                  >
                  <li>Status van verwerking van leermiddelen, aangegeven door leerlingen zelf</li>
                </ul>
              </li>
              <li
                >Om opdrachten te kunnen geven online
                <ul
                  ><li>Toegekende opdrachten en lesmateriaal</li></ul
                ></li
              >
              <li
                >Om een leerkracht toe te laten eigen lesmateriaal te delen
                <ul
                  ><li>Linken naar eigen lesmateriaal van een leerkracht </li></ul
                ></li
              >
            </ul>
          </li>
          <li>
            Gegevens bij het gebruik van het platform
            <ul>
              <li>
                Met het doeleind in-actieve accounts op te ruimen
                <ul
                  ><li>Laatste aanmelding op het platform (leerkracht en leerling)</li></ul
                >
              </li>
              <li>
                Voor het gebruiksgemak van de leerkracht:
                <ul>
                  <li>
                    Historiek van acties van een leerkracht:
                    <ul>
                      <li>Openen van leermiddelen</li>
                      <li>Navigatie in platform</li>
                      <li>Zoekopdrachten</li>
                      <li>Gebruik taken en bundels</li>
                    </ul>
                  </li>
                  <li>
                    Favorieten aangeduid door een leerkracht
                    <ul>
                      <li>Methodes/uitgaven/producten</li>
                      <li>Leermiddelen</li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                Voor een correcte werking van het platform
                <ul
                  ><li> Taken, toekenningen lesmateriaal aan leerlingen </li></ul
                >
              </li>
              <li> Aankoopgegevens i.v.m. licenties die een persoon deed voor 2023 </li>
            </ul>
          </li>
          <li>
            Gegevens bewaard bij ingave contactformulier<br />
            Om een correcte service te kunnen leveren als helpdesk/besteldienst
            <ul>
              <li>Naam & voornaam</li>
              <li>Schoolnaam</li>
              <li>E-mail</li>
              <li>Telefoon</li>
              <li>Bericht gericht aan die Keure</li>
            </ul>
          </li>
        </ul>
        <p>
          Die Keure verzamelt geen gevoelige persoonsgegevens over jou (zoals gegevens over ras, politieke opvattingen,
          gezondheid, godsdienstige en andere geloofsovertuigingen of seksuele geaardheid)
        </p>
      </div>
    </section>
    <section>
      <div class="[ stack stack-space-s ]">
        <h2>De school als verantwoordelijke voor de verwerking van persoonsgegevens </h2>
        <p>
          Aangezien de school verantwoordelijk is voor de verwerking van de persoonsgegevens in POLPO (zowel
          leerkrachten als leerlingen) is het ook aan de school om de gebruikers correct te informeren over het gebruik
          van deze hun persoonsgegevens. Eventuele uitoefeningen van rechten (bijvoorbeeld om gewist te worden als
          gebruiker) dienen gericht te zijn aan de school, aangezien het de school is die de gegevens registreert en
          onderhoudt.
        </p>
      </div>
    </section>
    <section>
      <div class="[ stack stack-space-s ]">
        <h2>Die Keure als verwerker van persoonsgegevens op eigen initiatief</h2>
        <p class=""
          >Die Keure zal persoonsgegevens op eigen initiatief steeds zo weinig mogelijk gebruiken, en zal altijd
          duidelijk zijn wanneer ze dit wel doet. De reden om persoonsgegevens als niet-verantwoordelijke toch te
          gebruiken heeft te maken met direct marketing, met de goede technische en inhoudelijke werking van het
          systeem, alsook met veiligheid (bijvoorbeeld de detectie van fraude), of met het doel om de werking van het
          systeem te verbeteren op basis van verzamelde gegevens.
        </p>
        <p>Meer bepaald kunnen persoonsgegevens gebruikt worden voor de volgende doeleinden:</p>
        <ul class="">
          <li>bezoekersadministratie (o.a. bevestigingsmail betreffende inloggegevens)</li
          ><li>
            directe marketing naar leerkrachten (nooit naar leerlingen) waaronder het verzenden van minstens één
            nieuwsbrief (iedereen kan zich altijd uitschrijven bij het ontvangen van een nieuwsbrief). Die Keure baseert
            zich op het gerechtvaardigd belang om directe marketing te versturen.
          </li>
          <li>het verbeteren van de inhoud en de kwaliteit van POLPO. </li>
          <li
            >algemene monitoring van de acties ondernomen op het onderwijsplatform door de verschillende gebruikers
            (leerlingen en leerkrachten).
          </li>
          <li
            >het accuraat bijhouden, verbeteren en vervolledigen van de persoonsgegevens zoals door de wet vereist.
          </li>
          <li>Het melden van een datalek aan de verantwoordelijke van de verwerking. </li>
          <li
            >Het verbeteren van AI-algoritmes a.d.h.v. trainingsdata.<br />
            Leerlingen kunnen oefeningen maken in taallessen op hun uitspraak. Een AI-algoritme evalueert dan de
            uitspraak en geeft feedback aan de leerling. Opnames van uitspraakoefeningen worden pseudoniem opgeslagen
            als trainingsdata met als doeleinde het algoritme te trainen en zo beter te maken. Aangezien de beslissing
            om deze oefeningen te gebruiken bij de school ligt, is het aan de school om hierover te communiceren naar de
            ouders van de leerlingen. (Indien leerlingen/ouders hiertegen bezwaar hebben, kunnen ze zelf op het
            platform, bij de profiel-instellingen van de leerling aangeven dat deze data niet bijgehouden en hergebruikt
            mag worden. Instructies hiervoor zijn ook beschikbaar in de veelgestelde vragen van het platform. Indien je
            meer informatie wil hieromtrent, neem dan contact op met
            <a href="mailto:polpo@diekeure.be" class="[ weight-bold text-primary hover:underline ]"
              >polpo&#64;diekeure.be</a
            >.)
          </li>
        </ul>
        <h3>Opslag en bewaring van de persoonsgegevens </h3>
        <p>
          De persoonsgegevens van de gebruikers worden bewaard via de public cloud (Amazon Web Services). Gegevens
          worden bewaard in Ierland binnen de EU zone. Die Keure is gebonden aan het AWS customer agreement, waarin
          gesteld wordt dat de nodige maatregelen genomen worden door AWS tegen verlies van data, diefstal van data of
          ongeoorloofd gebruik van data. </p
        ><p>
          Indien je meer informatie wil over het AWS customer agreement, neem dan contact op via
          <a href="mailto:polpo@diekeure.be" class="[ weight-bold text-primary hover:underline ]"
            >polpo&#64;diekeure.be</a
          >.
        </p>
      </div>
    </section>
    <section>
      <div class="[ stack stack-space-s ]">
        <h2>Het doorgeven van persoonsgegevens aan sub-verwerkers. </h2>
        <p>
          Die Keure zal geen gegevens met derden delen voor secundaire of niet gerelateerde doeleinden tenzij anders
          vermeld op het moment van het verzamelen van deze gegevens. De werknemers van die Keure of de door die Keure
          ingeschakelde derden zijn verplicht om de vertrouwelijkheid van de gegevens op POLPO te respecteren.</p
        ><p>
          POLPO als platform maakt gebruik van geïntegreerde functionaliteiten en hulpmiddelen die aangeleverde worden
          door derde partijen. Die Keure gaat enkel een samenwerking aan met derde partijen indien deze gekend zijn als
          betrouwbare partijen als het gaat om de beveiliging en de correcte verwerking van de persoonsgegevens.</p
        ><p>
          Waar gepast of vereist, kunnen en zullen persoonsgegevens door die Keure worden verstrekt aan de
          rechtshandhaving, regelgevende of andere overheidsinstanties, of derde partijen indien dit noodzakelijk of
          wenselijk is om te voldoen aan wettelijke of reglementaire verplichtingen of in het kader van de hierboven
          vermelde doeleinden.
        </p>
        <p>
          Voor specifieke verklaringen van derde partijen verwijzen we naar de verklaringen van onderstaande gekende
          partijen. Deze lijst kan toekomstig nog aangepast worden:
        </p>
        <h3>Integratie met Smartschool (Smartbit B.V.B.A.)</h3>
        <p>
          De eerder vermelde persoonsgegevens zijn deels afkomstig uit Smartschool. Deze gegevens zullen nooit op
          initiatief van die Keure doorgestuurd worden naar POLPO, noch op initiatief van Smartbit. Het is de school, en
          meer specifiek de smartschoolbeheerder die toelating geeft om de gegevens te mogen gebruiken.</p
        ><p>
          Privacy statement Smartbit BVBA:
          <a
            href="https://www.smartschool.be/privacybeleid/"
            target="_blank"
            class="[ weight-bold text-primary hover:underline ]"
            >https://www.smartschool.be/privacybeleid/</a
          >
        </p>
        <h3>Integratie met Learnosity</h3>
        <p>
          Digitale oefeningen op Polpo worden gemaakt met beoordelingssoftware van Learnosity. Dit is een online dienst
          die geïntegreerd is in Polpo. Alles wat ingegeven wordt in de oefeningen wordt opgeslagen bij Learnosity, en
          resultaten/scores van deze oefeningen worden doorgegeven aan Polpo. Enkel een gepseudonimiseerd userId wordt
          doorgegeven aan Learnosity, zodat resultaten bij Learnosity niet direct herleidbaar zijn tot een persoon.
        </p>
        <p>
          Meer info over hoe Learnosity omgaat met data vind je op hun
          <a href="https://www.learnosity.com/dpa" class="[ weight-bold text-primary hover:underline ]" target="_blank"
            >privacy policy</a
          >.
        </p>
        <h3>Integratie met NovoLearning</h3>
        <p>
          Digitale spraak-oefeningen op Polpo worden gemaakt met software van NovoLearning. Dit is een online dienst die
          geïntegreerd is in Polpo. Alle input bij het maken van de oefeningen wordt opgeslagen bij NovoLearning, en
          resultaten/scores van deze oefeningen worden doorgegeven aan Polpo. Enkel een gepseudonimiseerd userId wordt
          doorgegeven aan NovoLearning, zodat resultaten bij NovoLearning niet direct herleidbaar zijn tot een persoon.
        </p>
        <p>
          Meer info over hoe NovoLearning omgaat met data vind je op hun
          <a
            href="https://www.novo-learning.com/privacy/"
            class="[ weight-bold text-primary hover:underline ]"
            target="_blank"
            >privacy policy</a
          >.
        </p>
        <h3> Integratie met Google Analytics </h3>

        <p>
          Log-informatie wordt doorgegeven en verwerkt door Google Analytics voor analytische doeleinden (bijv. om het
          bezoek aan de website op te volgen (daluren, piekmomenten,..). Met log-informatie bedoelen we informatie zoals
          'tijdstip' en 'IP-adres'. Ook locatiegegevens worden doorgegeven, zodat jouw vermoedelijke locatie op een
          kaart kan worden weergegeven. Ook informatie met betrekking tot het gebruikte toestel zoals hardware en
          software informatie wordt gebruikt om het gebruik van POLPO te monitoren.</p
        ><p>
          Google Analytics :
          <a
            href="http://www.google.com/analytics/terms/nl.html"
            target="_blank"
            class="[ weight-bold text-primary hover:underline ]"
            >http://www.google.com/analytics/terms/nl.html</a
          >
        </p>
        <h3>Integratie met derde partijen met betrekking tot Single Sign-On functionaliteit.</h3>
        <p
          >Gebruikers die met POLPO en afgeleide producten werken hebben de mogelijkheid om inloggegevens van een aantal
          derde partijen te hergebruiken, door goedkeuring te geven aan een derde partij (zoals bijvoorbeeld Facebook)
          om gegevens door te sturen naar POLPO. Deze goedkeuring kan ten allen tijd geannuleerd worden via de derde
          partij.</p
        >
        <p
          >De koppelingen zorgen ervoor dat inloggen eenvoudiger wordt, omdat gegevens hergebruikt worden in plaats van
          deze apart te moeten ingeven. Die Keure biedt Single Sign On functionaliteit aan enkel en alleen om het
          inloggen te vergemakkelijken. Daarom heeft die Keure enkele basisgegevens nodig (zoals naam, userid,
          geboortedatum). Indien die Keure in de toekomst andere gegevens wil gebruiken dan zal die Keure er voor zorgen
          dat de bedoeling hiervan op voorhand duidelijk gemaakt wordt.</p
        >
        <p
          >Die Keure raadt aan om de verklaringen van de betreffende partijen door te nemen alvorens goedkeuring te
          geven om gegevens door te sturen naar POLPO</p
        >
        <p
          >Smartschool:
          <a
            href="http://www.smartschool.be/privacybeleid/"
            class="[ weight-bold text-primary hover:underline ]"
            target="_blank"
            >http://www.smartschool.be/privacybeleid/</a
          ></p
        >
        <p
          >Facebook:
          <a
            href="https://www.facebook.com/privacy/explanation"
            class="[ weight-bold text-primary hover:underline ]"
            target="_blank"
            >https://www.facebook.com/privacy/explanation</a
          ></p
        >
        <p
          >Google:
          <a
            href="https://www.google.com/policies/privacy/"
            class="[ weight-bold text-primary hover:underline ]"
            target="_blank"
            >https://www.google.com/policies/privacy/</a
          ></p
        >
      </div>
    </section>
    <section>
      <div class="[ stack stack-space-s ]">
        <h2>Goede technische werking </h2>
        <p class=""
          >Met het oog op een degelijke en adequate besturing van POLPO en teneinde een verbeterde navigatie mogelijk te
          maken binnen POLPO, is het mogelijk dat die Keure of die Keure's service providers zogenaamde cookies zullen
          gebruiken. Die Keure maakt gebruik van de volgende cookies:
        </p>
        <ul class="">
          <li> authentication cookies </li
          ><li> load balancing session cookies </li>
        </ul>
        <p>
          Cookies zijn kleine tekstbestanden of informatiedeeltjes die opgeslagen worden op de harde schijf van een
          gebruiker. Cookies zijn bestanden die als het ware de sporen bijhouden van de voorafgaande bezoeken van
          bepaalde websites en de informatie die met deze bezoeken gepaard gaat. Er bestaan technische cookies
          (bijvoorbeeld m.b.t. taalinstellingen), sessiecookies (tijdelijke cookies) en tracking cookies (cookies die
          betrekking hebben op het gedrag van een website-bezoeker die deze bezoekers a.h.w. op POLPO gaan volgen en de
          gedragingen bijhouden met het oog op een optimale gebruikservaring voor POLPO-bezoeker).
        </p>
        <p>
          Web beacons zijn elektronische beelden die het mogelijk maken het aantal bezoekers op een bepaalde websites te
          tellen en die toegang verlenen tot bepaalde cookies.
        </p>
        <p>
          Die Keure kan deze instrumenten gebruiken om informatie op haar systemen op te volgen en om categorieën van
          gebruikers te identificeren aan de hand van gegevens zoals het IP-adres, het domein, het type van browser en
          de bezochte pagina's. Deze informatie wordt meegedeeld aan die Keure's webmasters die deze informatie
          gebruiken om het aantal bezoekers op de verschillende delen van POLPO te analyseren en om ervoor te zorgen dat
          POLPO functioneert als een bruikbare en effectieve bron van informatie.
        </p>
        <h3>Soorten cookies</h3>
        <ul class="">
          <li>
            Functionele <br />
            Functionele cookies helpen een website gebruiksvriendelijk te maken doordat ze basisfunctionaliteit, zoals
            toegang verlenen tot een bepaalde zone van de website, mogelijk maken. De website kan zonder deze cookies
            niet goed functioneren.
          </li>
          <li>
            Voorkeuren <br />
            Dit zijn cookies die informatie over je keuzes en voorkeuren op een website bijhouden. Zo onthouden ze
            bijvoorbeeld je taalkeuze, inloggegevens of de regio waarin je woont.
          </li>
          <li>
            Marketing <br />
            Marketing cookies worden gebruikt om een bezoeker te volgen over meerdere websites. Het is de bedoeling
            advertenties weer te geven die relevant en aantrekkelijk zijn voor de individuele gebruiker en daardoor
            waardevoller zijn voor uitgevers en externe adverteerders.
          </li>
          <li>
            Statistieken <br />
            Statistische cookies verzamelen informatie over het gedrag van websitebezoekers en de prestaties van de
            website. Aan de hand van de resultaten kan de website verbeterd worden en krijgen bezoekers een betere
            gebruikservaring.
          </li>
          <li>
            Sociale media / derde partij <br />
            Social media cookies worden gebruikt om de integratie van sociaalnetwerksites op onze websites mogelijk te
            maken. Onze website kan ingebedde elementen van derde partijen bevatten. Dat kan gaan om inhoud die bij een
            andere partij opgeslagen is, maar die via onze website wordt getoond. Die derden kunnen via onze website
            cookies plaatsen om gegevens te verzamelen. Wijzelf hebben geen invloed op wat die sociale media met hun
            cookies doen. Voor meer informatie over deze cookies verwijzen we je graag naar de verklaringen die de
            derden hierover op hun eigen websites geven. Merk wel op dat die verklaringen regelmatig kunnen wijzigen.
          </li>
        </ul>
        <h3>Cookies die wij gebruiken op POLPO.be: </h3>
        <ul class="">
          <li>
            Statistische cookies: worden gebruikt om Google Analytics te integreren.<br />
            Verdere details over de cookies kan je
            <a href="https://business.safety.google/adscookies/" class="[ weight-bold text-primary hover:underline ]"
              >hier</a
            >
            vinden.
          </li>
          <li> 3rd party: wordt gebruikt om de Wistia media player te integreren en media af te spelen. </li>
        </ul>

        <p
          >Een gebruiker van POLPO kan altijd het gebruik van de cookies verwijderen via zijn of haar
          browserinstellingen.</p
        >
        <ul class="">
          <li>
            <a
              href="https://support.mozilla.org/nl/kb/cookies-verwijderen-gegevens-wissen-websites-opgeslagen"
              target="_blank"
              class="[ weight-bold text-primary hover:underline ]"
              >Mozilla Firefox
            </a> </li
          ><li>
            <a
              href="https://support.google.com/chrome/answer/95647?hl=nl"
              target="_blank"
              class="[ weight-bold text-primary hover:underline ]"
              >Google Chrome
            </a> </li
          ><li>
            <a
              href="https://support.apple.com/nl-nl/HT201265"
              target="_blank"
              class="[ weight-bold text-primary hover:underline ]"
              >Safari (iOS)
            </a> </li
          ><li>
            <a
              href="https://support.apple.com/nl-be/guide/safari/sfri11471/mac"
              target="_blank"
              class="[ weight-bold text-primary hover:underline ]"
              >Safari (macOS)
            </a> </li
          ><li>
            <a
              href="https://support.microsoft.com/nl-nl/help/10607/windows-10-view-delete-browser-history-microsoft-edge"
              target="_blank"
              class="[ weight-bold text-primary hover:underline ]"
              >Edge
            </a>
          </li>
        </ul>
        <p
          >Die Keure voorziet in een banner bij het eerste bezoek aan de website waarin toestemming wordt gevraagd voor
          het plaatsen van cookies.</p
        >
        <p>
          Noch de cookies noch de web beacons gebruikt op POLPO verzamelen de naam of het e-mailadres van de bezoeker.
          Onder bepaalde omstandigheden is het echter mogelijk dat de bezoekers geen toegang verkrijgen tot bepaalde
          delen van POLPO, indien hun webbrowser zodanig is ingesteld dat ze cookies weigeren.
        </p>
      </div>
    </section>
    <section>
      <div class="[ stack stack-space-s ]">
        <h2>Wat zijn jouw rechten als gebruiker? </h2>
        <p
          >Je hebt als leerling of als leerkracht een aantal rechten die je kan gebruiken als het over persoonsgegevens
          gaat.</p
        >
        <p>
          Voor al je vragen over jouw rechten kan je een email sturen naar:
          <a href="mailto:polpo@diekeure.be" class="[ weight-bold text-primary hover:underline ]"
            >polpo&#64;diekeure.be</a
          >
          Mogelijks wens je je gegevens aan te passen, of wil je gegevens permanent verwijderen en dergelijke meer.
          Aarzel niet om contact op te nemen met onze klantendienst. Om bepaalde rechten uit te oefenen zullen wij
          mogelijks verwijzen naar de school als verantwoordelijke van de verwerking.
        </p>
        <h3>Rechtmatige en veilige verwerking van persoonsgegevens </h3>
        <p>
          Die Keure zal er op toezien dat de gegevensverwerking gebeurt op basis van het gezond verstand (doeltreffend,
          niet overmatig).</p
        ><p>
          De persoonsgegevens zullen niet langer worden opgeslagen dan nodig. Zolang je account actief is zullen we de
          persoonsgegevens bijhouden. Wanneer leerkrachten en leerlingen hun account niet vernieuwen houden we de
          persoonsgegevens echter nog langer bij ons zodanig dat wanneer iemand alsnog (na het verlopen van een
          abonnement) zijn account wil vernieuwen nog gebruik kan maken van de door hem of haar opgeslagen
          functionaliteiten. Zo zal je als leerkracht je bundels of taken niet verliezen, en kan je nog steeds de
          resultaten bekijken van de leerlingen van de voorbije jaren.</p
        ><p>
          Die Keure heeft naar haar mening voldoende beveiligingsmaatregelen getroffen om een veilige verwerking van de
          persoonsgegevens te waarborgen.</p
        ><p>
          De risico's van een toevallige of ongeoorloofde vernietiging, toevallig verlies, de wijziging van of de
          toegang tot, en iedere andere niet toegelaten verwerking zijn zo goed mogelijk gereduceerd. Dit betekent
          helaas niet dat alle risico's uitgesloten kunnen worden. Bij een inbreuk op de wettelijk toegestane
          verwerkingen in POLPO, zal die Keure onmiddellijk alle mogelijke maatregelen nemen om schade tot een minimum
          te beperken.
        </p>
        <h3>Recht op verzet, restrictie en gegevensoverdracht </h3>
        <p>
          Je kan je als gebruiker te allen tijde kosteloos en zonder motivering verzetten tegen de verwerking van je
          persoonsgegevens indien 1) die Keure je persoonsgegevens verwerkt op basis van een legitiem doel (en die Keure
          de persoonsgegevens niet verder moet verwerken op een andere juridische basis); of 2) deze persoonsgegevens
          verkregen werden met het oog op direct marketing. </p
        ><p>
          In de beperkte gevallen waar dit wettelijk is toegelaten, kan je als gebruiker ook een verbod van de
          verwerking eisen. </p
        ><p>
          Verder kan je als gebruiker in bepaalde gevallen zoals bepaald door de wet ook de gegevensoverdracht eisen van
          de persoonsgegevens die je aan die Keure hebt verstrekt. Die Keure verbindt zich ertoe je deze
          persoonsgegevens op een gestructureerde, gangbare en machineleesbare vorm mee te delen, zodat je deze
          persoonsgegevens indien gewenst kan doorgeven aan een andere verwerkingsverantwoordelijke.</p
        ><p>
          Als gebruiker kan je je rechten uitoefenen door in eerste instantie je school te contacteren, aangezien zij de
          verantwoordelijke van de verwerking zijn. Die Keure verbindt er zich toe om zo snel mogelijk aan het verzoek
          van een school gevolg te geven zoals voorzien in de wet.
        </p>
        <h3> Recht op toegang tot, verbetering en verwijdering van je persoonsgegevens </h3>
        <p>
          Je beschikt over een recht op toegang tot je persoonsgegevens. Die Keure engageert zich tot een zo nauwkeurig
          mogelijke gegevensverzameling. Onnauwkeurige of onvolledige persoonsgegevens kunnen steeds verbeterd of
          eventueel zelfs verwijderd worden.</p
        ><p>
          Jij als gebruiker hebt het recht onnauwkeurigheden en onvolledigheden te melden indien de nodige aanpassingen
          niet door jou binnen je gebruikersregistratie of profiel gemaakt kunnen worden.</p
        ><p>
          Als gebruiker kan je je rechten uitoefenen door ons te contacteren via polpo&#64;diekeure.be die Keure
          verbindt er zich toe zo snel mogelijk aan jouw verzoek een gevolg te geven. Omwille van veiligheidsredenen
          zullen we je in deze procedure wel vragen je identiteit te bewijzen.
        </p>
        <h3>Recht om klacht in te dienen bij de Privacy-commissie </h3>
        <p>
          Je hebt als gebruiker het recht om een klacht in te dienen bij de Belgische Privacy-commissie, ook de
          ‘Commissie voor de bescherming van de persoonlijke levenssfeer’ genaamd. Voor meer informatie kan je terecht
          op
          <a
            href="https://www.gegevensbeschermingsautoriteit.be/burger"
            class="[ weight-bold text-primary hover:underline ]"
            target="_blank"
            >https://www.gegevensbeschermingsautoriteit.be/burger</a
          >.
        </p>
      </div>
    </section>
  </div>
</div>
