import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { VideoPlayerType } from '../../types';

@Component({
  selector: 'campus-about-section',
  templateUrl: './about-section.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AboutSectionComponent {
  @HostBinding('class')
  defaultClasses = ['block'];

  @Input() title: string;
  @Input() videoId: string;
  @Input() player: VideoPlayerType;
  @Input() readMoreLink: string;
}
