<div
  class="[ relative flex gap-s items-center justify-center ][ row-h-s p-is-l p-ie-m ][ corner-right-full ]"
  [class.primary-container]="selected"
>
  <campus-icon
    *ngIf="icon"
    [svgIcon]="selected ? 'filled:' + icon : icon"
    class="group-hover:on-surface group-focus-visible:on-surface"
    [class.icon-on-primary-container]="selected"
    aria-hidden="true"
  ></campus-icon>
  <label *ngIf="label" class="[ label-large flex-1 truncate ][ cursor-pointer ]" [class.on-primary-container]="active"
    >{{ label }}
  </label>

  <campus-badge
    *ngIf="badge && badge === true; else textBadge"
    [value]="badge"
    [size]="badgeSize"
    class="rail-item-badge"
  ></campus-badge>

  <ng-template #textBadge>
    <div *ngIf="badge" class="[ label-medium on-surface-variant ]">{{ badge }}</div>
  </ng-template>

  <campus-state-layer
    [for]="element"
    [ngClass]="selected ? 'bg-on-primary-container' : 'bg-primary'"
  ></campus-state-layer>
  <campus-focus-ring [for]="element"></campus-focus-ring>
</div>
