export const Corner = {
  END_START: 'end-start',
  END_END: 'end-end',
  START_START: 'start-start',
  START_END: 'start-end',
} as const;

export type Corner = typeof Corner[keyof typeof Corner];

export interface SurfacePositionTarget extends HTMLElement {
  getSurfacePositionClientRect?: () => DOMRect;
}

export interface SurfacePositionControllerProperties {
  /**
   * The corner of the anchor to align the surface's position.
   */
  anchorCorner: Corner;
  /**
   * The corner of the surface to align to the given anchor corner.
   */
  surfaceCorner: Corner;
  /**
   * The HTMLElement reference of the surface to be positioned.
   */
  surfaceEl: SurfacePositionTarget | null;
  /**
   * The HTMLElement reference of the anchor to align to.
   */
  anchorEl: SurfacePositionTarget | null;
  /**
   * Whether the positioning algorithim should calculate relative to the parent
   * of the anchor element (absolute) or relative to the window (fixed).
   *
   * Examples for `position = 'fixed'`:
   *
   * - If there is no `position:relative` in the given parent tree and the
   *   surface is `position:absolute`
   * - If the surface is `position:fixed`
   * - If the surface is in the "top layer"
   * - The anchor and the surface do not share a common `position:relative`
   *   ancestor
   */
  positioning: 'absolute' | 'fixed' | 'document';
  /**
   * Whether or not the surface should be "open" and visible
   */
  isOpen: boolean;
  /**
   * The number of pixels in which to offset from the inline axis relative to
   * logical property.
   *
   * Positive is right in LTR and left in RTL.
   */
  xOffset: number;
  /**
   * The number of pixes in which to offset the block axis.
   *
   * Positive is down and negative is up.
   */
  yOffset: number;
  /**
   * The strategy to follow when repositioning the menu to stay inside the
   * viewport. "move" will simply move the surface to stay in the viewport.
   * "resize" will attempt to resize the surface.
   *
   * Both strategies will still attempt to flip the anchor and surface corners.
   */
  repositionStrategy: 'move' | 'resize';

  onOpen: () => void;
  beforeClose: () => Promise<void>;
  onClose: () => void;
}
