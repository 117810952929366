<div class="[ flex flex-column flex-column-reverse gap-3xs items-center justify-center ][ corner-m ]">
  <label
    *ngIf="label"
    class="[ label-medium text-center ] [ cursor-pointer ][ group-hover:on-surface group-focus-visible:on-surface ]"
    [ngClass]="selected ? 'on-surface' : 'on-surface-variant'"
    >{{ label }}
  </label>
  <div
    class="icon-container [ relative ] [ flex items-center justify-center corner-full ]["
    [class.primary-container]="selected"
    #iconContainer
  >
    <span class="[ relative inline-flex justify-center items-center ]">
      <campus-icon
        [svgIcon]="selected ? 'filled:' + icon : icon"
        class="group-hover:on-surface group-focus-visible:on-surface"
        [ngClass]="selected ? 'on-primary-container' : 'on-surface-variant'"
        aria-hidden="true"
      ></campus-icon>
      <campus-badge
        *ngIf="badge"
        [value]="badge"
        [size]="badgeSize"
        class="rail-item-badge [ absolute ]"
      ></campus-badge>
    </span>
    <campus-state-layer
      [for]="element"
      [ngClass]="selected ? 'bg-on-primary-container' : 'bg-primary'"
    ></campus-state-layer>
    <campus-focus-ring [for]="element"></campus-focus-ring>
  </div>
</div>
