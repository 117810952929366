export type EmptyAttribute = '' | undefined;
export type InputBooleanAttribute = boolean | EmptyAttribute;

export function asEmptyAttribute(value: any): EmptyAttribute {
  return value != null && value !== false && value !== 'false' ? '' : undefined; //removed the attribute for false values
}

export function isTruthyInput(value: InputBooleanAttribute): boolean {
  return value || value === '';
}
