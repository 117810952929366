import { Injectable } from '@angular/core';

import { AuthAdapterInterface, BasicLoginCredentials, RegistrationCredentials } from '../../../interfaces';
import { LoginUser, RegisterUser } from '../../../state';
import { BaseAdapter } from './base.adapter';
@Injectable({
  providedIn: 'root',
})
export class BasicAuthAdapter extends BaseAdapter {
  constructor() {
    super();
  }

  public login(): void {
    const { adapter, ...credentials } = this.credentials;
    this.store.dispatch(new LoginUser({ credentials: credentials as BasicLoginCredentials }));
  }

  public register(): void {
    this.store.dispatch(new RegisterUser({ credentials: this.credentials as RegistrationCredentials }));
  }

  setCredentials(credentials: BasicLoginCredentials): AuthAdapterInterface {
    this.credentials = this.prepareCredentials(credentials);

    return this;
  }

  private prepareCredentials(credentials: BasicLoginCredentials): BasicLoginCredentials {
    const emailOrUsername = credentials.email?.includes('@') ? 'email' : 'username';
    return {
      type: credentials.type,
      [emailOrUsername]: credentials.email,
      password: credentials.password,
      adapter: credentials.adapter,
      userType: credentials.userType,
    };
  }
}
