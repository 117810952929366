import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { ButtonCoreComponent } from '../button-core/button-core.component';

@Component({
  selector: 'campus-filled-button, [filled-button]',
  templateUrl: './filled-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilledButtonComponent extends ButtonCoreComponent {
  @HostBinding('class')
  defaultClasses = [
    ...this.coreClasses,
    'filled-button',

    'inline-flex',
    'primary',
    'w-fit',
    'elevation-0',

    'corner-full',
    'border-0',
    'outline-none',

    'hover:elevation-1',
    'active:elevation-0',

    'disabled:bg-on-surface',
    'disabled:bg-opacity-pressed',
    'disabled:on-surface',
    'disabled:border-none',

    'transition-elevation',
  ];

  @HostBinding('attr.data-has-leading-icon')
  @Input()
  leadingIcon: string;

  @HostBinding('attr.data-has-trailing-icon')
  @Input()
  trailingIcon: string;
}
