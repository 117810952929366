<campus-portal-lib-header
  [links]="headerLinks"
  [userActions]="headerActions$ | async"
  (linkClicked)="navigateTo($event)"
>
</campus-portal-lib-header>

<campus-svg
  svgImage="bubbles-side"
  class="[ fixed h-3xs ] [ inset-inline-start-3xs tablet-l:inset-inline-start-m inset-block-1/2 transform -translate-y-1/2 index-pullback ]"
></campus-svg>
<campus-svg
  svgImage="bubbles-side"
  class="[ fixed h-3xs ] [ inset-inline-end-3xs tablet-l:inset-inline-end-m inset-block-1/3 transform -translate-y-3/4 index-pullback ]"
></campus-svg>

<router-outlet></router-outlet>
<footer class="[ relative primary p-m m-bs-3xl ]">
  <img
    class="[ absolute -translate-y-full max-w-2xs right-0 transform w-1/3 ]"
    src="assets/images/illustrations/footer_sleeping_octopus.svg"
  />
  <div class="[ stack p-be-3xl ]">
    <span class="[ text-center ]"
      >Polpo is een product van
      <a href="https://www.diekeure.be/nl-be/educatief" target="_blank" class="[ uppercase text-surface ]"
        >die Keure</a
      ></span
    >

    <div class="[ cluster ]">
      <div class="[ justify-center ]">
        <button filled-button (click)="openCookieConsent()">Cookievoorkeuren</button>
        <button filled-button routerLink="/legals/gebruiksvoorwaarden">Gebruiksvoorwaarden</button>
        <button filled-button routerLink="/legals/privacy">Privacy</button>
        <button filled-button routerLink="/legals/verkoopsvoorwaarden">Verkoopsvoorwaarden</button>
      </div>
    </div>

    <div class="[ flex justify-center gap-m ]">
      <a href="https://nieuwsbriefmodule.diekeure.be" target="_blank"
        ><svg class="[ icon-size-l fill-on-primary ]"><use xlink:href="#mail" /></svg
      ></a>
      <a href="https://www.facebook.com/DieKeureEducatief" target="_blank"
        ><svg class="[ icon-size-l fill-on-primary ]"><use xlink:href="#facebook" /></svg
      ></a>
      <a href="https://www.pinterest.com/diekeure" target="_blank"
        ><svg class="[ icon-size-l fill-on-primary ]"><use xlink:href="#pinterest" /></svg
      ></a>
      <a href="https://www.instagram.com/diekeureeducatief" target="_blank"
        ><svg class="[ icon-size-l fill-on-primary ]"><use xlink:href="#instagram" /></svg
      ></a>
    </div>
  </div>
</footer>
<campus-cookies-content
  *ngIf="cookieInputs$ | async as cookieInputs"
  [linkcookies]="cookieInputs.link"
  [linkCookiesLabel]="'in onze privacy voorwaarden'"
  [setVisible]="cookieInputs.visibility"
  [errormessage]="cookieInputs.errormessage"
  (close)="closeCookieConsent()"
></campus-cookies-content>
