export class ArrayFunctions {
  public static getArrayEquality(a, b): boolean {
    return (
      a === b ||
      (Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((aVal, aIdx) => aVal === b[aIdx]))
    );
  }

  public static compareSets<T>(set1: T[], set2: T[], comparer: (a: T, b: T) => boolean = (a, b) => a === b) {
    const onlySet1: T[] = [...set1];
    const onlySet2: T[] = [];
    const common: T[] = [];

    set2.forEach((el2) => {
      const index = onlySet1.findIndex((el1) => comparer(el1, el2));
      if (index > -1) {
        common.push(...onlySet1.splice(index, 1));
      } else {
        onlySet2.push(el2);
      }
    });

    return {
      onlySet1,
      onlySet2,
      common,
    };
  }

  public static compareSetsById<T extends { id?: number | string }>(set1: T[], set2: T[]) {
    const comparer = (a, b) => a.id === b.id;
    return ArrayFunctions.compareSets<T>(set1, set2, comparer);
  }

  public static sortByKey(key: string, sortOrder: 'ASC' | 'DESC' = 'ASC') {
    const modifier = sortOrder === 'DESC' ? -1 : 1;

    return <T>(a: T, b: T) => {
      const aVal = a[key];
      const bVal = b[key];

      let compareResult: number;
      if (typeof aVal === 'string') {
        compareResult = aVal.localeCompare(bVal as string, undefined, { numeric: true, sensitivity: 'base' });
      } else {
        compareResult = +aVal - +bVal;
      }

      return compareResult * modifier;
    };
  }

  public static flatten<T>(array: T[][]): T[] {
    return [].concat(...array);
  }

  public static unique<T>(array: T[]): T[] {
    return Array.from(new Set(array));
  }

  public static deepFlatten<T>(arr: (T | T[])[]): T[] {
    const flat: T[] = [];
    arr.forEach((item) => {
      if (Array.isArray(item)) {
        flat.push(...this.deepFlatten(item as (T | T[])[]));
      } else {
        flat.push(item);
      }
    });
    return flat;
  }

  public static shuffleArray<T>(array: T[]): T[] {
    const newArray = [...array];
    let m = newArray.length,
      t,
      i;

    while (m) {
      i = Math.floor(Math.random() * m--);
      t = newArray[m];
      newArray[m] = newArray[i];
      newArray[i] = t;
    }

    return newArray;
  }

  public static filterDuplicates<T>(array: T[], key: keyof T = 'id' as any): T[] {
    const processed = new Set();
    return array.filter(({ [key]: id }) => {
      const isDuplicate = processed.has(id);
      processed.add(id);

      return !isDuplicate;
    });
  }

  public static wrapIntoArray<T>(value: T | T[]): T[] {
    if (value === undefined || value === null) return [];
    return Array.isArray(value) ? value : [value];
  }
}
