<div class="[ stack stack-space-m ]">
  <h4>Contacteer onze helpdesk</h4>

  <ng-container *ngIf="state === StatEnum.INITIAL || state === StatEnum.PROCESSING">
    <ng-container *ngTemplateOutlet="initial; context: { uiState: uiState.initial }"></ng-container>
  </ng-container>

  <ng-container *ngIf="state === StatEnum.SUCCESS">
    <ng-container *ngTemplateOutlet="feedback; context: { uiState: uiState.success }"></ng-container>
  </ng-container>
  <ng-container *ngIf="state === StatEnum.ERROR">
    <ng-container *ngTemplateOutlet="feedback; context: { uiState: uiState.error }"></ng-container>
  </ng-container>
</div>

<ng-template #initial let-uiState="uiState">
  <div class="[ cluster ]">
    <div class="[ items-start ]">
      <campus-svg svgImage="contact-form" class="[ min-w-2xs max-w-xs w-full ]"></campus-svg>

      <form [formGroup]="form" (ngSubmit)="submit()" class="[ stack stack-space-m flex-1 ]">
        <div class="[ auto-grid auto-grid-min-2xs ]">
          <div class="[ stack stack-space-3xs ]">
            <label class="[ label-medium ]" for="name">Naam*</label>
            <input
              id="name"
              formControlName="name"
              type="text"
              class="[ body-large border corner-s p-i-xs p-b-2xs outline-none focus:ring focus:ring-2 hover:border-on-surface transition-colors ]"
              [ngClass]="
                form.get('name').invalid && form.get('name').touched ? 'border-error border-2 hover:border-error' : ''
              "
            />
            <span
              *ngIf="form.get('name').touched && form.get('name').hasError('required')"
              class="[ text-error label-small ]"
              >Naam is een verplicht veld.</span
            >
          </div>

          <div class="[ stack stack-space-3xs ]">
            <label class="[ label-medium ]" for="schoolName">School</label>
            <input
              id="schoolName"
              formControlName="schoolName"
              type="text"
              class="[ body-large border corner-s p-i-xs p-b-2xs outline-none focus:ring focus:ring-2 hover:border-on-surface transition-colors ]"
            />
          </div>

          <div class="[ stack stack-space-3xs ]">
            <label class="[ label-medium ]" for="email">E-mailadres*</label>
            <input
              id="email"
              formControlName="email"
              type="email"
              class="[ body-large ] [ border corner-s hover:border-on-surface ] [ p-i-xs p-b-2xs ] [ outline-none focus:ring focus:ring-2 ] [ transition-colors ]"
              [ngClass]="
                form.get('email').invalid && form.get('email').touched ? 'border-error border-2 hover:border-error' : ''
              "
            />
            <span
              *ngIf="form.get('email').touched && form.get('email').hasError('required')"
              class="[ text-error label-small ]"
              >E-mail is een verplicht veld.</span
            >
            <span
              *ngIf="form.get('email').touched && form.get('email').hasError('email')"
              class="[ text-error label-small ]"
              >Voer een geldig e-mailadres in. (bv. jan@domein.be)</span
            >
          </div>

          <div class="[ stack stack-space-3xs ]">
            <label class="[ label-medium ]" for="phone">Telefoonnummer</label>
            <input
              id="phone"
              formControlName="phone"
              type="text"
              class="[ body-large border corner-s p-i-xs p-b-2xs outline-none focus:ring focus:ring-2 hover:border-on-surface transition-colors ]"
            />
          </div>

          <div class="[ stack stack-space-3xs ][ col-span-full ]">
            <label class="[ label-medium ]" for="subject">Onderwerp*</label>
            <select
              id="subject"
              formControlName="subject"
              required
              class="[ body-large border corner-s p-i-xs p-b-2xs outline-none focus:ring focus:ring-2 hover:border-on-surface transition-colors ]"
            >
              <option *ngFor="let subject of subjects" [value]="subject">{{ subject }}</option>
            </select>
          </div>

          <div class="[ stack stack-space-3xs ][ col-span-full ]">
            <label class="[ label-medium ]" for="message">Bericht*</label>
            <textarea
              id="message"
              formControlName="message"
              type="text"
              rows="7"
              required
              class="[ body-large border corner-s p-i-xs p-b-2xs outline-none focus:ring focus:ring-2 hover:border-on-surface transition-colors ]"
              [ngClass]="
                form.get('message').invalid && form.get('message').touched
                  ? 'border-error border-2 hover:border-error'
                  : ''
              "
            ></textarea>
            <span
              *ngIf="form.get('message').touched && form.get('message').hasError('required')"
              class="[ text-error label-small ]"
              >Bericht is een verplicht veld.</span
            >
          </div>
        </div>

        <button filled-button [disabled]="!form.valid && state !== StatEnum.PROCESSING" type="submit">
          {{ uiState.cta }}
        </button>
      </form>
    </div>
  </div>
</ng-template>

<ng-template #feedback let-uiState="uiState">
  <div>
    <div class="[ stack stack-space-m items-center ]">
      <campus-svg [svgImage]="uiState.img" class="[ w-full ]"></campus-svg>

      <h5>{{ uiState.header }}</h5>

      <div>
        <p class="[ text-center ]" *ngFor="let paragraph of uiState.description" [innerHTML]="paragraph"></p>
      </div>

      <button *ngIf="state === StatEnum.SUCCESS" filled-button (click)="reset()">{{ uiState.cta }}</button>
      <button *ngIf="state === StatEnum.ERROR" filled-button (click)="submit()">{{ uiState.cta }}</button>
    </div>
  </div>
</ng-template>
