export type TextTransform = 'lowercase' | 'uppercase' | 'capitalize' | 'sentence';

export class StringFunctions {
  static matchNonDigits = new RegExp('[^0-9]', 'g');
  static transformOps: { [key in TextTransform]: (value: string) => string } = {
    lowercase: (value) => value.toLowerCase(),
    uppercase: (value) => value.toUpperCase(),
    capitalize: (value) =>
      value.split(' ').reduce((acc, word, i) => {
        return acc + (i > 0 ? ' ' : '') + (word.substring(0, 1).toUpperCase() + word.substring(1).toLowerCase());
      }, ''),
    sentence: (value) => value.substring(0, 1).toUpperCase() + value.substring(1).toLowerCase(),
  };

  public static removeLettersInString(stringToStrip: string): string {
    return stringToStrip.replace(StringFunctions.matchNonDigits, '');
  }

  public static transform(value: string, op: TextTransform): string {
    return this.transformOps[op](value);
  }

  /**
   * Splits the input string on spaces, but ignores spaces within single or double quotes.
   * The resulting array contains substrings without surrounding quotes.
   *
   * @param input - The input string to split.
   * @returns An array of substrings, split by spaces but ignoring spaces within single or double quotes.
   *
   * @example
   * const inputString = `This is an "example string" for 'splitting strings'`;
   * // Output: ['This', 'is', 'an', 'example string', 'for', 'splitting strings']
   */
  public static splitIgnoringQuotes(input: string): string[] {
    const regex = /(?:[^\s"']+|'[^']*'|"[^"]*")+/g;
    let match;
    const result: string[] = [];

    while ((match = regex.exec(input)) !== null) {
      // Replace the single and double quotes in the matched string and trim the result
      const cleanedMatch = match[0].replace(/["']/g, '').trim();
      result.push(cleanedMatch);
    }

    return result;
  }
}
