import { trigger } from '@angular/animations';
import { Component, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';
import { collapseAnimation, CollapsibleAnimationState } from '../animations';

@Component({
  selector: 'campus-collapsible-container',
  templateUrl: './collapsible-container.component.html',
  styleUrls: ['./collapsible-container.component.scss'],
  animations: [trigger('toggleOpened', collapseAnimation)],
})
export class CollapsibleContainerComponent {
  @HostBinding('class.ui-collapsible-container')
  public uiCollapsibleContainerClass = true;

  @HostBinding('class.ui-collapsible-container--open')
  @Input()
  open = false;

  @Input()
  closeable = false;

  @Output() openChange = new EventEmitter<boolean>();

  // emits after close animation
  @Output() closed = new EventEmitter<boolean>();

  @Output() animationStateChange = new EventEmitter<CollapsibleAnimationState>();

  @HostBinding('@toggleOpened')
  get toggleContainer(): string {
    return this.open ? CollapsibleAnimationState.OPENED : CollapsibleAnimationState.CLOSED;
  }

  @HostListener('@toggleOpened.start')
  toggleStart() {
    this.animationStateChange.emit(CollapsibleAnimationState.ANIMATING);
  }

  @HostListener('@toggleOpened.done')
  toggleDone() {
    if (!this.open) {
      this.closed.emit(this.open);
    }
    this.animationStateChange.emit(this.open ? CollapsibleAnimationState.OPENED : CollapsibleAnimationState.CLOSED);
  }

  constructor() {}
}
