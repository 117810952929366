<div *ngIf="!passwordResetRequested; else requestSent">
  <form [formGroup]="resetForm" (ngSubmit)="onSubmitForm()" class="[ stack ]" data-cy="request-password-form">
    <mat-form-field>
      <mat-label>E-mailadres</mat-label>
      <input matInput type="email" formControlName="email" data-cy="input-request-password-form-email" />
    </mat-form-field>
    <div class="[ stack stack-space-3xs ]">
      <button inline-button class="[ flex self-end ]" (click)="onCancel()" data-cy="btn-request-password-form-cancel"
        >Ik weet het weer!</button
      >
    </div>
    <div class="[ flex justify-between ]">
      <button
        filled-button
        type="submit"
        [disabled]="!resetForm.valid"
        leadingIcon="lock-reset"
        data-cy="btn-request-password-form-reset"
      >
        Stuur een reset e-mail
      </button>
      <button inline-button routerLink="/registratie" *ngIf="showRegister" data-cy="btn-request-password-form-register"
        >Registreer als nieuwe leerling</button
      >
    </div>
  </form>
</div>

<ng-template #requestSent>
  <div class="[ body-large text-center ] [ stack stack-space-s ]">
    <p data-cy="request-sent-message">
      We hebben een e-mail gestuurd naar <strong>{{ resetForm.value.email }}</strong> met instructies om je wachtwoord
      te resetten.
    </p>
    <button filled-button class="[ flex self-center ]" (click)="onCancel()">Terug naar login</button>
  </div>
</ng-template>
