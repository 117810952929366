<ng-container *ngIf="inMaintenanceMode; else operationalMode">
  <ng-content select="[maintenanceMode]"></ng-content>
</ng-container>

<ng-template #operationalMode>
  <ng-container *ngIf="!user; else loggedIn">
    <div
      [ngClass]="[
        '[ stack stack-space-xl items-center ]',
        '[ basis-2xl ]',
        '[ surface p-m corner-l ]',
        '[ elevation-2 hover:elevation-3 transition-elevation ]',
        '[ border border-surface bg-opacity-high backdrop-filter backdrop-blur-s ]'
      ]"
    >
      <campus-svg svgImage="logo" class="[ fill-primary ]"></campus-svg>

      <ng-content select="[operationalMode]"></ng-content>
    </div>
  </ng-container>
</ng-template>

<ng-template #loggedIn>
  <div
    [ngClass]="[
      '[ min-w-xs ]',
      '[ stack items-center ]',
      '[ surface p-m corner-l ]',
      '[ elevation-2 hover:elevation-3 transition-elevation ]',
      '[ border border-surface bg-opacity-high backdrop-filter backdrop-blur-s ]'
    ]"
  >
    <campus-svg svgImage="logo" class="[ fill-primary ]"></campus-svg>

    <div class="[ title-large ]" data-cy="logged-in-welcome-message"
      >Welkom terug<span *ngIf="user.firstName">, {{ user.firstName }}!</span></div
    >

    <button filled-button class="[ bg-opacity-full ]" (click)="goToPlatform.emit(true)" data-cy="btn-platform-link"
      >Naar het platform
    </button>
  </div>
</ng-template>

<ng-content select="[hero]"></ng-content>
<ng-content select="[bottom]"></ng-content>
