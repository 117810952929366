import { Component, HostBinding, inject, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContentLinkPageViewModel } from './content-link-page.viewmodel';

@Component({
  selector: 'polpo-content-link',
  templateUrl: './content-link-page.component.html',
  providers: [ContentLinkPageViewModel],
})
export class ContentLinkPageComponent implements OnInit {
  @HostBinding('class') classList = 'block p-m';

  // This is a temporary fix for the padding-top of the page, until we have a theme file
  @HostBinding('style.padding-top') paddingTop = '132px';
  @HostBinding('style.display') display = 'block';

  public isLoggedIn$: Observable<boolean>;
  public url$: Observable<string | undefined>;

  private vm = inject(ContentLinkPageViewModel);

  constructor() {}

  ngOnInit(): void {
    this.isLoggedIn$ = this.vm.user$.pipe(map((user) => !!user));
    this.url$ = this.vm.url$;
  }
}
