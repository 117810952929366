import { Inject, Injectable, InjectionToken } from '@angular/core';
import { PersistedUserCredentialsInterface, PersistedUsersServiceInterface } from './persisted-users.service.interface';

export function _localStorage() {
  return localStorage;
}
export const BROWSER_STORAGE = new InjectionToken<Storage>('Browser Storage', {
  providedIn: 'root',
  factory: _localStorage,
});
export const PERSISTED_USERS_KEY = 'persisted-users';

@Injectable()
export class PersistedUsersService implements PersistedUsersServiceInterface {
  private _persistedUsers: Map<number, PersistedUserCredentialsInterface>;

  constructor(@Inject(BROWSER_STORAGE) private storage: Storage) {}

  find(userId: number): PersistedUserCredentialsInterface {
    return this.getAll().get(userId);
  }
  save(userId: number, credentials: PersistedUserCredentialsInterface): void {
    this.getAll().set(userId, credentials);
    this.persistUsers();
  }
  remove(userId: number): void {
    if (!this.find(userId)) {
      return;
    }
    this.getAll().delete(userId);
    this.persistUsers();
  }

  getAll(): Map<number, PersistedUserCredentialsInterface> {
    if (!this._persistedUsers) {
      const stored = this.storage.getItem(PERSISTED_USERS_KEY) || '[]';
      this._persistedUsers = new Map(JSON.parse(stored));
    }

    return this._persistedUsers;
  }

  private persistUsers() {
    this.storage.setItem(PERSISTED_USERS_KEY, JSON.stringify(Array.from(this._persistedUsers.entries())));
  }
}
