import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CookieInputInterface, CookieInputServiceInterface } from './cookie-input.service.interface';

@Injectable({
  providedIn: 'root',
})
export class CookieInputService implements CookieInputServiceInterface {
  private defaultCookieInputs: CookieInputInterface = {
    visibility: false,
    errormessage: '',
    link: 'legals/privacy',
  };
  private _cookieInput$ = new BehaviorSubject<CookieInputInterface>(this.defaultCookieInputs);
  public cookieInputs$ = this._cookieInput$ as Observable<CookieInputInterface>;

  constructor() {}

  public setCookieInputs(inputs: Partial<CookieInputInterface>) {
    this._cookieInput$.next({
      ...this._cookieInput$.value,
      ...inputs,
    });
  }
}
