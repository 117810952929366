<div class="[ block tablet-l:hidden ]">
  <div
    [ngClass]="{ hidden: isNavOpen, block: !isNavOpen }"
    class="[ flex justify-between items-center justify-between p-m sticky no-elevation-layer transition-elevation ]"
  >
    <button inline-icon-button (click)="isNavOpen = true" icon="menu"></button>

    <campus-svg
      svgImage="logo-small"
      class="[ w-3xs max-w-1/4 ] [ fill-primary cursor-pointer ]"
      (click)="navigateTo({ url: '' })"
    ></campus-svg>
  </div>

  <div [ngClass]="{ block: isNavOpen, hidden: !isNavOpen }" class="[ p-m ]">
    <button inline-icon-button (click)="isNavOpen = false" icon="close"></button>
    <nav class="[ inset-0 h-screen w-screen gap-m stack stack-space-m items-center m-bs-m ]" data-cy="header-nav">
      <button
        *ngFor="let link of links"
        inline-button
        class="[ inline-button cursor-pointer ] [ label-large ] [ flex items-center ]"
        (click)="navigateTo(link.route)"
        data-cy="nav-link"
        >{{ link.label }}</button
      >

      <ng-container *ngTemplateOutlet="actions"></ng-container>
    </nav>
  </div>
</div>

<div class="[ hidden tablet-l:flex justify-between items-center sticky p-m no-elevation-layer transition-elevation ]">
  <campus-svg
    svgImage="logo-small"
    class="[ w-3xs max-w-1/6 ] [ fill-primary cursor-pointer ]"
    (click)="navigateTo({ url: '' })"
  ></campus-svg>

  <nav class="[ flex items-center gap-m ]" data-cy="header-nav">
    <a
      *ngFor="let link of links"
      class="[ cursor-pointer ] [ h-full self-center ]"
      (click)="navigateTo(link.route)"
      data-cy="nav-link"
      >{{ link.label }}</a
    >
    <ng-container *ngTemplateOutlet="actions"></ng-container>
  </nav>
</div>

<ng-template #actions>
  <div *ngIf="userActions && userActions.children && userActions.children.length">
    <div class="justify-center">
      <button
        filled-button
        [matMenuTriggerFor]="menu"
        trailingIcon="arrow-drop-down"
        data-cy="btn-header-dropdown-open"
      >
        {{ userActions.label }}
      </button>
    </div>
    <mat-menu #menu="matMenu" class="[ m-bs-xs m-ie-xs ]" data-cy="dropdown-actions-menu">
      <div
        class="[ border-be stack p-b-2xs p-is-m p-ie-xs last:border-none transition-colors ]"
        campusClickPreventDefault
      >
        <div
          *ngFor="let item of userActions.children"
          (click)="item.action()"
          class="[ flex items-center gap-xs m-ie-2xs cursor-pointer ]"
          data-cy="btn-dropdown-action"
        >
          <campus-icon *ngIf="item.icon" [svgIcon]="item.icon"></campus-icon>{{ item.label }}</div
        >
      </div>
    </mat-menu>
  </div>
  <div *ngIf="userActions && (!userActions.children || userActions.children.length === 0)">
    <div class="justify-center">
      <button filled-button (click)="userActions.action()">
        {{ userActions.label }}
      </button>
    </div>
  </div>
</ng-template>
