import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import {
  MatLegacySelectionList as MatSelectionList,
  MatLegacySelectionListChange as MatSelectionListChange,
} from '@angular/material/legacy-list';
import { Router } from '@angular/router';
import {
  EnvironmentCollectionManagementFeatureInterface,
  ENVIRONMENT_COLLECTION_MANAGEMENT_FEATURE_TOKEN,
} from '@campus/environment';
import { FilterServiceInterface, FILTER_SERVICE_TOKEN } from '@campus/utils';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { FilterTextInputComponent } from '../filter-text-input/filter-text-input.component';
import { ItemToggledInCollectionInterface } from './interfaces/item-toggled-in-collection.interface';
import { ManageCollectionItemInterface } from './interfaces/manage-collection-item.interface';

@Component({
  selector: 'campus-manage-collection',
  templateUrl: './manage-collection.component.html',
  styleUrls: ['./manage-collection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageCollectionComponent implements AfterViewInit, OnDestroy {
  public recentLinkableItems: ManageCollectionItemInterface[];
  public linkableItems$ = new BehaviorSubject<ManageCollectionItemInterface[]>([]);
  public activePanel;
  public listItems$: Observable<ManageCollectionItemInterface[]>;

  // needed to selected items after filtering
  private selectedIds: Set<number>;
  private isDirty = false;
  private subscriptions = new Subscription();

  public useFilter: boolean;

  public newCollectionName: string;

  @Output() close = new EventEmitter<boolean>();

  @Output() selectionChanged = new EventEmitter<ItemToggledInCollectionInterface>();

  @Output() createCollection = new EventEmitter<{ label: string }>();

  @ViewChild(MatSelectionList)
  private selectionList: MatSelectionList;

  @ViewChild(FilterTextInputComponent)
  public filterTextInput: FilterTextInputComponent<ManageCollectionItemInterface[], ManageCollectionItemInterface>;

  @HostBinding('class.ui-manage-collection')
  get isManageCollectionClass() {
    return true;
  }

  private _data;

  public data$: Observable<unknown>;

  public get data() {
    return this._data;
  }

  public set data(value) {
    this._data = value;
    this.linkableItems$.next(this._data.linkableItems);

    // _data.recentItemIds is sorted new to old
    // we need to keep this sorting
    this.recentLinkableItems = Array.from(this._data.recentItemIds as Set<number>).reduce((acc, id) => {
      const recentItem = this._data.linkableItems.find((l) => l.id === id);
      if (recentItem) acc.push(recentItem);
      return acc;
    }, []);

    this.selectedIds = this._data.linkedItemIds;
    this.selectListItems(this.selectedIds);
  }
  constructor(
    @Inject(FILTER_SERVICE_TOKEN) private filterService: FilterServiceInterface,
    private cd: ChangeDetectorRef,
    @Inject(ENVIRONMENT_COLLECTION_MANAGEMENT_FEATURE_TOKEN)
    environmentCollectionManagementFeatureToken: EnvironmentCollectionManagementFeatureInterface,
    private router: Router
  ) {
    this.useFilter = environmentCollectionManagementFeatureToken.useFilter;
    this.listItems$ = this.linkableItems$;
  }

  ngAfterViewInit() {
    if (this.selectionList) {
      this.subscriptions.add(
        // when options change i.e. after filtering
        // re-set selection
        this.selectionList.options.changes
          .pipe(startWith(null as any)) // emit once on init
          .subscribe(() => {
            this.selectListItems(this.selectedIds);
          })
      );
    }

    if (this.filterTextInput) {
      this.filterTextInput.setFilterableItem(this);
      this.listItems$ = this.filterTextInput.result$;
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public onSelectionChanged(event: MatSelectionListChange) {
    const changedItemId = event.options[0].value;
    this.isDirty = true;

    if (event.options[0].selected) {
      this.selectedIds.add(changedItemId);
    } else {
      this.selectedIds.delete(changedItemId);
    }
    // if item appears twice in list
    // also select/deselect other one
    // this does not trigger a new onSelectionChanged
    event.source.options
      .filter((listItem) => listItem.value === changedItemId)
      .forEach((listItem) => (listItem.selected = event.options[0].selected));

    // find changed item
    const changedItem = this.data.linkableItems.find((item) => item.id === changedItemId);

    this.selectionChanged.emit({
      items: this.data.items,
      relatedItem: changedItem,
      selected: event.options[0].selected,
    });
  }

  public onCreateCollectionClick(label: string) {
    this.isDirty = true;
    this.createCollection.emit({ label });
    this.newCollectionName = null;
  }

  public open() {
    this.activePanel = 0;
  }
  public onClose() {
    this.activePanel = -1;
  }
  public slideOutAnimationDone() {
    if (this.activePanel === -1) {
      this.close.emit(this.isDirty);
    }
  }

  filterFn(source: ManageCollectionItemInterface[], searchText: string): ManageCollectionItemInterface[] {
    const filteredItems = this.filterService.filter(source, {
      label: searchText,
    });
    return filteredItems;
  }

  private selectListItems(ids: Set<number>) {
    if (!this.selectionList) {
      return;
    }
    this.selectionList.options.forEach((listItem) => (listItem.selected = ids.has(listItem.value)));
    this.cd.detectChanges();
  }

  navigateTo(event: MouseEvent, link: string) {
    event.stopPropagation(); // do not trigger a selection
    this.router.navigateByUrl(link);
    this.onClose();
  }
}
