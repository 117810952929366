import { inject, Injectable, InjectionToken, Injector } from '@angular/core';

import { ENVIRONMENT_LOGOUT_TOKEN, EnvironmentLogoutInterface } from '@campus/environment';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, take } from 'rxjs/operators';
import { AuthAdapterInterface, AuthServiceInterface } from '../../interfaces';
import {
  ClearAuthError,
  ClearUser,
  selectAuthentication,
  selectCurrentUser,
  selectError,
  selectPortalMode,
  SetUser,
} from '../../state';
import { FormMode, LoginCredentials } from '../../types';
import { PersonApi, PersonInterface } from '../api';
import { AdapterFactory } from './adapter.factory';

/**
 * service for authentication
 *
 * @export
 * @class AuthService
 */
@Injectable({
  providedIn: 'root',
})
export class AuthService implements AuthServiceInterface {
  private adapter: AuthAdapterInterface;
  private personApi = inject(PersonApi);
  private store: Store = inject(Store);
  private injector = inject(Injector);
  private logoutEnvironment = inject<EnvironmentLogoutInterface>(ENVIRONMENT_LOGOUT_TOKEN);

  protected emailForgotten: string;
  protected mode$: Observable<FormMode>;

  public currentUser$: Observable<PersonInterface>;
  public error$: Observable<string>;
  public isAuthenticated$: Observable<boolean>;

  constructor() {
    this.mode$ = this.store.select(selectPortalMode);
    this.getCurrent()
      .pipe(
        take(1),
        filter((user) => !!user)
      )
      .subscribe((user) => {
        this.store.dispatch(new SetUser({ current: user }));
      });

    this.currentUser$ = this.store.select(selectCurrentUser);
    this.isAuthenticated$ = this.store.select(selectAuthentication);
    this.error$ = this.store.select(selectError);
  }

  public setAdapter(adapter: string | InjectionToken<AuthAdapterInterface>): AuthServiceInterface {
    this.adapter = AdapterFactory.createAdapter(adapter, this.injector);

    return this;
  }

  public getAdapter(): AuthAdapterInterface {
    if (!this.adapter) {
      this.adapter = AdapterFactory.createAdapter('basic', this.injector);
    }

    return this.adapter;
  }

  public login(): void {
    const adapter = this.getAdapter();

    adapter.login();
  }

  public register(): void {
    const adapter = this.getAdapter();

    adapter.register();
  }

  public logout() {
    this.logoutLeerId();
    this.personApi
      .logout()
      .pipe(take(1))
      .subscribe(() => {
        this.store.dispatch(new ClearUser());
      });
  }

  private logoutLeerId() {
    const iframe = document.createElement('iframe');
    iframe.src = this.logoutEnvironment.logOutLeerIdUrl;
    iframe.style.display = 'none';
    document.body.appendChild(iframe);
  }

  public withCredentials(credentials: LoginCredentials): AuthServiceInterface {
    this.getAdapter().setCredentials(credentials);

    return this;
  }

  public getCurrent(): Observable<PersonInterface> {
    return this.personApi.getCurrent();
  }

  public isUserInSync(stateUser: PersonInterface): Observable<boolean> {
    return this.personApi.isUserInSync(stateUser);
  }

  public resetPassword(email: string): Observable<boolean> {
    return this.personApi.resetPassword(email).pipe(
      catchError((error) => {
        // We return true here because there is a potential data leak
        // due to the API returning a 404 when the email-address is not found.
        return of(true);
      })
    );
  }

  public clearError(): void {
    this.store.dispatch(new ClearAuthError());
  }
}
