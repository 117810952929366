<ng-container *ngIf="status$ | async as status">
  <div
    class="[ relative ][ border border-2 corner-2xs ][ icon-size-s ]"
    [ngClass]="{
    'bg-surface': (!value && !indeterminate) || disabled,
    'bg-primary': (value || indeterminate) && !disabled,
    'bg-on-surface bg-opacity-disabled': (value || indeterminate) && disabled,
    'bg-error': status==='invalid' && (value || indeterminate) && !disabled,
    'border-opacity-disabled': disabled,
    'border-transparent': value || indeterminate,
    'border-error': status==='invalid'  && !value && !indeterminate && !disabled,
  }"
    aria-hidden="true"
  >
    <svg
      class="icon"
      viewBox="0 0 18 18"
      aria-hidden="true"
      [ngClass]="{
    'fill-surface': disabled,
    'fill-on-primary': !disabled, 
    'fill-on-error': status==='invalid' && !disabled,
    }"
    >
      <rect class="mark short" rx="1" />
      <rect class="mark long" rx="1" />
    </svg>
    <campus-touch-target [class.hidden]="target === 'none'"></campus-touch-target>
    <campus-state-layer [for]="element" type="circular" class="bg-primary"></campus-state-layer>
    <campus-focus-ring [for]="element" class="-m-xs"></campus-focus-ring>
  </div>

  <div *ngIf="label || supportingText">
    <div *ngIf="label" class="[ label-medium on-surface ]" [class.text-opacity-disabled]="disabled">{{ label }}</div>
    <div
      *ngIf="supportingTextOrErrorText$ | async as supportingTextOrErrorText"
      class="[ label-small ]"
      [ngClass]="status === 'invalid' ? 'text-error' : 'on-surface-variant'"
      [class.text-opacity-disabled]="disabled"
      >{{ supportingTextOrErrorText }}</div
    >
  </div>
</ng-container>
