import { Injectable, inject } from '@angular/core';

import { ENVIRONMENT_ASSETS_TOKEN, EnvironmentAssetsInterface } from '@campus/environment';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { RoleType } from '../../types';
import { FaqCategoryApi, FaqCategoryInterface, FaqInterface } from '../api';

@Injectable({
  providedIn: 'root',
})
export class FaqCategoryService {
  private faqCategoryApi = inject(FaqCategoryApi);
  private assets: EnvironmentAssetsInterface = inject(ENVIRONMENT_ASSETS_TOKEN);

  public getCategories$(): Observable<FaqCategoryInterface[]> {
    return this.faqCategoryApi
      .find({
        where: {
          published: {
            neq: null,
          },
        },
        include: [
          {
            relation: 'questions',
            scope: {
              include: {
                relation: 'faqOrder',
              },
              where: {
                published: {
                  neq: null,
                },
              },
            },
          },
        ],
        order: 'name ASC',
      })
      .pipe(shareReplay(1));
  }

  public mapBasedOnRole$(role: RoleType): Observable<FaqCategoryInterface[]> {
    return this.getCategories$().pipe(
      map((categories) => {
        let orderType: string;

        const faqAssetsPath = this.assets.images.faq.endsWith('/')
          ? this.assets.images.faq.slice(0, -1)
          : this.assets.images.faq;

        const filterByRole = (_role: RoleType) => {
          switch (_role) {
            case 'student':
              orderType = 'studentOrder';
              return (faq: FaqCategoryInterface | FaqInterface) => faq.forStudents;
            case 'teacher':
              orderType = 'teacherOrder';
              return (faq: FaqCategoryInterface | FaqInterface) => faq.forTeachers;
            case 'schooladmin':
              orderType = 'schoolAdminOrder';
              return (faq: FaqCategoryInterface | FaqInterface) => faq.forSchoolAdmins;
          }
        };

        function getQuestions(category: FaqCategoryInterface) {
          const sortByRole = (a: FaqInterface, b: FaqInterface) => {
            const aFaqOrder = a.faqOrder.find(
              (faqOrder) => faqOrder.faqId === a.id && faqOrder.faqCategoryId === category.id
            );
            const bFaqOrder = b.faqOrder.find(
              (faqOrder) => faqOrder.faqId === b.id && faqOrder.faqCategoryId === category.id
            );
            return aFaqOrder[orderType] - bFaqOrder[orderType];
          };

          return category.questions && category.questions.filter(filterByRole(role)).sort(sortByRole);
        }

        const mapFaqCategories = (category: FaqCategoryInterface) => ({
          ...category,
          questions: getQuestions(category),
          icon: `${faqAssetsPath}/${category.icon}.svg`,
        });

        return categories.filter(filterByRole(role)).map(mapFaqCategories);
      })
    );
  }
}
