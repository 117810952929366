import { Action } from '@ngrx/store';
import { FormMode, UserType } from '../../types';

export enum PortalActions {
  ACTION_CHANGE_ROLE = '[Portal] Change Role',
  ACTION_CHANGE_MODE = '[Portal] Change Mode',
  ACTION_OPEN_LOGIN_FORM = '[Portal Auth] Open Login Form',
  ACTION_CLOSE_LOGIN_FORM = '[Portal Auth] Close Login Form',
}

export class ChangeRole implements Action {
  readonly type = PortalActions.ACTION_CHANGE_ROLE;
  constructor(public payload: { role: UserType }) {}
}

export class ChangeMode implements Action {
  readonly type = PortalActions.ACTION_CHANGE_MODE;
  constructor(public payload: { mode: FormMode }) {}
}

export class OpenLoginForm implements Action {
  readonly type = PortalActions.ACTION_OPEN_LOGIN_FORM;
  constructor() {}
}

export class CloseLoginForm implements Action {
  readonly type = PortalActions.ACTION_CLOSE_LOGIN_FORM;
  constructor() {}
}

export type PortalActionsUnion = ChangeRole | ChangeMode | OpenLoginForm | CloseLoginForm;
