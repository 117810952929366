import { inject, Injectable } from '@angular/core';
import { MaintenanceService, WINDOW } from '@campus/browser';
import { AuthFacade } from '@campus/public-portal';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { PersistedUserCredentialsInterface } from './services/persisted-users.service.interface';
import { RedirectService } from './services/redirect.service';

@Injectable({
  providedIn: 'root',
})
export class AppViewModel {
  private auth = inject(AuthFacade);
  private redirect = inject(RedirectService);
  private window = inject(WINDOW);
  private maintenanceService = inject(MaintenanceService);

  public persistedUsers$: BehaviorSubject<PersistedUserCredentialsInterface[]>;
  public isMaintenanceMode$: Observable<boolean>;

  constructor() {
    this.setupStreams();
  }

  private setupStreams(): void {
    this.isMaintenanceMode$ = this.maintenanceService.isMaintenanceMode();
  }

  redirectToPlatform(): void {
    this.auth.user$.pipe(take(1)).subscribe((user) => {
      this.redirect.toPlatform(user);
    });
  }

  public checkUserValidity() {
    this.auth
      .isCurrentUserInSync()
      .pipe(filter((isSynced) => !isSynced))
      .subscribe(() => {
        this.window.location.reload();
      });
  }
}
