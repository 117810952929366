import { InjectionToken } from '@angular/core';

export const RANDOM_SVG_SERVICE_TOKEN = new InjectionToken<RandomSvgServiceInterface>('RandomSvgService');

export interface RandomSvgOptions {
  namespace?: string;
  probability?: number;
  session?: string;
}
export interface RandomSvgServiceInterface {
  /**
   * Picking a random svg from EnvironmentSvgMapping
   *
   * @param options A RandomSvgOptions object with
   *  - namespace: The namespace in wich the service should search for a svg key.
   *               If left empty, the service will search through all keys.
   *  - probality: The probabilty (between 0 and 1) a value will be returned.
   *  - session: A unique key to keep randomize sessions. If provided each key will be used only once in the session.
   */
  pick(options?: RandomSvgOptions): string;
}
