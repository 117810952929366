import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { PersonInterface } from '../../services';

@Component({
  selector: 'campus-hero-section',
  templateUrl: './hero-section.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeroSectionComponent {
  @HostBinding('class')
  defaultClasses = ['flex', 'h-screen', 'items-center', 'p-xl', 'relative', 'w-full'];

  @Input() user: PersonInterface;
  @Input() inMaintenanceMode = false;

  @Output() goToPlatform = new EventEmitter<boolean>();
  @Output() logout = new EventEmitter<boolean>();
}
