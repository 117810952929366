import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TooltipCoreComponent } from './tooltip-core.component';

@Injectable({
  providedIn: 'root',
})
export class TooltipService {
  private _visiblitySubject = new Subject<TooltipCoreComponent>();

  visibilityChange = this._visiblitySubject.asObservable();

  show(tooltip: TooltipCoreComponent) {
    this._visiblitySubject.next(tooltip);
  }
}
