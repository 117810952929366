import { InjectionToken } from '@angular/core';
import { EnvironmentInterface } from '@campus/environment';
import { AuthServiceInterface } from './interfaces/auth-service.interface';
import { RegistrationServiceInterface } from './interfaces/registration-service.interface';

export const AUTH_SERVICE = new InjectionToken<AuthServiceInterface>('AUTH_SERVICE');
export const REGISTRATION_SERVICE = new InjectionToken<RegistrationServiceInterface>('REGISTRATION_SERVICE');
export const PORTAL_ENVIRONMENT_TOKEN = new InjectionToken<EnvironmentInterface>('PORTAL_ENVIRONMENT_TOKEN');

/// <reference types="grecaptcha" />
export const GRECAPTCHA_TOKEN = new InjectionToken<ReCaptchaV2.ReCaptcha>('GRECAPTCHA_TOKEN');
