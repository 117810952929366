<campus-hero-section
  [user]="user$ | async"
  [inMaintenanceMode]="isMaintenanceMode$ | async"
  (goToPlatform)="goToPlatform()"
  (logout)="logout()"
  data-cy="hero-section"
>
  <ng-container operationalMode>
    <div class="[ flex ] [ gap-s ]" data-cy="login-links">
      <button filled-button routerLink="/login" data-cy="btn-login-link">Aanmelden</button>
      <button inline-button routerLink="/login" [queryParams]="{ role: 'schooladmin' }" data-cy="btn-login-link"
        >Aanmelden als beheerder</button
      >
    </div>
  </ng-container>

  <ng-container maintenanceMode>
    <campus-maintenance-mode [statusPageUrl]="statusPageUrl"></campus-maintenance-mode>
  </ng-container>

  <ng-container hero>
    <div class="[ isolate index-pullback ] [ absolute inset-0 ] [ overflow-hidden ]">
      <img
        src="/assets/images/illustrations/dashboard_hero.png"
        [ngClass]="[
          '[ absolute max-h-full min-w-xl ]',
          '[ inset-is-0 inset-b-1/2 transform -translate-y-1/2 ]',
          '[ index-surface ]',
          '[ tablet-l:inset-ie-0 tablet-l:inset-is-auto ]'
        ]"
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
        class="[ absolute ] [ bottom-0 w-screen index-pullback ]"
      >
        <path
          fill="#dff3f2"
          fill-opacity="1"
          d="M0,160L34.3,165.3C68.6,171,137,181,206,170.7C274.3,160,343,128,411,112C480,96,549,96,617,85.3C685.7,75,754,53,823,69.3C891.4,85,960,139,1029,144C1097.1,149,1166,107,1234,96C1302.9,85,1371,107,1406,117.3L1440,128L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"
        ></path>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
        class="[ absolute ] [ bottom-0 w-screen index-pullback ]"
      >
        <path
          fill="#44b0aa"
          fill-opacity="1"
          d="M0,128L34.3,122.7C68.6,117,137,107,206,138.7C274.3,171,343,245,411,261.3C480,277,549,235,617,218.7C685.7,203,754,213,823,224C891.4,235,960,245,1029,229.3C1097.1,213,1166,171,1234,133.3C1302.9,96,1371,64,1406,48L1440,32L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"
        ></path>
      </svg>
    </div>
  </ng-container>
</campus-hero-section>

<div class="[ stack stack-space-3xl ][ p-b-3xl p-i-xl tablet-l:p-i-xs ]">
  <campus-about-section
    class="[ w-full max-w-4xl m-i-auto ]"
    id="wat-is-polpo"
    [title]="'Wat is Polpo?'"
    readMoreLink="https://www.diekeure.be/educatief/polpo/"
    videoId="K7w2aNMqqzo"
    player="youtube"
  >
    <p class="[ body-large ]">
      Polpo is het digitale platform van die Keure voor het secundair onderwijs. Je vindt er bordboeken, lessuggesties,
      oefeningen en extra digitaal materiaal bij jouw lesmethodes. De leerkracht tijdswinst bezorgen en de leerling
      leerwinst, dat is de missie van Polpo.
    </p>
  </campus-about-section>

  <campus-faq-section
    id="veelgestelde-vragen"
    class="[ w-full max-w-4xl m-i-auto ]"
    [faqCategories]="faqCategories"
    (faqCategoryClick)="clickedFaqCategory($event)"
  ></campus-faq-section>

  <campus-contact-section
    id="contact"
    class="[ w-full max-w-4xl m-i-auto ]"
    [user]="user$ | async"
    [success]="contactSuccess$ | async"
    [subjects]="subjects"
    (resetForm)="resetContactForm()"
    (submitForm)="contactFormSubmitted($event)"
  ></campus-contact-section>
</div>
