import * as fromRouter from '@ngrx/router-store';
import { RouterStateUrl } from './route-serializer';

export const NAME = 'router';

export interface State extends fromRouter.RouterReducerState<RouterStateUrl> {}

export const reducer = fromRouter.routerReducer;

export const initialState: State = undefined;

// TO INVESTIGATE
// This breaks the breadcrumbs

// export const initialState: State = {
//   state: {
//     url: window.location.pathname,
//     params: {},
//     queryParams: {},
//   },
//   navigationId: 0,
// };
