import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChildren,
  EmbeddedViewRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { SlideoutModalComponent } from './slideout-modal.component';

@Component({
  selector: 'campus-slideout-modal-stack',
  templateUrl: './slideout-modal-stack.component.html',
})
export class SlideoutModalStackComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges, AfterContentInit {
  private embeddedViewRef: EmbeddedViewRef<any>;

  @ViewChild('portal', { static: true, read: TemplateRef }) portal;

  @ContentChildren(SlideoutModalComponent) panels: QueryList<SlideoutModalComponent>;

  @Input() activePanel = -1;

  constructor(private viewContainerRef: ViewContainerRef) {}

  ngOnInit() {
    document.body.classList.add('has-ui-slide-out-modal-stack');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.activePanel) {
      this.updateActivePanel();
    }
  }

  ngAfterViewInit() {
    this.embeddedViewRef = this.viewContainerRef.createEmbeddedView(this.portal);
    this.embeddedViewRef.detectChanges();
    this.embeddedViewRef.rootNodes.forEach((node) => document.body.appendChild(node));
    this.updateActivePanel();
  }

  ngAfterContentInit() {
    this.panels.forEach((panel, index) => (panel.index = index));
  }

  ngOnDestroy() {
    this.embeddedViewRef?.destroy();
    document.body.classList.remove('modal-open', 'has-ui-slide-out-modal-stack');
  }

  private updateActivePanel() {
    if (!this.panels) {
      return;
    }

    let activePanel = this.activePanel;
    if (!Number.isInteger(activePanel)) {
      activePanel = -1;
    }

    this.setAllInactive(this.panels);
    if (activePanel > -1) {
      const currentPanel = this.getPanelAsComponent(activePanel);
      this.setPanelActive(currentPanel);

      this.setPreviousPanelNextOffsetWidth(currentPanel);
      this.setAllPreviousPanelsActive(currentPanel);

      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }

  private setAllInactive(panels: QueryList<SlideoutModalComponent>) {
    if (!panels) {
      return;
    }

    panels.forEach((panel) => {
      panel.active = false;
      panel.isNextActive = false;
    });
  }
  private getPanelAsComponent(index: number): SlideoutModalComponent {
    return this.panels.toArray()[index] as SlideoutModalComponent;
  }
  private getPanelContentOffsetWidth(panel: SlideoutModalComponent): number {
    return panel.elemRef.nativeElement.childNodes[1].offsetWidth;
  }
  private setPanelActive(panel: SlideoutModalComponent) {
    panel.active = true;
    (panel.elemRef.nativeElement as HTMLElement).focus(); // focus panel for escape handler
  }
  private setPreviousPanelNextOffsetWidth(panel: SlideoutModalComponent) {
    if (panel.index === 0) {
      return;
    }

    const prevPanel = this.getPanelAsComponent(panel.index - 1);
    prevPanel.nextOffsetWidth = this.getPanelContentOffsetWidth(panel);
  }
  private setAllPreviousPanelsActive(currentPanel: SlideoutModalComponent) {
    if (currentPanel.index === 0) {
      return;
    }

    this.panels
      .filter((panel, index) => index < currentPanel.index)
      .forEach((panel) => {
        panel.active = true;
        panel.isNextActive = true;
      });
  }
}
