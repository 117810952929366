<div
  #surface
  class="[ absolute hidden ][ surface-container ][ max-w-2xs p-is-xs p-ie-2xs p-b-s m-0 ][ elevation-2 ][ border-none corner-m ][ index-overlay ]"
  [attr.popover]="tooltipPosition === 'popover' ? 'manual' : undefined"
>
  <div class="[ flex flex-column gap-xs ]">
    <div class="[ flex flex-column gap-3xs ]">
      <div class="[ title-small on-surface-variant ]">{{ tooltipSubhead }}</div>
      <div class="[ body-medium on-surface-variant ]">{{ richTooltip }}</div>
    </div>
    <button inline-button *ngIf="tooltipActionLabel" (click)="tooltipAction.emit()" class="[ -m-2xs ]">
      {{ tooltipActionLabel }}
    </button>
  </div>

  <campus-state-layer class="surface-tint" [for]="surface"></campus-state-layer>
</div>

<ng-content></ng-content>
