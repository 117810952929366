import {
  AfterViewInit,
  Component,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { EmptyAttribute, InputBooleanAttribute, asEmptyAttribute, isTruthyInput } from '../core/empty-attribute';
import { FormElementCore } from '../core/form-element-core';

/**
 * Switch Component
 * Switches toggle the selection of an item on or off
 *
 * Switches are similar to checkboxes, and can be unselected or selected, using `formControlName`, `ngModel` or `checked` (not recommended).
 * @example
 * <campus-switch
 *  [label]="Switch Label"
 *  [supportingText]="Switch SupportingText">
 *  [icons]="true"
 *  [showOnlySelectedIcon]="false"
 *  [(ngModel)]="switchControl"
 * </campus-switch>
 *
 * @example
 * <form [formGroup]="form">
 *  <campus-switch
 *    [label]="Switch Label"
 *    formControlName="switchControl"
 *  </campus-switch>
 * </form>
 *
 * @documentation https://docs.kabas.be/components/switch
 *
 * @see A11Y: Switch Pattern:  https://www.w3.org/WAI/ARIA/apg/patterns/switch/
 *
 */

@Component({
  selector: 'campus-switch',
  templateUrl: './switch.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SwitchComponent,
      multi: true,
    },
  ],
})
export class SwitchComponent extends FormElementCore<boolean> implements OnChanges, AfterViewInit, OnInit {
  private _withIcons: boolean;
  private _showOnlySelectedIcon: boolean;

  //#region public fields

  /**
   * This enables the switch to be used with [checked]="false|true" or as checked attribute.
   * @example <campus-switch [checked]="true"></campus-switch>
   * @example <campus-switch checked></campus-switch>
   */
  @Input()
  set checked(value: InputBooleanAttribute) {
    this.value = isTruthyInput(value);
  }

  @Input()
  get withIcons() {
    return this._withIcons;
  }
  set withIcons(value: InputBooleanAttribute) {
    this._withIcons = isTruthyInput(value);
  }

  @Input()
  get showOnlySelectedIcon() {
    return this._showOnlySelectedIcon;
  }
  set showOnlySelectedIcon(value: InputBooleanAttribute) {
    this._showOnlySelectedIcon = isTruthyInput(value);
  }

  @Input() unselectedIcon: string;
  @Input() selectedIcon: string;

  @Input() target: 'wrapper' | 'none' | undefined = 'wrapper';
  //#endregion

  //#region getters and setters
  @HostBinding('attr.role') role = 'switch';

  @HostBinding('attr.aria-checked')
  ariaChecked: boolean;

  @HostBinding('attr.tabindex')
  tabIndex = 0;

  @HostBinding('class')
  defaultClasses = [
    'switch',
    'inline-flex',
    'gap-2xs',
    'items-center',
    'max-w-fit',
    'outline-none',
    'cursor-pointer',

    'group',
  ];

  public pressing = false;
  public focused = false;

  public viewInitialized = false;

  @HostBinding('attr.data-has-icons')
  hasIcons: EmptyAttribute;

  @HostBinding('attr.data-checked')
  attrChecked: EmptyAttribute;

  //#endregion

  //#region public methods
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.code === 'Space' || event.code === 'Enter') {
      event.preventDefault();
      this.toggle();
    }
  }

  @HostListener('focusin')
  onFocusIn() {
    this.focused = true;
  }

  @HostListener('focusout')
  onFocusOut() {
    this.blur();
    this.focused = false;
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    event.preventDefault();
    this.pressing = true;
  }

  @HostListener('mouseup')
  onMouseUp() {
    this.toggle();
    this.pressing = false;
  }

  toggle() {
    this.handleInput({ target: { checked: !this.value } });
  }

  ngOnInit(): void {
    this.value = false;
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    this.viewInitialized = true;

    this._cd.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (changes.withIcons || changes.showOnlySelectedIcon || changes.value) {
      this.hasIcons = asEmptyAttribute(this.showOnlySelectedIcon ? this.value : this.withIcons);
    }
    if (changes.disabled) {
      this.tabIndex = isTruthyInput(changes.disabled.currentValue) ? -1 : 0;
    }
  }

  handleInput(event) {
    this.value = (event.target as HTMLInputElement).checked;
    this.touch();
  }
  //#endregion

  protected onValueChanged(): void {
    this.ariaChecked = this.value;
    this.attrChecked = asEmptyAttribute(this.value);
    this.hasIcons = asEmptyAttribute(this.showOnlySelectedIcon ? this.value : this.withIcons);
  }
}
