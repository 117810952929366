import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { TooltipCoreComponent } from './tooltip-core.component';

@Component({
  selector: 'campus-rich-tooltip, [richTooltip]',
  templateUrl: './rich-tooltip.component.html',
})
export class RichTooltipComponent extends TooltipCoreComponent implements OnChanges {
  @Input() richTooltip: string;
  @Input() tooltipSubhead: string;
  @Input() tooltipActionLabel: string;

  @Output() tooltipAction = new EventEmitter<void>();
}
