import { HttpParams } from '@angular/common/http';
import { PolpoApi, PolpoApiDelete, PolpoApiGet, PolpoApiPatch, PolpoApiPost, PolpoApiPut } from './polpo-api';

export class ModelApiBase {
  protected get: PolpoApiGet;
  protected put: PolpoApiPut;
  protected delete: PolpoApiDelete;
  protected post: PolpoApiPost;
  protected patch: PolpoApiPatch;

  constructor(api: PolpoApi, private model: string) {
    this.get = api.get(this.model);
    this.put = api.put(this.model);
    this.delete = api.delete(this.model);
    this.post = api.post(this.model);
    this.patch = api.patch(this.model);
  }

  protected _getHttpParams(
    paramsObj: {
      [key: string]: string | number | boolean | (string | number | boolean)[] | object;
    },
    replaceUndefined?: boolean
  ): HttpParams {
    const definedParams = Object.entries(paramsObj).reduce((acc, [key, value]) => {
      if (value === undefined) {
        // This mimics Loopback's 'undefined becomes {}' way of doing things
        if (replaceUndefined) acc[key] = '{}';
        return acc;
      }

      if (Array.isArray(value) || !['string', 'number', 'boolean'].includes(typeof value)) {
        acc[key] = JSON.stringify(value);
        return acc;
      }

      acc[key] = value;
      return acc;
    }, {});

    return new HttpParams({ fromObject: definedParams });
  }
}
