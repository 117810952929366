import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

interface LoginInterface {
  username: string;
  password: string;
}

@Component({
  selector: 'polpo-login-form',
  templateUrl: './login-form.component.html',
})
export class LoginFormComponent {
  private formBuilder = inject(FormBuilder);

  @Input() error: string;
  @Input() showRegister: boolean;

  @Output() forgotPassword = new EventEmitter<boolean>();
  @Output() login = new EventEmitter<LoginInterface>();

  public loginForm = this.formBuilder.group({
    email: ['', Validators.required],
    password: ['', Validators.required],
  });

  public onSubmit() {
    this.login.emit(this.loginForm.value as LoginInterface);
  }

  public setForgotPassword() {
    this.forgotPassword.emit(true);
  }
}
