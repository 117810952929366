import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: 'campus-claim-reward',
  templateUrl: './claim-reward.component.html',
})
export class ClaimRewardComponent {
  @HostBinding('class')
  defaultClasses = ['cover', 'fixed', 'top-none', 'left-none', 'w-full', 'max-h-full', 'surface', 'index-overlay'];

  @Input() time: number;
  @Input() nextLabel = 'Volgende';

  @Output()
  stopped = new EventEmitter<void>();
}
