import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({ template: '', changeDetection: ChangeDetectionStrategy.OnPush })
export class CardCoreComponent {
  @HostBinding('attr.aria-disabled')
  @Input()
  disabled = false;

  @HostBinding('class.state-layer')
  @Input()
  useStateLayer = false;

  @HostBinding('class.relative')
  @Input()
  relative = true;

  protected coreClasses = [
    'card',

    'block',
    'corner-m',
    'index-surface',
    'max-w-full',
    'outline-none',

    'focus-visible:ring',
  ];
}
