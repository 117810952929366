import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, HostBinding, HostListener, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ENVIRONMENT_WEBSITE_TOKEN } from '@campus/environment';
import { Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { FaqCategoryInterface } from '../../services';
import { RoleType, VideoPlayerType } from '../../types';
import { FaqViewModel, SelectedQuestionInterface } from './faq.viewmodel';

@Component({
  selector: 'campus-faq-page',
  templateUrl: './faq-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('openClose', [
      transition(':enter', [style({ height: '0' }), animate('500ms ease-in-out', style({ height: '*' }))]),
      transition(':leave', [style({ height: '*' }), animate('500ms ease-in-out', style({ height: '0' }))]),
    ]),
  ],
  providers: [FaqViewModel],
})
export class FaqPageComponent implements OnInit {
  @HostBinding('class')
  defaultClasses = ['faq-page'];

  // This is a temporary fix for the padding-top of the page, until we have a theme file
  @HostBinding('style.padding-top') paddingTop = '132px';
  @HostBinding('style.display') display = 'block';

  public selectedRole$: Observable<RoleType>;
  public selectedQuestions$: Observable<SelectedQuestionInterface>;
  public filteredFaqCategories$: Observable<FaqCategoryInterface[]>;
  public windowScrolled: boolean;
  public isMaintenanceMode$: Observable<boolean>;
  public statusPageUrl: string;
  public videoPlayer: VideoPlayerType;

  private viewModel = inject<FaqViewModel>(FaqViewModel);
  private router = inject<Router>(Router);
  private route = inject<ActivatedRoute>(ActivatedRoute);
  private environmentWebsite = inject(ENVIRONMENT_WEBSITE_TOKEN);

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.windowScrolled = (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) > 100;
  }

  ngOnInit() {
    this.setupStreams();
    this.statusPageUrl = this.environmentWebsite.statusPageUrl;
    this.videoPlayer = 'wistia';
  }

  private setupStreams(): void {
    this.selectedRole$ = this.viewModel.role$;
    this.filteredFaqCategories$ = this.viewModel.faqCategories$;

    this.selectedQuestions$ = this.viewModel.selectedQuestions$.pipe(startWith({}));
    this.isMaintenanceMode$ = this.viewModel.isMaintenanceMode$;
  }

  public categoryLoaded(id: string): void {
    this.viewModel.categoryLoaded(id);
  }

  public roleClicked(role: RoleType): void {
    this.router.navigate(['.'], { relativeTo: this.route, queryParams: { role: role } });
  }

  public categoryClicked(category: FaqCategoryInterface): void {
    this.router.navigate(['.'], { relativeTo: this.route, fragment: category.slug, queryParamsHandling: 'preserve' });
  }

  public trackById(index: number, category: FaqCategoryInterface): number {
    return category.id;
  }

  public scrollToTop(): void {
    window.scrollTo({ top: 0 });
  }

  public copyLink(slug: string, event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.viewModel.copyLink(slug);
  }
}
