import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'campus-touch-target, [touch-target]',
  template: '',
})
export class TouchTargetComponent {
  @HostBinding('class')
  defaultClasses = ['touch-target', 'absolute', 'block', 'inset-bs-1/2', 'transform', '-translate-y-1/2', 'w-full'];

  @HostBinding('style.appearance')
  appearance = 'none';
}
