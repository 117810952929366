import { Action, ActionReducer } from '@ngrx/store';
import { UserActionMethods } from './user.actions';
import { initialUserState, userReducer } from './user.reducer';
import { UserState } from './user.state';

export function userReducerFactory(additionalReducers: ActionReducer<UserState, Action>[] = []): ActionReducer<UserState, Action> {
  return (state: UserState = initialUserState, action: Action): UserState => {
    let nextState: UserState = userReducer(state, action as UserActionMethods);

    for (const reducer of additionalReducers) {
      nextState = reducer(nextState, action);
    }

    return nextState;
  };
}
