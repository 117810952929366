import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[campusInline],[button-inline], [inline]',
})
export class InlineDirective {
  private _inline: boolean;

  @HostBinding('class.ui-button--inline')
  @Input()
  set inline(value: boolean | '') {
    this._inline = value === '' || value;
  }
  get inline() {
    return this._inline;
  }
}
