<ng-content select="campus-card-hint"></ng-content>

<div class="[ stack stack-space-m ] [ h-full p-m ]">
  <ng-content select="campus-card-hero"></ng-content>

  <div class="[ flex gap-2xs ]">
    <ng-content select="campus-card-avatar-head"></ng-content>
    <div class="[ stack stack-space-3xs ] [ min-w-0 grow ]">
      <ng-content select="campus-card-overline-head"></ng-content>
      <ng-content select="campus-card-headline"></ng-content>
      <ng-content select="campus-card-subhead"></ng-content>
    </div>
    <ng-content select="campus-card-header-action-area"></ng-content>
  </div>

  <ng-content select="campus-card-text"></ng-content>

  <ng-content select="campus-card-media"></ng-content>

  <ng-content select="campus-card-action-area"></ng-content>
</div>

<ng-content select="campus-card-progress"></ng-content>
