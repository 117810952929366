<div class="[ stack stack-space-m ]">
  <h4>Veelgestelde vragen</h4>

  <div class="[ auto-grid auto-grid-min-2xs ]">
    <campus-outline-card
      *ngFor="let faqCategory of faqCategories"
      (click)="clickedCategory(faqCategory)"
      class="[ cursor-pointer ] [ elevation-0 hover:elevation-1 transition-elevation ][ group ]"
    >
      <campus-card-hero class="[ frame-contain ]"
        ><img [src]="faqCategory.img" [attr.alt]="faqCategory.title"
      /></campus-card-hero>
      <campus-card-headline>{{ faqCategory.title }}</campus-card-headline>
      <campus-card-text>{{ faqCategory.description }}</campus-card-text>
      <campus-card-action-area>
        <a class="[ inline-flex items-center gap-3xs ][ text-secondary ][ group-hover:gap-xs transition-spacing ]">
          meer info
          <campus-icon svgIcon="arrow-right" class="icon-current"></campus-icon>
        </a>
      </campus-card-action-area>
    </campus-outline-card>
  </div>
</div>
