import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  inject,
} from '@angular/core';
import { InputBooleanAttribute, isTruthyInput } from '../core/empty-attribute';

@Component({
  selector: 'campus-navigation-core-item, [navigation-core-item]',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationCoreItemComponent implements OnChanges {
  elementRef = inject(ElementRef);
  element = inject(ElementRef).nativeElement;

  @ViewChild('iconContainer', { static: true }) iconContainer: ElementRef;

  private _badge: number | '' | boolean = false;

  tabIndex = -1;

  @HostBinding('attr.role') role = 'presentation';

  @Input() label: string;
  @Input() icon: string;

  @HostBinding('attr.aria-current')
  ariaCurrent: string;

  @HostBinding('attr.aria-selected')
  @HostBinding('class.selected')
  @Input()
  selected: InputBooleanAttribute;

  @Input()
  get badge() {
    return this._badge;
  }
  set badge(value: number | '' | boolean) {
    this._badge = value || value === '';
  }

  @HostBinding('attr.data-badge-size')
  get badgeSize(): 'small' | 'large' {
    return this.badge === true ? 'small' : 'large';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selected) {
      this.selected = isTruthyInput(changes.selected.currentValue);
      this.ariaCurrent = this.selected ? 'page' : undefined;
    }
  }

  focus() {
    this.element.focus();
  }
}
