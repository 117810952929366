import { DOCUMENT } from '@angular/common';
import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  ErrorHandler,
  HostBinding,
  Inject,
  inject,
  InjectionToken,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  SimpleChanges,
} from '@angular/core';
import { take } from 'rxjs/operators';
import { SvgRegistry } from './../registry/svg.registry';

/**
 * Injection token used to provide the current location to `Campus Svg`.
 * Used to handle server-side rendering and to stub out during unit tests.
 * @docs-private
 */
export const SVG_LOCATION = new InjectionToken<SvgLocation>('campus-svg-location', {
  providedIn: 'root',
  factory: SVG_LOCATION_FACTORY,
});
/**
 * Stubbed out location for `Svg`.
 * @docs-private
 */
export interface SvgLocation {
  getPathname: () => string;
}
/** @docs-private */
export function SVG_LOCATION_FACTORY(): SvgLocation {
  const _document = inject(DOCUMENT);
  const _location = _document ? _document.location : null;
  return {
    // Note that this needs to be a function, rather than a property, because Angular
    // will only resolve it once, but we want the current path on each call.
    getPathname: () => (_location ? _location.pathname + _location.search : ''),
  };
}

/** SVG attributes that accept a FuncIRI (e.g. `url(<something>)`). */
const funcIriAttributes = [
  'clip-path',
  'color-profile',
  'src',
  'cursor',
  'fill',
  'filter',
  'marker',
  'marker-start',
  'marker-mid',
  'marker-end',
  'mask',
  'stroke',
];
/** Selector that can be used to find all elements that are using a `FuncIRI`. */
const funcIriAttributeSelector = funcIriAttributes.map((attr) => `[${attr}]`).join(', ');

/** Regex that can be used to extract the id out of a FuncIRI. */
const funcIriPattern = /^url\(['"]?#(.*?)['"]?\)$/;
@Component({
  selector: 'campus-svg',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content></ng-content>',
  styleUrls: ['./svg.component.scss'],
})
export class SvgComponent implements OnChanges, AfterViewChecked, OnDestroy {
  @HostBinding('attr.role')
  role = 'img';

  @HostBinding('class')
  defaultClasses = ['ui-svg'];

  /** Name of the icon in the SVG icon set. */
  @Input() svgImage: string;

  @Input() triggerRerenderBy: any;

  /** Keeps track of the current page path. */
  private _previousPath?: string;

  /** Keeps track of the elements and attributes that we've prefixed with the current path. */
  private _elementsWithExternalReferences?: Map<Element, { name: string; value: string }[]>;

  constructor(
    public _elementRef: ElementRef,
    protected _svgRegistry: SvgRegistry,
    @Optional() @Inject(SVG_LOCATION) private _location?: SvgLocation,
    @Optional() protected readonly _errorHandler?: ErrorHandler
  ) {}

  /**
   * Splits an svgImage binding value into its svg set and svg image name components.
   * Returns a 2-element array of [(svg set), (svg image name)].
   * The separator for the two fields is ':'. If there is no separator, an empty
   * string is returned for the svg set and the entire value is returned for
   * the svg image name. If the argument is falsy, returns an array of two empty strings.
   * Throws an error if the name contains two or more ':' separators.
   * Examples:
   *   `'social:cake' -> ['social', 'cake']
   *   'penguin' -> ['', 'penguin']
   *   null -> ['', '']
   *   'a:b:c' -> (throws Error)`
   */
  protected _splitSvgImage(svgImage: string): [string, string] {
    if (!svgImage) {
      return ['', ''];
    }
    const parts = svgImage.split(':');
    let namespace: string;

    if (parts.length > 1) {
      namespace = parts.shift();
      svgImage = parts.join(':');
    } else {
      svgImage = parts[0];
      namespace = '';
    }

    return [namespace, svgImage];
  }

  ngOnChanges(changes: SimpleChanges) {
    // Only update the inline SVG image if the inputs changed, to avoid unnecessary DOM operations.
    const svgImageChanges = changes.svgImage;
    const trackByChanges = changes.trackBy;
    if (svgImageChanges) {
      if (this.svgImage) {
        const [namespace, imageName] = this._splitSvgImage(this.svgImage);
        this._svgRegistry
          .getNamedSvgImage(imageName, namespace)
          .pipe(take(1))
          .subscribe(
            (svg) => this._setSvgElement(svg),
            (err: Error) => {
              const errorMessage = `Error retrieving svg image ${namespace}:${imageName}! ${err.message}`;

              if (this._errorHandler) {
                this._errorHandler.handleError(new Error(errorMessage));
              } else {
                console.error(errorMessage);
              }
            }
          );
      } else if (svgImageChanges.previousValue) {
        this._clearSvgElement();
      }
    }
    // Retrigger _prependPathToReferences for svg's that live outside lifeCycle of route changes
    // Using trackBy supports prepending the correct path to not loose reference to the funcIriAttributes
    if (trackByChanges && !trackByChanges.isFirstChange()) {
      this.ngAfterViewChecked();
    }
  }

  ngAfterViewChecked() {
    const cachedElements = this._elementsWithExternalReferences;

    if (cachedElements && this._location && cachedElements.size) {
      const newPath = this._location.getPathname();
      // We need to check whether the URL has changed on each change detection since
      // the browser doesn't have an API that will let us react on link clicks and
      // we can't depend on the Angular router. The references need to be updated,
      // because while most browsers don't care whether the URL is correct after
      // the first render, Safari will break if the user navigates to a different
      // page and the SVG isn't re-rendered.
      if (newPath !== this._previousPath) {
        this._previousPath = newPath;
        this._prependPathToReferences(newPath);
      }
    }
  }

  ngOnDestroy() {
    if (this._elementsWithExternalReferences) {
      this._elementsWithExternalReferences.clear();
    }
  }

  protected _setSvgElement(svg: SVGElement) {
    this._clearSvgElement();

    // Workaround for IE11 and Edge ignoring `style` tags inside dynamically-created SVGs.
    // See: https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/10898469/
    // Do this before inserting the element into the DOM, in order to avoid a style recalculation.
    const styleTags = svg.querySelectorAll('style') as NodeListOf<HTMLStyleElement>;

    for (let i = 0; i < styleTags.length; i++) {
      styleTags[i].textContent += ' ';
    }

    // Note: we do this fix here, rather than the icon registry, because the
    // references have to point to the URL at the time that the icon was created.
    if (this._location) {
      const path = this._location.getPathname();
      this._previousPath = path;
      this._cacheChildrenWithExternalReferences(svg);
      this._prependPathToReferences(path);
    }

    this._elementRef.nativeElement.appendChild(svg);
  }

  protected _clearSvgElement() {
    const layoutElement: HTMLElement = this._elementRef.nativeElement;
    let childCount = layoutElement.childNodes.length;

    if (this._elementsWithExternalReferences) {
      this._elementsWithExternalReferences.clear();
    }

    // Remove existing non-element child nodes and SVGs, and add the new SVG element. Note that
    // we can't use innerHTML, because IE will throw if the element has a data binding.
    while (childCount--) {
      const child = layoutElement.childNodes[childCount];

      // 1 corresponds to Node.ELEMENT_NODE. We remove all non-element nodes in order to get rid
      // of any loose text nodes, as well as any SVG elements in order to remove any old icons.
      if (child.nodeType !== 1 || child.nodeName.toLowerCase() === 'svg') {
        layoutElement.removeChild(child);
      }
    }
  }

  /**
   * Prepends the current path to all elements that have an attribute pointing to a `FuncIRI`
   * reference. This is required because WebKit browsers require references to be prefixed with
   * the current path, if the page has a `base` tag.
   */
  private _prependPathToReferences(path: string) {
    const elements = this._elementsWithExternalReferences;

    if (elements) {
      elements.forEach((attrs, element) => {
        attrs.forEach((attr) => {
          element.setAttribute(attr.name, `url('${path}#${attr.value}')`);
        });
      });
    }
  }

  /**
   * Caches the children of an SVG element that have `url()`
   * references that we need to prefix with the current path.
   */
  private _cacheChildrenWithExternalReferences(element: SVGElement) {
    const elementsWithFuncIri = element.querySelectorAll(funcIriAttributeSelector);
    const elements = (this._elementsWithExternalReferences = this._elementsWithExternalReferences || new Map());

    for (let i = 0; i < elementsWithFuncIri.length; i++) {
      funcIriAttributes.forEach((attr) => {
        const elementWithReference = elementsWithFuncIri[i];
        const value = elementWithReference.getAttribute(attr);
        const match = value ? value.match(funcIriPattern) : null;

        if (match) {
          let attributes = elements.get(elementWithReference);

          if (!attributes) {
            attributes = [];
            elements.set(elementWithReference, attributes);
          }

          attributes.push({ name: attr, value: match[1] });
        }
      });
    }
  }
}
