/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, mapTo, tap } from 'rxjs/operators';

import { StudentCodeLoginCredentials } from '../../../interfaces';
import { LoginCredentials } from '../../../types';
import { PersonInterface } from '../interfaces';
import { ApiAuth } from '../internals';
import { ModelApiBase } from '../model-api.base';
import { PolpoApi } from '../polpo-api';

@Injectable({ providedIn: 'root' })
export class PersonApi extends ModelApiBase {
  constructor(api: PolpoApi, private authApi: ApiAuth) {
    super(api, 'People');
  }

  public getData(personId: number, fields?: string | string[], since?: number | Date) {
    const path = `${personId}/data`;

    if (Array.isArray(fields)) fields = fields.join(',');
    if (since instanceof Date) since = +since;

    const params = this._getHttpParams({
      fields,
      since,
    });

    return this.get(path, params);
  }

  public login(credentials: Partial<LoginCredentials>, include = 'user', rememberMe = true) {
    const path = `login`;

    const params = this._getHttpParams({
      include,
    });

    const body = credentials;
    return this.post<any>(path, params, body).pipe(
      tap((response) => {
        response.ttl = parseInt(response.ttl, 10);
        response.rememberMe = rememberMe;
        this.authApi.setToken(response);
        return response;
      })
    );
  }

  public loginWithPersonalCode(credentials: StudentCodeLoginCredentials) {
    const path = `personal-code-login`;

    const body = credentials;
    return this.post(path, undefined, body);
  }

  public logout() {
    const path = `logout`;

    return this.post(path).pipe(
      tap(() => this.authApi.setUser(null)),
      mapTo(true),
      catchError((_) => of(true))
    );
  }

  public validateToken(personId: number, tokenId: string) {
    const path = `validate-token/${personId}/${tokenId}`;

    return this.post(path);
  }

  public destroyByIdCredentials(personId: number, credential: number) {
    const path = `${personId}/credentials/${credential}`;

    return this.delete<{ count: number }>(path);
  }

  public checkUnique(personId: number, property: string, value: any) {
    const path = `checkUnique`;

    const params = this._getHttpParams({
      property,
      value,
    });

    return this.post<{ unique: boolean }>(path, params);
  }

  public checkProfileComplete() {
    const path = `check-profile-complete`;

    return this.get(path);
  }

  public checkUniqueAdmin(email: string) {
    const path = `check-unique/admin`;

    const params = this._getHttpParams({
      email,
    });

    return this.post<{ unique: boolean; user?: PersonInterface }>(path, params);
  }

  public register({ email, password, userType }): Observable<any> {
    const path = 'register';
    return this.post(path, undefined, { email, password, type: userType });
  }

  public getCurrent(): Observable<PersonInterface> {
    const path = `me`;

    return this.get<PersonInterface>(path).pipe(
      catchError(() => of(null)),
      tap((user) => {
        this.authApi.setUser(user);
      })
    );
  }

  public getCurrentId(): number {
    return this.authApi.getCurrentUserId();
  }

  public isUserInSync(stateUser: PersonInterface): Observable<boolean> {
    return this.getCurrent().pipe(
      map((apiUser) => {
        const apiUserId = apiUser ? apiUser.id : null;
        const stateUserId = stateUser ? stateUser.id : null;

        return apiUserId === stateUserId;
      })
    );
  }

  public resetPassword(email) {
    const path = `reset`;

    return this.post(path, undefined, { email }).pipe(mapTo(true));
  }
}
