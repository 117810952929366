import { NavigableListController, NavigableOption } from './navigable-list.controller';

export interface HighlightableOption {
  setActive(): void;
  setInactive(): void;
}

export class ActiveDescendantListController<T extends NavigableOption> extends NavigableListController<
  HighlightableOption & T
> {
  override setActiveItem(item: HighlightableOption & T) {
    if (this.activeItem) {
      this.activeItem.setInactive();
    }
    super.setActiveItem(item);
    if (this.activeItem) {
      this.activeItem.setActive();
    }
  }
}
