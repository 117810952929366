import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EVENT_MANAGER_PLUGINS } from '@angular/platform-browser';
import { ClickPreventDefaultDirective } from './directives/click-prevent-default.directive';
import { ClickStopPropagationDirective } from './directives/click-stop-propagation.directive';
import { ComponentInitDirective } from './directives/component-init/component-init.directive';
import { DataCyDirective } from './directives/data-cy.directive';
import { DataSourceDataDirective } from './directives/data-source-data/data-source-data.directive';
import { DataSourceFilterByColumnDirective } from './directives/data-source-filter-by-column/data-source-filter-by-column.directive';
import { DataSourceFilterColumnsDirective } from './directives/data-source-filter-columns/data-source-filter-columns.directive';
import { DataSourceFilterDirective } from './directives/data-source-filter/data-source-filter.directive';
import { DataSourcePaginatorDirective } from './directives/data-source-paginator/data-source-paginator.directive';
import { DataSourceSortDirective } from './directives/data-source-sort/data-source-sort.directive';
import { ResizeColumnDirective } from './directives/mat-table-column-resize/mat-table-column-resize.directive';
import { ScrollToFragmentDirective } from './directives/scroll-to-fragment/scroll-to-fragment.directive';
import { ToDictionaryPipe } from './pipes/dictionary/to-dictionary.pipe';
import { RangePipe } from './pipes/range/range.pipe';
import { BindEventPlugin } from './plugins/bind-event.plugin';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  declarations: [
    ClickStopPropagationDirective,
    ClickPreventDefaultDirective,
    DataSourceFilterDirective,
    DataSourceDataDirective,
    DataSourceDataDirective,
    DataSourceFilterDirective,
    DataSourceFilterColumnsDirective,
    DataSourceSortDirective,
    DataSourcePaginatorDirective,
    DataCyDirective,
    DataSourceFilterByColumnDirective,
    RangePipe,
    ScrollToFragmentDirective,
    ResizeColumnDirective,
    ComponentInitDirective,
    ToDictionaryPipe,
  ],
  exports: [
    ClickStopPropagationDirective,
    ClickPreventDefaultDirective,
    DataSourceFilterDirective,
    DataSourceDataDirective,
    DataSourceDataDirective,
    DataSourceFilterDirective,
    DataSourceFilterColumnsDirective,
    DataSourceSortDirective,
    DataSourcePaginatorDirective,
    DataCyDirective,
    DataSourceFilterByColumnDirective,
    RangePipe,
    ScrollToFragmentDirective,
    ResizeColumnDirective,
    ComponentInitDirective,
    ToDictionaryPipe,
  ],
  providers: [
    {
      provide: EVENT_MANAGER_PLUGINS,
      useClass: BindEventPlugin,
      multi: true,
    },
  ],
})
export class UtilsModule {}
