import { PortalActions, PortalActionsUnion } from './portal.actions';
import { PortalState } from './portal.state';

export const initialPortalState: PortalState = {
  role: null,
  mode: 'login',
  loginForm: {
    isOpen: false,
  },
};

export const NAME = 'portal';

export const portalReducer = function (
  state: PortalState = initialPortalState,
  action: PortalActionsUnion
): PortalState {
  switch (action.type) {
    case PortalActions.ACTION_CHANGE_ROLE:
      return { ...state, role: action.payload.role };
    case PortalActions.ACTION_CHANGE_MODE:
      return { ...state, mode: action.payload.mode };
    case PortalActions.ACTION_OPEN_LOGIN_FORM:
      return { ...state, loginForm: { isOpen: true } };
    case PortalActions.ACTION_CLOSE_LOGIN_FORM:
      return { ...state, loginForm: { isOpen: false } };
    default:
      return state;
  }
};
