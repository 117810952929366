import { LicenseInterface } from '../../services';
import { UserActionMethods, UserActions } from './user.actions';
import { UserState } from './user.state';

export const initialUserState: UserState = {
  isAuthenticated: null,
  current: undefined,
  activeLicenses: <LicenseInterface[]>undefined,
};

export const NAME = 'user';

export const userReducer = function (state: UserState = initialUserState, action: UserActionMethods): UserState {
  switch (action.type) {
    case UserActions.USER_LOGIN:
      return { ...state, isAuthenticated: null, error: undefined };
    case UserActions.ACTION_CLEAR_USER:
      return { ...initialUserState, current: null, isAuthenticated: false };
    case UserActions.ACTION_CHANGE_USER:
      return { ...state, ...action.payload, isAuthenticated: true };
    case UserActions.USER_LOGIN_FAIL:
    case UserActions.USER_REGISTER_FAIL:
      return { ...state, isAuthenticated: false, error: action.payload.error.toString() };
    case UserActions.USER_LOGIN_SUCCESS:
      return { ...state, ...action.payload, isAuthenticated: true };
    case UserActions.USER_CHECK_PASSWORD_FAIL:
    case UserActions.USER_SEND_RESET_PASSWORD_LINK_SUCCESS:
    case UserActions.USER_SEND_RESET_PASSWORD_LINK_FAIL:
    case UserActions.USER_RESET_PASSWORD_SUCCESS:
    case UserActions.USER_RESET_PASSWORD_FAIL:
      return { ...state, isAuthenticated: false };
    case UserActions.USER_UPDATE_TYPE:
      return {
        ...state,
        current: { ...state.current, type: action.payload.type },
      };
    case UserActions.ADD_ACTIVE_LICENSE:
      return {
        ...state,
        activeLicenses: [...state.activeLicenses, action.payload.license],
      };
    case UserActions.SET_ACTIVE_LICENSES:
      return { ...state, activeLicenses: [...action.payload.licenses] };
    case UserActions.CLEAR_ACTIVE_LICENSES:
      return { ...state, activeLicenses: undefined };
    case UserActions.CLEAR_AUTH_ERROR:
      return { ...state, error: undefined };
    default:
      return state;
  }
};
