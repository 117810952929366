import {
  animate,
  animateChild,
  AnimationMetadata,
  group,
  keyframes,
  query,
  stagger,
  state,
  style,
  transition,
} from '@angular/animations';
import { easing, sleep, speed } from './constants';

export const coinMoveYAnim: AnimationMetadata[] = [
  state(
    'void',
    style({
      transform: 'translateY(36px)',
    })
  ),
  state(
    '*',
    style({
      transform: 'translateY(4px)',
    })
  ),
  transition(':enter', [animate(`${speed.slow} ${easing.easeInQuart}`)]),
  transition(':leave', [animate(`${speed.slow} ${easing.easeInQuart}`)]),
];

export const coinMoveXAnim: AnimationMetadata[] = [
  state(
    'void',
    style({
      opacity: 0,
      transform: 'translateX(-150%)',
    })
  ),
  state(
    '*',
    style({
      opacity: 0,
      transform: 'translateX(0)',
    })
  ),
  transition(
    ':enter',
    group([
      query('@coinMoveY', animateChild()),
      group([
        animate(
          `${speed.verySlow} ${easing.easeIn}`,
          keyframes([
            style({ opacity: 0, offset: 0 }),
            style({ opacity: 1, offset: 0.2 }),
            style({ opacity: 1, offset: 0.85 }),
            style({ opacity: 0, offset: 1 }),
          ])
        ),
        animate(
          `${speed.slow} ${easing.easeInQuart}`,
          keyframes([style({ transform: 'translateX(-150%)' }), style({ transform: 'translateX(0)' })])
        ),
      ]),
    ])
  ),
  transition(
    'end => start',
    group([query('@coinMoveY', animateChild()), animate(`${speed.verySlow} ${sleep.fast} ${easing.easeInOut}`)])
  ),
];

export const coinListAnim: AnimationMetadata[] = [
  transition(':enter', [query('@coinMoveX', stagger(200, animateChild()), { optional: true })]),
];
