import { animate, AnimationMetadata, query, stagger, style, transition } from '@angular/animations';
import { easing, speed } from './constants';

export const listAnimation: AnimationMetadata[] = [
  transition('* <=> *', [
    query(
      ':enter',
      [
        style({ opacity: 0, transform: 'translateY(50%)' }),
        stagger(
          '60ms',
          animate(`${speed.normal} ${easing.easeOutQuad}`, style({ opacity: 1, transform: 'translateY(0)' }))
        ),
      ],
      { optional: true }
    ),
  ]),
];
