import { inject, Injectable, OnDestroy } from '@angular/core';
import { MaintenanceService } from '@campus/browser';

import {
  AUTH_SERVICE,
  AuthServiceInterface,
  BasicLoginCredentials,
  ChangeRole,
  getRouterState,
  selectPortalRole,
  UserType,
} from '@campus/public-portal';
import { Store } from '@ngrx/store';
import { merge, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay } from 'rxjs/operators';

@Injectable()
export class LoginPageContainerViewModel implements OnDestroy {
  private authService: AuthServiceInterface = inject(AUTH_SERVICE);
  private maintenanceService = inject(MaintenanceService);
  private store: Store = inject(Store);

  public error$: Observable<string>;
  public role$: Observable<UserType>;
  public isMaintenanceMode$: Observable<boolean>;

  constructor() {
    this.isMaintenanceMode$ = this.maintenanceService.isMaintenanceMode();
    this.error$ = merge(this.authService.error$, this.getErrorFromQueryParams()).pipe(
      map((error) => error || null), // normalize undefined, null and ''
      distinctUntilChanged(),
      shareReplay(1)
    );
    this.role$ = this.store.select(selectPortalRole);
  }

  public loginWithCredentials(credentials: BasicLoginCredentials) {
    this.authService.setAdapter('basic').withCredentials(credentials).login();
  }

  public loginWithProvider(provider: string) {
    this.authService.setAdapter(provider).login();
  }

  public resetPassword(email: string) {
    return this.authService.resetPassword(email);
  }

  ngOnDestroy(): void {
    this.authService.clearError();
  }

  public setRole(role: UserType) {
    this.store.dispatch(new ChangeRole({ role }));
  }

  private getErrorFromQueryParams(): Observable<string> {
    return this.store.select(getRouterState).pipe(
      filter((state) => !!state),
      map((routerState) => {
        const { err, error } = routerState.state.queryParams || {};
        if (error) return 'unknown_error';
        return err;
      })
    );
  }
}
