import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MaintenanceService } from '@campus/browser';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RedirectIfMaintenanceGuard {
  private maintenanceService = inject(MaintenanceService);
  private router = inject(Router);

  canActivate(): Observable<boolean> {
    return this.maintenanceService.isMaintenanceMode().pipe(
      take(1),
      map((isMaintenanceMode) => {
        if (isMaintenanceMode) {
          this.router.navigateByUrl('/');
          return false;
        }
        return true;
      })
    );
  }
}
