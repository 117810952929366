import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { pessimisticUpdate } from '@nrwl/angular';
import { from } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { PersonApi } from '../../services';
import {
  LoginUser,
  LoginUserFail,
  LoginUserSuccess,
  OauthLoginUser,
  RegisterUser,
  RegisterUserFail,
  RegisterUserSuccess,
  UserActions,
} from './user.actions';

@Injectable()
export class UserEffects {
  private actions$ = inject(Actions);
  private personApi = inject(PersonApi);

  loginUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.USER_LOGIN),
      pessimisticUpdate({
        run: (action: LoginUser) => {
          return this.personApi[action.method](action.payload.credentials).pipe(
            map((data) => new LoginUserSuccess(data['user'], action.payload.credentials))
          );
        },
        onError: (action: LoginUser, error) => {
          return new LoginUserFail({ error: 'Kon niet inloggen. Controleer uw gegevens en probeer opnieuw.' });
        },
      })
    )
  );
  registerUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.USER_REGISTER),
      pessimisticUpdate({
        run: (action: RegisterUser) => {
          return this.personApi[action.method](action.payload.credentials).pipe(
            switchMap((_) =>
              from([new RegisterUserSuccess(), new LoginUser({ credentials: action.payload.credentials })])
            )
          );
        },
        onError: (action: RegisterUser, error) => {
          return new RegisterUserFail({ error: 'Registratie mislukt, gelieve opnieuw te proberen' });
        },
      })
    )
  );

  loginOauthUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.OAUTH_USER_LOGIN),
        tap((action: OauthLoginUser) => {
          const { authBase, provider, returnUrl, type } = action.payload;
          window.location.href = `${authBase}/${provider}-login/${returnUrl}?type=${type || ''}`;
        })
      ),
    { dispatch: false }
  );
}
