<h1>Algemene gebruiksvoorwaarden</h1>
<div>
  <div class="[ stack stack-space-2xl ]">
    <section>
      <div class="[ stack stack-space-s ]">
        <h2>Artikel 1 - Eigendom en wijziging website</h2>
        <p>
          De website www.polpo.be (hierna “POLPO”) en alle afgeleide producten van POLPO worden beheerd door - en zijn
          eigendom van - Die Keure NV, met maatschappelijke zetel te Kleine Pathoekeweg 3, 8000 Brugge, België,
          ingeschreven in de Kruispuntbank van Ondernemingen onder het nummer 0405.108.325 ("Die Keure").
        </p>
        <p>
          In geval van vragen, klachten en/of opmerkingen met betrekking tot POLPO kan u zich steeds richten tot Die
          Keure, Kleine Pathoekeweg 3, 8000 Brugge, aan het volgende e-mailadres
          <a href="mailto:info@diekeure.be">info@diekeure.be</a> of op het telefoonnummer 050/47.12.72.
        </p>
        <p>
          Die Keure behoudt zich het recht voor deze Algemene Gebruiksvoorwaarden te allen tijde, en zonder voorafgaande
          verwittiging, te wijzigen om te voldoen aan de wettelijke verplichtingen of om de service te verbeteren. De
          eventuele aangepaste versie van deze Algemene Gebruiksvoorwaarden wordt geacht van toepassing te zijn vanaf de
          eerste datum van publicatie op POLPO. Wij raden u daarom aan de POLPO website regelmatig te raadplegen.
        </p>
        <p>
          Het gebruik van de Website impliceert dat "de gebruiker" van de Website de in de Algemene Gebruiksvoorwaarden
          opgenomen voorwaarden en richtlijnen voor gebruik van de Website volledig en onherroepelijk aanvaardt. Deze
          Algemene Gebruiksvoorwaarden gelden zodoende zowel voor de loutere bezoeker van de website en de afgeleide
          producten (zonder in te loggen) als voor de deelnemers op het digitale onderwijsplatform waar men, na
          registratie en het aanmaken van een persoonlijke account, onder een gebruikersnaam ten volle van alle
          functionaliteiten en diensten gebruik kan maken.
        </p>
        <p>
          Die Keure behoudt zich ten allen tijde het recht voor om elke gebruiker de toegang tot de Website geheel of
          gedeeltelijk te ontzeggen, onder andere in geval van duidelijke schending of vermoeden van schending van
          onderhavige bepalingen.
        </p>
      </div>
    </section>

    <section>
      <div class="[ stack stack-space-s ]">
        <h2>Artikel 2 - Voorwerp </h2>
        <p>
          POLPO vormt een zogenaamd onderwijsplatform waarin er een digitale leeromgeving wordt gecreëerd voor pc, mac
          en tablets, waarin zowel leerlingen als leerkrachten zich terugvinden. Verschillende doelgroepen krijgen
          binnen POLPO toegang tot verschillende delen van de website. Op heden zijn de verschillende doelgroepen:
          leerlingen, leerkrachten en onderwijspersoneel uit het secundair onderwijs.
        </p>
        <p>
          Die Keure behoudt zich te allen tijde het recht voor om de registratie van een gebruiker te weigeren zonder
          opgave van motivering. In het bijzonder geldt dit weigeringsrecht bij een vermoeden van opgave, door de
          gebruiker, van onjuiste of onvolledige gegevens tijdens het inloggen. Verder geldt dit weigeringsrecht onder
          andere ook bij vermoeden van het gebruik van geautomatiseerde systemen waaronder zogenaamde ‘bots’.
        </p>
        <p>
          Die Keure behoudt zich eveneens het recht voor om geregistreerde gebruikers te allen tijde de toegang tot
          POLPO te weigeren en, indien toepasselijk, accounts definitief te verwijderen indien de gebruiker in kwestie
          op een dergelijke manier gebruik maakt van POLPO dat dit gebruik redelijkerwijze als onaanvaardbaar kan
          beschouwd worden. Een “onaanvaardbaar gebruik” van POLPO is onder andere, doch niet uitsluitend, het meedelen
          van berichten, commentaren, data enz. die:
        </p>
        <ul class="[ title-small ]">
          <li> kwetsend, racistisch, haatdragend, extremistisch, beledigend of pornografisch zijn;</li
          ><li> het risico lopen als “laster en eerroof” te worden beschouwd of smaad beogen; </li
          ><li>
            als gelijk welke vorm van hacken of poging tot hacken van een onderdeel van POLPO zoudenkunnen worden
            beschouwd;
          </li>
          <li>het zich voordoen als een school of onderwijsinstelling als privaat persoon; </li>
          <li
            >op eender welke manier de rechten van Die Keure – met inbegrip van – doch niet uitsluitend, een aantasting
            van haar goede reputatie uitmaken;
          </li>
          <li
            >hetzelfde of een gelijkaardig effect teweeg brengen als het uploaden van bestanden of informatie, het
            gebruiken of uitwisselen van mogelijk illegale bestanden of informatie (bijvoorbeeld muziek, films enz.),
            het verzenden van spam enz. via POLPO;
          </li>
          <li
            >op welke manier dan ook een commerciële achtergrond hebben en bijvoorbeeld gericht zijn op het werven van
            cliënteel, het maken van reclame, marketing-gerelateerde doeleinden, enz.
          </li>
          <li
            >het schenden van auteursrechten en intellectuele eigendomsrechten met betrekking tot de inhoud en de vorm
            van de website en afgeleide producten. Een voorafgaande toestemming omtrent het delen van inhoudelijke of
            vormelijke elementen van POLPO en afgeleide producte is altijd vereist
          </li>
        </ul>
        <h3>Licentiëring </h3>
        <p>
          Het integraal aanwezige aanbod van digitaal materiaal in POLPO mag uitsluitend gebruikt worden als aanvulling
          op de leerboeken en leerwerkboeken bij de methodes van die Keure. POLPO kan onder geen beding als vervanging
          van leerboeken en leerwerkboeken gebruikt worden, uitgezonderd in een met die Keure overeengekomen tijdelijke
          periode waarbinnen een leerkracht of school POLPO kan uitproberen (de zogenaamde ‘demo-licentie’). Ook andere,
          eventuele uitzonderingen op deze regel dienen afzonderlijk te worden overeengekomen tussen die Keure en de
          school en/of leerkracht in kwestie. Bij vermoeden van overtreding van deze gebruiksbepaling behoudt die Keure
          zich het recht voor om leerkrachten en leerlingen de toegang tot POLPO te ontnemen.
        </p>
      </div>
    </section>

    <section>
      <div class="[ stack stack-space-s ]">
        <h2>Artikel 3 - Registratie en gebruik POLPO</h2>
        <p>
          Naast de verplichte aanvaarding van Die Keure’s Privacyverklaring (zie hieronder artikel 6) zullen de
          gebruikers van POLPO hun registratie slechts kunnen afsluiten indien zij zich akkoord verklaren met deze
          Algemene Gebruiksvoorwaarden.
        </p>
        <p>
          De gebruikers worden geacht volledige en correcte informatie te geven tijdens de registratieprocedure. Deze
          verplichting geldt zowel bij aanmaak van een gebruikersprofiel als na de aanmaak. Het is en blijft de
          uitsluitende verantwoordelijkheid van de gebruikers om achterhaalde informatie te (laten) corrigeren of
          verwijderen.
        </p>
        <p>
          Gebruikers hebben de mogelijkheid om gebruik te maken van hun Smartschool, Google of Facebook account om in te
          loggen in POLPO. Dit maakt het aanloggen gemakkelijker en zal -in het geval van Smartschool- ook tot een
          geïntegreerd gebruik tussen POLPO en Smartschool leiden. Die Keure is niet aansprakelijk voor eventuele
          problemen met de accounts van Google, Facebook of Smartschool die gebruikers mogelijks ondervinden.
        </p>
        <p>
          Voor verdere vragen en/of opmerkingen betreffende het afronden, op een geldige en correcte manier, van de
          registratie van een gebruiker op POLPO (bijvoorbeeld: het aanmaken van een uniek wachtwoord, de
          verantwoordelijkheid van de gebruikers omtrent de geheimhouding van hun gegevens, het aanmaken van een unieke
          gebruikersnaam, enz.) wordt verwezen naar de ‘veel gestelde vragen’.
        </p>
        <h3>Leerlingen</h3>
        <p>
          Leerlingen uit het secundair onderwijs maken in de regel gebruik van POLPO op basis van de relatie met een
          school (scholen die de onderwijsmethodes van Die Keure gebruiken)
        </p>
        <p> In hoofdzaak vervullen leerlingen vooral één (of meerdere) activiteiten zoals: </p>
        <ul class="[ title-small ]">
          <li>Een Book-e bekijken, na het ingeven van een licentie code;</li>
          <li>Vrijblijvende oefeningen maken die door een leerkracht aan een leerling toegewezen werden;</li>
          <li
            >Verplichte taken afwerken (binnen een bepaalde periode) die door een leerkracht aan een leerling toegewezen
            werden;</li
          >
          <li>Feedback vragen aan een leerkracht indien een taak niet duidelijk was;</li>
          <li>Bekijken van de resultaten van reeds voltooide taken;</li>
          <li>Heen-en-weer navigeren tussen POLPO en Smartschool;</li>
        </ul>
        <h3>Leerkrachten</h3>
        <p
          >Leerkrachten (alsook erkende specialisten uit het onderwijssysteem zoals o.a. een zorgcoördinator,
          ICT-coördinator) ontvangen een (of meerdere profielen) op POLPO wanneer de registratieprocedure correct is
          doorlopen.</p
        >
        <p>In hoofdzaak vervullen leerkrachten vooral één (of meerdere) activiteiten zoals:</p>
        <ul class="[ title-small ]">
          <li>Inloggen via de accountgegevens van Smartschool;</li>
          <li>Bekijken van alle digitaal lesmateriaal van alle methodes van Die Keure;</li>
          <li>Bordboeken gebruiken;</li>
          <li>Oplossingen van oefeningen raadplegen;</li>
          <li>Handleidingen raadplegen;</li>
          <li>Diverse media (audio/video) raadplegen als onderdeel van een methode;</li>
          <li
            >Bundels maken om lessen te geven op basis van diverse leerobjecten en deze ontsluiten naar leerlingen;</li
          >
          <li
            >Toewijzen van vrijblijvende oefeningen en verplichte taken aan leerlingen. Deze kunnen opgelost worden
            tijdens de schooluren (bijvoorbeeld in de computerklas) alsook thuis (als onderdeel van huiswerk);</li
          >
          <li>Oefeningen kunnen afdrukken, exporteren, en doorsturen via email;</li>
          <li>Feedback geven aan leerlingen indien nodig;</li>
          <li>Bekijken van de resultaten van gemaakte taken;</li>
          <li>Externe tools gebruiken tijdens de les;</li>
          <li>Heen-en-weer navigeren tussen POLPO en Smartschool;</li>
        </ul>
        <p>
          Een leerkracht kan een oefening of taak toewijzen aan één leerling of aan een groep leerlingen. Wanneer het
          een groep betreft kan de leerkracht kiezen om deze zelf aan te maken, dit in het kader van remediëring,
          differentiatie, zorgwerking en M-decreet. Daarnaast worden ook de klassen zoals gekend in Smartschool
          beschikbaar gesteld in POLPO voor de respectievelijke leerkrachten, Deze gegevens worden echter enkel
          beschikbaar gesteld indien de school in kwestie een goedkeuring gaf aan Smartschool om deze gegevens te
          gebruiken.
        </p>
        <p
          >Bepaalde informatie over de minderjarige kinderen zal niet aan de leerkrachten ter beschikking worden gesteld
          (bijvoorbeeld de duur van de oefeningen gemaakt door de leerlingen, het tijdstip waarop een oefening gemaakt
          werd)</p
        >
      </div>
    </section>

    <section>
      <div class="[ stack stack-space-s ]">
        <h2>Artikel 4 - Intellectuele eigendom </h2>
        <p>
          Alle bestanddelen van POLPO en de daarbij horende sub-domeinen (waaronder doch niet beperkt tot de gebruikte
          software, sourcecodes, de structuur, lay-out, teksten, logo's, muziekjes, de ingesproken stemmen, foto’s,
          tekeningen, oefeningen, beeld, geluid, databanken, benamingen, beschrijvingen, handels- en domeinnamen) zijn
          werken die beschermd zijn door het auteursrecht en/of andere intellectuele eigendomsrechten waaronder het
          merkenrecht.
        </p>
        <p>
          Alle desbetreffende eigendomsrechten, inclusief intellectuele eigendomsrechten (auteursrechten, merkenrechten,
          databankrechten, tekeningen- en modellenrechten, etc.) behoren toe aan Die Keure of zijn opgenomen op POLPO en
          bijhorende sub-domeinen met toestemming van de eigenaar van de desbetreffende rechten.
        </p>
        <p>
          Geen enkel bestanddeel van POLPO en zijn sub-domeinen zelf, noch de op POLPO en zijn subdomeinen aangeboden
          gegevens en informatie mag worden opgeslagen (anders dan noodzakelijk om POLPO te kunnen bezoeken),
          gereproduceerd, gewijzigd, vertaald, openbaar gemaakt, verspreid, verhuurd, verkocht, overgedragen aan anderen
          of op enige wijze worden gebruikt zonder voorafgaande en schriftelijke toelating van Die Keure.
        </p>
      </div>
    </section>

    <section>
      <div class="[ stack stack-space-s ]">
        <h2>Artikel 5 - Aansprakelijkheid </h2>
        <p>
          Die Keure waakt ervoor zo correct mogelijke informatie te verstrekken maar kan nooit de juistheid,
          volledigheid en geschiktheid van de informatie op POLPO en bijbehorende sub-domeinen verzekeren en kan
          hiervoor derhalve niet aansprakelijk worden gesteld. Dit geldt zowel voor informatie die door Die Keure zelf
          op POLPO en bijhorende sub-domeinen werd geplaatst, alsook voor informatie afkomstig van gebruikers of
          anderen. Onder informatie wordt verstaan: al hetgeen op POLPO en de bus-domeinen terug te vinden is, waaronder
          teksten, oefeningen, foto’s, geluid, data enz.
        </p>
        <p>
          Die Keure is niet aansprakelijk voor welke beslissing of handeling dan ook die door de gebruiker zou zijn
          genomen op basis van de verstrekte informatie t.a.v. gebruikers of anderen, noch is zij verantwoordelijk voor
          fouten of vergissingen van de gebruiker.
        </p>
        <p>
          Die Keure is evenmin aansprakelijk voor directe of indirecte schade of gelijk welke schade die veroorzaakt zou
          kunnen worden door onjuistheid, onvolledigheid, ongeschiktheid, vergetelheid of nalatigheid in het
          verschaffen, samenstellen, opstellen, schrijven en interpretatie van de informatie op de POLPO en de
          bijhorende sub-domeinen.
        </p>
        <p>
          De POLPO software voldoet aan alle gangbare normen en standaarden inzake veilige en betrouwbare software. Die
          Keure is daarom niet aansprakelijk voor permanente of tijdelijke schade of defecten aan gegevens of
          computerapparatuur van de gebruikers tijdens of na het gebruik van POLPO en bijbehorende sub-domeinen. In het
          bijzonder is Die Keure niet aansprakelijk voor de mogelijke overdracht van virussen, trojans en dergelijke via
          de POLPO en bijbehorende sub-domeinen.
        </p>
        <p>
          Die Keure is niet aansprakelijk voor links naar websites die door anderen worden beheerd of de schade die
          veroorzaakt wordt in het kader van een bezoek aan deze websites. Die Keure kan geen enkele controle uitoefenen
          op deze Websites en bijbehorende applicaties voor pc, mac en tablets en kan niet aansprakelijk worden gesteld
          voor de inhoud ervan.
        </p>
        <p>
          Voor de realisatie van POLPO doet Die Keure zoveel mogelijk beroep op de modernste technieken. Die Keure kan
          evenwel niet aansprakelijk gesteld worden voor het (tijdelijk) uitvallen of eventuele storingen of
          onderhoudswerken op - of van – POLPO.
        </p>
        <p>
          De gebruiker is te allen tijde exclusief verantwoordelijk voor alle berichten, data, commentaren, informatie
          enz. die de gebruiker middels zijn/haar actieve deelname op POLPO meedeelt. De gebruiker is zodoende ook
          exclusief verantwoordelijk voor alle mogelijke rechten, waaronder auteursrechten, merkenrechten en dergelijke,
          die kunnen rusten op de informatie die de gebruiker op POLPO meedeelt. Dit houdt dus in dat de gebruiker
          exclusief verantwoordelijk zal zijn voor elke vordering vanwege derden of betrokkenen wegens inbreuken op hun
          rechten. De gebruiker vrijwaart Die Keure volledig van elke vordering vanwege derden wegens dergelijke
          (vermeende) inbreuken op hun rechten door de informatie (beeld, tekst, klank enz.) die door de gebruiker op de
          POLPO werd geplaatst.
        </p>
        <p>
          Overeenkomstig artikel 1384 van het Burgerlijke Wetboek dienen ouders te allen tijde in te staan voor de daden
          (en eventuele veroorzaakte schade) van hun kinderen. Dit geldt eveneens voor elk gebruik door een minderjarig
          kind van POLPO. In geval van enige vorm van schade berokkend door het gebruik, door een minderjarig kind, op
          POLPO zal Die Keure gerechtigd zijn de ouders (of bevoegde meerderjarigen) die toegestemd hebben met de
          registratie van het kind in kwestie, in rechte aan te spreken.
        </p>
      </div>
    </section>

    <section>
      <div class="[ stack stack-space-s ]">
        <h2>Artikel 6 - Privacy </h2>
        <p>
          Gelet op de evolutie van de privacywetgeving, behoudt Die Keure zich het recht voor haar Privacyverklaring op
          ieder moment te wijzigen. Bijgevolg raadt Die Keure de gebruiker aan regelmatig op de Website na te gaan of er
          wijzigingen zijn doorgevoerd in de Privacyverklaring. Net zoals deze Algemene Gebruiksvoorwaarden zak ook de
          boven vermelde Privacyverklaring dienen aanvaard te worden door de gebruikers van POLPO alvorens via hun
          persoonlijke account ten volle toegang te krijgen tot het Platform.
        </p>
      </div>
    </section>

    <section>
      <div class="[ stack stack-space-s ]">
        <h2>Artikel 7 - Afstand </h2>
        <p>
          Het ontbreken van het afdwingen door Die Keure van enige bepaling uit deze Algemene Gebruiksvoorwaarden, of
          het niet ondernemen van enige actie ten aanzien van een gebruiker van in het geval van een mogelijke inbreuk
          op enige bepaling, kan niet worden geïnterpreteerd als een afstand van vordering of recht ten aanzien van
          enige bepaling in het kader van een mogelijke toekomstige inbreuk door een gebruiker.
        </p>
      </div>
    </section>

    <section>
      <div class="[ stack stack-space-s ]">
        <h2>Artikel 8 - Deelbaarheid </h2>
        <p>
          Indien een bepaling van deze Algemene Gebruiksvoorwaarden geheel of gedeeltelijk onwettig, nietig of
          niet-afdwingbaar wordt verklaard onder het toepasselijke recht, zal deze bepaling geen deel meer uitmaken van
          deze Algemene Gebruiksvoorwaarden. De wettigheid, geldigheid en bindende aard van de andere bepalingen van
          deze Algemene Gebruiksvoorwaarden blijven onaangetast.
        </p>
      </div>
    </section>

    <section>
      <div class="[ stack stack-space-s ]">
        <h2>Artikel 9 - Bevoegde rechtbank en toepasselijk recht </h2>
        <p>
          Deze Algemene Gebruiksvoorwaarden worden uitsluitend beheerst door - en geïnterpreteerd in overeenstemming met
          - de Belgische wetgeving.
        </p>
        <p>
          De bevoegde rechtbanken en hoven voor kennisname van betwistingen omtrent deze Algemene Gebruiksvoorwaarden
          zullen de rechtbanken en hoven van het arrondissement Brugge zijn, behoudens andersluidende dwingende
          wettelijke bepalingen.
        </p>
      </div>
    </section>
  </div>
</div>
