<form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="[ stack ]" data-cy="login-form">
  <mat-form-field>
    <mat-label>E-mailadres/Gebruikersnaam</mat-label>
    <input matInput type="text" formControlName="email" data-cy="input-login-form-email" />
  </mat-form-field>
  <div class="[ stack stack-space-3xs ]">
    <mat-form-field>
      <mat-label>Wachtwoord</mat-label>
      <input matInput type="password" formControlName="password" data-cy="input-login-form-password" />
    </mat-form-field>
    <div *ngIf="error">
      <p class="[ text-error ][ body-large weight-medium ]" data-cy="login-error"> {{ error }} </p>
    </div>
    <button
      inline-button
      class="[ flex self-end ]"
      (click)="setForgotPassword()"
      type="button"
      data-cy="btn-login-form-request-password"
    >
      Wachtwoord vergeten?
    </button>
  </div>
  <div class="[ cluster ]">
    <div class="[ justify-between ]">
      <button
        filled-button
        type="submit"
        [disabled]="!loginForm.valid"
        leadingIcon="login"
        data-cy="btn-login-form-sign-in"
      >
        Aanmelden
      </button>
      <button
        *ngIf="showRegister"
        inline-button
        routerLink="/registratie"
        type="button"
        data-cy="btn-login-form-register"
      >
        Registreer als nieuwe leerling
      </button>
    </div>
  </div>
</form>
