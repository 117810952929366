import { CommonModule } from '@angular/common';
import { Inject, ModuleWithProviders, NgModule } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ENVIRONMENT_ICON_MAPPING_TOKEN, ENVIRONMENT_SVG_MAPPING_TOKEN } from '@campus/environment';
import { IconComponent } from './components/icon.component';
import { SvgComponent } from './components/svg.component';
import { SvgRegistry } from './registry/svg.registry';

export interface MappingInterface {
  [key: string]: string;
}
export interface SvgOptions {
  svgMapping?: MappingInterface;
  iconMapping?: MappingInterface;
}
@NgModule({
  declarations: [SvgComponent, IconComponent],
  exports: [SvgComponent, IconComponent],
  imports: [CommonModule],
  providers: [],
})
export class SvgModule {
  static forRoot(options: SvgOptions): ModuleWithProviders<SvgModule> {
    return {
      ngModule: SvgModule,
      providers: [
        { provide: ENVIRONMENT_SVG_MAPPING_TOKEN, useValue: options.svgMapping, multi: true },
        { provide: ENVIRONMENT_ICON_MAPPING_TOKEN, useValue: options.iconMapping, multi: true },
      ],
    };
  }

  constructor(
    private svgRegistry: SvgRegistry,
    private sanitizer: DomSanitizer,
    @Inject(ENVIRONMENT_ICON_MAPPING_TOKEN)
    private iconMapping: MappingInterface[],
    @Inject(ENVIRONMENT_SVG_MAPPING_TOKEN)
    private svgMapping: MappingInterface[]
  ) {
    this.setupIconRegistry();
    this.setupSvgRegistry();
  }

  public setupIconRegistry() {
    if (!this.iconMapping) {
      return;
    }
    const icons = this.iconMapping.reduce((acc, iconMap) => ({ ...acc, ...iconMap }), {});

    Object.entries(icons).forEach(([key, value]) => {
      const parts = key.split(':');
      let namespace: string, svgIcon: string;

      if (parts.length > 1) {
        namespace = parts.shift();
        svgIcon = parts.join(':');
      } else {
        svgIcon = parts[0];
        namespace = '';
      }

      if (value.startsWith('<svg')) {
        this.svgRegistry.addSvgImageLiteralInNamespace(
          namespace,
          svgIcon,
          this.sanitizer.bypassSecurityTrustHtml(value)
        );
      } else {
        this.svgRegistry.addSvgImageInNamespace(
          namespace,
          svgIcon,
          this.sanitizer.bypassSecurityTrustResourceUrl(value)
        );
      }
    });
  }

  public setupSvgRegistry() {
    if (!this.svgMapping) {
      return;
    }
    const svgs = this.svgMapping.reduce((acc, svgMap) => ({ ...acc, ...svgMap }), {});

    Object.entries(svgs).forEach(([key, value]) => {
      const parts = key.split(':');
      let namespace: string, svgImage: string;

      if (parts.length > 1) {
        namespace = parts.shift();
        svgImage = parts.join(':');
      } else {
        svgImage = parts[0];
        namespace = '';
      }

      if (value.startsWith('<svg')) {
        this.svgRegistry.addSvgImageLiteralInNamespace(
          namespace,
          svgImage,
          this.sanitizer.bypassSecurityTrustHtml(value)
        );
      } else {
        this.svgRegistry.addSvgImageInNamespace(
          namespace,
          svgImage,
          this.sanitizer.bypassSecurityTrustResourceUrl(value)
        );
      }
    });
  }
}
