import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { Ripple } from '../ripple/ripple.decorator';

@Component({ template: '', changeDetection: ChangeDetectionStrategy.OnPush })
@Ripple({ disablePressdown: true })
export class ChipCoreComponent implements OnInit, OnDestroy {
  private _disabled: boolean;
  element = inject(ElementRef).nativeElement;

  protected coreClasses = [
    'chip',

    'relative',
    'inline-flex',
    'items-center',

    'outline-none',
    'select-none',

    'border',
    'corner-s',

    'surface',
    'on-surface-variant',
    'label-large',

    'index-surface',

    'disabled:border-opacity-disabled',
    'disabled:text-opacity-disabled',
    'disabled:pointer-event-none',
  ];

  @HostBinding('attr.aria-disabled')
  @HostBinding('attr.disabled')
  @Input()
  get disabled() {
    return this._disabled === true ? true : undefined; //removed the disabled attribute for false values
  }
  set disabled(value: boolean) {
    this._disabled = value;
  }

  @HostBinding('attr.data-has-leading-icon') hasLeadingIcon: boolean;
  @HostBinding('attr.data-has-trailing-icon') hasTrailingIcon: boolean;

  @HostBinding('attr.data-has-trailing-icon')
  @Input()
  removable: boolean;

  @HostBinding('attr.tabindex')
  @Input()
  tabindex = 0;

  @Input() label = '';

  @HostBinding('attr.aria-label')
  @Input()
  ariaLabel = this.label;

  @Output() remove = new EventEmitter<void>();

  private elementRef = inject(ElementRef);
  private observer: MutationObserver;

  private _hasLeadingIcon: boolean;
  private _hasTrailingIcon: boolean;

  ngOnInit(): void {
    this.observer = new MutationObserver((changes) => this.onContentChange(changes));
    this.observer.observe(this.elementRef.nativeElement, { childList: true, subtree: true });
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
  }

  protected onContentChange(changes: MutationRecord[]) {
    //get all elements in the addednodes with the attribute chip-icon
    const iconTargets = this._getAddedNodesWithAttribute(changes, 'chip-icon');

    this.hasLeadingIcon =
      this._hasLeadingIcon ||
      !!iconTargets.filter((icon) => !icon.hasAttribute('chip-suffix-icon')).length ||
      undefined;
    this._hasLeadingIcon = this.hasLeadingIcon;

    this.hasTrailingIcon =
      this._hasTrailingIcon ||
      !!iconTargets.filter((icon) => icon.hasAttribute('chip-suffix-icon')).length ||
      undefined;
    this._hasTrailingIcon = this.hasTrailingIcon;

    iconTargets.forEach((target) => target.classList.add('flex-none')); //don't shrink nor grow
  }

  handleRemoveClick() {
    if (!this.removable || this.disabled) {
      return;
    }

    this.remove.emit();
  }

  /**
   * Returns all added nodes that have the given attribute
   *
   * @param changes  MutationRecord[] the nodeList to search in
   * @param attribute string the attribute to search for
   * @returns HTMLElement[] the nodes that have the given attribute
   */
  protected _getAddedNodesWithAttribute(changes: MutationRecord[], attribute: string): HTMLElement[] {
    const nodeListToArray = (nodeList: NodeList) => Array.from(nodeList);
    const nodesAttributesFilter = (node: Node) => (node as HTMLElement).attributes?.length;
    const nodesHasAttributeFilter = (node: Node) => (node as HTMLElement).hasAttribute(attribute);

    return changes
      .filter(
        (change) =>
          change.addedNodes.length > 0 &&
          nodeListToArray(change.addedNodes).filter(nodesAttributesFilter).some(nodesHasAttributeFilter)
      )
      .reduce((arr, change) => [...arr, ...nodeListToArray(change.addedNodes)], []);
  }
}
