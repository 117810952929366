import { QueryList } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InputBooleanAttribute } from '../empty-attribute';
import { NavigableKeys, isNavigableKey } from './keyboard';

export interface NavigableOption {
  disabled: InputBooleanAttribute;
}

export class NavigableListController<T extends NavigableOption> {
  readonly destroyed$ = new Subject<void>();
  readonly tabOut = new Subject<KeyboardEvent>();
  readonly change = new Subject<number>();

  private _activeItemIndex = -1;
  private _activeItem: T | null = null;
  private _pageUpAndDown = { enabled: true, delta: 10 };

  private _isActivatableFn: (item: T) => boolean = (item: T) => !item.disabled;
  private _isNavigableKeyFn: (key: string) => boolean = (key: string) => isNavigableKey(key);
  private _isItemFn: (item: T) => boolean = (item: T) => true;
  private _isRtlFn: () => boolean = () => false;

  get activeItemIndex(): number | null {
    return this._activeItemIndex;
  }
  get activeItem(): T | null {
    return this._activeItem;
  }

  constructor(private _items: QueryList<T> | T[]) {
    if (_items instanceof QueryList) {
      _items.changes.pipe(takeUntil(this.destroyed$)).subscribe((newItems: QueryList<T>) => {
        if (this._activeItem) {
          const items = newItems.toArray();
          const newIndex = items.indexOf(this._activeItem);

          if (newIndex > -1 && newIndex !== this._activeItemIndex) {
            this._activeItemIndex = newIndex;
          }
        }
      });
    }
  }

  setItems = (items: T[]) => {
    this._items = items;
    if (this._activeItem) {
      const newIndex = items.indexOf(this._activeItem);

      if (newIndex > -1 && newIndex !== this._activeItemIndex) {
        this._activeItemIndex = newIndex;
      }
    }
  };

  setIsActivatable(fn: (item: T) => boolean) {
    this._isActivatableFn = fn;
    return this;
  }
  setIsNavigableKey(fn: (key: string) => boolean) {
    this._isNavigableKeyFn = fn;
    return this;
  }
  setIsItem(fn: (item: T) => boolean) {
    this._isItemFn = fn;
    return this;
  }
  setIsRtl(fn: () => boolean) {
    this._isRtlFn = fn;
    return this;
  }
  withPageUpAndDown(enabled: boolean, delta: number) {
    this._pageUpAndDown = { enabled, delta };
    return this;
  }

  setActiveItem(item: T) {
    const previous = this._activeItem;

    this.updateActiveItem(item);

    if (this._activeItem !== previous) {
      this.change.next(this._activeItemIndex);
    }
  }

  updateActiveItem(item: T) {
    const items = this._getPossibleItems();
    const index = items.indexOf(item);
    const activeItem = items[index];

    this._activeItem = activeItem == null ? null : activeItem;
    this._activeItemIndex = index;
  }

  onKeydown(event: KeyboardEvent) {
    const key = event.code;

    if (event.defaultPrevented || !this._isNavigableKeyFn(key)) {
      return;
    }

    const isRtl = this._isRtlFn();
    const inlinePrevious = isRtl ? NavigableKeys.ArrowRight : NavigableKeys.ArrowLeft;
    const inlineNext = isRtl ? NavigableKeys.ArrowLeft : NavigableKeys.ArrowRight;

    switch (key) {
      case NavigableKeys.Tab:
        this.tabOut.next(event);
        return;
      case NavigableKeys.ArrowDown:
      case inlineNext:
        this.setNextItemActive();
        break;
      case NavigableKeys.ArrowUp:
      case inlinePrevious:
        this.setPreviousItemActive();
        break;
      case NavigableKeys.Home:
        this.setFirstItemActive();
        break;
      case NavigableKeys.End:
        this.setLastItemActive();
        break;
      case NavigableKeys.PageUp:
        if (this._pageUpAndDown) {
          const targetIndex = this._activeItemIndex - this._pageUpAndDown.delta;
          this._setActiveItemByIndex(targetIndex > 0 ? targetIndex : 0, 1);
        } else return;
        break;
      case NavigableKeys.PageDown:
        if (this._pageUpAndDown) {
          const targetIndex = this._activeItemIndex + this._pageUpAndDown.delta;
          const itemsLength = this._getPossibleItems().length;
          this._setActiveItemByIndex(targetIndex < itemsLength ? targetIndex : itemsLength - 1, -1);
        } else return;
        break;
    }

    event.preventDefault();
  }

  setFirstItemActive(): void {
    this._setActiveItemByIndex(0, 1);
  }
  setLastItemActive(): void {
    this._setActiveItemByIndex(this._getPossibleItems().length - 1, -1);
  }
  setNextItemActive(): void {
    if (this._activeItemIndex < 0) this.setFirstItemActive();
    else this._setActiveItemByDelta(1);
  }
  setPreviousItemActive(): void {
    if (this._activeItemIndex < 0) this.setLastItemActive();
    else this._setActiveItemByDelta(-1);
  }

  destroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.tabOut.complete();
    this.change.complete();
  }

  private _setActiveItemByDelta(delta: -1 | 1): void {
    const items = this._getPossibleItems();

    for (let i = 1; i <= items.length; i++) {
      const index = (this._activeItemIndex + i * delta + items.length) % items.length;
      const item = items[index];

      if (this._isActivatableFn(item)) {
        this._setActiveItemByIndex(index, delta);
        break;
      }
    }
  }

  private _setActiveItemByIndex(index: number, fallbackDelta: -1 | 1): void {
    const items = this._getPossibleItems();

    if (!items[index]) return;

    while (!this._isActivatableFn(items[index])) {
      index += fallbackDelta;
      if (!items[index]) return;
    }

    this.setActiveItem(items[index]);
  }

  private _getPossibleItems(): T[] {
    return this._getItemsArray().filter(this._isItemFn);
  }

  private _getItemsArray(): T[] {
    return this._items instanceof QueryList ? this._items.toArray() : this._items;
  }
}
