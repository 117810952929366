import { Injectable } from '@angular/core';
import { OauthAdapter } from './oauth.adapter';

@Injectable({
  providedIn: 'root',
})
export class GoogleAdapter extends OauthAdapter {
  protected provider = 'google';

  constructor() {
    super();
  }
}

