import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'campus-maintenance-mode',
  templateUrl: './maintenance-mode.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaintenanceModeComponent {
  @HostBinding('class')
  defaultClasses = [
    'border',
    'border-2',
    'border-warn',
    'corner-m',
    'max-w-l',
    'p-b-m',
    'p-i-l',
    'stack',
    'stack-space-s',
    'w-full',
    'warn-container',
  ];

  @Input() statusPageUrl: string;
}
