import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[campusExpansionPanelHeader], [expansionPanelHeader], campus-expansion-panel-header',
})
export class ExpansionPanelHeaderDirective {
  @HostBinding('class.ui-expansion-panel-header')
  expansionPanelHeaderClass = true;

  @Output() headerClick: EventEmitter<ExpansionPanelHeaderDirective> = new EventEmitter();

  @HostBinding('attr.data-cy')
  dataCyAttribute = 'expansion-panel-header';

  @HostListener('click') onClick() {
    this.headerClick.emit(this);
  }
}
