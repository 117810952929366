import { NgModule } from '@angular/core';

import {
  CustomSerializer,
  RouterReducer,
  clearState,
  initialPortalState,
  portalReducer,
  userReducerFactory,
} from '@campus/public-portal';
import { NavigationActionTiming, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { handleUndo } from 'ngrx-undo';
import { environment } from '../environments/environment';

@NgModule({
  imports: [
    StoreModule.forRoot(
      {},
      {
        metaReducers: [handleUndo, clearState],
      }
    ),
    StoreModule.forFeature('user', userReducerFactory([])),
    StoreModule.forFeature('portal', portalReducer, {
      initialState: initialPortalState,
    }),
    addFeature(RouterReducer),
    StoreRouterConnectingModule.forRoot({
      navigationActionTiming: NavigationActionTiming.PostActivation,
      serializer: CustomSerializer,
      stateKey: RouterReducer.NAME,
    }),
    environment.enableDevTools ? StoreDevtoolsModule.instrument() : [],
  ],
})
export class AppStoreModule {}

function addFeature(reducer) {
  return StoreModule.forFeature(reducer.NAME, reducer.reducer, {
    initialState: reducer.initialState,
  });
}
