/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ModelApiBase } from '../model-api.base';
import { PolpoApi } from '../polpo-api';
@Injectable({ providedIn: 'root' })
export class EduContentApi extends ModelApiBase {
  constructor(api: PolpoApi) {
    super(api, 'EduContents');
  }

  requestURLRemote(
    contentId: number,
    stream?,
    taskId?: number,
    unlockedContentId?: number,
    unlockedFreePracticeId?: number,
    context?
  ): Observable<any> {
    const path = `/:${contentId}/requestURL`;

    const params = this._getHttpParams({
      stream,
      taskId,
      unlockedContentId,
      unlockedFreePracticeId,
      context,
    });

    return this.get(path, params);
  }
}
