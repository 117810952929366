import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  TemplateRef,
  inject,
} from '@angular/core';
import { InputBooleanAttribute } from '../core/empty-attribute';

interface FocusRingEvent extends Event {
  handledByFocusRing: true;
}

@Component({
  selector: 'campus-focus-ring',
  template: '',
})
export class FocusRingComponent implements OnChanges, OnDestroy {
  private _events = ['focusin', 'focusout', 'pointerdown'];
  private _prevControl: HTMLElement | null = null;
  private _cd = inject(ChangeDetectorRef);

  //#region Inputs
  @HostBinding('attr.data-visible')
  visible: InputBooleanAttribute;

  @HostBinding('attr.aria-hidden')
  ariaHidden = true;

  @Input() for: TemplateRef<Component> | ElementRef | HTMLElement;

  @HostBinding('attr.data-inward')
  @Input()
  inward: InputBooleanAttribute;

  @HostBinding('class')
  defaultClasses = ['focus-ring'];
  //#endregion

  //#region Lifecycle Hooks
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.for) {
      const control = this._getForElement();
      if (control) {
        this._cleanup(this._prevControl);
        this._listen(control);
        this._prevControl = control;
      }
    }
  }
  ngOnDestroy(): void {
    if (!this.for) return;
    this._cleanup(this._getForElement());
  }
  //#endregion

  handleEvent(event: FocusRingEvent) {
    if (event.handledByFocusRing) {
      // This ensures the focus ring does not activate when multiple focus rings
      // are used within a single component.tml
      return;
    }

    switch (event.type) {
      default:
        return;
      case 'focusin':
        this.visible = this._getForElement().matches(':focus-visible') || undefined;
        this._cd.markForCheck();
        break;
      case 'focusout':
      case 'pointerdown':
        this.visible = undefined;
        this._cd.markForCheck();
        break;
    }

    event.handledByFocusRing = true;
  }

  private _listen(control: HTMLElement) {
    if (!control) return;

    this._events.forEach((event) => {
      control.addEventListener(event, this);
    });
  }
  private _cleanup(control: HTMLElement) {
    if (!control) return;

    this._events.forEach((event) => {
      control.removeEventListener(event, this);
    });
  }

  private _getForElement() {
    if (!this.for) throw new Error('For is required');

    if ('nativeElement' in this.for) return this.for.nativeElement;
    else if ('elementRef' in this.for) return this.for.elementRef.nativeElement;
    else return this.for;
  }
}
