import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { FaqPageComponent, PageNotFoundComponent } from '@campus/public-portal';
import { RedirectIfAuthenticatedGuard } from './guards/redirect-if-authenticated.guard';
import { RedirectIfMaintenanceGuard } from './guards/redirect-if-mainenance.guard';
import { redirectIfOldUrl } from './guards/seo-mapping.guard';
import { ContentLinkPageComponent } from './pages/content-link-page/content-link-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { LegalsPageContainerComponent } from './pages/legals-page/legals-page-container.component';
import { LoginPageContainerComponent } from './pages/login-page/login-page-container.component';
import { RegisterPageContainerComponent } from './pages/register-page/register-page-container.component';

const routes: Routes = [
  {
    path: '',
    runGuardsAndResolvers: 'always',
    component: HomePageComponent,
  },
  {
    path: 'faq',
    component: FaqPageComponent,
  },
  {
    path: 'legals/:slug',
    component: LegalsPageContainerComponent,
  },
  {
    path: 'login',
    component: LoginPageContainerComponent,
    canActivate: [RedirectIfAuthenticatedGuard, RedirectIfMaintenanceGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'registratie',
    component: RegisterPageContainerComponent,
    canActivate: [RedirectIfAuthenticatedGuard, RedirectIfMaintenanceGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'link/:id',
    component: ContentLinkPageComponent,
    canActivate: [RedirectIfMaintenanceGuard],
  },
  {
    path: 'veelgestelde-vragen/:category/:slug',
    children: [],
    canActivate: [redirectIfOldUrl],
  },
  {
    path: 'platform',
    canActivate: [RedirectIfAuthenticatedGuard],
    children: [],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      scrollOffset: [0, 120],
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
