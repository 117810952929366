/// <reference types="grecaptcha" />

import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  inject,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ContactFormEntryInterface, PersonInterface } from '../../services';
import { BaseFormComponent, StateEnum } from '../base-form/base-form.component';

export type Role = 'teacher' | 'schooladmin' | 'student';

export type PersonWithToken = PersonInterface & {
  role: Role;
};

@Component({
  selector: 'campus-contact-section',
  templateUrl: './contact-section.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactSectionComponent extends BaseFormComponent<ContactFormEntryInterface> implements OnInit, OnChanges {
  @HostBinding('class')
  defaultClasses = ['block'];

  @Input() user: PersonWithToken;
  @Input() subjects: string[];

  public StatEnum = StateEnum;

  uiState = {
    initial: {
      cta: 'Versturen',
    },
    processing: {
      cta: '',
    },
    error: {
      header: 'Ai ai, er liep iets fout!',
      description: [
        'We konden je bericht niet ontvangen.',
        'Probeer het gerust nog eens opnieuw.',
        'Of contacteer onze helpdesk op <a href="mailto:kabas@diekeure.be">kabas@diekeure.be</a>',
      ],
      cta: 'Probeer opnieuw',
      img: 'contact-error',
    },
    success: {
      header: 'Bedankt om ons te contacteren!',
      description: ['We hebben je bericht ontvangen.', 'We nemen zo snel mogelijk contact met je op!'],
      cta: 'Stuur een nieuwe vraag',
      img: 'contact-success',
    },
  };

  private fb = inject(UntypedFormBuilder);

  ngOnInit() {
    this.buildForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    const { user } = changes;

    if (user && !user.firstChange) {
      if (user.currentValue !== null) {
        const { displayName: name, email, id: personId } = user.currentValue;
        this.form.patchValue({ name, email, personId });
      } else {
        this.reset();
      }
    }
  }

  private buildForm(): void {
    this.form = this.fb.group({
      name: ['', Validators.required],
      schoolName: [''],
      email: ['', [Validators.required, Validators.email]],
      phone: [''],
      subject: [this.subjects[0], Validators.required],
      message: ['', Validators.required],
      personId: [null],
    });
  }

  public submit() {
    if (this.form.invalid) return;
    this.setFormControlsState('disable');
    this.submitForm.emit(this.form.value);
    this.state = StateEnum.PROCESSING;
  }
}
