import { Injectable } from '@angular/core';
import { OauthAdapter } from './oauth.adapter';

@Injectable({
  providedIn: 'root',
})
export class FacebookAdapter extends OauthAdapter {
  protected provider = 'facebook';

  constructor() {
    super();
  }
}

