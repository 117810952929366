import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { MenuItemComponent, MenuItemInterface } from '../../menu/menu-item/menu-item.component';
import { CDS_MENU_ITEM_PARENT } from '../../menu/menu.component';
import { SelectComponent } from '../select.component';

export class SelectOptionSelectionChange {
  constructor(public source: SelectOptionComponent, public isUserInput = false) {}
}

@Component({
  selector: 'campus-select-option',
  templateUrl: './select-option.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: CDS_MENU_ITEM_PARENT, useExisting: forwardRef(() => SelectComponent) }],
})
export class SelectOptionComponent<T = any> extends MenuItemComponent implements MenuItemInterface<T> {
  role = 'option';
}
