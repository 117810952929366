import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { FaqInterface, FaqItemService } from '@campus/public-portal';

import { of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SEOMappingGuard {
  private faqItemService = inject(FaqItemService);
  private router = inject(Router);

  private DEFAULT_ROLE = 'teacher';
  private FAQ_URL = 'faq';

  canActivate(route: ActivatedRouteSnapshot) {
    const { category, slug } = route.params;
    return this.faqItemService.getBySlug$(slug).pipe(
      switchMap((faqItem) => this.redirectToFaqItem(faqItem)),
      catchError(() => of(this.redirectToFaqCategoryWithError(slug, category)))
    );
  }

  private redirectToFaqItem(faqItem: FaqInterface) {
    let valid = false;
    let role = this.DEFAULT_ROLE;

    switch (true) {
      case faqItem.forStudents:
        role = 'student';
        valid = true;
        break;
      case faqItem.forSchoolAdmins:
        role = 'schooladmin';
        valid = true;
        break;
      case faqItem.forTeachers:
        role = 'teacher';
        valid = true;
        break;
      default:
        break;
    }

    if (!valid) {
      return throwError('No FaqItem found.');
    }

    const url = this.router.createUrlTree([this.FAQ_URL], {
      queryParams: {
        slug: faqItem.slug,
        role,
      },
    });

    return this.router.navigateByUrl(url);
  }

  private redirectToFaqCategoryWithError(slug: string, category: string) {
    const url = this.router.createUrlTree([this.FAQ_URL], {
      fragment: category,
      queryParams: {
        slug,
        error: 'faq-item-not-found',
        role: this.DEFAULT_ROLE,
      },
    });

    return this.router.navigateByUrl(url);
  }
}

export function redirectIfOldUrl(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  return inject(SEOMappingGuard).canActivate(next);
}
