<div class="[ container p-bs-3xl ]">
  <div class="[ relative h-0 p-bs-1/2 ]">
    <svg class="[ absolute top-0 left-0 ]" viewBox="0 0 995.17 451.5" id="legals-hero--background">
      <path
        d="M721.59,83.62A223.54,223.54,0,0,0,665.2,90.8C619.1,35.87,547.06.51,466.08.51,346,.51,245.52,78.28,220.25,182.38a53.69,53.69,0,0,0-10.23-1c-27.41,0-49.63,20.56-49.63,45.93a43,43,0,0,0,1,9.2c-46.28,11-82.32,45.67-92.18,89.24a109.65,109.65,0,0,0,15.15,84.09H869c24.6-23.08,42.92-51.9,52.4-84.09a178.47,178.47,0,0,0,7.27-50.51C928.66,169.42,836,83.62,721.59,83.62Z"
        style="fill: #64b6b9"
      />
      <path
        d="M450.57,340a1.89,1.89,0,0,1,2.11,2.69,56.66,56.66,0,0,1,4,5.2,29,29,0,0,1,.29-7,1.82,1.82,0,0,1-.92-1.21,1.87,1.87,0,1,1,2.25,1.4,2,2,0,0,1-.34,0,30.78,30.78,0,0,0-.08,8.59A81.26,81.26,0,0,1,467,371l-1,.24a75.27,75.27,0,0,0-3.38-9.89c-.7-1.69-1.56-3.63-2.6-5.67a19.91,19.91,0,0,0-6.95-.86,1.86,1.86,0,0,1-1.43,1.59,1.87,1.87,0,1,1,1.28-2.6,22,22,0,0,1,6.45.62,60.3,60.3,0,0,0-7.38-11,1.88,1.88,0,1,1-1.41-3.43Z"
        style="fill: #a25a6e"
      />
      <path
        d="M471.89,355.66a19.8,19.8,0,0,0-6.2-3.25,1.87,1.87,0,1,1,.22-1,21.62,21.62,0,0,1,5.81,2.86,59.73,59.73,0,0,0-3-12.9,1.66,1.66,0,0,1-.59,0,1.88,1.88,0,1,1,2.11-1.62,1.92,1.92,0,0,1-.6,1.15,56,56,0,0,1,1.87,6.27,28.9,28.9,0,0,1,2.72-6.41,1.85,1.85,0,0,1-.43-1.46,1.87,1.87,0,1,1,1.62,2.1l-.34-.08a30.78,30.78,0,0,0-3.1,8,77.3,77.3,0,0,1,1.39,12.5,76.27,76.27,0,0,1-.33,10.6l-1-.12a77.19,77.19,0,0,0,.32-10.45C472.27,360.05,472.14,357.94,471.89,355.66Z"
        style="fill: #a25a6e"
      />
      <path
        d="M227.32,344.74a40.42,40.42,0,0,0-9.42,9.47l-.27-.44c-2.35-3.72-5.1-7.85-8.24-12.22A40.51,40.51,0,0,0,220,332.9,39.9,39.9,0,0,0,227.18,320a40,40,0,0,0-11.57,9.18,40.57,40.57,0,0,0-6.77,11.58c-2.62-3.61-5.5-7.37-8.64-11.19a40.77,40.77,0,0,0,7.94-11,40.18,40.18,0,0,0,3.69-14.3A40,40,0,0,0,203,316.14a40.67,40.67,0,0,0-3.54,12.55c-3.16-3.79-6.57-7.63-10.22-11.42a40.44,40.44,0,0,0,6.17-12.08,39.92,39.92,0,0,0,1.47-14.69,39.81,39.81,0,0,0-6.92,13,40.59,40.59,0,0,0-1.6,12.84c-2.48-2.54-5.07-5.05-7.77-7.5a40.65,40.65,0,0,0,1.14-13.49,40,40,0,0,0-4.19-14.16A40.11,40.11,0,0,0,176,295.92a40.69,40.69,0,0,0,3,11.56q-2.87-2.55-5.9-5a38.1,38.1,0,0,0-20.87-16,39.92,39.92,0,0,0,9.35,11.43,40.41,40.41,0,0,0,10.21,6q3.13,2.52,6.1,5.16a40.36,40.36,0,0,0-12.84,1.19,39.88,39.88,0,0,0-13.25,6.5,40,40,0,0,0,14.72-1A40.43,40.43,0,0,0,178.87,310q4.08,3.69,7.82,7.52a40.42,40.42,0,0,0-12.48,5,39.75,39.75,0,0,0-10.8,10.05,39.84,39.84,0,0,0,13.81-5.21,40.39,40.39,0,0,0,10.12-9.14c3.77,3.87,7.28,7.81,10.52,11.7a38.19,38.19,0,0,0-24.45,12.89,39.94,39.94,0,0,0,14.22-4,40.57,40.57,0,0,0,10.85-8.17c3.18,3.85,6.09,7.65,8.74,11.29a40.54,40.54,0,0,0-12.86,3.36,39.9,39.9,0,0,0-12,8.64,40,40,0,0,0,14.35-3.46,40.47,40.47,0,0,0,11.08-7.71c3.09,4.31,5.81,8.39,8.13,12.06l.34.55a38.23,38.23,0,0,0-24.21,15.2,38.27,38.27,0,0,0,24.38-14.93,212.73,212.73,0,0,1,14.35,26.59l1.81-.76a214.2,214.2,0,0,0-14.18-26.36,40.61,40.61,0,0,0,12.15-5.66,40,40,0,0,0,10.22-10.66A39.81,39.81,0,0,0,227.32,344.74Z"
        style="fill: #a48661"
      />
      <path
        d="M296.93,339.41a58.05,58.05,0,0,0-17.29,8.34c-.06-.24-.11-.47-.17-.72-1.41-6.16-3.17-13.09-5.34-20.52a58.25,58.25,0,0,0,18.46-6.68,57.32,57.32,0,0,0,16-14,57.35,57.35,0,0,0-20.07,6.93,58,58,0,0,0-14.72,12.48c-1.83-6.15-3.95-12.63-6.39-19.31a58.35,58.35,0,0,0,16-11.13,57.41,57.41,0,0,0,11.84-17.63A57.59,57.59,0,0,0,277.58,289a58.31,58.31,0,0,0-10.8,15.34c-2.47-6.66-5.27-13.49-8.42-20.37a58.56,58.56,0,0,0,14.15-13.45,57.37,57.37,0,0,0,9-19.23,57.45,57.45,0,0,0-15.62,14.38,58.19,58.19,0,0,0-8.3,16.66q-3.23-6.94-7-13.89a58.12,58.12,0,0,0,8-17.76,57.42,57.42,0,0,0,1.07-21.21,54.79,54.79,0,0,0-10.52,36.32q-2.69-4.81-5.62-9.58a58.07,58.07,0,0,0-6.43-15.95,57.46,57.46,0,0,0-14.25-15.74,54.73,54.73,0,0,0,18.2,33q3,4.92,5.81,9.91a58.47,58.47,0,0,0-18-4.52,57.38,57.38,0,0,0-21.09,2.5,57.5,57.5,0,0,0,20.46,5.68,58.13,58.13,0,0,0,19.52-2c2.52,4.63,4.86,9.29,7,13.93a58.43,58.43,0,0,0-19.3.77,57.55,57.55,0,0,0-19.47,8.49,55.05,55.05,0,0,0,39.34-8c3.26,7.06,6.14,14.07,8.68,20.9a58.07,58.07,0,0,0-19.2-.91A57.46,57.46,0,0,0,224.73,311a55,55,0,0,0,39.83-4.51c2.47,6.75,4.61,13.29,6.46,19.5a55,55,0,0,0-39.43,4.42,54.9,54.9,0,0,0,39.84-3c2.15,7.33,3.89,14.16,5.28,20.25l.21.91a58.28,58.28,0,0,0-20.65.58,57.43,57.43,0,0,0-19.47,8.48A55,55,0,0,0,277,349a307,307,0,0,1,6.78,42.95l2.81-.17A308,308,0,0,0,280,349.18a58.08,58.08,0,0,0,19.2-1.88,57.42,57.42,0,0,0,19-9.58A57.31,57.31,0,0,0,296.93,339.41Z"
        style="fill: #a48661"
      />
      <path
        d="M824.45,321.64c-.4,6.13,3.69,11.38,3.69,11.38s4.76-4.67,5.17-10.79-3.7-11.38-3.7-11.38S824.86,315.52,824.45,321.64Z"
        style="fill: #a48661"
      />
      <path
        d="M802,337.85c.61,6.11,5.52,10.62,5.52,10.62s3.92-5.4,3.31-11.5-5.53-10.62-5.53-10.62S801.4,331.75,802,337.85Z"
        style="fill: #a48661"
      />
      <path
        d="M853.88,317.58c-5.79,2-9,7.88-9,7.88s6.17,2.53,12,.49,9-7.89,9-7.89S859.67,315.54,853.88,317.58Z"
        style="fill: #a48661"
      />
      <path
        d="M839.72,334.08c-5.51-2.7-11.93-.91-11.93-.91s2.51,6.18,8,8.88,11.93.91,11.93.91S845.23,336.79,839.72,334.08Z"
        style="fill: #a48661"
      />
      <path
        d="M819.58,349.28c-5.51-2.7-11.93-.91-11.93-.91s2.52,6.17,8,8.88,11.93.91,11.93.91S825.1,352,819.58,349.28Z"
        style="fill: #a48661"
      />
      <path
        d="M775.49,386.52l1.09.61a169,169,0,0,1,19.73-26.82,144.11,144.11,0,0,1,23-20.95,93.76,93.76,0,0,1,30.17-14.83l-.33-1.21a95,95,0,0,0-30.58,15,145.4,145.4,0,0,0-23.18,21.14A171,171,0,0,0,775.49,386.52Z"
        style="fill: #a48661"
      />
      <path
        d="M775.21,279c3,7.26,10.77,10.95,10.77,10.95s2.78-8.09-.27-15.35S775,263.63,775,263.63,772.17,271.72,775.21,279Z"
        style="fill: #a48661"
      />
      <path
        d="M758.76,310.46c4.21,6.65,12.43,9,12.43,9s1.4-8.44-2.81-15.1-12.43-9-12.43-9S754.55,303.8,758.76,310.46Z"
        style="fill: #a48661"
      />
      <path
        d="M806.66,257.44c-5.47,5.66-5.81,14.21-5.81,14.21s8.53-.63,14-6.3,5.81-14.21,5.81-14.21S812.13,251.78,806.66,257.44Z"
        style="fill: #a48661"
      />
      <path
        d="M799.88,284.5c-7.88.06-14.22,5.8-14.22,5.8s6.42,5.65,14.3,5.59,14.22-5.79,14.22-5.79S807.75,284.45,799.88,284.5Z"
        style="fill: #a48661"
      />
      <path
        d="M785.48,313.49c-7.88.06-14.22,5.8-14.22,5.8s6.42,5.65,14.3,5.59,14.22-5.79,14.22-5.79S793.35,313.44,785.48,313.49Z"
        style="fill: #a48661"
      />
      <path
        d="M756.22,381.52l1.6.08a217.92,217.92,0,0,1,7.26-42.09,185.25,185.25,0,0,1,14.35-37.22c7.24-13.8,16-25.35,26.12-34.33l-1.06-1.19c-10.25,9.11-19.16,20.81-26.49,34.8a185.9,185.9,0,0,0-14.47,37.55A219.14,219.14,0,0,0,756.22,381.52Z"
        style="fill: #a48661"
      />
      <path d="M483.7,360.23a45.45,45.45,0,0,1,43.68,32.93H440A45.45,45.45,0,0,1,483.7,360.23Z" style="fill: #fac493" />
      <path
        d="M542.69,370.43a31.38,31.38,0,0,1,30.15,22.73H512.55A31.35,31.35,0,0,1,542.69,370.43Z"
        style="fill: #fac493"
      />
      <path d="M469.44,393.16c-5.87-20.3-29.12-35.47-56.91-35.47s-51,15.17-56.92,35.47Z" style="fill: #fac493" />
      <path d="M734.35,423H258.78a15.91,15.91,0,1,1,0-31.82H734.35a15.91,15.91,0,0,1,0,31.82Z" style="fill: #a7c1a9" />
      <path d="M640.1,418a13.81,13.81,0,0,1,13.28,10H626.82A13.81,13.81,0,0,1,640.1,418Z" style="fill: #a25a6e" />
      <path d="M655.64,421.1a9.54,9.54,0,0,1,9.17,6.91H646.48A9.53,9.53,0,0,1,655.64,421.1Z" style="fill: #a25a6e" />
      <path d="M276.59,362.15a45.8,45.8,0,0,0-44,33.2h88.06A45.8,45.8,0,0,0,276.59,362.15Z" style="fill: #a48661" />
      <path
        d="M225.06,372.44a31.61,31.61,0,0,0-30.39,22.91h60.77A31.6,31.6,0,0,0,225.06,372.44Z"
        style="fill: #a48661"
      />
      <path d="M290.55,395.35c5.92-20.47,29.36-35.76,57.37-35.76s51.45,15.29,57.37,35.76Z" style="fill: #a48661" />
      <path d="M214.26,385a22.23,22.23,0,0,1,21.37,16.11H192.89A22.24,22.24,0,0,1,214.26,385Z" style="fill: #a25a6e" />
      <path d="M239.27,390A15.35,15.35,0,0,1,254,401.11h-29.5A15.35,15.35,0,0,1,239.27,390Z" style="fill: #a25a6e" />
      <path d="M207.49,401.11c-2.88-9.93-14.25-17.35-27.84-17.35s-25,7.42-27.84,17.35Z" style="fill: #a25a6e" />
      <path d="M363.07,390.25a13.52,13.52,0,0,1,13,9.8h-26A13.51,13.51,0,0,1,363.07,390.25Z" style="fill: #90b193" />
      <path
        d="M299.73,385.62a19.92,19.92,0,0,1,19.14,14.43H280.58A19.92,19.92,0,0,1,299.73,385.62Z"
        style="fill: #90b193"
      />
      <path d="M359.14,400.05c-2.94-10.16-14.57-17.75-28.47-17.75s-25.54,7.59-28.48,17.75Z" style="fill: #90b193" />
      <circle cx="371.67" cy="205.3" r="4.74" style="fill: #fff" />
      <path
        d="M745.58,358.46a50.74,50.74,0,0,1,48.77,36.76H696.81A50.74,50.74,0,0,1,745.58,358.46Z"
        style="fill: #90b193"
      />
      <path d="M802.65,369.85a35,35,0,0,1,33.66,25.37H769A35,35,0,0,1,802.65,369.85Z" style="fill: #90b193" />
      <path d="M730.11,395.22c-6.55-22.66-32.5-39.59-63.53-39.59s-57,16.93-63.54,39.59Z" style="fill: #90b193" />
      <path d="M596,386a21,21,0,0,1,20.2,15.23H575.83A21,21,0,0,1,596,386Z" style="fill: #77607d" />
      <path
        d="M619.66,390.73a14.5,14.5,0,0,1,13.94,10.51H605.72A14.5,14.5,0,0,1,619.66,390.73Z"
        style="fill: #77607d"
      />
      <path d="M589.62,401.24c-2.71-9.39-13.47-16.4-26.32-16.4s-23.6,7-26.31,16.4Z" style="fill: #77607d" />
    </svg>

    <svg
      class="[ absolute top-0 left-0 ]"
      viewBox="0 0 995.17 451.5"
      *ngFor="let i of [1, 2, 3]"
      [id]="'legals-hero--clouds-' + i"
    >
      <path
        d="M286.59,188.36a11.74,11.74,0,0,1,11.28,8.51H275.31A11.74,11.74,0,0,1,286.59,188.36Z"
        style="fill: #b3cbd4"
      />
      <path d="M299.79,191a8.09,8.09,0,0,1,7.78,5.87H292A8.08,8.08,0,0,1,299.79,191Z" style="fill: #b3cbd4" />
      <path d="M283,196.87c-1.51-5.25-7.52-9.16-14.69-9.16s-13.18,3.91-14.7,9.16Z" style="fill: #b3cbd4" />
      <path d="M765.43,193.81a8.66,8.66,0,0,1,8.32,6.27H757.11A8.65,8.65,0,0,1,765.43,193.81Z" style="fill: #b3cbd4" />
      <path d="M775.16,195.75a6,6,0,0,1,5.74,4.33H769.42A6,6,0,0,1,775.16,195.75Z" style="fill: #b3cbd4" />
      <path d="M762.79,200.08c-1.12-3.87-5.54-6.76-10.84-6.76s-9.72,2.89-10.83,6.76Z" style="fill: #b3cbd4" />
      <path d="M400.34,118a17.57,17.57,0,0,1,16.88,12.72H383.45A17.57,17.57,0,0,1,400.34,118Z" style="fill: #b3cbd4" />
      <path
        d="M318.05,111.92a25.88,25.88,0,0,1,24.87,18.75H293.18A25.87,25.87,0,0,1,318.05,111.92Z"
        style="fill: #b3cbd4"
      />
      <path d="M395.23,130.67c-3.82-13.19-18.93-23-37-23s-33.18,9.86-37,23Z" style="fill: #b3cbd4" />
      <path d="M518,37a7.76,7.76,0,0,1,7.46,5.62H510.57A7.75,7.75,0,0,1,518,37Z" style="fill: #fff" />
      <path d="M481.68,34.32a11.44,11.44,0,0,1,11,8.28h-22A11.43,11.43,0,0,1,481.68,34.32Z" style="fill: #fff" />
      <path d="M515.77,42.6c-1.69-5.83-8.36-10.18-16.34-10.18S484.78,36.77,483.1,42.6Z" style="fill: #fff" />
      <path
        d="M382.83,126.79A11.62,11.62,0,0,1,394,135.2H371.67A11.62,11.62,0,0,1,382.83,126.79Z"
        style="fill: #b3cbd4"
      />
      <path d="M395.89,129.4a8,8,0,0,1,7.7,5.8h-15.4A8,8,0,0,1,395.89,129.4Z" style="fill: #b3cbd4" />
      <path d="M379.29,135.2c-1.5-5.18-7.44-9.06-14.54-9.06s-13,3.88-14.53,9.06Z" style="fill: #b3cbd4" />
      <path
        d="M724.6,150.77a12.24,12.24,0,0,1,11.77,8.88H712.83A12.24,12.24,0,0,1,724.6,150.77Z"
        style="fill: #b3cbd4"
      />
      <path d="M667.21,146.57a18.06,18.06,0,0,1,17.35,13.08H649.87A18,18,0,0,1,667.21,146.57Z" style="fill: #b3cbd4" />
      <path d="M721,159.65c-2.66-9.2-13.2-16.08-25.8-16.08s-23.13,6.88-25.79,16.08Z" style="fill: #b3cbd4" />
    </svg>
    <svg class="[ absolute top-0 left-0 ]" viewBox="0 0 995.17 451.5" id="legals-hero--contract">
      <defs>
        <clipPath id="legals-hero__clip-path-contract">
          <circle
            cx="516.38"
            cy="91.46"
            r="5.88"
            transform="translate(303.03 570.52) rotate(-76.04)"
            style="fill: none"
          />
        </clipPath>
      </defs>
      <path
        d="M516.38,91.46h0A37.1,37.1,0,0,1,558,123.36l4.87,36.78"
        style="fill: none; stroke: #7b7e8d; stroke-miterlimit: 10; stroke-width: 14.4843326250306px"
      />
      <polygon
        points="539.48 75.61 372.59 75.61 372.59 317.17 554.14 317.17 554.14 90.27 539.48 75.61"
        style="fill: #2d334a; opacity: 0.2"
      />
      <polygon
        points="532.69 67.64 365.8 67.64 365.8 309.21 547.35 309.21 547.35 82.3 532.69 67.64"
        style="fill: #fff"
      />
      <polygon points="532.69 82.3 547.35 82.3 532.69 67.64 532.69 82.3" style="fill: #dfe9ed" />
      <rect x="391.95" y="113.11" width="129.25" height="12.7" style="fill: #d5d6db" />
      <rect x="391.95" y="177.6" width="129.25" height="12.7" style="fill: #d5d6db" />
      <rect x="391.95" y="134.96" width="129.25" height="1.89" style="fill: #d5cfcc" />
      <rect x="391.95" y="143.56" width="129.25" height="1.89" style="fill: #d5cfcc" />
      <rect x="391.95" y="152.16" width="129.25" height="1.89" style="fill: #d5cfcc" />
      <rect x="391.95" y="160.76" width="88.5" height="1.89" style="fill: #d5cfcc" />
      <rect x="391.95" y="198.92" width="129.25" height="1.89" style="fill: #d5cfcc" />
      <rect x="391.95" y="207.52" width="129.25" height="1.89" style="fill: #d5cfcc" />
      <rect x="391.95" y="216.12" width="129.25" height="1.89" style="fill: #d5cfcc" />
      <rect x="391.95" y="224.73" width="88.5" height="1.89" style="fill: #d5cfcc" />
      <rect x="454.51" y="287.19" width="66.69" height="1.49" style="fill: #bcd2d9" />
      <rect x="391.95" y="243.7" width="93.23" height="1.94" style="fill: #fedadc" />
      <rect x="391.95" y="250.91" width="69.55" height="1.94" style="fill: #fedadc" />
      <rect x="391.95" y="265.92" width="69.55" height="1.94" style="fill: #fedadc" />
      <path
        d="M462.79,292.73s-1-20.9,12.33-28.83,4.66,33.48,0,32.6,3.53-22.28,9.06-22.53,1,12.21,2.14,11.33,6.67-10.77,6.93-7.33,0,8.21,2.76,6.57,5.72-3.06,6.13-1.4.67,1.66,3.94,1"
        style="
          fill: none;
          stroke: #6f728a;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 2.50539274340204px;
        "
      />
      <path
        d="M572,213.66l-77.56,10.26a10.33,10.33,0,0,1-11.6-8.89l-8.53-64.46a4,4,0,0,1,3.47-4.53l90.05-11.91a4,4,0,0,1,4.53,3.47l8.53,64.46A10.33,10.33,0,0,1,572,213.66Z"
        style="fill: #7b7e8d"
      />
      <path
        d="M572.37,137.6l8.53,64.46A10.32,10.32,0,0,1,572,213.65l-77.56,10.27a10.32,10.32,0,0,1-11.06-6.71c33,7,69.25-22,82.39-66.66a118.77,118.77,0,0,0,3.6-16.33A4,4,0,0,1,572.37,137.6Z"
        style="fill: #707484"
      />
      <circle
        cx="516.38"
        cy="91.46"
        r="5.88"
        transform="translate(303.03 570.52) rotate(-76.04)"
        style="fill: #eff2f5"
      />
      <path
        d="M489.34,169.87l-4.86-36.77a37.09,37.09,0,0,1,31.9-41.64"
        style="fill: none; stroke: #7b7e8d; stroke-miterlimit: 10; stroke-width: 14.4843326250306px"
      />
      <g style="clip-path: url(#legals-hero__clip-path-contract)">
        <path
          d="M516.38,91.46h0A37.1,37.1,0,0,1,558,123.36l4.87,36.78"
          style="fill: none; stroke: #7b7e8d; stroke-miterlimit: 10; stroke-width: 14.4843326250306px"
        />
        <path
          d="M489.34,169.87l-4.86-36.77a37.09,37.09,0,0,1,31.9-41.64"
          style="fill: none; stroke: #7b7e8d; stroke-miterlimit: 10; stroke-width: 14.4843326250306px"
        />
      </g>
      <circle cx="526.7" cy="169.45" r="5.88" style="fill: #484e62" />
      <path
        d="M533.87,196.19l-7.13.95a1.9,1.9,0,0,1-2.15-1.87l-.22-21.59a1.89,1.89,0,0,1,1.65-1.9l2-.26a1.9,1.9,0,0,1,2.09,1.41l5.4,20.9A1.9,1.9,0,0,1,533.87,196.19Z"
        style="fill: #484e62"
      />
    </svg>
    <svg class="[ absolute top-0 left-0 ]" viewBox="0 0 995.17 451.5" id="legals-hero--stars">
      <circle cx="331.57" cy="181.24" r="8.49" style="fill: #fff" />
      <circle cx="660.95" cy="183.72" r="8.49" style="fill: #fff" />
      <circle cx="614.71" cy="229.88" r="4.74" style="fill: #fff" />
      <circle cx="420.26" cy="320.48" r="4.74" style="fill: #fff" />
      <circle cx="448.41" cy="184.33" r="4.74" style="fill: #fff" />
      <circle cx="387.13" cy="331.21" r="8.49" style="fill: #fff" />
      <circle cx="461.13" cy="163.57" r="8.49" style="fill: #fff" />
    </svg>
    <svg class="[ absolute top-0 left-0 ]" viewBox="0 0 995.17 451.5" id="legals-hero--maestro">
      <polygon
        points="271.63 443.12 272.62 450.11 251.45 450.11 251.45 448.3 259.89 445.82 264.74 442.62 271.63 443.12"
        style="fill: #242e47"
      />
      <polygon
        points="304.15 443.12 303.15 450.11 316.88 450.11 316.88 448.3 311.04 442.62 304.15 443.12"
        style="fill: #242e47"
      />
      <path
        d="M282.89,320.53l-8.56,55.38a6.1,6.1,0,0,0,5.19,7h0a6,6,0,0,0,6.66-4.65L299,323.43a8.3,8.3,0,0,0-6-9.87h0A8.2,8.2,0,0,0,282.89,320.53Z"
        style="fill: #20294d"
      />
      <path
        d="M262.28,444.25l9.89.77,15.13-71a6.38,6.38,0,0,0-5.35-7.68h0a6.32,6.32,0,0,0-7,5.26Z"
        style="fill: #20294d"
      />
      <path
        d="M302.68,318.18l1.52,56a6.07,6.07,0,0,0,6.36,5.94h0a6.07,6.07,0,0,0,5.71-5.8L319,318.07a8.24,8.24,0,0,0-7.69-8.6h0A8.24,8.24,0,0,0,302.68,318.18Z"
        style="fill: #20294d"
      />
      <path
        d="M304,445l9.29-.38,3.21-74.76a6.35,6.35,0,0,0-6.56-6.66h0a6.34,6.34,0,0,0-6.05,6.39Z"
        style="fill: #20294d"
      />
      <path
        d="M368.08,222.3l-28,28.38a7.17,7.17,0,0,0-.07,10h0a7,7,0,0,0,10.95-1.09l21.46-33.53,6.26-1.51c2.26-.63,6-3.16,3.51-5.78h0a3.94,3.94,0,0,0-5.88-1.91Z"
        style="fill: #f2a370"
      />
      <path
        d="M301.13,258.19l.1,1a7,7,0,0,0,7.46,6.31l36.74-3a7,7,0,0,0,6.35-8.13h2.09a7,7,0,0,0-7.23-5.94l-38.93,2A7,7,0,0,0,301.13,258.19Z"
        style="fill: #f2a370"
      />
      <path
        d="M330.87,248.91l.7,16.86-21.58.91a8.38,8.38,0,0,1-8.68-8.07h0a8.39,8.39,0,0,1,8-8.78Z"
        style="fill: #764696"
      />
      <path
        d="M282.76,298.23v17.38A15.32,15.32,0,0,0,298,331h7.89a13.44,13.44,0,0,0,13.37-13.53l-.79-19.26Z"
        style="fill: #20294d"
      />
      <path
        d="M271.13,266.73,283,317.85l36.23-5.54-1.79-53a10.66,10.66,0,0,0-12-9.45L280,253.36A10.76,10.76,0,0,0,271.13,266.73Z"
        style="fill: #764696"
      />
      <path
        d="M283.36,327.09a16.17,16.17,0,0,0-1.25-3.08l-17.52-33a6.81,6.81,0,0,0-9.2-2.88h0a7,7,0,0,0-2.07,10.65l24.9,29.71-.55,5.59c-.06,2.31-.45,5.62,1.83,5.45l1.95,1.74c2.45-.19,4.26-4.8,3.56-7.19Z"
        style="fill: #f2a370"
      />
      <path
        d="M292.34,261.92h0a7.22,7.22,0,0,1-7.41-7.05l-.67-22.5,14.38-.44.67,22.5A7.23,7.23,0,0,1,292.34,261.92Z"
        style="fill: #f2a370"
      />
      <path d="M301.68,212.76c2.55,4.24,3.37,13.13-.08,15.58s-8.93-14.05-8.93-14.05Z" style="fill: #943f2e" />
      <path
        d="M285.77,213.79c8.66-5.48,13.35-2.13,15.91,5s4,20.72-.72,22.75-13.29,0-15.73-7.29S280.89,216.87,285.77,213.79Z"
        style="fill: #f2a370"
      />
      <path
        d="M301.75,207.25c5.37,4.55,4.38,8-.32,9a61.6,61.6,0,0,1-10.4,1s-2,15.31,1.6,16.51,3.9-3.12,10.52-2.6c4.06.32,4.08,13-.34,14s-12.47-1.58-13.76-5.52.79-13.64-1.56-16.15-4-.38-3.67,1.67,1.09,11.35,1.09,11.35-4.5-9.48-4.82-15.55,1.55-11.47,7.81-11.31,7.24,2,10.85.82S301.75,207.25,301.75,207.25Z"
        style="fill: #943f2e"
      />
      <path
        d="M282.37,255.88l-.85-.49a6.76,6.76,0,0,0-9.17,2.43l-19.6,32.91a6.91,6.91,0,0,0,2.79,9.7h0A6.75,6.75,0,0,0,264.3,298l20.44-32.41A6.92,6.92,0,0,0,282.37,255.88Z"
        style="fill: #f2a370"
      />
      <path
        d="M274,284.32l-13.86-8.6,11-18.17a8.08,8.08,0,0,1,11.18-2.71h0A8.29,8.29,0,0,1,285,266.16Z"
        style="fill: #764696"
      />
      <path d="M380.16,219.09s3.77-3.82,6.15-5.89.86-3.16.86-3.16L376,216.91l-6.69,6.1" style="fill: #f2a370" />
    </svg>
    <svg class="[ absolute top-0 left-0 ]" viewBox="0 0 995.17 451.5" id="legals-hero--miss">
      <defs>
        <clipPath id="legals-hero__clip-path-miss">
          <path
            d="M698.42,318.62a4.45,4.45,0,0,1,3,5.47l-2.48,8.85a4.47,4.47,0,0,1-5.41,3.12h0l-.07,0h0l-1.23-.34v0L601.3,307.7,527.49,287l3.65-13L605,294.72l93.48,23.84Z"
            style="fill: none"
          />
        </clipPath>
      </defs>
      <path
        d="M719.53,425.29s4.69,6,4.73,6.84,3.48,8.1-6.17,12c-2.32.94,4.21-1.32,4,.43s-.4-1.76,3.09-2.49.44,4.93,2.7,2.95a63.76,63.76,0,0,1,6.43-4.81c1.77-1.18,1.44-2.2.78-3.68s-4.22-5.24-5-6.73-3.64-7.81-3.64-7.81S721.39,423.9,719.53,425.29Z"
        style="fill: #f2a370"
      />
      <path
        d="M708.81,325.88s-3.6,8.6-9.62,24.16a323.67,323.67,0,0,0-10.47,31.62c-.81,3.13,1.43,6.83,5.53,9s5.42,1.54,8.75-3.13,24.27-41.23,27.25-47.67-3.8-11.79-7.57-14.21S712.05,319.14,708.81,325.88Z"
        style="fill: #20294d"
      />
      <path
        d="M691.05,387.82c6,8.87,27.68,37.45,27.68,37.45a68.65,68.65,0,0,1,8.5-4.06s-7.37-16.93-12.11-25.24-8-13.51-11.42-16.58S684.27,377.78,691.05,387.82Z"
        style="fill: #20294d"
      />
      <path
        d="M629.86,302.59s-5.42-1.48-8.68-.76c-1,.23-1.11.08-1,.84s2.17.64,3.8,1S631,305.27,629.86,302.59Z"
        style="fill: #e69c6b"
      />
      <path
        d="M629.86,302.59a27.2,27.2,0,0,0-4.8.07c-3.16.66-6.76,1.19-8.93,1.77-1.3.35-3.5-.26-3.09.91a5.86,5.86,0,0,0,3.05,3.22c2.1,1,3.92,2.93,4.83,3.28,1.45.56,8.6-1.49,10.91-3.14C633.13,307.76,635.71,304.06,629.86,302.59Z"
        style="fill: #f2a370"
      />
      <path
        d="M667.46,304.83c-9.81,2.87-29.21,3.71-35.73,3.48-1.24,0-3.89-3.77.72-5.19,27.8-8.59,29.16-7.84,32.64-7.92S674.36,302.82,667.46,304.83Z"
        style="fill: #f2a370"
      />
      <path
        d="M703.29,281.44c-.19,2.35-3.17,4.78-8.14,9.09s-9.37,6.55-10.48,7.51a10.29,10.29,0,0,1-3.13,1.65c-1.18.55-2,2.4-3.47,3.36s-4.29,1.65-5.41,2.16-5.09-.45-7-3.87a8.31,8.31,0,0,1,.92-8.78c1.27-1.54,16.95-14.27,23.11-18.21S703.86,274.05,703.29,281.44Z"
        style="fill: #3c4696"
      />
      <path
        d="M676.43,301.4c-1.87,5.52-4.09,4.75-7.71,5.46s-4.43.4-6.76,1.07-20.1.07-21.34.87-6,0-5.71-2.9.57-4.48.65-5.09,1.38-1.41,4.32-2.08,27.17-6.21,31.93-6.68S677.27,298.93,676.43,301.4Z"
        style="fill: #3c4696"
      />
      <path d="M688.35,260.09s2.34,11.78,2.83,12.25,12.35,3.74,9.71-7l-4.35-18.91Z" style="fill: #e69c6b" />
      <path
        d="M696.78,236.67c-3.67-2.18-12.08-5.37-12.44-5.15s-3.2,6-4.47,11.71c-.31,1.38-2.72,6-2.77,6.84-.09,1.49,0,4.1,0,4.1s.26,5.26.54,5.63c1.28,1.69,8.73,1.1,9.37,1,7.5-1.12,9.33-11.53,9.79-13.92C697.31,244,696.78,236.67,696.78,236.67Z"
        style="fill: #f2a370"
      />
      <path
        d="M679.47,245.57c-.55-.22-2.81,1.32-3.14,2s.49,2.45.77,2.54a6.76,6.76,0,0,0,2.89-.62C680.16,249.14,679.78,245.7,679.47,245.57Z"
        style="fill: #f2a370"
      />
      <path d="M680.94,236.72h0C680.92,236.77,680.92,236.77,680.94,236.72Z" style="fill: #e73c59" />
      <path d="M680.94,236.72h0C680.92,236.77,680.92,236.77,680.94,236.72Z" style="fill: #e73c59" />
      <path
        d="M693.6,246c2.19-.5,5.83,16,5.79,14-.16-6.55,6.82-2.32,10.62-14.25,1.63-5.09.24-6.66-.51-10.82s-6.09-8.13-12.93-8.71-9.77,2.18-11.67,4.57a51.87,51.87,0,0,0-4,5.91,28,28,0,0,1,3.13,1,28.39,28.39,0,0,1,2.07-4.17,4.75,4.75,0,0,1,1.42-1.4,18.85,18.85,0,0,0-3.2,5.69,26.91,26.91,0,0,1,4.13,2.11"
        style="fill: #e73c59"
      />
      <path
        d="M705.07,222.89c-1.71.48-3.36.82-4.24,2.46s-.4,3.61,2.3,4.72,3.38,3.29,4.44,4.89,2,3.11,4.15,2.12,2.92-2.08,3-3.76C715.06,229.09,710,221.5,705.07,222.89Z"
        style="fill: #e73c59"
      />
      <path
        d="M693.3,244.23s2.8-.58,3.3,0-1.13,6.3-1.83,7-3.84.4-3.84.4A28.54,28.54,0,0,0,693.3,244.23Z"
        style="fill: #f2a370"
      />
      <path d="M693.34,244.23s2.55-1.4,4.17,0,1.06,5.75-2.36,7-2.6-3-2.6-3" style="fill: #f2a370" />
      <path
        d="M713.52,313.16s-.57,1.66-3.4,11.15a72.55,72.55,0,0,0-3.64,19.79c-.07,3,3.53,6.8,11.84,8.54,4.38.92,7.54,0,12.75-3.84,5.62-4.1,6.39-12.46,5.64-16.94-1.52-9-3-15.79-3-15.79S724.09,304.64,713.52,313.16Z"
        style="fill: #f2a370"
      />
      <path
        d="M736.88,333c1.38,13.52-7.76,17.17-7.76,17.17s-.36-3.7-16.35-5.54c-4.43-.51-6.63,1.05-6.63,1.05S706.05,338.6,709,328a40.86,40.86,0,0,0,7.19,2.33C721.56,331.77,736.88,333,736.88,333Z"
        style="fill: #38368b"
      />
      <path
        d="M703.36,275.37c7.19,7,17.08,34,17.75,35.41s-3.34,7.71-8,6.4c-1.3-.37-18.19-32.07-19.2-34.57C691.14,275.73,699.67,271.76,703.36,275.37Z"
        style="fill: #e69c6b"
      />
      <path
        d="M712.1,313.56s2.82,2.78,5.36,3.48c2.2.62,15.71.13,17.76,1.08,0,0,2.63,18.48,2.66,22.16.13,13.43-9,14.69-18,12.81s-14.77-3-14.87-6.53,1.11-11,3.19-18.47C710.13,321.11,711.81,314.59,712.1,313.56Z"
        style="fill: #20294d"
      />
      <path d="M709.72,325.78c-.71.37.35,2.39,1.06,2S710.43,325.41,709.72,325.78Z" style="fill: #d6bdb1" />
      <path
        d="M712.36,327a16.38,16.38,0,0,0,4.36-1.43s3,4.82,2.94,6.76-4,1.06-4,1.06S712.87,327.8,712.36,327Z"
        style="fill: #203565"
      />
      <path
        d="M735.67,321.52h-2.38s.84,8.31,1.09,8.92,2.75,3.79,2.75,3.79S736,323.88,735.67,321.52Z"
        style="fill: #203565"
      />
      <path
        d="M698.66,264.17s-7.95,6.09-9.26,8.87c-2.29,4.84-2.4,27.76-.72,32.19s7,5.67,17.85,26c.42.79,5,3.5,10.35.68,11.09-5.81,16.85-2.9,19.77-3.46s-3.74-16.76-5.5-19.19S718,281.48,714,276.18a101.59,101.59,0,0,0-7.51-8.91S701.67,261.43,698.66,264.17Z"
        style="fill: #38368b"
      />
      <path
        d="M687.45,282.7c-.47,4.25,0,8.51,4.45,9a14.85,14.85,0,0,0-.24,2.91,10.22,10.22,0,0,0,4.76,8.31,12.79,12.79,0,0,0,0,3.39,8.46,8.46,0,0,0,5.54,6.36c0,3.91.09,7.78,4,9a1.21,1.21,0,0,0,.48.24s0,0,0,.07a8.08,8.08,0,0,0,.22,2.61,3.67,3.67,0,0,0,.77,1.8c.6,1,1.8,2.41,3,2.63a1.09,1.09,0,0,0,1.3-1.1,1.18,1.18,0,0,0,1.47-1.17,7.28,7.28,0,0,0-4.24-6.46c0-.2,0-.39,0-.54a11.68,11.68,0,0,0-.39-4.12c-.77-2.14-2.64-3.47-4.55-4.54a15.63,15.63,0,0,0-.41-4.5,8.05,8.05,0,0,0-4.64-5,15.23,15.23,0,0,0,.4-4.37,8.54,8.54,0,0,0-4.95-7.2,12.15,12.15,0,0,0,.12-1.59c0-3.29-2.19-5.35-4.71-7.06.37-2.17.9-4.19,1.07-5.18a1.14,1.14,0,0,0-2.24-.4c-.2,1.16-.56,2.73-.86,4.43m23.09,46.57h-.07c.13,0-.58-.55-.58-.55a4.54,4.54,0,0,1-.38-.38,1,1,0,0,0-.42-.68,1.07,1.07,0,0,1-.19-.18,4.48,4.48,0,0,1-.43-1.12,5.25,5.25,0,0,1-.07-1.07A5.09,5.09,0,0,1,710.91,326.78Zm-21.37-42.85c1.63,1.28,2.88,2.84,2.71,5.2l0,.33C689.82,289.31,689.34,286.79,689.54,283.93Zm4.42,9.7a10.12,10.12,0,0,1,.13-1.35c3.19,1.53,3,4.76,2.64,8.07A8.13,8.13,0,0,1,694,293.63Zm7.92,16.53a6.26,6.26,0,0,1-3.24-6.28C701.09,305.08,701.71,307.48,701.88,310.16Zm2.2,3.61a6.38,6.38,0,0,1,2,2,7,7,0,0,1,.63,3.69C704.68,318.77,704.2,316.39,704.08,313.77Z"
        style="fill: #3c4696"
      />
      <path
        d="M725.94,301.35s3.64,7.87-19.56-2.47c-5.38-2.4-19.44-19.47-14-27.77,4.54-6.94,14.51-6.51,14.51-6.51l3.42,7.95s-4.16-5.46-11.72-1.13,2.16,14.3,2.16,14.3,11.17,15.86,23.5,11.65"
        style="fill: #229fa2"
      />
      <path
        d="M709.74,262.25l-.88-1.56c-.53-.93-.46-2,.15-2.3l.19-.11c.61-.34,1.53.13,2,1.06l1.09,1.91.76-.43-1.49-2.62c-.86-1.52-2.36-2.29-3.36-1.73l-.3.17c-1,.57-1.11,2.25-.25,3.77l1.28,2.27Z"
        style="fill: #41b8ba"
      />
      <path
        d="M708.58,259a1,1,0,0,1,.43-.56l.19-.11a.92.92,0,0,1,.23-.09,3,3,0,0,1,2.68.89l-.5-.88c-.86-1.52-2.36-2.29-3.36-1.73l-.3.17c-1,.57-1.11,2.25-.25,3.77l.53.94A3.92,3.92,0,0,1,708.58,259Z"
        style="fill: #94d0d0"
      />
      <path
        d="M735,277.19h.55a1.6,1.6,0,0,1,1.6,1.6v13a1.6,1.6,0,0,1-1.6,1.6h-.23a2.82,2.82,0,0,1-2.82-2.82V279.68a2.49,2.49,0,0,1,2.49-2.49Z"
        transform="translate(1514.87 171.6) rotate(150.49)"
        style="fill: #41b8ba"
      />
      <path
        d="M730.52,278.34c.28.48,1.52,2.68,1.79,3.16l.41.73.55-.31-.41-.72c-.28-.49-1.52-2.69-1.8-3.17"
        style="fill: #bdbdbd"
      />
      <path
        d="M735.15,284.62c.33.59.06.69-.24.87s-.57.3-.87-.24-1.25-2.79-.94-3S734.81,284,735.15,284.62Z"
        style="fill: #3e3e3e"
      />
      <path
        d="M733.53,281.76c.34.59.07.7-.24.87s-.56.3-.87-.24-.07-.7.24-.87S733.2,281.17,733.53,281.76Z"
        style="fill: #2a2a2a"
      />
      <path
        d="M718.06,257.9h.71a11.34,11.34,0,0,1,11.34,11.34v35.19a3,3,0,0,1-3,3h-8.22a3,3,0,0,1-3-3V260.12A2.21,2.21,0,0,1,718.06,257.9Z"
        transform="translate(-45.45 392.75) rotate(-29.51)"
        style="fill: #229fa2"
      />
      <path
        d="M738.91,296.35l1.37,2.42a2.26,2.26,0,0,1-.85,3.07l-8.5,4.8a2.23,2.23,0,0,1-3.06-.84l-1.37-2.43"
        style="fill: #434343"
      />
      <path
        d="M711.85,260.9a6.51,6.51,0,0,0-1.17.35c-.46.21-1.1.56-1.1.56l9.78,17.29,2.12-1.2Z"
        style="fill: #41b8ba"
      />
      <path
        d="M710.38,261.37c.28.48,8.35,14.75,8.62,15.23l.41.73L720,277l-.41-.72c-.28-.49-8.31-14.69-8.59-15.17"
        style="fill: #bdbdbd"
      />
      <path
        d="M721.84,279.72c.33.59.06.69-.24.87s-.57.3-.87-.25-1.25-2.78-.94-2.95S721.5,279.12,721.84,279.72Z"
        style="fill: #3e3e3e"
      />
      <path
        d="M720.22,276.86c.34.59.07.7-.24.87s-.56.3-.87-.24-.06-.7.24-.87S719.89,276.27,720.22,276.86Z"
        style="fill: #2a2a2a"
      />
      <path
        d="M715.56,337.65s-6.27,7.1-17.14,20.08-19.26,22.92-21,25.64-.79,6.77,2.52,9.92,4.81,2.86,9.56-.63,37.91-33.59,42.89-38.82,6.93-14.84,4-18.1S720.89,332.2,715.56,337.65Z"
        style="fill: #20294d"
      />
      <path
        d="M677.26,391.44c5,9,18.85,37.82,18.85,37.82a72.46,72.46,0,0,1,9.08-2.9s-3.38-21.74-7.22-30.2-4.64-10.72-7.71-13.8S671.6,381.23,677.26,391.44Z"
        style="fill: #20294d"
      />
      <path
        d="M696.86,429.39s4.18,6.86,3.67,7.61-5.15,7.71-7.7,8.24-4.35.53-5.62,1.86.41.59,3.72,1.79,5.24-2,8.33-2.51,2.26,2.56,4.44,2.49,2.55-1.09,2.93-2.66-.19-6.52.11-8.15-2.48-11-2.48-11A42.94,42.94,0,0,0,696.86,429.39Z"
        style="fill: #f2a370"
      />
      <path
        d="M725.59,419.86c.32-.29,1.23-1.71,1.78-1.13s1.86,2.62,1.62,3.13a21.41,21.41,0,0,1-5.69,4.86c-2.08.95-3.57,2-4.06,1.39s-2.08-2.73-1.94-3.36,1.74-1,3.27-1.67A47.91,47.91,0,0,0,725.59,419.86Z"
        style="fill: #203565"
      />
      <path
        d="M703.63,424.65c.38-.22,1.67-1.43,2-.74s1,3,.59,3.46a23.25,23.25,0,0,1-6.83,3.57c-2.24.49-4,1.23-4.24.5s-1.15-3.15-.83-3.75,1.93-.58,3.59-.94A52.79,52.79,0,0,0,703.63,424.65Z"
        style="fill: #203565"
      />
      <path
        d="M729.42,428.22a89.82,89.82,0,0,1,6.22,8.92,12.54,12.54,0,0,1,.47,2.47l.17,2.09-3.68,1.9s-1.44-1-2.54-.32c-2,1.27-2.57,2.88-3.52,3.63-1.41,1.12-11,2-11,2s-2.83-2.66.57-4.42a13.72,13.72,0,0,0,7.77-12.89Z"
        style="fill: #e73c59"
      />
      <path
        d="M707.46,435.47a28.62,28.62,0,0,1,0,10.4c-.12.64-.7,1.67-.86,2.3l-.68,1.52-4.24.4s-.8-1.44-2.13-1.21c-2.45.43-3.74,1.7-5,2.05-1.82.51-10.93-2.18-10.93-2.18s-1.21-3.45,2.72-3.82c9.91-.95,14.1-9.42,14.1-9.42Z"
        style="fill: #e73c59"
      />
      <path d="M682.21,235.18s-3.24,3.34-6,.32c0,0,2.17,4.1,7.33.68" style="fill: #e83d59" />
      <path d="M703.87,223.22s-4.08,2.25-5.84-1.44c0,0,.88,4.55,6.81,2.79" style="fill: #e83d59" />
      <rect
        x="566.37"
        y="247.28"
        width="13.49"
        height="90.98"
        transform="translate(136.16 765.26) rotate(-74.3)"
        style="fill: #2d334a"
      />
      <path
        d="M695.4,320.81l-1.78-.5,2.09-7.43a4.16,4.16,0,0,0-2.87-5.12l-54.46-15.31a4.17,4.17,0,0,0-5.11,2.83v0l-.65,2.31-1.78-.5.65-2.31a6,6,0,0,1,7.4-4.15L693.34,306a6,6,0,0,1,4.15,7.4Z"
        style="fill: #2d334a"
      />
      <path
        d="M688.33,324.87h18.12a0,0,0,0,1,0,0v.28a5.46,5.46,0,0,1-5.46,5.46h-7.2a5.46,5.46,0,0,1-5.46-5.46v-.28A0,0,0,0,1,688.33,324.87Z"
        transform="translate(193.14 910.41) rotate(-74.3)"
        style="fill: #fb9fa4"
      />
      <path
        d="M505.3,273.71h0a.71.71,0,0,0,.45.87l5,1.74a.71.71,0,0,0,.93-.48l.18-.64a.71.71,0,0,0-.54-.89l-5.16-1.11A.71.71,0,0,0,505.3,273.71Z"
        style="fill: #7b7e8d"
      />
      <polygon points="509.72 273.62 531.14 273.97 527.49 286.95 509.03 276.09 509.72 273.62" style="fill: #2d334a" />
      <polygon points="604.95 294.72 601.3 307.7 693.51 336.06 698.43 318.56 604.95 294.72" style="fill: #2d334a" />
      <g style="clip-path: url(#legals-hero__clip-path-miss)">
        <rect
          x="523.16"
          y="280.04"
          width="20.93"
          height="1.09"
          transform="matrix(0.27, -0.96, 0.96, 0.27, 119.09, 718.35)"
          style="fill: #fdc7ca"
        />
        <rect
          x="596.83"
          y="299.56"
          width="20.93"
          height="1.09"
          transform="translate(154.04 803.51) rotate(-74.3)"
          style="fill: #fdc7ca"
        />
        <rect
          x="685.51"
          y="326.77"
          width="20.93"
          height="1.09"
          transform="translate(192.52 908.73) rotate(-74.3)"
          style="fill: #9a9da8"
        />
      </g>
      <path
        d="M641.05,319.87s-5.79.62-8.66,2.55c-.89.59-1,.49-.68,1.19s2.33-.2,4-.42S643.16,322,641.05,319.87Z"
        style="fill: #f2a370"
      />
      <path
        d="M641.05,319.87a28.77,28.77,0,0,0-4.6,1.88c-2.8,1.83-5.54,3.43-7.42,4.82-1.12.83-1.77.82-.94,1.79.54.64.4.84,2.26-.09,1.08-.53,2.86,4.87,4.69,4.57,1.59-.25,8.61-5.35,10.21-7.81C646.15,323.63,647.25,319.09,641.05,319.87Z"
        style="fill: #f2a370"
      />
      <path
        d="M678.17,307.87c-8.39,6.47-26.79,14.58-33.16,16.82-1.21.43-5.17-2.17-1.26-5.28,23.58-18.76,25.18-18.55,28.5-19.94S684.07,303.33,678.17,307.87Z"
        style="fill: #f2a370"
      />
      <path
        d="M703.92,271.81c.71,2.34-1.25,5.8-4.42,11.83s-6.58,9.85-7.29,11.2-1.68,2.62-2.33,3.8a24.67,24.67,0,0,1-2.15,3.52c-1.09,1.49-3.51,3.21-4.4,4.12s-5.08,1.49-8.18-1.11-3.07-6.86-2.42-8.82,12.13-21.75,16.59-27.88S701.68,264.46,703.92,271.81Z"
        style="fill: #3c4696"
      />
      <path
        d="M685.53,301.18c.27,6-2.15,6.13-5.38,8.18s-4.13,2.06-6.12,3.57-19.37,7.64-20.26,8.88-5.8,2.29-6.6-.65-1.14-4.53-1.29-5.14.8-1.89,3.38-3.64,23.87-16.23,28.29-18.47S685.4,298.48,685.53,301.18Z"
        style="fill: #3c4696"
      />
    </svg>
  </div>
</div>
