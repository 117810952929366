import { trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  Directive,
  HostBinding,
  Input,
  OnChanges,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import {
  animateIcon,
  coinListAnim,
  coinMoveXAnim,
  coinMoveYAnim,
  easing,
  ParticlesAnimationEnum,
  toggleXpAnim,
} from '../animations';

@Directive({
  selector: '[campusXpCounterIcon], [xpCounterIcon], campus-xp-counter-icon',
})
export class XpCounterIconDirective {
  @HostBinding('class.ui-xp-counter__icon') hasXpCounterIconClass = true;
}

@Component({
  selector: 'campus-xp-counter',
  templateUrl: './xp-counter.component.html',
  styleUrls: ['./xp-counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('coinList', coinListAnim),
    trigger('coinMoveX', coinMoveXAnim),
    trigger('coinMoveY', coinMoveYAnim),
    trigger('animateIcon', animateIcon),
    trigger('showHide', toggleXpAnim),
  ],
})
export class XpCounterComponent implements OnChanges {
  @HostBinding('class.ui-xp-counter') hasXpCounterClass = true;

  @Input() amount = 0;

  @HostBinding('class.ui-xp-counter--read-only')
  @Input()
  readOnly = false;

  addedAmount = 0;
  isAddedAmountVisible = false;
  coinIsAnimating = false;

  particleAnimation = ParticlesAnimationEnum.NONE;
  particleEasing = easing.easeOutSine;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.amount) {
      this.addXp(changes.amount);
    }
  }

  animEnd(event) {
    if (event.toState === 'shown') this.isAddedAmountVisible = false;
  }

  coinListDone() {
    this.coinIsAnimating = false;
  }

  private addXp(changedAmount: SimpleChange) {
    const { previousValue = 0, currentValue = 0 } = changedAmount;
    this.addedAmount = currentValue - previousValue;
    if (!this.readOnly && !changedAmount.isFirstChange()) {
      this.coinIsAnimating = true;
      this.isAddedAmountVisible = true;
      this.particleAnimation = ParticlesAnimationEnum.EXPLODE;
    }
  }
}
