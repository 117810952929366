import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[campusCardHero], [campus-card-hero], campus-card-hero',
})
export class CardHeroDirective {
  @HostBinding('class.-m-be-xl')
  @Input()
  solo = false;

  @HostBinding('class')
  defaultClasses = ['frame', 'frame-169', 'corner-m', '-m-i-m', '-m-bs-m'];
}
