<div
  [ngClass]="dense ? ['stack-space-2xs', 'max-w-xs'] : ['stack-space-s', 'max-w-s']"
  class="[ stack surface items-center justify-center ] [ w-full m-auto text-center p-m corner-m ]"
>
  <campus-icon class="[ h-auto w-full ]" [svgIcon]="svgIcon" *ngIf="svgIcon"></campus-icon>
  <campus-svg class="[ h-auto w-full ]" [svgImage]="svgImage" *ngIf="svgImage && !svgIcon"></campus-svg>

  <div *ngIf="title" [ngClass]="dense ? ['title-small'] : ['title-medium']">{{ title }}</div>

  <div
    *ngIf="description"
    [ngClass]="dense ? ['label-small'] : ['label-medium']"
    class="[ on-surface text-opacity-medium ]"
    [innerHTML]="description"
  ></div>

  <button
    *ngIf="showCta && ctaLabel"
    [disabled]="disabled"
    filled-button
    data-cy="btn-empty-state"
    (click)="ctaClick()"
    [leadingIcon]="ctaIcon"
  >
    {{ ctaLabel }}
  </button>
</div>
