import { Values } from '../shared';

export const NavigableKeys = {
  ArrowDown: 'ArrowDown',
  ArrowLeft: 'ArrowLeft',
  ArrowUp: 'ArrowUp',
  ArrowRight: 'ArrowRight',
  Home: 'Home',
  End: 'End',
  Tab: 'Tab',
  PageUp: 'PageUp',
  PageDown: 'PageDown',
} as const;

export type NavigableKeys = typeof NavigableKeys[keyof typeof NavigableKeys];

export function isNavigableKey(code: string): code is Values<typeof NavigableKeys> {
  return Object.values(NavigableKeys).some((value) => value === code);
}
