import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { ChangeRole, CloseLoginForm, OpenLoginForm, loginFormIsOpen } from '../state';
import { UserType } from '../types';

@Injectable({
  providedIn: 'root',
})
export class FormFacade {
  private store = inject(Store);

  public loginForm$ = this.store.select(loginFormIsOpen);

  public setUserType(userType: UserType) {
    this.store.dispatch(new ChangeRole({ role: userType }));
  }

  public openLoginForm(userType?: UserType): void {
    if (userType) {
      this.setUserType(userType);
    }

    this.store.dispatch(new OpenLoginForm());
  }

  public closeLoginForm(): void {
    this.store.dispatch(new CloseLoginForm());
  }
}
