import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { WindowScrollService } from '@campus/browser';
import { ComponentsModule } from '@campus/components';
import { SvgModule } from '@campus/svg';
import { UiModule } from '@campus/ui';
import { AboutSectionComponent } from './components/about/about-section.component';
import { BaseFormComponent } from './components/base-form/base-form.component';
import { ContactSectionComponent } from './components/contact/contact-section.component';
import { FaqSectionComponent } from './components/faq/faq-section.component';
import { HeaderAvatarDirective, HeaderComponent } from './components/header/header.component';
import { HeroSectionComponent } from './components/hero/hero-section.component';
import { MaintenanceModeComponent } from './components/maintenance-mode/maintenance-mode.component';
import { NewsComponent } from './components/news/news.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { FaqHeroComponent } from './pages/faq/components/faq-hero/faq-hero.component';
import { FaqPageComponent } from './pages/faq/faq-page.component';
import { NotFoundHeroComponent } from './pages/not-found/components/not-found-hero.component';
import { PageNotFoundComponent } from './pages/not-found/not-found-page.component';
import { AuthService } from './services';
import { DAL_OPTIONS, DalOptions } from './services/api';
import { ApiAuth } from './services/api/internals';
import { AUTH_SERVICE } from './tokens';

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    SvgModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    UiModule,
    BrowserAnimationsModule,
    MatMenuModule,
    RouterModule,
    MatSnackBarModule,
  ],
  declarations: [
    FaqHeroComponent,
    HeroSectionComponent,
    FaqSectionComponent,
    AboutSectionComponent,
    VideoPlayerComponent,
    ContactSectionComponent,
    FaqPageComponent,
    ClickOutsideDirective,
    PageNotFoundComponent,
    NotFoundHeroComponent,
    HeaderComponent,
    HeaderAvatarDirective,
    NewsComponent,
    BaseFormComponent,
    MaintenanceModeComponent,
  ],
  exports: [
    HeroSectionComponent,
    FaqSectionComponent,
    AboutSectionComponent,
    VideoPlayerComponent,
    ContactSectionComponent,
    FaqPageComponent,
    NotFoundHeroComponent,
    PageNotFoundComponent,
    HeaderComponent,
    HeaderAvatarDirective,
    NewsComponent,
    BaseFormComponent,
    MaintenanceModeComponent,
  ],
  providers: [WindowScrollService, { provide: AUTH_SERVICE, useClass: AuthService }, ApiAuth],
})
export class PublicPortalModule {
  static forRoot(dalOptions: DalOptions) {
    return {
      ngModule: PublicPortalModule,
      providers: [
        {
          provide: DAL_OPTIONS,
          useValue: dalOptions,
        },
      ],
    };
  }
}
