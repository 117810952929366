<ng-container>
  <div class="ui-expansion-panel-scroll-target"></div>
  <ng-content select="campus-expansion-panel-header"></ng-content>

  <div
    class="ui-expansion-panel-content"
    *ngIf="isActive"
    @expandVisible
    (@expandVisible.start)="expandAnimationStart($event)"
    (@expandVisible.done)="expandAnimationDone($event)"
  >
    <div class="ui-expansion-panel-content__container">
      <ng-content select="campus-expansion-panel-content"></ng-content>
    </div>
  </div>
</ng-container>
