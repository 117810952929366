import { Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';

export interface ChipInterface {
  label: string;
  type?: any;
  icon?: string;
  className?: string;
}

@Component({
  selector: 'campus-capped-chip',
  templateUrl: './capped-chip.component.html',
})
export class CappedChipComponent implements OnChanges {
  public cappedChips: ChipInterface[];

  private chipSpacing = 6; // 6 to count extra space between ui elements

  @Input() public chips: ChipInterface[];
  @Input() public infoLabel?: string;
  @Input() public ctaIcon?: string;
  @Input() public ctaLabel?: string;
  @Input() public maxCharCount = 40;

  @HostBinding('class.ui-capped-chip')
  hasCappedChipClass = true;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.chips) {
      this.updateCappedChips();
    }
  }

  private updateCappedChips() {
    const chips = this.chips;

    let chipCharCount = 0;
    let nrOverCap = 0;
    const cappedChips = chips.reduce(
      (acc, chip, index) => {
        const currentChipCharCount = chip.label.length + this.chipSpacing;
        chipCharCount += currentChipCharCount;
        if ((index === 0 && currentChipCharCount >= this.maxCharCount) || chipCharCount < this.maxCharCount) {
          acc.visibleChips.push({ ...chip });
          return acc;
        }
        if (chipCharCount >= this.maxCharCount) {
          acc.overflow = { label: `+${++nrOverCap}`, type: '--overflow' };
        }

        return acc;
      },
      { overflow: null, visibleChips: [] }
    );

    this.cappedChips = [...cappedChips.visibleChips, cappedChips.overflow].filter((chip) => !!chip);
  }
}
