<div class="[ stack stack-space-m ]">
  <h4>{{ title }}</h4>

  <div class="[ cluster cluster-space-xl ]">
    <div>
      <div class="[ flex-1 stack stack-space-s ]">
        <div class="[ body-large ]">
          <ng-content></ng-content>
        </div>

        <a
          [href]="readMoreLink"
          target="_blank"
          class="[ inline-flex items-center gap-3xs ][ text-secondary ][ hover:gap-xs transition-spacing ]"
        >
          meer info
          <campus-icon svgIcon="arrow-right"></campus-icon>
        </a>
      </div>

      <campus-video-player
        *ngIf="videoId"
        [videoId]="videoId"
        [player]="player"
        class="[ min-w-3xs ]"
      ></campus-video-player>
    </div>
  </div>
</div>
