import { Injectable, inject } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { RegistrationCredentials } from '../interfaces';
import { PersonApi, PersonInterface } from '../services';
import { selectPortalRole } from '../state';
import { AUTH_SERVICE } from '../tokens';
import { LoginCredentials } from '../types';

@Injectable({
  providedIn: 'root',
})
export class AuthFacade {
  private authService = inject(AUTH_SERVICE);
  private personApi = inject(PersonApi);
  private store = inject(Store);

  public role$: Observable<string>;
  public user$: Observable<PersonInterface>;
  public isAuthenticated$: Observable<boolean>;
  public error$: Observable<string>;

  constructor() {
    this.role$ = this.store.select(selectPortalRole);
    this.user$ = this.authService.currentUser$;
    this.isAuthenticated$ = this.authService.isAuthenticated$;
    this.error$ = this.authService.error$;
  }

  private isUserInSync(user: PersonInterface): Observable<boolean> {
    return this.personApi.isUserInSync(user);
  }

  public login(credentials: LoginCredentials): void {
    this.authService.setAdapter(credentials.adapter).withCredentials(credentials).login();
  }

  public logout(): Observable<boolean> {
    return this.authService.logout();
  }

  public resetPassword(email: string): Observable<boolean> {
    return this.authService.resetPassword(email).pipe(catchError((error) => of(false)));
  }

  public register(credentials: RegistrationCredentials) {
    this.authService.setAdapter('basic').withCredentials(credentials).register();
  }

  public getCurrent(): Observable<PersonInterface> {
    return this.authService.getCurrent();
  }

  public isCurrentUserInSync(): Observable<boolean> {
    return this.user$.pipe(
      switchMap((user) => this.isUserInSync(user)),
      catchError((err) => of(false))
    );
  }
}
