import { take } from 'rxjs/operators';
import { OauthLoginUser } from '../../../state';
import { BaseAdapter } from './base.adapter';

export abstract class OauthAdapter extends BaseAdapter {
  protected provider: string;

  constructor() {
    super();
  }

  public login(): void {
    const returnUrl = encodeURIComponent(this.getReturnUrl());
    this.userType$
      .pipe(take(1))
      .subscribe((type) => {
        this.store.dispatch(new OauthLoginUser({
          authBase: this.environment.api.authBase,
          provider: this.provider,
          returnUrl,
          type
        }))
      });
  }

  private getReturnUrl(): string {
    return window.location.href;
  }
}
