import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
  inject
} from '@angular/core';
import { ExpansionPanelHeaderDirective } from './expansion-panel-header.directive';
import { ExpansionPanelStatusService } from './expansion-panel-status.service';

@Component({
  selector: 'campus-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss'],
  animations: [
    trigger('expandVisible', [
      transition(':enter', [
        style({ overflow: 'hidden', opacity: 0, height: 0 }),
        animate(
          '300ms cubic-bezier(.43,0,.31,1)',
          keyframes([style({ height: '*', offset: 0.75 }), style({ opacity: 1, offset: 1 })])
        ),
      ]),
      transition(':leave', [
        style({ overflow: 'hidden', opacity: 1, height: '*' }),
        animate(
          '300ms cubic-bezier(.43,0,.31,1)',
          keyframes([style({ opacity: 0, offset: 0.25 }), style({ height: 0, offset: 1 })])
        ),
      ]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpansionPanelComponent implements AfterContentInit, OnDestroy, AfterViewInit {
  @Input() value: number;

  @HostBinding('class.ui-expansion-panel--disabled')
  @Input()
  disabled = false;

  @HostBinding('class.ui-expansion-panel--readonly')
  @Input()
  readonly = false;

  @HostBinding('class.ui-expansion-panel--next-active')
  @Input()
  nextIsActive = false;

  @HostBinding('class.ui-expansion-panel--selected')
  @Input()
  isSelected = false;

  @HostBinding('attr.area-expanded')
  @HostBinding('class.ui-expansion-panel--active')
  @Input()
  isActive = false;

  @HostBinding('class.ui-expansion-panel') isExpansionPanelClass = true;

  @HostBinding('attr.data-cy')
  dataCy = 'expansion-panel';

  @Output()
  headerClicked: EventEmitter<ExpansionPanelComponent> = new EventEmitter();

  @Output()
  expandAnimationDone$: EventEmitter<boolean> = new EventEmitter();
  @Output()
  expandAnimationStart$: EventEmitter<boolean> = new EventEmitter();

  @ContentChild(ExpansionPanelHeaderDirective, { static: true })
  header: ExpansionPanelHeaderDirective;

  private statusService: ExpansionPanelStatusService = inject(ExpansionPanelStatusService);
  private panelId: string;

  constructor(private cd: ChangeDetectorRef, private elementRef: ElementRef) {
    this.panelId = this.statusService.registerPanel();
  }

  expandAnimationDone(evt) {
    this.expandAnimationDone$.emit(true);
  }
  expandAnimationStart(evt) {
    this.isSelected = evt.toState === null;
    this.expandAnimationStart$.emit(true);
  }

  ngAfterContentInit() {
    this.header.headerClick.subscribe(() => {
      if (!(this.readonly || this.disabled)) {
        this.isSelected = true;
        this.headerClicked.emit(this);
      }
    });
  }

  ngAfterViewInit() {
    this.statusService.notifyPanelLoaded(this.panelId);
  }

  ngOnDestroy() {
    this.header.headerClick.unsubscribe();
  }

  update(values: Partial<ExpansionPanelComponent>) {
    let runChangeDetection = false;
    const expansionChange = values.isActive !== this.isActive;

    Object.keys(values).forEach((key) => {
      const value = values[key];
      if (this[key] === value) return;

      this[key] = value;
      runChangeDetection = true;
    });

    if (runChangeDetection) this.cd.detectChanges();

    if (!expansionChange) {
      this.isSelected = false;
      this.expandAnimationStart$.emit(false);
      this.expandAnimationDone$.emit(false);
    }
  }

  scrollToTop() {
    this.elementRef.nativeElement.children[0].scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }
}
