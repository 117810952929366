import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Subject } from 'rxjs';
import { FileReaderServiceInterface } from './filereader.service.interface';

export function _fileReader() {
  return new FileReader();
}

export const FILE_READER = new InjectionToken<FileReader>('FileReaderToken', {
  providedIn: 'root',
  factory: _fileReader,
});

export enum FileReaderError {
  INVALID_FILETYPE = 'invalid filetype',
  READ_ERROR = 'invalid file',
}

export type FileReaderResult = string | ArrayBuffer | File;

@Injectable({
  providedIn: 'root',
})
export class FileReaderService implements FileReaderServiceInterface {
  loaded$ = new Subject<FileReaderResult>();
  error$ = new Subject<FileReaderError>();
  progress$ = new Subject<number>();

  constructor(@Inject(FILE_READER) private fileReader: FileReader) {
    this.setEventHandlers();
  }

  getFileReader(): FileReaderServiceInterface {
    return new FileReaderService(new FileReader());
  }

  // service methods
  isFileTypeAllowed(file: File, regex: RegExp): boolean {
    if (!file || !regex.test(file.name)) {
      this.error$.next(FileReaderError.INVALID_FILETYPE);
      return false;
    }

    return true;
  }

  reset(): void {
    this.fileReader.abort();
    this.loaded$.next(null);
    this.error$.next(null);
    this.progress$.next(null);
  }

  // filereader method implementations
  abort() {
    this.fileReader.abort();
  }
  readAsFile(blob: File): void {
    this.loaded$.next(blob);
  }
  readAsArrayBuffer(blob: Blob): void {
    this.fileReader.readAsArrayBuffer(blob);
  }
  readAsBinaryString(blob: Blob): void {
    this.fileReader.readAsBinaryString(blob);
  }
  readAsDataURL(blob: Blob): void {
    this.fileReader.readAsDataURL(blob);
  }
  readAsText(blob: Blob, encoding?: string): void {
    this.fileReader.readAsText(blob, encoding);
  }
  download(blob: Blob, filename: string): void {
    const url = URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.download = filename;

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    /**
     * TODO
     *
     * By observing chrome://blob-internals/, we can see that the Blob
     * remains in the blob store unless we call URL.revokeObjectURL(url) here. In
     * other words, download() is creating memory leaks by not clearing the url
     * to the blob store.
     *
     * However, we can't just do URL.revokeObjectURL because we have no certainty
     * that the file was downloaded entirely. You can't safely revoke it unless
     * you're certain it's fully done downloading.
     *
     * Ideally, we ditch having to use this method at all and ensure all our file
     * downloads happen through direct hyperlinks to GET-endpoints. That way, we
     * can put down a hyperlink in the page that refers to the API url which in
     * turn uses the browser to download the file. No blobs involved.
     *
     * Unfortunately, the problem is that GET doesn't take a body. So you'd have
     * to use the query parameters to send additional information like a list of
     * student ids to generate a PDF for. URLs have a much smaller max size than
     * POST-bodies so this isn't very practical.
     *
     * The current use cases shouldn't pose any problems since the downloaded
     * blobs are small in size. But the way we're doing it here is not technically
     * correct and will leak memory because we're retaining references to the blobs.
     *
     */
  }

  private setEventHandlers() {
    this.fileReader.onload = this.onload; // fired when a read has completed successfully
    this.fileReader.onabort = this.onabort; // fired when a read has been aborted, for example because the program called FileReader.abort()
    this.fileReader.onerror = this.onerror; // fired when the read failed due to an error
    this.fileReader.onloadstart = this.onloadstart; // fired when a read has started
    this.fileReader.onprogress = this.onprogress; // fired periodically as data is read
  }

  // event handlers
  // use arrow functions to maintain 'this' scope, because FileReader applies his own scope
  private onabort = (ev: ProgressEvent): void => {
    this.error$.next(FileReaderError.READ_ERROR);
  };
  private onerror = (ev: ProgressEvent): void => {
    this.fileReader.abort();
  };
  private onload = (ev: ProgressEvent): void => {
    this.loaded$.next((ev.target as FileReader).result);
  };
  private onloadstart = (ev: ProgressEvent): void => {
    this.loaded$.next(null);
  };

  private onprogress = (ev: ProgressEvent): void => {
    if (ev.lengthComputable) {
      const progress = (ev.loaded / ev.total) * 100;
      this.progress$.next(progress);
    }
  };
}
