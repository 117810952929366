import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[campusExpansionPanelHeaderIcon], [expansionPanelHeaderIcon], [campus-expansion-panel-header-icon]',
})
export class ExpansionPanelHeaderIconDirective {
  @HostBinding('attr.data-cy')
  dataCyAttribute = 'expansion-panel-header-icon';

  @HostBinding('class.ui-expansion-panel-header__icon')
  expansionPanelHeaderIconClass = true;

  @HostBinding('class.ui-expansion-panel-header__icon--disable-rotate')
  @Input()
  disableRotate: boolean;
}
