import { animate, AnimationMetadata, keyframes, state, style, transition } from '@angular/animations';
import { easing, sleep, speed } from './constants';

export const toggleXpAnim: AnimationMetadata[] = [
  state(
    'shown',
    style({
      opacity: 1,
      top: '0%',
    })
  ),
  state(
    'hidden',
    style({
      opacity: 0,
      top: '100%',
    })
  ),
  transition('hidden => shown', [animate(`${speed.normal} ${sleep.fast} ${easing.easeOutBack}`)]),
  transition('shown => hidden', [animate(`${speed.slow} ${sleep.readableSlow} ${easing.easeInBack}`)]),
];

export const animateIcon: AnimationMetadata[] = [
  state('false', style({ transform: 'scale(1) rotate(0)' })),
  state(
    'true',
    style({
      transform: 'scale(1.5) rotate(0)',
    })
  ),
  transition('false => true', [
    animate(
      `${speed.verySlow} ${sleep.normal} ${easing.easeOut}`,
      keyframes([
        style({
          transform: 'scale(1) rotate(0)',
          offset: 0,
        }),
        style({
          transform: 'scale(1.7) rotate(-30deg)',
          offset: 0.1,
        }),
        style({
          transform: 'scale(1.1) rotate(30deg)',
          offset: 0.2,
        }),
        style({
          transform: 'scale(1.6) rotate(-28deg)',
          offset: 0.3,
        }),
        style({
          transform: 'scale(1.2) rotate(28deg)',
          offset: 0.4,
        }),
        style({
          transform: 'scale(1.5) rotate(-25deg)',
          offset: 0.5,
        }),
        style({
          transform: 'scale(1.5) rotate(25deg)',
          offset: 0.6,
        }),
        style({
          transform: 'scale(1.5) rotate(-22deg)',
          offset: 0.7,
        }),
        style({
          transform: 'scale(1.5) rotate(22deg)',
          offset: 0.8,
        }),
        style({
          transform: 'scale(1.5) rotate(-20deg)',
          offset: 0.9,
        }),
        style({
          transform: 'scale(1.5) rotate(0)',
          offset: 1,
        }),
      ])
    ),
  ]),
  transition('true => false', [
    animate(
      `${speed.slow} ${easing.easeOut}`,
      keyframes([
        style({
          transform: 'scale(1) rotate(0)',
          offset: 0,
        }),
        style({
          transform: 'scale(0.75) rotate(0)',
          offset: 0.5,
        }),
        style({
          transform: 'scale(1.1) rotate(0)',
          offset: 0.7,
        }),
        style({
          transform: 'scale(0.9) rotate(0)',
          offset: 0.8,
        }),
        style({
          transform: 'scale(1.05) rotate(0)',
          offset: 0.95,
        }),
        style({
          transform: 'scale(1) rotate(0)',
          offset: 1,
        }),
      ])
    ),
  ]),
];
