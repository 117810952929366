/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FaqCategoryInterface } from '../interfaces';
import { ModelApiBase } from '../model-api.base';
import { PolpoApi } from '../polpo-api';

@Injectable({ providedIn: 'root' })
export class FaqCategoryApi extends ModelApiBase {
  constructor(api: PolpoApi) {
    super(api, 'FaqCategories');
  }

  find(options?: object): Observable<FaqCategoryInterface[]> {
    const path = '';

    const params = this._getHttpParams({ filter: options });

    return this.get<FaqCategoryInterface[]>(path, params);
  }
}
