import { Component, HostBinding, inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'campus-public-portal-not-found',
  styleUrls: ['./not-found-page.component.scss'],
  templateUrl: './not-found-page.component.html',
})
export class PageNotFoundComponent {
  private router = inject(Router);

  @HostBinding('class.kabas-not-found')
  hasKabasNotFoundClass = true;

  @HostBinding('class.-m-bs-3xl') hasMarginBlockStart3xlClass = true;
  @HostBinding('class') classList = 'm-b-0';

  goHome() {
    this.router.navigate(['/']);
  }
}
