import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ContextInterface, EduContentApi } from '../api';
import { ContentRequestServiceInterface } from './content-request.service.interface';

@Injectable({
  providedIn: 'root',
})
export class ContentRequestService implements ContentRequestServiceInterface {
  constructor(private educontentApi: EduContentApi) {}

  requestUrl(contentId: number, context?: ContextInterface): Observable<string> {
    return this.educontentApi.requestURLRemote(contentId, false, null, null, null, context || null).pipe(
      map((res) => {
        return res.url;
      })
    );
  }
}
