import { SurfacePositionControllerProperties } from './surface-position-properties.interface';

export class SurfacePositionController {
  private lastValues = {
    isOpen: false,
  } as SurfacePositionControllerProperties;

  constructor(private readonly getProperties: () => SurfacePositionControllerProperties) {}

  position() {
    const {
      surfaceEl,
      anchorEl,
      anchorCorner: anchorCornerRaw,
      surfaceCorner: surfaceCornerRaw,
      positioning,
      xOffset,
      yOffset,
      repositionStrategy,
    } = this.getProperties();
    const anchorCorner = anchorCornerRaw.toLowerCase().trim();
    const surfaceCorner = surfaceCornerRaw.toLowerCase().trim();

    if (!surfaceEl || !anchorEl) {
      return;
    }

    // Store these before we potentially resize the window with the next set of
    // lines
    const windowInnerWidth = window.innerWidth;
    const windowInnerHeight = window.innerHeight;

    const div = document.createElement('div');
    div.style.opacity = '0';
    div.style.position = 'fixed';
    div.style.display = 'block';
    div.style.inset = '0';
    document.body.appendChild(div);
    const scrollbarTestRect = div.getBoundingClientRect();
    div.remove();

    const blockScrollbarHeight = window.innerHeight - scrollbarTestRect.bottom;
    const inlineScrollbarWidth = window.innerWidth - scrollbarTestRect.right;

    surfaceEl.style.display = 'block';
    surfaceEl.style.opacity = '0';

    if ((surfaceEl as unknown as { popover: string }).popover && surfaceEl.isConnected) {
      (surfaceEl as unknown as { showPopover: () => void }).showPopover();
    }

    const surfaceRect = surfaceEl.getSurfacePositionClientRect
      ? surfaceEl.getSurfacePositionClientRect()
      : surfaceEl.getBoundingClientRect();
    const anchorRect = anchorEl.getSurfacePositionClientRect
      ? anchorEl.getSurfacePositionClientRect()
      : anchorEl.getBoundingClientRect();
    const [surfaceBlock, surfaceInline] = surfaceCorner.split('-') as Array<'start' | 'end'>;
    const [anchorBlock, anchorInline] = anchorCorner.split('-') as Array<'start' | 'end'>;

    // LTR depends on the direction of the SURFACE not the anchor.
    const isLTR = getComputedStyle(surfaceEl as HTMLElement).direction === 'ltr';

    /*
     * For more on inline and block dimensions, see MDN article:
     * https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_logical_properties_and_values
     *
     * ┌───── inline/blockDocumentOffset  inlineScrollbarWidth
     * │       │                                    │
     * │     ┌─▼─────┐                              │Document
     * │    ┌┼───────┴──────────────────────────────┼────────┐
     * │    ││                                      │        │
     * └──► ││ ┌───── inline/blockWindowOffset      │        │
     *      ││ │       │                            ▼        │
     *      ││ │     ┌─▼───┐                 Window┌┐        │
     *      └┤ │    ┌┼─────┴───────────────────────┼│        │
     *       │ │    ││                             ││        │
     *       │ └──► ││  ┌──inline/blockAnchorOffset││        │
     *       │      ││  │     │                    ││        │
     *       │      └┤  │  ┌──▼───┐                ││        │
     *       │       │  │ ┌┼──────┤                ││        │
     *       │       │  └─►│Anchor│                ││        │
     *       │       │    └┴──────┘                ││        │
     *       │       │                             ││        │
     *       │       │     ┌───────────────────────┼┼────┐   │
     *       │       │     │ Surface               ││    │   │
     *       │       │     │                       ││    │   │
     *       │       │     │                       ││    │   │
     *       │       │     │                       ││    │   │
     *       │       │     │                       ││    │   │
     *       │      ┌┼─────┼───────────────────────┼│    │   │
     *       │   ┌─►┴──────┼────────────────────────┘    ├┐  │
     *       │   │         │ inline/blockOOBCorrection   ││  │
     *       │   │         │                         │   ││  │
     *       │   │         │                         ├──►├│  │
     *       │   │         │                         │   ││  │
     *       │   │         └────────────────────────┐▼───┼┘  │
     *       │  blockScrollbarHeight                └────┘   │
     *       │                                               │
     *       └───────────────────────────────────────────────┘
     */

    // Calculate the block positioning properties
    let { blockInset, blockOutOfBoundsCorrection, surfaceBlockProperty } = this._calculateBlock({
      surfaceRect,
      anchorRect,
      anchorBlock,
      surfaceBlock,
      yOffset,
      positioning,
      windowInnerHeight,
      blockScrollbarHeight,
    });

    // If the surface should be out of bounds in the block direction, flip the
    // surface and anchor corner block values and recalculate
    if (blockOutOfBoundsCorrection) {
      const flippedSurfaceBlock = surfaceBlock === 'start' ? 'end' : 'start';
      const flippedAnchorBlock = anchorBlock === 'start' ? 'end' : 'start';

      const flippedBlock = this._calculateBlock({
        surfaceRect,
        anchorRect,
        anchorBlock: flippedAnchorBlock,
        surfaceBlock: flippedSurfaceBlock,
        yOffset,
        positioning,
        windowInnerHeight,
        blockScrollbarHeight,
      });

      // In the case that the flipped version would require less out of bounds
      // correcting, use the flipped corner block values
      if (blockOutOfBoundsCorrection > flippedBlock.blockOutOfBoundsCorrection) {
        blockInset = flippedBlock.blockInset;
        blockOutOfBoundsCorrection = flippedBlock.blockOutOfBoundsCorrection;
        surfaceBlockProperty = flippedBlock.surfaceBlockProperty;
      }
    }

    // Calculate the inline positioning properties
    let { inlineInset, inlineOutOfBoundsCorrection, surfaceInlineProperty } = this._calculateInline({
      surfaceRect,
      anchorRect,
      anchorInline,
      surfaceInline,
      xOffset,
      positioning,
      isLTR,
      windowInnerWidth,
      inlineScrollbarWidth,
    });

    // If the surface should be out of bounds in the inline direction, flip the
    // surface and anchor corner inline values and recalculate
    if (inlineOutOfBoundsCorrection) {
      const flippedSurfaceInline = surfaceInline === 'start' ? 'end' : 'start';
      const flippedAnchorInline = anchorInline === 'start' ? 'end' : 'start';

      const flippedInline = this._calculateInline({
        surfaceRect,
        anchorRect,
        anchorInline: flippedAnchorInline,
        surfaceInline: flippedSurfaceInline,
        xOffset,
        positioning,
        isLTR,
        windowInnerWidth,
        inlineScrollbarWidth,
      });

      // In the case that the flipped verion would require less out of bounds
      // correcting, use the flipped corner inline values
      if (Math.abs(inlineOutOfBoundsCorrection) > Math.abs(flippedInline.inlineOutOfBoundsCorrection)) {
        inlineInset = flippedInline.inlineInset;
        inlineOutOfBoundsCorrection = flippedInline.inlineOutOfBoundsCorrection;
        surfaceInlineProperty = flippedInline.surfaceInlineProperty;
      }
    }

    // If we are simply repositioning the surface back inside the viewport,
    // subtract the out of bounds correction values from the positioning.
    if (repositionStrategy === 'move') {
      blockInset = blockInset - blockOutOfBoundsCorrection;
      inlineInset = inlineInset - inlineOutOfBoundsCorrection;
    }

    surfaceEl.style.display = 'block';
    surfaceEl.style.opacity = '1';
    surfaceEl.style.position = positioning;
    surfaceEl.style[surfaceBlockProperty] = `${blockInset}px`;
    surfaceEl.style[surfaceInlineProperty] = `${inlineInset}px`;

    surfaceEl.style[surfaceBlockProperty === 'inset-block-start' ? 'inset-block-end' : 'inset-block-start'] = 'auto';
    surfaceEl.style[surfaceInlineProperty === 'inset-inline-start' ? 'inset-inline-end' : 'inset-inline-start'] =
      'auto';

    // In the case that we are resizing the surface to stay inside the viewport
    // we need to set height and width on the surface.
    if (repositionStrategy === 'resize') {
      // Add a height property to the styles if there is block height correction
      if (blockOutOfBoundsCorrection) {
        surfaceEl.style.height = `${surfaceRect.height - blockOutOfBoundsCorrection}px`;
      }

      // Add a width property to the styles if there is block height correction
      if (inlineOutOfBoundsCorrection) {
        surfaceEl.style.width = `${surfaceRect.width - inlineOutOfBoundsCorrection}px`;
      }
    }
  }

  async update() {
    const props = this.getProperties();
    let hasChanged = false;
    for (const [key, value] of Object.entries(props)) {
      // tslint:disable-next-line
      hasChanged = hasChanged || (value !== this.lastValues[key] && typeof value !== 'function');

      if (hasChanged) break;
    }

    const openChanged = this.lastValues.isOpen !== props.isOpen;
    const hasAnchor = !!props.anchorEl;
    const hasSurface = !!props.surfaceEl;

    if (hasChanged && hasAnchor && hasSurface) {
      // Only update isOpen, because if it's closed, we do not want to waste
      // time on a useless reposition calculation. So save the other "dirty"
      // values until next time it opens.
      this.lastValues.isOpen = props.isOpen;

      if (props.isOpen) {
        // We are going to do a reposition, so save the prop values for future
        // dirty checking.
        this.lastValues = props;

        this.position();
        props.onOpen();
      } else if (openChanged) {
        await props.beforeClose();
        this._close(props.surfaceEl);
        props.onClose();
      }
    }
  }

  private _close(surfaceEl: HTMLElement) {
    surfaceEl.style.display = 'none';
    surfaceEl.style.height = 'auto';
  }

  private _calculateBlock(config: {
    surfaceRect: DOMRect;
    anchorRect: DOMRect;
    anchorBlock: 'start' | 'end';
    surfaceBlock: 'start' | 'end';
    yOffset: number;
    positioning: 'absolute' | 'fixed' | 'document';
    windowInnerHeight: number;
    blockScrollbarHeight: number;
  }) {
    const {
      surfaceRect,
      anchorRect,
      anchorBlock,
      surfaceBlock,
      yOffset,
      positioning,
      windowInnerHeight,
      blockScrollbarHeight,
    } = config;
    // We use number booleans to multiply values rather than `if` / ternary
    // statements because it _heavily_ cuts down on nesting and readability
    const relativeToWindow = positioning === 'fixed' || positioning === 'document' ? 1 : 0;
    const relativeToDocument = positioning === 'document' ? 1 : 0;
    const isSurfaceBlockStart = surfaceBlock === 'start' ? 1 : 0;
    const isSurfaceBlockEnd = surfaceBlock === 'end' ? 1 : 0;
    const isOneBlockEnd = anchorBlock !== surfaceBlock ? 1 : 0;

    // Whether or not to apply the height of the anchor
    const blockAnchorOffset = isOneBlockEnd * anchorRect.height + yOffset;
    // The absolute block position of the anchor relative to window
    const blockTopLayerOffset =
      isSurfaceBlockStart * anchorRect.top +
      isSurfaceBlockEnd * (windowInnerHeight - anchorRect.bottom - blockScrollbarHeight);
    const blockDocumentOffset = isSurfaceBlockStart * window.scrollY - isSurfaceBlockEnd * window.scrollY;

    // If the surface's block would be out of bounds of the window, move it back
    // in
    const blockOutOfBoundsCorrection = Math.abs(
      Math.min(0, windowInnerHeight - blockTopLayerOffset - blockAnchorOffset - surfaceRect.height)
    );

    // The block logical value of the surface
    const blockInset =
      relativeToWindow * blockTopLayerOffset + relativeToDocument * blockDocumentOffset + blockAnchorOffset;

    const surfaceBlockProperty = surfaceBlock === 'start' ? 'inset-block-start' : 'inset-block-end';

    return { blockInset, blockOutOfBoundsCorrection, surfaceBlockProperty };
  }

  private _calculateInline(config: {
    isLTR: boolean;
    surfaceInline: 'start' | 'end';
    anchorInline: 'start' | 'end';
    anchorRect: DOMRect;
    surfaceRect: DOMRect;
    xOffset: number;
    positioning: 'absolute' | 'fixed' | 'document';
    windowInnerWidth: number;
    inlineScrollbarWidth: number;
  }) {
    const {
      isLTR: isLTRBool,
      surfaceInline,
      anchorInline,
      anchorRect,
      surfaceRect,
      xOffset,
      positioning,
      windowInnerWidth,
      inlineScrollbarWidth,
    } = config;
    // We use number booleans to multiply values rather than `if` / ternary
    // statements because it _heavily_ cuts down on nesting and readability
    const relativeToWindow = positioning === 'fixed' || positioning === 'document' ? 1 : 0;
    const relativeToDocument = positioning === 'document' ? 1 : 0;
    const isLTR = isLTRBool ? 1 : 0;
    const isRTL = isLTRBool ? 0 : 1;
    const isSurfaceInlineStart = surfaceInline === 'start' ? 1 : 0;
    const isSurfaceInlineEnd = surfaceInline === 'end' ? 1 : 0;
    const isOneInlineEnd = anchorInline !== surfaceInline ? 1 : 0;

    // Whether or not to apply the width of the anchor
    const inlineAnchorOffset = isOneInlineEnd * anchorRect.width + xOffset;
    // The inline position of the anchor relative to window in LTR
    const inlineTopLayerOffsetLTR =
      isSurfaceInlineStart * anchorRect.left +
      isSurfaceInlineEnd * (windowInnerWidth - anchorRect.right - inlineScrollbarWidth);
    // The inline position of the anchor relative to window in RTL
    const inlineTopLayerOffsetRTL =
      isSurfaceInlineStart * (windowInnerWidth - anchorRect.right - inlineScrollbarWidth) +
      isSurfaceInlineEnd * anchorRect.left;
    // The inline position of the anchor relative to window
    const inlineTopLayerOffset = isLTR * inlineTopLayerOffsetLTR + isRTL * inlineTopLayerOffsetRTL;

    // The inline position of the anchor relative to window in LTR
    const inlineDocumentOffsetLTR = isSurfaceInlineStart * window.scrollX - isSurfaceInlineEnd * window.scrollX;
    // The inline position of the anchor relative to window in RTL
    const inlineDocumentOffsetRTL = isSurfaceInlineEnd * window.scrollX - isSurfaceInlineStart * window.scrollX;
    // The inline position of the anchor relative to window
    const inlineDocumentOffset = isLTR * inlineDocumentOffsetLTR + isRTL * inlineDocumentOffsetRTL;

    // If the surface's inline would be out of bounds of the window, move it
    // back in
    const inlineOutOfBoundsCorrection = Math.abs(
      Math.min(0, windowInnerWidth - inlineTopLayerOffset - inlineAnchorOffset - surfaceRect.width)
    );

    // The inline logical value of the surface
    const inlineInset =
      relativeToWindow * inlineTopLayerOffset + inlineAnchorOffset + relativeToDocument * inlineDocumentOffset;

    let surfaceInlineProperty = surfaceInline === 'start' ? 'inset-inline-start' : 'inset-inline-end';

    // There are cases where the element is RTL but the root of the page is not.
    // In these cases we want to not use logical properties.
    if (positioning === 'document' || positioning === 'fixed') {
      if ((surfaceInline === 'start' && isLTRBool) || (surfaceInline === 'end' && !isLTRBool)) {
        surfaceInlineProperty = 'inset-inline-start';
      } else {
        surfaceInlineProperty = 'inset-inline-end';
      }
    }

    return {
      inlineInset,
      inlineOutOfBoundsCorrection,
      surfaceInlineProperty,
    };
  }
}
