import { ViewportScroller } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { MaintenanceService, WINDOW } from '@campus/browser';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, shareReplay, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { AuthFacade } from '../../facades';
import { FaqCategoryInterface, PersonInterface } from '../../services';
import { FaqCategoryService } from '../../services/faq-category/faq-category.service';
import { RoleType } from '../../types';

export type PersonWithToken = PersonInterface & {
  role: RoleType;
};
export interface SelectedQuestionInterface {
  [categoryId: string]: number | boolean;
}
@Injectable()
export class FaqViewModel {
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private window = inject(WINDOW);
  private faqCategoryService = inject(FaqCategoryService);
  private authService = inject(AuthFacade);
  private viewPortScroller = inject(ViewportScroller);
  private maintenanceService = inject(MaintenanceService);
  private snackbar: MatSnackBar = inject(MatSnackBar);

  private defaultRole: RoleType = 'student';
  private categoriesLoaded: { [id: string]: boolean } = {};
  private error$: Observable<string> = new Observable();
  private errorMessages = {
    'faq-item-not-found': 'De vraag die je zoekt bestaat niet (meer).',
  };

  public role$: Observable<RoleType>;
  public slug$: Observable<string>;
  public faqCategories$: Observable<FaqCategoryInterface[]>;
  public selectedQuestions$: Observable<SelectedQuestionInterface> = new Observable();
  public isMaintenanceMode$: Observable<boolean>;
  public errorMessage: string;

  constructor() {
    this.setupStreams();
    this.checkAndShowErrors();
  }

  private setupStreams(): void {
    this.role$ = this.getRole$();
    this.faqCategories$ = this.getFaqCategories$();
    this.slug$ = this.currentQueryParamSlug$();
    this.selectedQuestions$ = this.getSelectedQuestions$();
    this.isMaintenanceMode$ = this.maintenanceService.isMaintenanceMode();
  }

  private checkAndShowErrors(): void {
    this.error$ = this.route.queryParams.pipe(
      take(1),
      map(({ error }) => this.errorMessages[error] || error),
      tap((error) => {
        if (!error) return;

        this.showError(error);
      })
    );
  }

  private showError(errorMessage: string = null): void {
    this.snackbar.open(errorMessage, 'OK', {
      duration: 5000,
      panelClass: [
        'warn',
        'weight-bold',
        'animate-normal',
        'animate-jello-horizontal',
        'animation-delay-extra-long-1',
        'corner-m',
        'overflow-hidden',
      ],
    });
  }

  private currentUserRole$(): Observable<RoleType | undefined> {
    return this.authService.getCurrent().pipe(
      map((user) => {
        const role = ((user && user.types) || []).find((type) => this.isRole(type)) as RoleType;

        return role;
      })
    );
  }

  private currentQueryParamRole$(): Observable<RoleType | undefined> {
    return this.route.queryParams.pipe(
      map(({ role }) => {
        return this.isRole(role) ? (role as RoleType) : undefined;
      })
    );
  }

  private currentQueryParamSlug$(): Observable<string | undefined> {
    return this.route.queryParams.pipe(
      take(1),
      map(({ slug }) => {
        return slug;
      })
    );
  }

  private getSelectedQuestions$(): Observable<SelectedQuestionInterface> {
    return combineLatest([this.faqCategories$, this.slug$]).pipe(
      map(([categories, slug]) => {
        const selectedQuestions = {};

        categories.forEach((category) => {
          const index = this.getSelectedQuestionIndex(category, slug);
          selectedQuestions[category.id] = index > -1 ? index : false;
        });

        return selectedQuestions;
      }),
      shareReplay(1)
    );
  }

  private getRole$(): Observable<RoleType> {
    return combineLatest([this.currentUserRole$(), this.currentQueryParamRole$()]).pipe(
      map(([userRole, queryParamRole]) => {
        return queryParamRole ?? userRole ?? this.defaultRole;
      })
    );
  }

  private getFaqCategories$(): Observable<FaqCategoryInterface[]> {
    return this.role$.pipe(
      switchMap((role) => this.faqCategoryService.mapBasedOnRole$(role)),
      tap((categories) => {
        categories.forEach((category) => {
          this.categoriesLoaded[category.id] = false;
        });
      })
    );
  }

  private isRole(role: string): boolean {
    return ['teacher', 'schooladmin', 'student'].includes(role);
  }

  private allCategoriesLoaded(): boolean {
    return Object.values(this.categoriesLoaded).every((loaded) => loaded !== false);
  }

  public categoryLoaded(id: string): void {
    this.categoriesLoaded[id] = true;
    if (this.allCategoriesLoaded()) {
      this.selectedQuestions$
        .pipe(take(1), withLatestFrom(this.slug$, this.error$))
        .subscribe(([selectedQuestionIndexes, slug, error]) => {
          if (!slug || error) return;

          // If all indexes are false, no valid question was found
          if (Object.values(selectedQuestionIndexes).every((index) => index === false)) {
            this.showError(this.errorMessages['faq-item-not-found']);
          } else {
            this.scrollToSlug();
          }
        });
    }
  }

  public scrollToSlug() {
    this.slug$
      .pipe(
        filter((slug) => slug !== undefined),
        take(1)
      )
      .subscribe((slug) => {
        return this.viewPortScroller.scrollToAnchor(slug);
      });
  }

  public copyLink(slug: string) {
    const url = this.router.createUrlTree([], {
      queryParams: { slug, error: undefined },
      queryParamsHandling: 'merge',
      preserveFragment: true,
    });
    const fullUrl = this.window.location.origin + url.toString();
    this.window.navigator.clipboard.writeText(fullUrl);

    this.snackbar.open('Link gekopieerd', 'Sluiten', {
      duration: 2000,
    });
  }

  public getSelectedQuestionIndex(category: FaqCategoryInterface, slug: string) {
    return category.questions.findIndex((question) => question.slug === slug);
  }
}
