import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
} from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { first, map, skip, take, takeUntil } from 'rxjs/operators';
import { ManageCollectionsDataInterface } from '../../manage-collection/interfaces/manage-collection-data.interface';
import { ManageCollectionItemInterface } from '../../manage-collection/interfaces/manage-collection-item.interface';
import { ManageCollectionComponent } from '../../manage-collection/manage-collection.component';
import { ItemToggledInCollectionInterface } from '../interfaces/item-toggled-in-collection.interface';
import { ManageCollectionEventInterface } from '../interfaces/manage-collection-event.interface';
import { CollectionManagerServiceInterface } from './collection-manager.service.interface';

// key K of T must have a value of type VType
type KeyWithType<T, VType> = keyof Pick<T, { [K in keyof T]: T[K] extends VType ? K : never }[keyof T]>;

type BubbleTuple<streamType> = [KeyWithType<ManageCollectionComponent, Observable<streamType>>, Subject<streamType>];

type EventsToBubble = (BubbleTuple<ItemToggledInCollectionInterface> | BubbleTuple<{ label: string }>)[]; // TODO extract this from ManageCollectionEventInterface

@Injectable({
  providedIn: 'root',
})
export class CollectionManagerService implements CollectionManagerServiceInterface {
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {}

  manageCollections(
    title: string,
    items: ManageCollectionItemInterface[],
    linkableItems: ManageCollectionItemInterface[],
    linkedItemIds: number[],
    recentItemIds: number[],
    collectionType: string,
    subtitle: string
  ): ManageCollectionEventInterface {
    const collectionData: ManageCollectionsDataInterface = {
      title,
      subtitle,
      items,
      linkableItems,
      linkedItemIds: new Set(linkedItemIds),
      recentItemIds: new Set(recentItemIds),
      collectionType,
    };

    // setup observables
    const itemToggled$ = new Subject<ItemToggledInCollectionInterface>();
    const createCollection$ = new Subject<{ label: string }>();

    const eventsToBubble: EventsToBubble = [
      ['selectionChanged', itemToggled$],
      ['createCollection', createCollection$],
    ];

    const componentRef = this.appendCollectionComponentToBody();
    componentRef.instance.data = collectionData;
    componentRef.instance.open();

    this.setupCollectionSubscriptions(eventsToBubble, componentRef.instance.close, componentRef);

    return {
      itemToggled$,
      createCollection$,
      componentRef,
    };
  }

  manageCollectionsDynamic(
    title: string,
    items: ManageCollectionItemInterface[],
    linkableItems$: Observable<ManageCollectionItemInterface[]>,
    linkedItemIds$: Observable<number[]>,
    recentItemIds$: Observable<number[]>,
    collectionType: string,
    subtitle: string,
    createCollectionLabel: string,
    context?: string
  ): ManageCollectionEventInterface {
    const data$ = combineLatest([linkableItems$, linkedItemIds$, recentItemIds$]).pipe(
      map(([linkableItems, linkedItemIds, recentItemIds]) => ({
        title,
        subtitle,
        items,
        linkableItems,
        linkedItemIds: new Set(linkedItemIds),
        recentItemIds: new Set(recentItemIds),
        collectionType,
        createCollectionLabel,
        context,
      }))
    );

    const componentRef = this.appendCollectionComponentToBody();

    // setup observables
    const itemToggled$ = new Subject<ItemToggledInCollectionInterface>();
    const createCollection$ = new Subject<{ label: string }>();

    const eventsToBubble: EventsToBubble = [
      ['selectionChanged', itemToggled$],
      ['createCollection', createCollection$],
    ];

    data$.pipe(first()).subscribe((collectionData) => {
      componentRef.instance.data = collectionData;
      componentRef.instance.open();

      this.setupCollectionSubscriptions(eventsToBubble, componentRef.instance.close, componentRef);
    });

    data$
      .pipe(skip(1), takeUntil(componentRef.instance.close))
      .subscribe((dialogData) => this.updateDialogData(componentRef, dialogData));

    return {
      itemToggled$,
      createCollection$,
      componentRef,
    };
  }

  private appendCollectionComponentToBody() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ManageCollectionComponent);
    const componentRef = componentFactory.create(this.injector);

    this.appRef.attachView(componentRef.hostView);

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);

    return componentRef;
  }

  private removeCollectionComponentFromBody(componentRef: ComponentRef<ManageCollectionComponent>) {
    this.appRef.detachView(componentRef.hostView);
    componentRef.destroy();
  }

  private setupCollectionSubscriptions(
    eventsToBubble: EventsToBubble,
    slideoutClosed$: Observable<boolean>,
    componentRef: ComponentRef<ManageCollectionComponent>
  ) {
    eventsToBubble.forEach((tuple) => {
      const [key, subject] = tuple;
      const event = componentRef.instance[key];

      this.bubbleEventInSubject(event, subject, slideoutClosed$);
    });

    slideoutClosed$.pipe(take(1)).subscribe(() => {
      this.cleanupSubscriptions(eventsToBubble.map(([key, subject]) => subject));
      this.removeCollectionComponentFromBody(componentRef);
    });
  }

  private bubbleEventInSubject(
    event: Observable<unknown>,
    subject: Subject<unknown>,
    closeEvent$: Observable<unknown>
  ) {
    event.pipe(takeUntil(closeEvent$)).subscribe((ev) => subject.next(ev));
  }

  private updateDialogData(
    componentRef: ComponentRef<ManageCollectionComponent>,
    data: ManageCollectionsDataInterface
  ) {
    componentRef.instance.data = data;
  }

  private cleanupSubscriptions(subjects: Subject<unknown>[]) {
    subjects.forEach((subject) => subject.complete());
  }
}
