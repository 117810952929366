import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT_SVG_MAPPING_TOKEN } from '@campus/environment';
import { Dictionary } from '@ngrx/entity';
import { MappingInterface } from '../svg.module';
import { RandomSvgOptions, RandomSvgServiceInterface } from './random-svg.service.interface';

@Injectable()
export class RandomSvgService implements RandomSvgServiceInterface {
  private sessionDict: Dictionary<string[]> = {};

  constructor(
    @Inject(ENVIRONMENT_SVG_MAPPING_TOKEN)
    private svgMapping: MappingInterface
  ) {}

  pick(options?: RandomSvgOptions): string {
    const { session, probability, namespace } = options || {
      session: undefined,
      probability: 1,
      namespace: '',
    };

    const svgs = this.getSessionSvgs(session, namespace);
    return this.getRandomSvg(svgs, probability, session);
  }

  private getSessionSvgs(session: string, namespace: string): string[] {
    if (!session) {
      return this.getSvgsForNamespace(namespace);
    }

    if (!this.sessionDict[session]) {
      this.sessionDict[session] = this.getSvgsForNamespace(namespace);
    }
    return this.sessionDict[session];
  }
  private getSvgsForNamespace(namespace: string): string[] {
    const filterByNamespace = (key: string) => (namespace ? key.startsWith(`${namespace.replace(/:$/, '')}:`) : true);

    return Object.keys(this.svgMapping).filter(filterByNamespace);
  }

  private getRandomSvg(svgs: string[], probability: number, session: string): string {
    if (Math.random() <= probability) {
      const svg = svgs[Math.floor(Math.random() * svgs.length)];
      if (!!session && this.sessionDict[session]) {
        this.sessionDict[session] = this.sessionDict[session].filter((key) => key !== svg);
      }
      return svg;
    }
    return undefined;
  }
}
