import { HttpClient, HttpContext, HttpContextToken } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { WINDOW } from '../window/window';
import { MaintenanceServiceInterface } from './maintenance.service.interface';

export interface MaintenanceInfo {
  enabled?: boolean;
}

export const MAINTENANCE_CHECK_BASE_URL = new InjectionToken('MaintenanceCheckBaseUrl');

export const NO_INTERCEPT = new HttpContextToken(() => false);

@Injectable({ providedIn: 'root' })
export class MaintenanceService implements MaintenanceServiceInterface {
  private lastMaintenanceCheck: Date = new Date(0);
  private cachedMaintenanceInfo: MaintenanceInfo;

  private MAINTENANCE_FILE_NAME = 'maintenance.json';
  private MIN_TIME_BETWEEN_CHECKS_MS = 1000 * 5;

  constructor(
    private http: HttpClient,
    @Inject(WINDOW)
    private window: Window,
    @Optional()
    @Inject(MAINTENANCE_CHECK_BASE_URL)
    private baseUrl: string
  ) {}

  isMaintenanceMode(): Observable<boolean> {
    if (this.isDeveloper()) {
      return of(false);
    }

    if (this.shouldReturnCache()) {
      return of(this.cachedMaintenanceInfo.enabled === true);
    }

    // do this before the http.get, to prevent multiple requests from launching unnecessarily
    this.lastMaintenanceCheck = new Date();

    return this.fetchMaintenanceInfo().pipe(
      tap((response: MaintenanceInfo) => {
        this.cachedMaintenanceInfo = response;
      }),
      map((response: MaintenanceInfo) => {
        return response.enabled === true;
      })
    );
  }

  private fetchMaintenanceInfo() {
    return this.http
      .get<MaintenanceInfo>(this.baseUrl + this.MAINTENANCE_FILE_NAME, {
        context: new HttpContext().set(NO_INTERCEPT, true),
      })
      .pipe(
        catchError((_) => {
          // could be the parsing failed, or that the file simply doesn't exist
          // it's not worth logging because some platforms might not support maintenance mode anyway
          return of({});
        })
      );
  }

  private isDeveloper(): boolean {
    return this.window.localStorage.getItem('dkFlags')?.includes('debug=1');
  }

  private shouldReturnCache(): boolean {
    return this.cachedMaintenanceInfo && !this.enoughTimePassedSinceLastCheck();
  }

  private enoughTimePassedSinceLastCheck(): boolean {
    return new Date().valueOf() - this.lastMaintenanceCheck.valueOf() > this.MIN_TIME_BETWEEN_CHECKS_MS;
  }
}
