import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { PublicPortalFaqCategoryInterface } from '../../interfaces/faq-category.interface';

@Component({
  selector: 'campus-faq-section',
  templateUrl: './faq-section.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FaqSectionComponent {
  @HostBinding('class')
  defaultClasses = ['block'];

  @Input()
  faqCategories: PublicPortalFaqCategoryInterface[];

  @Output()
  faqCategoryClick = new EventEmitter<PublicPortalFaqCategoryInterface>();

  clickedCategory(category: PublicPortalFaqCategoryInterface) {
    this.faqCategoryClick.emit(category);
  }
}
