import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UserType } from '@campus/public-portal';

@Component({
  selector: 'polpo-login-no-access',
  templateUrl: './login-no-access.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginNoAccessComponent {
  @Input() public role: UserType;
}
