import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { WINDOW } from '@campus/browser';
import { COOKIE_INPUT_TOKEN } from '@campus/cookies';
import { Observable } from 'rxjs';
import { VideoPlayerType } from '../../types';

@Component({
  selector: 'campus-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoPlayerComponent implements OnInit {
  private domSanitizer = inject(DomSanitizer);
  private cookieInputService = inject(COOKIE_INPUT_TOKEN);
  private window = inject(WINDOW);

  @Input() videoId: string;
  @Input() player: VideoPlayerType;

  public ERROR_MESSAGE = 'Om deze media te bekijken moet je de cookie "Sociale media / derde partij" aanvaarden.';

  cookieSocialMedia: boolean;
  cookieVisiblity: boolean;
  modalOpen$: Observable<boolean>;

  get sanitizedUrl() {
    const playerSourceUrl = this.getPlayerSourceUrl();
    return this.domSanitizer.bypassSecurityTrustResourceUrl(playerSourceUrl);
  }

  ngOnInit() {
    const cookieSettings = JSON.parse(this.window.localStorage.getItem('cookies'));
    this.cookieSocialMedia = (cookieSettings && cookieSettings.social_media) || false;
    if (this.player === 'wistia' && this.cookieSocialMedia) this.insertWistiaScriptTags();
  }

  public getPlayerSourceUrl(): string {
    switch (this.player) {
      case 'youtube':
        return `https://www.youtube.com/embed/${this.videoId}`;
      case 'wistia':
        return `https://fast.wistia.com/embed/iframe/${this.videoId}?videoFoam=true&autoPlay=false`;
      default:
        return '';
    }
  }

  openCookieConsent() {
    this.cookieInputService.setCookieInputs({
      visibility: true,
      errormessage: this.ERROR_MESSAGE,
      link: 'legals/privacy',
    });
  }

  insertWistiaScriptTags() {
    const existingScript = document.getElementById('wistia-script');

    if (!existingScript) {
      const el2 = document.createElement('script');
      el2.id = 'wistia-script';
      el2.src = 'https://fast.wistia.com/assets/external/E-v1.js';
      el2.type = 'text/javascript';
      el2.async = true;

      document.body.appendChild(el2);
    }
  }
}
