import { ChangeDetectionStrategy, Component, HostBinding, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ENVIRONMENT_WEBSITE_TOKEN } from '@campus/environment';
import { BasicLoginCredentials, isUserType, UserType } from '@campus/public-portal';
import { Observable } from 'rxjs';
import { filter, map, shareReplay, tap } from 'rxjs/operators';
import { LoginPageContainerViewModel } from './login-page-container.viewmodel';

type ModeType = 'basic-login' | 'forgot-password' | 'sso';

@Component({
  selector: 'polpo-login-page-container',
  templateUrl: './login-page-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [LoginPageContainerViewModel],
})
export class LoginPageContainerComponent implements OnInit {
  @HostBinding('class') classList = 'block p-m';

  // This is a temporary fix for the padding-top of the page, until we have a theme file
  @HostBinding('style.padding-top') paddingTop = '132px';
  @HostBinding('style.display') display = 'block';

  private vm = inject(LoginPageContainerViewModel);
  private environmentWebsite = inject(ENVIRONMENT_WEBSITE_TOKEN);
  private route = inject(ActivatedRoute);
  private router = inject(Router);

  public mode: ModeType = 'basic-login';
  public passwordResetRequested$: Observable<boolean>;
  public loginError$: Observable<string> = this.vm.error$;

  public isMaintenanceMode$: Observable<boolean>;
  public statusPageUrl: string;
  public ssoError$: Observable<string> = this.vm.error$.pipe(filter((error) => !error || this.mode === 'sso'));
  public provider$: Observable<string>;
  public role$ = this.vm.role$;

  public ngOnInit(): void {
    this.isMaintenanceMode$ = this.vm.isMaintenanceMode$;
    this.statusPageUrl = this.environmentWebsite.statusPageUrl;
    this.vm.setRole(null);
    this.provider$ = this.route.queryParams.pipe(
      tap((params) => {
        const mode: ModeType = params.provider ? 'sso' : 'basic-login';
        const role: UserType = params.provider ? 'student' : this.getDefaultRole(params);
        this.setMode(mode);
        this.vm.setRole(role);
      }),
      map((params) => {
        if (params.provider === 'acm') return 'leerId'; // only students are allowed to create new accounts
        return params.provider;
      }),
      shareReplay(1)
    );
  }

  private getDefaultRole(params: Params): UserType | null {
    if (params.role && isUserType(params.role)) {
      return params.role;
    }

    return null;
  }

  private setMode(mode: ModeType) {
    this.mode = mode;
  }

  public setForgotPasswordMode() {
    this.setMode('forgot-password');
  }

  public setLoginMode() {
    this.passwordResetRequested$ = null;
    this.setMode('basic-login');

    // clear query params
    const queryParams = { ...this.route.snapshot?.queryParams };
    Object.keys(queryParams).forEach((key) => {
      queryParams[key] = null;
    });
    this.router.navigate([], { queryParams, queryParamsHandling: 'merge', relativeTo: this.route });
  }

  public resetPassword(email: string) {
    this.passwordResetRequested$ = this.vm.resetPassword(email);
  }

  public loginWithCredentials(credentials: BasicLoginCredentials) {
    this.vm.loginWithCredentials(credentials);
  }

  public loginWith(provider: string) {
    this.vm.loginWithProvider(provider);
  }
}
