<div [style.display]="visible ? 'block' : 'none'">
  <div (click)="clearElement()" class="cookies__close"><campus-icon>close</campus-icon></div>

  <div class="cookies__wrapper">
    <div class="cookies__container">
      <div class="cookies__header">
        <h1> Je kan meer met cookies! </h1>
        <p class="cookies__error">{{ errormessage }}</p>
      </div>
      <div class="cookies__main">
        <div class="cookies__main__info">
          <h2>Functionele cookies</h2>
          <p>
            Functionele cookies zorgen ervoor dat de basisfunctionaliteit van de website werkt. Daarom staan deze altijd
            aan.
          </p>
          <p>
            Meer info hierover vind je <a [href]="linkcookies">{{ linkCookiesLabel }}.</a>
          </p>
        </div>

        <ng-container *ngIf="cookies$ | async as cookies">
          <div class="cookies__main__items">
            <h2>Bijkomende cookies</h2>
            <mat-slide-toggle
              [checked]="cookies.preferences ? true : false"
              (change)="setConsentValue('preferences', $event.checked)"
            >
              Voorkeuren
            </mat-slide-toggle>
            <mat-slide-toggle
              [checked]="cookies.marketing ? true : false"
              (change)="setConsentValue('marketing', $event.checked)"
            >
              Marketing
            </mat-slide-toggle>
            <mat-slide-toggle
              [checked]="cookies.statistics ? true : false"
              (change)="setConsentValue('statistics', $event.checked)"
            >
              Statistieken
            </mat-slide-toggle>
            <mat-slide-toggle
              [checked]="cookies.social_media ? true : false"
              (change)="setConsentValue('social_media', $event.checked)"
            >
              Sociale media / derde partij
            </mat-slide-toggle>
          </div>
          <div class="cookies__main__footer">
            <div class="cookies__main__footer--navigate">
              <p (click)="setCookies('minimal')"> Functionele cookies aanvaarden </p></div
            >

            <div class="cookies__main__footer__button" (click)="setCookies()">
              <button mat-raised-button color="#64b1aa">
                <span
                  >{{ cookieStatus === 'current' ? 'Huidige selectie aanvaarden' : 'Alle cookies aanvaarden' }}
                </span></button
              ></div
            >
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
