import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  TemplateRef,
  inject,
} from '@angular/core';
import { InputBooleanAttribute } from '../core/empty-attribute';

export type InteractionStates = 'hover' | 'focus' | 'press' | 'drag' | 'disable';

export interface StateLayerEvent extends Event {
  handledByStateLayer: true;
}

@Component({
  selector: 'campus-state-layer',
  template: '',
})
export class StateLayerComponent implements OnChanges, AfterViewInit, OnDestroy {
  private _events = ['mouseenter', 'mouseleave', 'focusin', 'focusout', 'pointerdown', 'pointerup'];
  private _prevControl: HTMLElement | null = null;
  private _cd = inject(ChangeDetectorRef);

  @HostBinding('attr.data-visible')
  visible: InputBooleanAttribute;

  @HostBinding('attr.aria-hidden')
  ariaHidden = true;

  @Input() for: TemplateRef<Component> | ElementRef | HTMLElement;

  @HostBinding('class.transition-opacity')
  initialized;

  @HostBinding('class')
  defaultClasses = [
    'state-layer',
    'absolute',
    'block',
    'origin-center',
    'relative',
    'overflow-hidden',
    'pointer-event-none',
  ];

  @HostBinding('attr.data-type')
  @Input()
  type: 'content' | 'circular' = 'content';

  @HostBinding('attr.data-state')
  state: InteractionStates;

  ngOnChanges(): void {
    const control = this._getForElement();
    if (control) {
      this._cleanup(this._prevControl);
      this._listen(control);
      this._prevControl = control;
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => (this.initialized = true), 0);
    this._cd.detectChanges();
  }

  ngOnDestroy(): void {
    if (!this.for) return;
    this._cleanup(this._getForElement());
  }

  handleEvent(event: StateLayerEvent): void {
    if (event.handledByStateLayer) return;

    switch (event.type) {
      case 'mouseenter':
        this.state = this.state ?? 'hover';
        break;
      case 'mouseleave':
        this.state = this.state !== 'hover' ? this.state : undefined;
        break;
      case 'focusin':
        this.state = 'focus';
        break;
      case 'focusout':
        this.state = undefined;
        break;
      case 'pointerdown':
        this.state = 'press';
        break;
      case 'pointerup':
        this.state = undefined;
        break;
      default:
        return;
    }

    this._cd.markForCheck();
  }

  private _listen(control: HTMLElement): void {
    this._events.forEach((event) => {
      control.addEventListener(event, this);
    });
  }
  private _cleanup(control: HTMLElement | null): void {
    if (!control) return;

    this._events.forEach((event) => {
      control.removeEventListener(event, this);
    });
  }

  private _getForElement(): HTMLElement {
    if (!this.for) throw new Error('For is required');

    if ('nativeElement' in this.for) return this.for.nativeElement;
    else if ('elementRef' in this.for) return this.for.elementRef.nativeElement;
    else return this.for;
  }
}
