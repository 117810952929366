import { Injectable, InjectionToken } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FilterStateInterface } from '../interfaces/filter-state.interface';

export const FILTER_ON_SEARCH_TERM_SERVICE_TOKEN = new InjectionToken('FilterOnSearchTermService');

export interface FilterOnSearchTermServiceInterface<T> {
  hasSearchTerm$: Observable<boolean>;
  setFilterItemFn(value: (item: T, searchTerm: string) => boolean): void;
  setSource$(value$: Observable<T[]>): void;
  updateSearchTerm(term: string);
  getFilteredItems$(): Observable<T[]>;
}

@Injectable()
export class FilterOnSearchTermService<T> implements FilterOnSearchTermServiceInterface<T> {
  private source$: Observable<T[]>;
  private filterItemFn: (item: T, searchTerm: string) => boolean;

  public hasSearchTerm$: Observable<boolean>;
  private filterState$ = new BehaviorSubject<FilterStateInterface>({});

  constructor() {
    this.setupStreams();
  }

  setFilterItemFn(value: (item: T, searchTerm: string) => boolean) {
    this.filterItemFn = value;
  }
  setSource$(value$: Observable<T[]>) {
    this.source$ = value$;
  }

  public updateSearchTerm(term: string) {
    const filterValues = { searchTerm: term };
    this.updateFilterState(filterValues);
  }

  public getFilteredItems$(): Observable<T[]> {
    return combineLatest([this.filterState$, this.source$]).pipe(
      map(([filterState, items]) => this.filterItems(filterState, items))
    );
  }

  private setupStreams() {
    this.hasSearchTerm$ = this.filterState$.pipe(map((filterState) => !!filterState.searchTerm));
  }

  private filterItems(filterState: FilterStateInterface, items: T[]): T[] {
    if (!filterState.searchTerm) {
      return items;
    }

    return items.filter(
      (item) => !filterState || (this.filterItemFn && this.filterItemFn(item, filterState.searchTerm))
    );
  }

  private updateFilterState(updatedFilter: FilterStateInterface): void {
    const currentFilterState = this.filterState$.value;
    const newFilterState = { ...currentFilterState, ...updatedFilter };
    this.filterState$.next(newFilterState);
  }
}
