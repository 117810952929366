import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { CookiesCategoriesInterface } from '../../interfaces/cookies-categories.interface';

export interface CookiesServiceInterface {
  cookies$: Observable<CookiesCategoriesInterface>;
  setInitialConsentValues(): void;
  setConsentValue(label, value): void;
  saveConsentValues(): void;
  setAllConsentValues(value: boolean): void;
  isLocalStorageEmpty(): boolean;
}

export const COOKIES_SERVICE_TOKEN = new InjectionToken<CookiesServiceInterface>('CookiesService');
