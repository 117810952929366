import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface ExpansionPanelStatusInterface {
  identifier: string
  isLoaded: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ExpansionPanelStatusService {
  private allPanelsLoadedSubject$ = new BehaviorSubject<boolean>(false);
  allPanelsLoaded$ = this.allPanelsLoadedSubject$.asObservable();

  private panels: ExpansionPanelStatusInterface[] = [];

  private getIdentifier() {
    return Math.random().toString(36).substring(2, 9);
  }

  public registerPanel(): string {
    const identifier = this.getIdentifier();
    this.panels.push({ identifier, isLoaded: false });

    return identifier
  }

  public notifyPanelLoaded(identifier: string) {
    const panel = this.panels.find((info) => info.identifier === identifier);

    if (panel) {
      panel.isLoaded = true;
    }

    if(this.panels.every((info) => info.isLoaded)) {
      this.allPanelsLoadedSubject$.next(true);
    }
  }
}
