import { EASE_EMPHASIZED, EASE_EMPHASIZED_DECELERATE } from '@campus/animations';

const PRESS_GROW_MS = 525;
const PRESS_SCALE_MS = 750;
const PRESS_SCALE = 0.9;
const MINIMUM_PRESS_MS = 400;
const INITIAL_ORIGIN_SCALE = 0.1;
const PADDING = 3;
const SOFT_EDGE_MINIMUM_SIZE = 15;
const SOFT_EDGE_CONTAINER_RATIO = 0.35;
const ANIMATION_FILL = 'forwards';

const RIPPLE_GAP_DELAY_MS = 150;

enum State {
  INACTIVE,
  TOUCH_DELAY,
  HOLDING,
  WAITING_FOR_CLICK,
}

const TOUCH_DELAY_MS = 250;

export function Ripple(options?: {
  elementRef?: string;
  triggerRef?: string;
  disableRipple?: boolean;
  disablePressdown?: boolean;
}) {
  return function (constructor) {
    const targetNgOnInit: () => void = constructor.prototype.ngOnInit;

    constructor.prototype.ngOnInit = function () {
      if (targetNgOnInit) {
        targetNgOnInit.apply(this);
      }

      if (this.ripple === false) return;

      this.state = State.INACTIVE;
      this.rippleOptions = options;

      const elementRef = this[options?.elementRef || 'elementRef'];
      const triggerRef = this[options?.triggerRef || 'elementRef'];

      if (!elementRef) {
        console.error('Ripple decorator requires an elementRef property');
      }

      this.hovered = false;
      this.pressed = false;
      this.checkBoundsAfterContextMenu = false;

      this.nativeElement = elementRef.nativeElement;
      this.triggerElement = triggerRef?.nativeElement || this.nativeElement;

      this.nativeElement.classList.add('relative');

      if (!this.rippleOptions?.disableRipple) {
        this.container = document.createElement('div');
        this.container.classList.add('ripple-container');
        this.container.appendChild(document.createElement('span'));
        this.container.appendChild(document.createElement('span'));

        this.nativeElement.appendChild(this.container);
      }

      this.triggerElement.addEventListener('click', handleClick.bind(this));
      this.triggerElement.addEventListener('contextmenu', handleContextMenu.bind(this));
      this.triggerElement.addEventListener('pointercancel', handlePointerCancel.bind(this));
      this.triggerElement.addEventListener('pointerdown', handlePointerdown.bind(this));
      this.triggerElement.addEventListener('pointerenter', handlePointerenter.bind(this));
      this.triggerElement.addEventListener('pointerleave', handlePointerleave.bind(this));
      this.triggerElement.addEventListener('pointerup', _endPressAnimation.bind(this));
    };

    constructor.prototype.handleClick = handleClick;
    constructor.prototype.determineRippleSize = _determineRippleSize;
    constructor.prototype.getNormalizedPointerEventCoords = _getNormalizedPointerEventCoords;
    constructor.prototype.getTranslationCoordinates = _getTranslationCoordinates;
    constructor.prototype.endPressAnimation = _endPressAnimation;
    constructor.prototype.startPressAnimation = _startPressAnimation;
  };
}
function handlePointerenter(event: PointerEvent) {
  if (!_shouldReactToEvent(event, this)) return;

  this.hovered = true;
}
function handlePointerleave(event: PointerEvent) {
  if (!_shouldReactToEvent(event, this)) return;

  this.hovered = false;

  if (this.state !== State.INACTIVE) {
    this.endPressAnimation();
  }
}
async function handlePointerdown(event: PointerEvent) {
  if (!_shouldReactToEvent(event, this)) return;

  this.rippleStartEvent = event;
  if (!_isTouch(event)) {
    this.state = State.WAITING_FOR_CLICK;
    this.startPressAnimation(event);
    return;
  }

  if (this.checkBoundsAfterContextMenu && !_inBounds(event)) {
    return;
  }

  this.checkBoundsAfterContextMenu = false;

  this.state = State.TOUCH_DELAY;
  await new Promise((resolve) => {
    setTimeout(resolve, TOUCH_DELAY_MS);
  });

  if (this.state !== State.TOUCH_DELAY) {
    return;
  }

  this.state = State.HOLDING;
  this.startPressAnimation(event);
}

function handleClick(event: PointerEvent) {
  if (this.disabled) return;

  if (this.state === State.WAITING_FOR_CLICK) {
    this.endPressAnimation();
    return;
  }

  if (this.state === State.INACTIVE) {
    this.startPressAnimation(event);
    this.endPressAnimation();
  }
}

function handlePointerCancel(event: PointerEvent) {
  if (!_shouldReactToEvent(event, this)) return;

  this.endPressAnimation();
}

function handleContextMenu() {
  if (this.disabled) return;

  this.checkBoundsAfterContextMenu = true;
  this.endPressAnimation();
}

function _determineRippleSize() {
  const { height, width } = this.nativeElement.getBoundingClientRect();
  const maxDim = Math.max(height, width);
  const softEdgeSize = Math.max(SOFT_EDGE_CONTAINER_RATIO * maxDim, SOFT_EDGE_MINIMUM_SIZE);

  const initialSize = Math.floor(maxDim * INITIAL_ORIGIN_SCALE);
  const hypotenuse = Math.sqrt(width ** 2 + height ** 2);
  const maxRadius = hypotenuse + PADDING;

  this.initialSize = initialSize;
  this.rippleScale = `${(maxRadius + softEdgeSize) / initialSize}`;
  this.rippleSize = `${initialSize}px`;
}

function _getNormalizedPointerEventCoords(pointerEvent: PointerEvent): {
  x: number;
  y: number;
} {
  const { scrollX, scrollY } = window;
  const { left, top } = this.nativeElement.getBoundingClientRect();
  const documentX = scrollX + left;
  const documentY = scrollY + top;
  const { pageX, pageY } = pointerEvent;
  return { x: pageX - documentX, y: pageY - documentY };
}

function _getTranslationCoordinates(positionEvent?: Event) {
  const { height, width } = this.nativeElement.getBoundingClientRect();

  const endPoint = {
    x: (width - this.initialSize) / 2,
    y: (height - this.initialSize) / 2,
  };

  let startPoint;
  if (positionEvent instanceof PointerEvent) {
    startPoint = this.getNormalizedPointerEventCoords(positionEvent);
  } else {
    startPoint = {
      x: width / 2,
      y: height / 2,
    };
  }

  startPoint = {
    x: startPoint.x - this.initialSize / 2,
    y: startPoint.y - this.initialSize / 2,
  };

  return { startPoint, endPoint };
}

function _startPressAnimation(positionEvent?: Event) {
  this.container.classList.add('pressed');
  this.pressed = true;

  this.growAnimation?.cancel();
  this.growAnimation2?.cancel();
  this.pressDownAnimation?.cancel();

  if (!this.rippleOptions?.disableRipple) {
    const rippleAnimations = _getRippleAnimation.bind(this)(positionEvent);
    this.growAnimation = rippleAnimations[0];
    this.growAnimation2 = rippleAnimations[1];
  }

  if (!this.rippleOptions?.disablePressdown) {
    this.pressDownAnimation = _getPressDownAnimation.bind(this)();
  }
}

function _getRippleAnimation(positionEvent?: Event) {
  this.determineRippleSize();
  const { startPoint, endPoint } = this.getTranslationCoordinates(positionEvent);
  const translateStart = `${startPoint.x}px, ${startPoint.y}px`;
  const translateEnd = `${endPoint.x}px, ${endPoint.y}px`;

  const keyFrame = (scale: number) => ({
    top: [0, 0],
    left: [0, 0],
    height: [this.rippleSize, this.rippleSize],
    width: [this.rippleSize, this.rippleSize],
    transform: [`translate(${translateStart}) scale(1)`, `translate(${translateEnd}) scale(${scale})`],
  });
  const options = (delay?: number) => ({
    duration: PRESS_GROW_MS,
    easing: EASE_EMPHASIZED,
    fill: ANIMATION_FILL,
    ...(delay && { delay }),
  });

  const animation1 = this.container
    .querySelectorAll('span')[0]
    .animate(keyFrame(this.rippleScale), options(RIPPLE_GAP_DELAY_MS));

  const animation2 = this.container.querySelectorAll('span')[1].animate(keyFrame(1 / INITIAL_ORIGIN_SCALE), options());

  return [animation1, animation2];
}

function _getPressDownAnimation() {
  return this.triggerElement.animate(
    { transform: ['scale(1)', `scale(${PRESS_SCALE})`, 'scale(1)'] },
    {
      duration: PRESS_SCALE_MS,
      easing: EASE_EMPHASIZED_DECELERATE,
      fill: ANIMATION_FILL,
    }
  );
}

async function _endPressAnimation() {
  this.state = State.INACTIVE;
  const animation = this.growAnimation;
  const pressDownAnimation = this.pressDownAnimation;
  let pressAnimationPlayState = Infinity;

  if (typeof pressDownAnimation?.currentTime === 'number') {
    pressAnimationPlayState = pressDownAnimation.currentTime;
  } else if (pressDownAnimation?.currentTime) {
    pressAnimationPlayState = pressDownAnimation.currentTime.to('ms').value;
  }

  if (typeof animation?.currentTime === 'number') {
    pressAnimationPlayState = Math.min(animation.currentTime, pressAnimationPlayState);
  } else if (animation?.currentTime) {
    pressAnimationPlayState = Math.min(animation.currentTime.to('ms').value, pressAnimationPlayState);
  }

  if (pressAnimationPlayState >= MINIMUM_PRESS_MS) {
    this.pressed = false;
    this.container.classList.remove('pressed');
    return;
  }

  await new Promise((resolve) => {
    setTimeout(resolve, MINIMUM_PRESS_MS - pressAnimationPlayState);
  });

  if (this.growAnimation !== animation) {
    return;
  }

  this.pressed = false;
  this.container.classList.remove('pressed');
}

function _inBounds({ x, y }: PointerEvent) {
  const { top, left, bottom, right } = this.nativeElement.getBoundingClientRect();
  return x >= left && x <= right && y >= top && y <= bottom;
}

function _shouldReactToEvent(event: PointerEvent, comp: any) {
  if (comp.disabled || !event.isPrimary) return false;
}
function _isTouch(event: PointerEvent) {
  return event.pointerType === 'touch';
}
