import { Action } from '@ngrx/store';

export enum MetaReducerActionTypes {
  ClearState = '[State] Clear state',
}

export class ClearState implements Action {
  readonly type = MetaReducerActionTypes.ClearState;
}

export type MetaReducerActions = ClearState;
