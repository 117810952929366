<div class="[ container container-max-width-4xl stack ]">
  <h1 class="[ headline-large text-center ]" data-cy="form-header">Registreer als leerling</h1>

  <campus-maintenance-mode *ngIf="isMaintenanceMode$ | async; else operationalMode" [statusPageUrl]="statusPageUrl"></campus-maintenance-mode>

  <ng-template #operationalMode>
  <div class="[ cluster ]">
    <div class="[ items-start justify-center ]">
      <campus-svg svgImage="contact-form" alt="Login Formulier" class="[ max-w-2xs ]" />
      <div class="[ stack flex-1 max-w-full tablet-p:min-w-m ]">
        <div class="[ p-b-m ]">
          <polpo-register-form
            (register)="register($event)"
            [error]="registrationError$ | async"
          />
        </div>
      </div>
    </div>
  </div>
  </ng-template>
</div>
