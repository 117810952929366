<ng-container *ngIf="linkableItems$ | async as linkableItems">
  <campus-slideout-modal-stack [activePanel]="activePanel">
    <campus-slideout-modal
      [title]="data.title"
      (animationDone)="slideOutAnimationDone()"
      class="ui-manage-collection__slideout"
      (scrimClicked)="activePanel = activePanel - 1"
    >
      <campus-slideout-modal-header-actions>
        <button inline-icon-button data-cy="sidesheet-close" (click)="onClose()" icon="close"></button>
      </campus-slideout-modal-header-actions>

      <ng-container *ngTemplateOutlet="filter"></ng-container>
      <ng-container *ngTemplateOutlet="createCollectionTemplate"></ng-container>

      <ng-container *ngIf="data.context">
        <div class="ui-manage-collection__context ui-caption"
          >{{ data.context }}
          <campus-button inline (click)="showItemsList = !showItemsList">{{
            showItemsList ? 'toon minder' : 'toon meer'
          }}</campus-button></div
        >
        <campus-collapsible-container [open]="showItemsList">
          <ol>
            <li class="ui-caption" *ngFor="let item of data.items">{{ item.label }}</li>
          </ol>
        </campus-collapsible-container>
      </ng-container>

      <h6 data-cy="sidesheet-subtitle" *ngIf="data.subtitle">{{ data.subtitle }}</h6>

      <ng-container *ngTemplateOutlet="collection"></ng-container>

      <!-- UI blocks -->
      <ng-template #createCollectionTemplate
        ><mat-form-field class="ui-manage-collection__quick-add" data-cy="manage-collection-new">
          <mat-label>{{ data.createCollectionLabel || 'Nieuw' }}</mat-label>
          <input data-cy="manage-collection-new-input" matInput [(ngModel)]="newCollectionName" type="text" />
          <campus-button
            data-cy="manage-collection-new-button"
            default
            flat
            icon
            iconClass="add"
            (click)="onCreateCollectionClick(newCollectionName)"
            matSuffix
            [disabled]="!newCollectionName"
          ></campus-button> </mat-form-field
      ></ng-template>

      <ng-template #noFilterResultsTemplate>
        <div class="ui-manage-collection__no-results">
          <span data-cy="manage-collection-no-results-message">Het filteren leverde geen resultaten op.</span>
          <a data-cy="manage-collection-no-results-reset" (click)="filterTextInput && filterTextInput.clear()"
            >Filter verwijderen</a
          >
        </div>
      </ng-template>

      <ng-template #emptyCollectionTemplate>
        <div class="ui-manage-collection__no-results" data-cy="empty-collection-message">
          <campus-empty-state
            [dense]="true"
            [svgImage]="'empty:' + data.collectionType"
            [title]="'Geen ' + data.collectionType"
          ></campus-empty-state>
        </div>
      </ng-template>

      <ng-template #filter>
        <campus-filter-text-input
          data-cy="manage-collection-filter"
          *ngIf="useFilter && linkableItems.length > 0"
          theme="dark"
          [source]="linkableItems"
          class="ui-manage-collection__filter"
          autocomplete="off"
        ></campus-filter-text-input>
      </ng-template>

      <ng-template #collection>
        <ng-container *ngIf="linkableItems.length > 0; else emptyCollectionTemplate">
          <mat-selection-list (selectionChange)="onSelectionChanged($event)">
            <div
              class="ui-manage-collection__recent-items"
              *ngIf="
                (!filterTextInput && recentLinkableItems.length > 0) ||
                (filterTextInput && !filterTextInput.input.value && recentLinkableItems.length > 0)
              "
            >
              <h3 data-cy="manage-collection-subheader-recent" mat-subheader>Recent gebruikt</h3>
              <mat-list-option
                data-cy="manage-collection-recent-list-option"
                checkboxPosition="before"
                *ngFor="let item of recentLinkableItems"
                [value]="item.id"
                color="primary"
                class="ui-manage-collection__recent-item"
              >
                <ng-container *ngTemplateOutlet="listItem; context: { listItem: item }"></ng-container>
              </mat-list-option>
            </div>
            <ng-container *ngIf="listItems$ | async as filteredItems">
              <div class="ui-manage-collection__all-items" *ngIf="filteredItems.length; else noFilterResultsTemplate">
                <h3 data-cy="manage-collection-subheader-all" mat-subheader>Alle</h3>
                <mat-list-option
                  data-cy="manage-collection-all-list-option"
                  *ngFor="let item of filteredItems"
                  checkboxPosition="before"
                  color="primary"
                  [value]="item.id"
                >
                  <ng-container *ngTemplateOutlet="listItem; context: { listItem: item }"></ng-container>
                </mat-list-option>
              </div>
            </ng-container>
          </mat-selection-list>
        </ng-container>
      </ng-template>

      <ng-template #listItem let-item="listItem">
        <div class="ui-manage-collection__list-item">
          <div data-cy="manage-collection-list-item" class="ui-body-1 ui-text-ellipsis">{{ item.label }}</div>
          <campus-icon
            *ngIf="item.linkToItem"
            data-cy="sidesheet-open-in-new"
            svgIcon="open-in-new"
            (click)="navigateTo($event, item.linkToItem)"
            [matTooltip]="item.linkToTooltip || 'Ga naar taak detail'"
          ></campus-icon>
        </div>
      </ng-template>
    </campus-slideout-modal>
  </campus-slideout-modal-stack>
</ng-container>
