import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export interface CookieInputServiceInterface {
  cookieInputs$: Observable<CookieInputInterface>;
  setCookieInputs(inputs: Partial<CookieInputInterface>): void;
}

export interface CookieInputInterface {
  visibility: boolean;
  errormessage: string;
  link: string;
}

export const COOKIE_INPUT_TOKEN = new InjectionToken<CookieInputServiceInterface>('CookieInputService');
