import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WindowScrollService } from '@campus/browser';
import { ENVIRONMENT_ASSETS_TOKEN, ENVIRONMENT_WEBSITE_TOKEN } from '@campus/environment';
import {
  ContactFormEntryInterface,
  FaqCategoryInterface,
  PersonInterface,
  PublicPortalFaqCategoryInterface,
} from '@campus/public-portal';
import { Observable, Subscription } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { Role } from '../../polpo.interfaces';
import { HomePageViewModel } from './home-page.viewmodel';

interface HomePageFaqCategoryInterface {
  img: string;
  title: string;
  description: string;
  role: string;
}
interface HomePageEaInterface {
  name: string;
  number: string;
  email: string;
  province: string;
  img: string;
}
@Component({
  selector: 'polpo-home-page',
  templateUrl: './home-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [HomePageViewModel],
})
export class HomePageComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  private _faqCategories = [
    {
      img: 'student.svg',
      title: 'Leerling',
      description: 'Veelgestelde vragen voor leerlingen.',
      role: 'student',
    },
    {
      img: 'teacher.svg',
      title: 'Leerkracht',
      description: 'Veelgestelde vragen voor leerkrachten.',
      role: 'teacher',
    },
    {
      img: 'schooladmin.svg',
      title: 'Schoolbeheerder',
      description: 'Veelgestelde vragen voor schoolbeheerders.',
      role: 'schooladmin',
    },
  ];

  private router = inject(Router);
  private vm = inject(HomePageViewModel);
  private cdRef = inject(ChangeDetectorRef);
  private scrollService = inject(WindowScrollService);
  private assets = inject(ENVIRONMENT_ASSETS_TOKEN);
  private environmentWebsite = inject(ENVIRONMENT_WEBSITE_TOKEN);

  public role: Role;
  public user$: Observable<PersonInterface>;
  public isMaintenanceMode$: Observable<boolean>;
  public contactSuccess$: Observable<boolean>;
  public isHeaderPinned = false;
  public faqCategories: HomePageFaqCategoryInterface[];
  public subjects = [
    'Ik wil meer info over Polpo',
    'Ik wil een afspraak voor een demo',
    'Ik heb een vraag over mijn bestelling',
    'Ik heb een technische vraag of probleem',
    'Ik heb een taalfout gevonden',
    'Ik heb een andere vraag',
  ];
  public statusPageUrl: string;

  constructor() {
    this.subscriptions.add(
      this.scrollService.thresholdReached$.subscribe((reached) => {
        this.isHeaderPinned = reached;
        this.cdRef.markForCheck();
      })
    );
  }

  ngOnInit() {
    this.contactSuccess$ = this.vm.contactSuccess$;

    this.user$ = this.vm.user$.pipe(shareReplay(1));
    this.faqCategories = this._faqCategories.map(this.mapAssets('faq')) as HomePageFaqCategoryInterface[];

    this.isMaintenanceMode$ = this.vm.isMaintenanceMode$;
    this.statusPageUrl = this.environmentWebsite.statusPageUrl;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public goToPlatform() {
    this.vm.redirectToPlatform();
  }

  public logout() {
    this.vm.logout();
  }

  trackById(index: number, category: FaqCategoryInterface): number {
    return category.id;
  }

  private mapAssets(directory: 'ea' | 'faq') {
    const assetsPath = this.assets.images[directory].endsWith('/')
      ? this.assets.images[directory].slice(0, -1)
      : this.assets.images[directory];

    return (item: HomePageEaInterface | HomePageFaqCategoryInterface) => ({
      ...item,
      img: `${assetsPath}/${item.img}`,
    });
  }

  public clickedFaqCategory(category: PublicPortalFaqCategoryInterface) {
    this.router.navigate(['faq'], { queryParams: { role: category.role } });
  }

  public contactFormSubmitted(formValues: ContactFormEntryInterface) {
    this.vm.sendContactForm(formValues);
  }

  public resetContactForm() {
    this.vm.resetContactForm();
  }
}
