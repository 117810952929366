export const icons = {
  /* outlined */
  'camera-permission': 'assets/icons/outlined/camera-permission.svg',
  'arrow-right': 'assets/icons/outlined/arrow_right.svg',
  'chevron-down': 'assets/icons/outlined/chevron_down.svg',
  'chevron-up': 'assets/icons/outlined/chevron_up.svg',
  check: 'assets/icons/outlined/done.svg',
  'expand-more': 'assets/icons/outlined/expand_more.svg',
  link: 'assets/icons/outlined/link.svg',
  close: 'assets/icons/outlined/close.svg',
  menu: 'assets/icons/outlined/menu.svg',
  'arrow-drop-down': 'assets/icons/outlined/arrow_drop_down.svg',
  'logo-small': 'assets/svgs/logo_small.svg',
  lock: 'assets/icons/outlined/lock.svg',
  'logo-letter': 'assets/icons/outlined/logo_letter.svg',
  acm: 'assets/icons/outlined/acm.svg',
  leerId: 'assets/icons/outlined/leerid.svg',
  smartschool: 'assets/icons/outlined/smartschool.svg',
  google: 'assets/icons/outlined/google.svg',
  facebook: 'assets/icons/outlined/facebook.svg',
  microsoft: 'assets/icons/outlined/microsoft.svg',
  login: 'assets/icons/outlined/login.svg',
  'lock-reset': 'assets/icons/outlined/lock_reset.svg',
};
