import { inject, Injectable, OnDestroy } from '@angular/core';
import { MaintenanceService } from '@campus/browser';
import { AUTH_SERVICE, AuthFacade, AuthServiceInterface, RegistrationCredentials } from '@campus/public-portal';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RegisterPageContainerViewModel implements OnDestroy {
  private authService: AuthServiceInterface = inject(AUTH_SERVICE);
  private auth = inject(AuthFacade);
  private maintenanceService = inject(MaintenanceService);

  public registrationError$ = this.getRegistrationError$();
  public isMaintenanceMode$: Observable<boolean>;

  constructor() {
    this.isMaintenanceMode$ = this.maintenanceService.isMaintenanceMode();
  }

  public register(credentials: RegistrationCredentials) {
    const credentialsWithType = { ...credentials, userType: 'student' };
    this.auth.register(credentialsWithType);
  }

  private getRegistrationError$(): Observable<string> {
    return this.auth.error$;
  }

  ngOnDestroy(): void {
    this.authService.clearError();
  }
}
