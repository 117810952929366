import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  SimpleChanges,
  inject,
} from '@angular/core';
import { ArrayFunctions } from '@campus/utils';
import { BehaviorSubject, Subject, Subscription, combineLatest } from 'rxjs';
import { filter, startWith, take, takeUntil } from 'rxjs/operators';
import { ExpansionPanelStatusService } from './expansion-panel-status.service';
import { ExpansionPanelComponent } from './expansion-panel.component';

@Component({
  selector: 'campus-expansion-panels',
  template: '<ng-content></ng-content>',
  styleUrls: ['./expansion-panels.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpansionPanelsComponent implements AfterContentInit, OnDestroy, OnChanges {
  @Input() multiple: boolean;
  @Input() max: number;
  @HostBinding('class.ui-expansion-panels--mandatory')
  @Input()
  mandatory: boolean;
  @Input() disabled: boolean;
  @Input() readonly: boolean;
  @HostBinding('class.ui-expansion-panels')
  isExpansionPanelsClass = true;
  @HostBinding('class.ui-expansion-panels--has-active-panel')
  hasActiveExpansionPanelClass = false;
  @HostBinding('class.ui-expansion-panels--flat') @Input() flat: boolean;
  @HostBinding('class.ui-expansion-panels--hover') @Input() hover: boolean;
  @HostBinding('class.ui-expansion-panels--focusable')
  @Input()
  focusable: boolean;
  @HostBinding('class.ui-expansion-panels--inset') @Input() inset: boolean;
  @HostBinding('class.ui-expansion-panels--popout') @Input() popout: boolean;
  @HostBinding('class.ui-expansion-panels--tile') @Input() tile: boolean;
  @HostBinding('class.ui-expansion-panels--accordion')
  @Input()
  accordion: boolean;
  @Input() expandAll: boolean;

  @Input() autoScroll = false;

  @Output() selectionChanged: EventEmitter<any> = new EventEmitter();
  @Output() ready: EventEmitter<boolean> = new EventEmitter();

  @Input()
  set selected(v: number | number[]) {
    if (v === this._selected || ArrayFunctions.getArrayEquality(v, this._selected)) {
      return;
    }
    this.updateSelected(v, true);
  }
  get selected(): number | number[] {
    return this._selected;
  }

  @ContentChildren(ExpansionPanelComponent) panels: QueryList<ExpansionPanelComponent>;

  private _selected: number | number[];
  private statusService: ExpansionPanelStatusService = inject(ExpansionPanelStatusService);

  private headerClickSubscriptions: Subscription[] = [];
  private loadedAllPanels$ = new Subject<boolean>();

  updateSelected(value: number | number[], keep: boolean) {
    if (this.multiple) {
      this.updateMultiple(value, keep);
    } else {
      this.updateSingle(value, keep);
    }

    this.hasActiveExpansionPanelClass = this.multiple
      ? (this._selected as number[]).length > 0
      : this._selected !== undefined;

    if (this.panels) {
      this.updatePanelsState();
    }
  }

  ngAfterContentInit() {
    this.panels.changes.pipe(startWith(this.panels)).subscribe((panels: QueryList<ExpansionPanelComponent>) => {
      // give all panels and 'id'
      const panelValues: number[] = panels.map(
        (panel, index) => (panel.value = panel.value === undefined ? index : panel.value)
      );
      // in case of removed panels, also clear the removed panel values from the selection
      if (Array.isArray(this._selected)) {
        this._selected = this._selected.filter((value) => panelValues.includes(value));
      } else {
        this._selected = panelValues.includes(this._selected) ? this._selected : undefined;
      }

      this.manageHeaderClicks(panels);
    });

    this.checkExpandAll();

    this.statusService.allPanelsLoaded$
      .pipe(
        takeUntil(this.loadedAllPanels$),
        filter((status) => !!status)
      )
      .subscribe((status) => {
        this.loadedAllPanels();
        this.loadedAllPanels$.next(true);
      });
  }

  /**
   * Keeps track of the header click subscriptions.
   *
   * @private
   * @param {QueryList<ExpansionPanelComponent>} panels
   * @memberof ExpansionPanelsComponent
   */
  private manageHeaderClicks(panels: QueryList<ExpansionPanelComponent>) {
    this.headerClickSubscriptions.forEach((sub) => sub.unsubscribe());
    this.headerClickSubscriptions = [];

    panels.forEach((panel, index) => {
      if (this.mandatory && !this.getSelectedValues().length) {
        this.updateMandatory();
      }

      const nextPanel = panels[index + 1];
      this.updateActivePanel(panel, nextPanel);
      const subscription = panel.headerClicked.subscribe((comp: ExpansionPanelComponent) => {
        this.updateSelected(panel.value, false);
      });
      this.headerClickSubscriptions.push(subscription);
    });

    if (!!this.disabled) {
      this.disableAllPanels(this.disabled);
    }
    if (!!this.readonly) {
      this.readonlyAllPanels(this.readonly);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.panels) {
      if (changes.disabled) {
        this.disableAllPanels(changes.disabled.currentValue);
      }
      if (changes.readonly) {
        this.readonlyAllPanels(changes.readonly.currentValue);
      }
      if (changes.mandatory && !changes.mandatory.isFirstChange) {
        this.updatePanelsState();
      }
      if (changes.expandAll && this.multiple) {
        this.checkExpandAll();
      }
    }
  }

  ngOnDestroy() {
    this.panels.forEach((panel) => {
      panel.headerClicked.unsubscribe();
    });
  }

  public updateActivePanel(panel: ExpansionPanelComponent, nextPanel: ExpansionPanelComponent) {
    panel.update({
      isActive: this.isActive(panel),
      nextIsActive: this.isActive(nextPanel),
    });
  }

  public updatePanelsState() {
    if (this.mandatory && !this.getSelectedValues().length) {
      return this.updateMandatory();
    }

    const panels = this.panels.toArray();

    if (!this.autoScroll) {
      return panels.forEach((panel, index) => this.updateActivePanel(panel, panels[index + 1]));
    }

    const activePanels = panels.filter((p) => this.isActive(p));

    const { on, open, close, AnimationState } = getAnimationEvents(this);

    on(AnimationState.NOT_STARTED, () => {
      this.scrollToPanel(activePanels);
      close(panels);
    });
    on(AnimationState.CLOSE_END, () => {
      this.scrollToPanel(activePanels);
      open(panels);
    });
    on(AnimationState.OPEN_END, (expandChanges) => {
      this.scrollToPanel(activePanels, expandChanges);
    });
  }

  private isActive(panel: ExpansionPanelComponent | number) {
    if (!panel) return false;
    const value = typeof panel === 'number' ? panel : panel.value;
    return this.getSelectedValues().includes(value);
  }

  private scrollToPanel(panels, expansionChanges?) {
    const panelToScrollIntoView = this.getPanelThatNeedsToScrollIntoView(panels, expansionChanges);

    if (panelToScrollIntoView) panelToScrollIntoView.scrollToTop();
  }

  private getPanelThatNeedsToScrollIntoView(panels, expansionChanges) {
    if (!expansionChanges) return panels[0];

    const panelIndex = Object.keys(expansionChanges).find(
      (index) => expansionChanges[index] && this.isActive(panels[index])
    );

    return panelIndex && panels[panelIndex];
  }

  private checkExpandAll() {
    if (this.expandAll && this.multiple) {
      this.panels.forEach((panel) => this.updateSelected(panel.value, false));
    }
  }
  private disableAllPanels(disabled: boolean) {
    this.panels.forEach((panel) => panel.update({ disabled }));
  }
  private readonlyAllPanels(readonly: boolean) {
    this.panels.forEach((panel) => panel.update({ readonly }));
  }

  private getSelectedValues() {
    return this._selected !== undefined ? (Array.isArray(this._selected) ? this._selected : [this._selected]) : [];
  }

  /**
   * Keep at least one enabled panel open.
   */
  private updateMandatory(last?: boolean) {
    if (!this.panels.length) {
      return;
    }
    const panels = this.panels.toArray();

    if (last) {
      panels.reverse();
    }

    const panel = panels.find((p) => !p.disabled);

    if (!panel) {
      return;
    }

    this.updateSelected(panel.value, false);
  }

  private updateMultiple(value: any, keep: boolean) {
    const defaultValue = Array.isArray(this._selected) ? this._selected : [];
    let _value = [...defaultValue];
    const index = _value.findIndex((val) => val === value);
    const valueExists = index > -1;

    if (valueExists) {
      if (keep) return;
      if (this.mandatory && _value.length - 1 < 1) return;

      _value.splice(index, 1);
    } else {
      if (this.max && _value.length + 1 > this.max) return;

      if (Array.isArray(value)) {
        _value = value;
      } else {
        _value.push(value);
      }
    }

    this._selected = _value;
    this.selectionChanged.emit(this._selected);
  }

  private updateSingle(value: any, keep: boolean) {
    if (value === this._selected) {
      if (keep || this.mandatory) return;

      this._selected = undefined;
    } else {
      this._selected = value;
    }
    this.selectionChanged.emit(this._selected);
  }

  private loadedAllPanels() {
    this.ready.emit(true);
  }
}

function getAnimationEvents(self) {
  enum AnimationState {
    NOT_STARTED,
    CLOSE_START,
    CLOSE_END,
    OPEN_START,
    OPEN_END,
  }

  const animationState$: BehaviorSubject<[AnimationState, any]> = new BehaviorSubject([
    AnimationState.NOT_STARTED,
    null,
  ]);

  const on = (animationState, handler) =>
    animationState$
      .pipe(
        filter(([state, data]) => state === animationState),
        take(1)
      )
      .subscribe(([state, data]) => handler(data));

  const expandAnimationsStart = (panels: ExpansionPanelComponent[]) =>
    combineLatest(panels.map((p) => p.expandAnimationStart$)).pipe(take(1));

  const expandAnimationsDone = (panels: ExpansionPanelComponent[]) =>
    combineLatest(panels.map((p) => p.expandAnimationDone$)).pipe(take(1));

  const setupListeners = (panels: ExpansionPanelComponent[], [startState, endState]: AnimationState[]) => {
    if (!panels.length) {
      animationState$.next([startState, []]);
      animationState$.next([endState, []]);
    } else {
      expandAnimationsStart(panels).subscribe((data) => animationState$.next([startState, data]));
      expandAnimationsDone(panels).subscribe((data) => animationState$.next([endState, data]));
    }
  };

  // doesn't force closing, is more update and emit the close events
  const close = (panels: ExpansionPanelComponent[]) => {
    const inActivePanels = panels.filter((panel) => !self.isActive(panel));

    setupListeners(inActivePanels, [AnimationState.CLOSE_START, AnimationState.CLOSE_END]);

    inActivePanels.forEach((inactivePanel) => {
      const panelIndex = panels.findIndex((p) => p.value === inactivePanel.value);
      self.updateActivePanel(inactivePanel, panels[panelIndex + 1]);
    });
  };

  // doesn't force opening, is more update and emit the open events
  const open = (panels: ExpansionPanelComponent[]) => {
    const activePanels = panels.filter((panel) => self.isActive(panel));
    setupListeners(activePanels, [AnimationState.OPEN_START, AnimationState.OPEN_END]);

    activePanels.forEach((activePanel) => {
      const panelIndex = panels.findIndex((p) => p.value === activePanel.value);
      self.updateActivePanel(activePanel, panels[panelIndex + 1]);
    });
  };

  return { on, open, close, AnimationState };
}
