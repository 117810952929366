<ng-content select="campus-icon, [button-icon]"></ng-content>
<campus-icon *ngIf="icon" [svgIcon]="icon"></campus-icon>
<campus-state-layer [for]="element" type="circular" class="bg-primary"></campus-state-layer>
<campus-focus-ring [for]="element"></campus-focus-ring>
<campus-badge
  *ngIf="badge != null && badge !== 0"
  [value]="badge"
  class="[ absolute -top-3xs -right-3xs ] [ animate-scale-up-center duration-short-1 ]"
></campus-badge>
<campus-tooltip
  *ngIf="plainTooltip"
  [plainTooltip]="plainTooltip"
  [tooltipDelay]="250"
  [anchor]="element"
  class="[ visually-hidden ]"
>
</campus-tooltip>
