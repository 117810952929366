import { animate, animation, AnimationReferenceMetadata, keyframes, style } from '@angular/animations';

import { EASE_EMPHASIZED_ACCELERATE, EASE_EMPHASIZED_DECELERATE, EASE_STANDARD, TIMING_DEFAULT } from './constants';

export const flip = animation(
  [
    style({ 'backface-visibility': 'visible' }),
    animate(
      '{{ timing }}ms {{ delay }}ms {{ ease }}',
      keyframes([
        style({
          transform: 'perspective(400px) rotate3d(0, 1, 0, -360deg)',
          offset: 0,
        }),
        style({
          transform: 'perspective(400px) scale3d(1.5, 1.5, 1.5) rotate3d(0, 1, 0, -190deg)',
          offset: 0.4,
        }),
        style({
          transform: 'perspective(400px) scale3d(1.5, 1.5, 1.5) rotate3d(0, 1, 0, -170deg)',
          offset: 0.5,
        }),
        style({
          transform: 'perspective(400px) scale3d(.95, .95, .95)',
          offset: 0.8,
        }),
        style({
          transform: 'perspective(400px)',
          offset: 1,
        }),
      ])
    ),
  ],
  {
    params: { timing: TIMING_DEFAULT, ease: EASE_EMPHASIZED_DECELERATE, delay: 0 },
  }
);

export function flipIn(rotateX: number, rotateY: number): AnimationReferenceMetadata {
  return animation(
    [
      style({ 'backface-visibility': 'visible' }),
      animate(
        '{{ timing }}ms {{ delay }}ms {{ ease }}',
        keyframes([
          style({
            opacity: 0,
            transform: 'perspective(400px) rotate3d({{ rotateX }}, {{ rotateY }}, 0, 90deg)',
            offset: 0,
          }),
          style({
            opacity: 1,
            transform: 'perspective(400px) rotate3d({{ rotateX }}, {{ rotateY }}, 0, -20deg)',
            offset: 0.4,
          }),
          style({
            transform: 'perspective(400px) rotate3d({{ rotateX }}, {{ rotateY }}, 0, 10deg)',
            offset: 0.6,
          }),
          style({
            transform: 'perspective(400px) rotate3d({{ rotateX }}, {{ rotateY }}, 0, -5deg)',
            offset: 0.8,
          }),
          style({
            transform: 'perspective(400px) rotate3d(0, 0, 0, 0)',
            offset: 1,
          }),
        ])
      ),
    ],
    { params: { timing: TIMING_DEFAULT, ease: EASE_EMPHASIZED_ACCELERATE, delay: 0, rotateX, rotateY } }
  );
}

export const flipInX = flipIn(1, 0);
export const flipInY = flipIn(0, 1);

export function flipOut(rotateX: number, rotateY: number): AnimationReferenceMetadata {
  return animation(
    [
      style({ 'backface-visibility': 'visible' }),
      animate(
        '{{ timing }}ms{{ delay }}ms {{ ease }}',
        keyframes([
          style({
            transform: 'perspective(400px)',
            offset: 0,
          }),
          style({
            opacity: 1,
            transform: 'perspective(400px) rotate3d({{ rotateX }}, {{ rotateY }}, 0, -20deg)',
            offset: 0.3,
          }),
          style({
            opacity: 0,
            transform: 'perspective(400px) rotate3d({{ rotateX }}, {{ rotateY }}, 0, 90deg)',
            offset: 1,
          }),
        ])
      ),
    ],
    { params: { timing: TIMING_DEFAULT, ease: EASE_STANDARD, delay: 0, rotateX, rotateY } }
  );
}

export const flipOutX = flipOut(1, 0);
export const flipOutY = flipOut(0, 1);
