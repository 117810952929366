import { animate, AnimationMetadata, keyframes, state, style, transition } from '@angular/animations';
import { easing, speed } from './constants';

export const scaleBounceAnimation: AnimationMetadata[] = [
  state('void', style({ transform: 'scale(0)' })),
  state('*', style({ transform: 'scale(1)' })),
  transition(':enter', [
    animate(
      `${speed.verySlow} ${easing.easeOut}`,
      keyframes([
        style({ transform: 'scale(0)', offset: 0 }),
        style({ transform: 'scale(1.3)', offset: 0.3 }),
        style({ transform: 'scale(0.85)', offset: 0.5 }),
        style({ transform: 'scale(1.2)', offset: 0.75 }),
        style({ transform: 'scale(0.9)', offset: 0.85 }),
        style({ transform: 'scale(1.1)', offset: 0.9 }),
        style({ transform: 'scale(1)', offset: 1 }),
      ])
    ),
  ]),
];
export const popBounceAnimation: AnimationMetadata[] = [
  state('false', style({ transform: 'scale(1)' })),
  state('true', style({ transform: 'scale(1)' })),
  transition('false=>true', [
    animate(
      `${speed.slow} ${easing.easeOut}`,
      keyframes([
        style({ transform: 'scale(1)', offset: 0 }),
        style({ transform: 'scale(1.3)', offset: 0.3 }),
        style({ transform: 'scale(0.85)', offset: 0.6 }),
        style({ transform: 'scale(1.1)', offset: 0.85 }),
        style({ transform: 'scale(1)', offset: 1 }),
      ])
    ),
  ]),
];
