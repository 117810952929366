import { animate, AnimationMetadata, state, style, transition } from '@angular/animations';
import { easing, speed } from './constants';

export enum CollapsibleAnimationState {
  ANIMATING = 'animating',
  OPENED = 'opened',
  CLOSED = 'closed',
}

export const collapseAnimation: AnimationMetadata[] = [
  state(CollapsibleAnimationState.CLOSED, style({ height: 0 })),
  state(CollapsibleAnimationState.OPENED, style({ height: '*' })),

  transition(
    `${CollapsibleAnimationState.CLOSED} <=> ${CollapsibleAnimationState.OPENED}`,
    animate(`${speed.slow} ${easing.default}`)
  ),
];
