<div class="ui_person-badge ui_person-badge--{{ align }}">
  <div
    *ngIf="person; else avatarNoPerson"
    class="ui_person-badge__badge ui_person-badge__badge--{{ size }} [ on-surface-variant ]"
    [class.ui_person-badge__badge--show-border]="showBorder"
    >{{ person | personInitials }}
    <img data-cy="person-badge-avatar" *ngIf="person.avatar" [src]="person.avatar" alt="avatar" />
  </div>
  <ng-template #avatarNoPerson>
    <campus-icon
      class="ui_person-badge__badge ui_person-badge__badge--{{ size }} ui_person-badge__badge__no-account-icon"
      [svgIcon]="'account'"
    ></campus-icon>
  </ng-template>
  <div class="ui_person-badge__display ui_person-badge__display--{{ size }}" *ngIf="showText">
    <div data-cy="person-badge-display-name" class="ui_person-badge__display__name ui-body-1">{{
      person?.displayName
    }}</div>
    <div data-cy="person-badge-sub-text" class="ui_person-badge__display__subtext ui-caption" *ngIf="subText">{{
      subText
    }}</div>
    <a class="ui_person-badge__display__email ui-caption" *ngIf="showEmail" [href]="'mailto:' + person?.email">{{
      person?.email
    }}</a>
  </div>
</div>
