import { ChangeDetectionStrategy, Component, Directive, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { WindowScrollService } from '@campus/browser';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[campusHeaderAvatar], [headerAvatar], campus-header-avatar',
})
export class HeaderAvatarDirective {
  @HostBinding('class')
  hasHeaderAvatarClass = 'kabas-header__avatar';
}

export interface RouteInterface {
  url: string;
  goToId?: string;
}
export interface HeaderLinkInterface {
  label: string;
  route: RouteInterface;
}

export interface HeaderActionInterface {
  label: string;
  icon?: string;
  action?: () => void;
  children?: HeaderActionInterface[];
}

@Component({
  selector: 'campus-portal-lib-header',
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  public isNavOpen = false;

  @HostBinding('class') classList =
    'backdrop-blur-m backdrop-filter background-emphasis-high block index-content-on-surface index-sticky-container no-elevation-layer top-0 fixed w-full surface';

  @HostBinding('class.elevation-1')
  public isPinned: boolean;

  @Input() links: HeaderLinkInterface[];
  @Input() userActions: HeaderActionInterface;

  @Output() actionClicked = new EventEmitter<string>();
  @Output() linkClicked = new EventEmitter<RouteInterface>();

  private subscriptions: Subscription = new Subscription();

  constructor(private scrollService: WindowScrollService) {
    this.subscriptions.add(this.scrollService.thresholdReached$.subscribe((reached) => (this.isPinned = reached)));
  }

  public navigateTo(route: RouteInterface) {
    this.isNavOpen = false;
    this.linkClicked.emit(route);
  }
}
