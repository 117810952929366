import { Component, HostBinding, HostListener, inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { COOKIE_INPUT_TOKEN, CookieInputInterface, CookieInputServiceInterface } from '@campus/cookies';

import { AuthFacade, HeaderActionInterface, PersonInterface } from '@campus/public-portal';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AppViewModel } from './app.viewmodel';

@Component({
  selector: 'polpo-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  @HostBinding('class')
  defaultClasses = ['max-w-screen', 'overflow-x-clip'];

  private auth = inject(AuthFacade);
  private router: Router = inject(Router);
  private appViewModel: AppViewModel = inject(AppViewModel);
  private cookieInputService: CookieInputServiceInterface = inject(COOKIE_INPUT_TOKEN);
  private isMaintenanceMode$: Observable<boolean>;

  user$: Observable<PersonInterface>;
  dropped = false;
  cookieInputs$: Observable<CookieInputInterface>;
  isHeaderPinned: boolean;
  isHomeRoute$: Observable<boolean>;

  public headerLinks = [
    { label: 'Wat is Polpo?', route: { url: '', goToId: 'wat-is-polpo' } },
    { label: 'Veelgestelde vragen', route: { url: '', goToId: 'veelgestelde-vragen' } },
    { label: 'Contact', route: { url: '', goToId: 'contact' } },
  ];

  public headerActions$: Observable<HeaderActionInterface>;

  @HostListener('window:focus', ['$event'])
  onFocus(event: FocusEvent): void {
    this.appViewModel.checkUserValidity();
  }

  ngOnInit(): void {
    this.user$ = this.auth.user$;
    this.cookieInputs$ = this.cookieInputService.cookieInputs$;
    this.isHomeRoute$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map((event: NavigationEnd) => this.router.isActive('/', true))
    );
    this.isMaintenanceMode$ = this.appViewModel.isMaintenanceMode$;
    this.headerActions$ = this.setHeaderActions$();
  }

  toggleMenu() {
    this.dropped = !this.dropped;
  }

  closeMenu() {
    this.dropped = false;
  }

  openCookieConsent() {
    this.cookieInputService.setCookieInputs({
      visibility: true,
    });
  }

  closeCookieConsent() {
    this.cookieInputService.setCookieInputs({ visibility: false });
  }

  private setHeaderActions$() {
    return combineLatest([this.user$, this.isMaintenanceMode$]).pipe(
      map(([user, isMaintenanceMode]) => {
        if (isMaintenanceMode) {
          return null;
        } else if (user) {
          return {
            label: user.displayName,
            children: [
              {
                label: 'Naar het platform',
                icon: 'logo-letter',
                action: () => this.goToPlatform(),
              },
              {
                label: 'Afmelden',
                icon: 'lock',
                action: () => this.logout(),
              },
            ],
          };
        } else {
          return {
            label: 'Aanmelden',
            children: [
              {
                label: 'Aanmelden',
                action: () => this.navigateTo({ url: '/login' }),
              },
              {
                label: 'Aanmelden als beheerder',
                action: () => this.router.navigate(['/login'], { queryParams: { role: 'schooladmin' } }),
              },
            ],
          };
        }
      })
    );
  }

  goToPlatform() {
    this.appViewModel.redirectToPlatform();
  }

  logout() {
    this.auth.logout();
  }

  public navigateTo(route: { url: string; goToId?: string }) {
    this.router.navigate([route.url], { fragment: route.goToId });
  }
}
