<campus-faq-hero></campus-faq-hero>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  preserveAspectRatio="none"
  viewBox="0 0 2105.395 232.146"
  class="[ absolute w-full ] [ index-pullback ] [ min-h-3xs ]"
>
  <defs>
    <linearGradient id="faq-hero-gradient" x1="0.546" y1="0.795" x2="0.546" gradientUnits="objectBoundingBox">
      <stop offset="0" class="start" />
      <stop offset="1" class="stop" />
    </linearGradient>
  </defs>
  <path
    d="M502.05,898.139c300.284-4.094,534.518-97.6,914.478,4.5,391.126,105.1,689.749,22.985,689.749,22.985V724.011H.882V868.02S236.895,901.755,502.05,898.139Z"
    transform="translate(-0.882 -724.011)"
    fill-rule="evenodd"
    fill="url(#faq-hero-gradient)"
  />
</svg>

<div class="[ m-auto max-w-4xl p-m ] [ stack stack-space-3xl items-center ]">
  <campus-maintenance-mode
    *ngIf="isMaintenanceMode$ | async; else operationalMode"
    [statusPageUrl]="statusPageUrl"
    class="[ m-b-m ]"
  ></campus-maintenance-mode>

  <ng-template #operationalMode>
    <section class="[ m-b-m ]">
      <ng-container *ngIf="selectedRole$ | async as role">
        <campus-chip-group class="[ flex justify-center ]">
          <campus-filter-chip
            label="voor leerlingen"
            [selected]="role === 'student'"
            (click)="roleClicked('student')"
          ></campus-filter-chip>

          <campus-filter-chip
            label="voor leerkrachten"
            [selected]="role === 'teacher'"
            (click)="roleClicked('teacher')"
          ></campus-filter-chip>

          <campus-filter-chip
            label="voor schoolbeheerders"
            [selected]="role === 'schooladmin'"
            (click)="roleClicked('schooladmin')"
          ></campus-filter-chip>
        </campus-chip-group>
      </ng-container>
    </section>

    <ng-container *ngIf="filteredFaqCategories$ | async as faqCategories">
      <section class="[ reel reel-item-h-3xs reel-item-w-3xs overflowing max-w-full ]">
        <campus-outline-card
          type="faq"
          *ngFor="let faqCategory of faqCategories; trackBy: trackById"
          class="[ cursor-pointer ]"
          (click)="categoryClicked(faqCategory)"
        >
          <campus-card-hero *ngIf="faqCategory.icon" class="frame-contain [ m-0 ]">
            <img [src]="faqCategory.icon" />
          </campus-card-hero>

          <campus-card-headline *ngIf="faqCategory.name" class="[ body-large ]">{{
            faqCategory.name
          }}</campus-card-headline>
        </campus-outline-card>
      </section>
      <div>
        <section class="[ stack stack-space-3xl ]">
          <ng-container *ngFor="let faqCategory of faqCategories; trackBy: trackById">
            <section>
              <div class="[ stack stack-space-m ]">
                <ng-container>
                  <h2 [id]="faqCategory.slug" class="[ title-large ]">{{ faqCategory.name }}</h2>

                  <campus-expansion-panels
                    *ngIf="faqCategory.questions"
                    [selected]="(selectedQuestions$ | async)[faqCategory.id]"
                    (ready)="categoryLoaded(faqCategory.id)"
                  >
                    <campus-expansion-panel *ngFor="let question of faqCategory.questions" [id]="question.slug">
                      <campus-expansion-panel-header
                        headline="List Item"
                        trailing-icon="arrow_right"
                        class="flex gap-s"
                      >
                        <campus-icon
                          svgIcon="link"
                          (click)="copyLink(question.slug, $event)"
                          class="[ on-surface-variant text-opacity-medium hover:text-opacity-full hover:text-secondary min-w-fit ]"
                        ></campus-icon>
                        <span>{{ question.question }}</span>
                        <campus-icon campus-expansion-panel-header-icon svgIcon="expand-more"></campus-icon>
                      </campus-expansion-panel-header>
                      <campus-expansion-panel-content>
                        <div [innerHTML]="question.answer"></div>
                        <campus-video-player
                          class="[ block ] [ m-bs-m ]"
                          *ngIf="question.videoId"
                          [videoId]="question.videoId"
                          [player]="videoPlayer"
                        ></campus-video-player>
                      </campus-expansion-panel-content>
                    </campus-expansion-panel>
                  </campus-expansion-panels>
                </ng-container>
              </div>
            </section>
          </ng-container>
        </section>
      </div>
      <button
        *ngIf="windowScrolled"
        inline-icon-button
        (click)="scrollToTop()"
        class="[ elevation-4 ] [ index-fab ] [ sticky bottom-xl self-end -right-m ] [ transform translate-x-1/2 ]"
        icon="chevron-up"
      >
      </button>
    </ng-container>
  </ng-template>
</div>
