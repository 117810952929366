import { SelectionModel } from './selection.model';

export class OptionSelectionChange<T extends SelectableOption> {
  constructor(public source: T, public isUserInput = false) {}
}

export interface SelectableOption {
  selected: boolean;
  select(emit?: boolean): void;
  deselect(emit?: boolean): void;
  selectViaInteraction(): void;
}

export class SelectableListController<T extends SelectableOption> extends SelectionModel<T> {
  constructor(
    public multiple = false,
    initiallySelectedValues?: T[],
    emitChanges = true,
    compareWith?: (o1: T, o2: T) => boolean
  ) {
    super(true, initiallySelectedValues, emitChanges, compareWith);
  }

  override isMultipleSelection(): boolean {
    return this.multiple;
  }

  override select(...values: T[]): boolean {
    if (this.multiple) {
      return super.select(...values);
    }
    return super.setSelection(...values);
  }

  isSelectable(option: T): boolean {
    return typeof option.select === 'function' && typeof option.deselect === 'function';
  }
}
