import { ChangeDetectionStrategy, Component, HostBinding, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CardCoreComponent } from '../card-core/card-core.component';

@Component({
  selector: 'campus-outline-card, [outline-card]',
  templateUrl: './../card-core/card-core.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OutlineCardComponent extends CardCoreComponent implements OnInit, OnChanges {
  defaultClasses = [...this.coreClasses, 'outline-card', 'surface', 'border', 'border-opacity-medium'];

  @HostBinding('class')
  classes = this.defaultClasses;

  ngOnInit() {
    this.classes = this.getClasses();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.disabled) {
      this.classes = this.getClasses();
    }
  }

  private getClasses() {
    return [
      ...this.defaultClasses,
      ...(this.disabled ? ['surface-variant', 'opacity-low', 'pointer-event-none', 'grayscale-full', 'filter'] : []),
    ];
  }
}
