<div class="[ container container-max-width-4xl stack ]">
  <h1 *ngIf="mode !== 'forgot-password'" class="[ headline-large text-center ]" data-cy="form-header">
    <ng-container *ngIf="(role$ | async) === 'schooladmin'; else standardLoginTitle">
      Aanmelden als schoolbeheerder
    </ng-container>
    <ng-template #standardLoginTitle> Aanmelden </ng-template>
  </h1>
  <h1 *ngIf="mode === 'forgot-password'" class="[ headline-large text-center ]" data-cy="form-header"
    >Wachtwoord vergeten</h1
  >

  <campus-maintenance-mode
    *ngIf="isMaintenanceMode$ | async; else operationalMode"
    [statusPageUrl]="statusPageUrl"
  ></campus-maintenance-mode>
  <ng-template #operationalMode>
    <div class="[ cluster ]">
      <div class="[ items-start justify-center ]">
        <campus-svg svgImage="contact-form" alt="Login Formulier" class="[ max-w-2xs ]"></campus-svg>
        <div class="[ stack flex-1 max-w-full tablet-p:min-w-m ]">
          <div class="[ p-b-m ]" *ngIf="mode !== 'sso'">
            <polpo-login-form
              *ngIf="mode === 'basic-login'"
              (login)="loginWithCredentials($event)"
              [showRegister]="(role$ | async) !== 'schooladmin'"
              (forgotPassword)="setForgotPasswordMode()"
              [error]="loginError$ | async"
            ></polpo-login-form>
            <polpo-forgot-password-form
              *ngIf="mode === 'forgot-password'"
              (whenSubmitted)="resetPassword($event)"
              [showRegister]="(role$ | async) !== 'schooladmin'"
              (whenCancelled)="setLoginMode()"
              [passwordResetRequested]="passwordResetRequested$ | async"
            ></polpo-forgot-password-form>
          </div>

          <div class="[ border-1 p-b-m ]" [class.border-bs]="mode !== 'sso'" *ngIf="(role$ | async) !== 'schooladmin'">
            <polpo-login-methods
              (login)="loginWith($event)"
              (whenCancelled)="setLoginMode()"
              [provider]="provider$ | async"
              [error]="ssoError$ | async"
            ></polpo-login-methods>
          </div>

          <div class="[ border-1 border-bs p-b-m ]">
            <polpo-login-no-access [role]="role$ | async" data-cy="login-no-access"></polpo-login-no-access>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
