import { Injectable, inject } from '@angular/core';
import { LoginUserFail, LoginUserSuccess, UserActions } from '@campus/public-portal';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { pessimisticUpdate } from '@nrwl/angular';
import { RedirectService } from '../../services/redirect.service';

@Injectable()
export class AppUserEffects {

  private actions$ = inject(Actions);
  private redirect = inject(RedirectService);

  loginUserSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        UserActions.USER_LOGIN_SUCCESS
      ),
      pessimisticUpdate({
        run: (action: LoginUserSuccess) => {
          return this.redirect.toPlatform(action.payload.current);
        },
        onError: (action: LoginUserSuccess, error) => {
          return new LoginUserFail({error: 'Kon niet inloggen. Controleer uw gegevens en probeer opnieuw.'});
        }
      })
    )
  )
}
