import { ArrayFunctions } from './array.functions';

export class ObjectFunctions {
  public static deepEquals<T = object>(x: T, y: T): boolean {
    if (x === y) {
      return true; // if both x and y are null or undefined and exactly the same
    } else if (x instanceof Array || y instanceof Array) {
      return false; // only objects
    } else if (!(x instanceof Object) || !(y instanceof Object)) {
      return false; // if they are not strictly equal, they both need to be Objects
    } else if (x instanceof Date && y instanceof Date) {
      return +x === +y;
    } else {
      for (const p of Object.keys(x)) {
        if (!Object.prototype.hasOwnProperty.call(y, p)) {
          return false; // allows to compare x[ p ] and y[ p ] when set to undefined
        }
        if (x[p] === y[p]) {
          continue; // if they have the same strict value or identity then they are equal
        }
        if (Array.isArray(x[p])) {
          if (!Array.isArray(y[p])) return false;

          // TODO: replace compareSets with something more efficient ...
          const { onlySet1, common, onlySet2 } = ArrayFunctions.compareSets(x[p], y[p]);
          if (onlySet1.length || onlySet2.length) {
            return false;
          } else {
            continue;
          }
        }
        if (typeof x[p] !== 'object') {
          return false; // Numbers, Strings, Functions, Booleans must be strictly equal
        }
        if (!ObjectFunctions.deepEquals(x[p], y[p])) {
          return false;
        }
      }
      for (const p of Object.keys(y)) {
        if (Object.prototype.hasOwnProperty.call(y, p) && !Object.prototype.hasOwnProperty.call(x, p)) {
          return false;
        }
      }
      return true;
    }
  }
  /**
   * Used to cast certain properties of an object to a type
   * Makes a shalllow copy of the original object
   * @example castAs(Date)(['start', 'end', 'lastUpdated'])(bundle)
   * @example castAs(Set)('classGroup')(setting)
   */
  public static castAs(castType) {
    return (props: string | string[]) => {
      return (obj) => {
        if (!obj) return obj;
        if (!(obj instanceof Object)) return obj;
        const clone = { ...obj };

        if (!props) return clone;
        props = Array.isArray(props) ? props : [props];

        props.forEach((prop) => {
          if (obj[prop] == null) return;
          clone[prop] = new castType(obj[prop]);
        });
        return clone;
      };
    };

    function hasKey(obj: object, key: string) {
      return Object.prototype.hasOwnProperty.call(obj, key);
    }
  }

  public static mergeKeysAndFn(mergeFn, maxDepth: number, ...objects) {
    return mergeWithDepth(0, objects);

    function mergeWithDepth(depth, objects) {
      if (depth >= maxDepth) {
        return objects[objects.length - 1];
      }

      return objects.reduce((acc, obj) => {
        Object.keys(obj).forEach((key) => {
          if (Object.prototype.hasOwnProperty.call(acc, key)) {
            if (acc[key] instanceof Array && obj[key] instanceof Array) {
              acc[key].push(...obj[key]);
            } else if (typeof acc[key] === 'object' && typeof obj[key] === 'object') {
              acc[key] = mergeWithDepth(depth + 1, [acc[key], obj[key]]);
            } else {
              acc[key] = mergeFn(acc[key], obj[key]);
            }
          } else {
            acc[key] = obj[key];
          }
        });
        return acc;
      }, {});
    }
  }

  public static mergeObjects(mergeTo, mergeFrom, overwriteWithNullish = true) {
    for (const prop in mergeFrom) {
      if (Object.prototype.hasOwnProperty.call(mergeFrom, prop)) {
        if (mergeFrom[prop] !== null && typeof mergeFrom[prop] === 'object' && !Array.isArray(mergeFrom[prop])) {
          if (
            !Object.prototype.hasOwnProperty.call(mergeTo, prop) ||
            typeof mergeTo[prop] !== 'object' ||
            Array.isArray(mergeTo[prop])
          ) {
            mergeTo[prop] = {};
          }
          ObjectFunctions.mergeObjects(mergeTo[prop], mergeFrom[prop]);
        } else {
          if (overwriteWithNullish || (mergeFrom[prop] !== undefined && mergeFrom[prop] !== null)) {
            mergeTo[prop] = mergeFrom[prop];
          }
        }
      }
    }
    return mergeTo;
  }

  /**
   * Retrieves the value of a nested object property using dot notation.
   *
   * This method takes an object and a string representing a property path in dot notation.
   * It traverses the object hierarchy and returns the value of the nested property
   * if it exists, or null if any part of the path is not found.
   *
   * @param obj The object to search for the nested property.
   * @param dotNotation A string representing the property path in dot notation (e.g. 'property1.property2.property3').
   * @returns The value of the nested property if it exists, or null if any part of the path is not found.
   */
  public static getObjectPropertyByDotNotation(obj: object, dotNotation: string): unknown {
    const keys = dotNotation.split('.');
    let currentObj = obj;

    for (const key of keys) {
      if (currentObj && Object.prototype.hasOwnProperty.call(currentObj, key)) {
        currentObj = currentObj[key];
      } else {
        return null;
      }
    }

    return currentObj;
  }

  public static isObject(value: unknown): boolean {
    if (value === null) return false;
    if (Array.isArray(value)) return false;
    if (value instanceof Date) return false;

    return typeof value === 'object';
  }
}
