import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { take } from 'rxjs/operators';
import { SvgComponent } from './svg.component';

@Component({
  selector: 'campus-icon',
  template: '<ng-content></ng-content>',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent extends SvgComponent implements OnChanges {
  @Input() svgIcon: string;

  defaultClasses = ['campus-icon'];

  ngOnChanges(changes: SimpleChanges) {
    // Only update the inline SVG image if the inputs changed, to avoid unnecessary DOM operations.
    const svgIconChanges = changes.svgIcon;

    if (svgIconChanges) {
      if (this.svgIcon) {
        const [namespace, imageName] = this._splitSvgImage(this.svgIcon);
        this._svgRegistry
          .getNamedSvgImage(imageName, namespace)
          .pipe(take(1))
          .subscribe(
            (svg) => this._setSvgElement(svg),
            (err: Error) => {
              const errorMessage = `Error retrieving svg icon ${namespace}:${imageName}! ${err.message}`;

              if (this._errorHandler) {
                this._errorHandler.handleError(new Error(errorMessage));
              } else {
                console.error(errorMessage);
              }
            }
          );
      } else if (svgIconChanges.previousValue) {
        this._clearSvgElement();
      }
    }
  }
}
