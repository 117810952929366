import { Component, HostBinding } from '@angular/core';
import { Ripple } from '../../ripple/ripple.decorator';
import { NavigationCoreItemComponent } from '../navigation-core-item.component';

@Component({
  selector: 'campus-navigation-drawer-item, [navigation-drawer-item]',
  templateUrl: './navigation-drawer-item.component.html',
})
@Ripple({ disablePressdown: true })
export class NavigationDrawerItemComponent extends NavigationCoreItemComponent {
  @HostBinding('class')
  defaultClasses = [
    'cds-navigation-drawer-item',
    'group',
    'block',
    'outline-none',
    'cursor-pointer',
    'corner-right-full',
    'decoration-none',
    'color-inherit',
  ];
}
